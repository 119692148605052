import {Grid} from '@mui/material';
import {AsyncState, ProfileView} from '@ozark/common';
import {Loading} from '@ozark/common/components';
import {ProfileCard} from './ProfileCard';

export const ProfileGrid = ({
  profiles,
  isAuthUserAdmin,
}: {
  profiles: AsyncState<ProfileView[]>;
  isAuthUserAdmin?: boolean;
}) => {
  if (profiles.promised) return <Loading />;
  if (!profiles.data) return <div>No users</div>;
  return (
    <Grid container spacing={2}>
      {profiles.data.map(profile => (
        <Grid key={profile.id} item xs={12} sm={6} md={4} lg={3} xl={3}>
          <ProfileCard profile={profile} isAuthUserAdmin={isAuthUserAdmin} />
        </Grid>
      ))}
    </Grid>
  );
};
