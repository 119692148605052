import {Box, Theme} from '@mui/material';
import {SxProps} from '@mui/system';
import {SalesLeadsSourceRatio} from './SalesLeadsSourceRatio';

const styles: Record<string, SxProps<Theme>> = {
  root: {
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
};

export const SalesLeadsSourceRatioPage = () => {
  return (
    <Box sx={styles.root}>
      <SalesLeadsSourceRatio />
    </Box>
  );
};
