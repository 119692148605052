import {
  AssociationApplicationData,
  useAssociatedApplicationsOfApplication,
  useCallable,
} from '@ozark/common';
import {useMemo} from 'react';

type AssociatedApplication = Omit<AssociationApplicationData, 'applicationId'> & {
  id: string;
};

type ApplicationAssociationProps = {
  applicationId: string;
};

export const useApplicationAssociations = ({applicationId}: ApplicationAssociationProps) => {
  const {createAssociationBetweenApplications, removeAssociationBetweenApplications} =
    useCallable();

  const addApplicationAssociation = async (associatedApplicationId: string) => {
    const result = await createAssociationBetweenApplications({
      sourceApplicationId: applicationId,
      destinationApplicationId: associatedApplicationId,
    });

    if (result.status === 'error') {
      throw new Error(result.message);
    }
  };

  const removeApplicationAssociation = async (associatedApplicationId: string) => {
    const result = await removeAssociationBetweenApplications({
      sourceApplicationId: applicationId,
      destinationApplicationId: associatedApplicationId,
    });

    if (result.status === 'error') {
      throw new Error(result.message);
    }
  };

  return {
    addApplicationAssociation,
    removeApplicationAssociation,
  };
};

type ApplicationAssociationsListProps = {
  applicationId: string;
};

export const useApplicationAssociationsList = ({
  applicationId,
}: ApplicationAssociationsListProps) => {
  const {documents: associations} = useAssociatedApplicationsOfApplication(applicationId);
  const associatedApplications = useMemo<AssociatedApplication[]>(() => {
    if (!associations.data) {
      return [];
    }

    return associations.data.reduce<AssociatedApplication[]>((acc, association) => {
      if (association.source.applicationId === applicationId) {
        acc.push({
          ...association.destination,
          id: association.destination.applicationId,
        });
      } else if (association.destination.applicationId === applicationId) {
        acc.push({
          ...association.source,
          id: association.source.applicationId,
        });
      }
      return acc;
    }, []);
  }, [applicationId, associations.data]);

  return {
    data: associatedApplications,
    promised: associations.promised,
  };
};
