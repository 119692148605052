import {Firebase} from '@ozark/common';
import {Column} from '@ozark/common/api/Column';
import {BpsSearchResponse} from '@ozark/functions/src/lib/integrations/lexis/BpsSearchResponse';
import {SmartLinxReportResponse} from '@ozark/functions/src/lib/integrations/lexis/SmartLinxReportResponse';
import {AccordionProps} from '..';
import {TwoStepScorecardReportAccordion} from './TwoStepScorecardReportAccordion';

const formatResults = (peopleSearchData: BpsSearchResponse) => {
  if (peopleSearchData.BpsSearchResponseEx.response.SubjectTotalCount === 0) {
    return [];
  } else {
    return peopleSearchData.BpsSearchResponseEx.response.Records.Record.map(
      (searchRecord, index) => {
        const result = {
          id: index,
          firstName: searchRecord.Name.First,
          lastName: searchRecord.Name.Last,
          middleName: searchRecord.Name.Middle,
          age: searchRecord.Age,
          address: '',
          dob: searchRecord.DOB
            ? `${searchRecord.DOB.Day}/${searchRecord.DOB.Month}/${searchRecord.DOB.Year}`
            : '',
          dateFirstSeen: searchRecord.DateFirstSeen
            ? `${searchRecord.DateFirstSeen.Month}/${searchRecord.DateFirstSeen.Year}`
            : '',
          dateLastSeen: searchRecord.DateLastSeen
            ? `${searchRecord.DateLastSeen.Month}/${searchRecord.DateLastSeen.Year}`
            : '',
          deceased: searchRecord.Deceased,
          uniqueId: searchRecord.UniqueId,
        };

        if (searchRecord.Address?.StreetNumber) {
          result.address += ' ' + searchRecord.Address.StreetNumber;
        }

        if (searchRecord.Address?.StreetName) {
          result.address += ' ' + searchRecord.Address.StreetName;
        }

        if (searchRecord.Address?.StreetSuffix) {
          result.address += ' ' + searchRecord.Address.StreetSuffix;
        }

        if (searchRecord.Address?.UnitDesignation) {
          result.address += ' ' + searchRecord.Address.UnitDesignation;
        }

        if (searchRecord.Address?.UnitNumber) {
          result.address += ' ' + searchRecord.Address.UnitNumber;
        }

        if (searchRecord.Address?.City) {
          result.address += ' ' + searchRecord.Address.City;
        }
        if (searchRecord.Address?.State) {
          result.address += ' ' + searchRecord.Address.State;
        }
        if (searchRecord.Address?.Zip5) {
          result.address += ' ' + searchRecord.Address.Zip5;
        }

        return result;
      }
    );
  }
};

export const LexisNexisPeopleSearchAccordion = ({
  application,
  scorecardReport,
  scores,
  whichExpanded,
  onChange,
}: AccordionProps) => {
  const columns: Column<{}>[] = [
    {id: 'firstName', label: 'First name', width: 130},
    {id: 'lastName', label: 'Last name', width: 130},
    {id: 'middleName', label: 'Middle', width: 100},
    {
      id: 'age',
      label: 'Age',
      numeric: true,
      width: 60,
    },
    {
      id: 'address',
      label: 'Address',
      width: 450,
    },
    {
      id: 'dob',
      label: 'DOB',
      width: 100,
    },
    {
      id: 'dateFirstSeen',
      label: 'First Seen',
      width: 105,
    },
    {
      id: 'dateLastSeen',
      label: 'Last Seen',
      width: 100,
    },
    {
      id: 'deceased',
      label: 'Deceased',
      width: 100,
    },
    {
      id: 'uniqueId',
      label: 'Unique Id',
      width: 160,
    },
  ];

  return (
    <TwoStepScorecardReportAccordion
      application={application}
      scorecardReport={scorecardReport}
      scores={scores}
      whichExpanded={whichExpanded}
      onChange={onChange}
      columns={columns}
      formatResults={results => formatResults(results as BpsSearchResponse)}
      resolveActionPayload={selectedRow => ({
        uniqueId: selectedRow.uniqueId,
        type: 'people',
      })}
      resolveDownloadReportLink={async result => {
        const storageRef = Firebase.storage.ref(
          (result as SmartLinxReportResponse).SmartLinxReportResponseEx.response.Pdf
        );
        return await storageRef.getDownloadURL();
      }}
      confirmationDialogTitle={selectedRow =>
        `Please confirm that you would like to request a Lexis Nexis person report for ${selectedRow.firstName} ${selectedRow.lastName}.`
      }
      step1Action="peopleSearch"
      step1Name="Perform Person Search"
      step2Action="peopleReport"
      step2Name="Select Person"
    />
  );
};
