/* eslint-disable react-hooks/exhaustive-deps */
import {Box, CircularProgress, Stack} from '@mui/material';
import {
  MinMaxBatchInfo,
  SearchCriteria,
  useGroupsAndAgents,
  useInfiniteData,
  useNotification,
} from '@ozark/common';
import {BoxParentHeight, Filter, InfoNoData} from '@ozark/common/components';
import {useCallback, useEffect, useState} from 'react';
import {useStore} from '../../store/helpers';
import {AssociatedApplicationsModal} from './AssociatedApplicationsModal';
import {CardView, RiskOverviewCard} from './Card';
import {RiskOverviewHeader} from './Header';
import {RiskAssigneeFilterProps} from './RiskAssigneeFilter';
import {filtersConfig, RiskOverviewBar} from './RiskOverviewBar';

const ITEMS_PER_PAGE = 10;

const VIEW_LOCAL_STORAGE_KEY = 'riskOverviewView';

const RiskOverview = () => {
  const {profiles} = useStore();
  const showNotification = useNotification();
  const [associatedApplicationsApplicationId, setAssociatedApplicationsApplicationId] =
    useState<string>();
  const {apiClient} = useStore();
  const [merchantsData, setMerchantsData] = useState<MinMaxBatchInfo[]>([]);
  const [isMerchantsDataLoading, setIsMerchantsDataLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [pageNum, setPageNum] = useState(1);
  const [filters, setFilters] = useState<Filter>({});
  const [searchQuery, setSearchQuery] = useState('');
  const [riskAssigneeFilterValue, setRiskAssigneeFilterValue] =
    useState<RiskAssigneeFilterProps['value']>(null);

  const [options, setOptions] = useState<{order: 'asc' | 'desc'; orderBy: string}>({
    order: 'asc',
    orderBy: 'riskPriority',
  });

  const {groups, onGroupChange, filteredAgents, onAgentChange, selectedGroup, selectedAgent} =
    useGroupsAndAgents();

  const [view, setView] = useState<CardView>(
    (localStorage.getItem(VIEW_LOCAL_STORAGE_KEY) as CardView) ?? 'card'
  );

  const getBatches = async (page: number) => {
    setIsMerchantsDataLoading(true);

    try {
      const result = await apiClient.transactions.getMinMaxBatches(
        {limit: ITEMS_PER_PAGE, offset: page, ...options} as SearchCriteria,
        [],
        {
          search: searchQuery,
          groupId: selectedGroup,
          agentId: selectedAgent,
          riskAssigneeUid: riskAssigneeFilterValue?.uid,
          filters: Object.values(filters),
        }
      );

      if (!result) {
        throw new Error('getMinMaxBatches result is null or undefined');
      }

      const merchants = page === 1 ? result.data : [...merchantsData, ...result.data];

      setMerchantsData(merchants);

      setPageNum(page);
      setHasMore(merchants.length < result.totalCount);
    } catch (error) {
      showNotification('error', 'Failed to load merchants data');
      console.error('Failed to load min max batches data with an error:', error);
    } finally {
      setIsMerchantsDataLoading(false);
    }
  };

  const onLoadMore = () => {
    getBatches(pageNum + 1);
  };

  const loadMoreRef = useInfiniteData(onLoadMore, hasMore);

  useEffect(() => {
    setMerchantsData([]);
    getBatches(1);
  }, [selectedAgent, selectedGroup, options, filters, searchQuery, riskAssigneeFilterValue?.id]);

  const onSortChange = (orderBy: string, order: 'desc' | 'asc') => {
    setOptions({...options, order, orderBy});
  };

  const onFilterableValueClick = (key: string, value: string) => {
    const filterOption = filtersConfig.find(filter => filter.id === key);

    if (!filterOption) {
      return;
    }

    if (filters[key]?.value === value) {
      return;
    }

    setFilters({
      ...filters,
      [key]: {
        option: filterOption,
        value,
        operator: filterOption.operators[0],
      },
    });
  };

  const onViewChange = (value: CardView) => {
    setView(value);
    localStorage.setItem(VIEW_LOCAL_STORAGE_KEY, value);
  };

  const onSearchQueryChange = useCallback((value: string) => {
    setSearchQuery(value);
  }, []);

  return (
    <Box sx={{height: '100%', minHeight: '100%', display: 'flex', flexDirection: 'column'}}>
      <RiskOverviewBar
        selectedAgent={selectedAgent}
        selectedGroup={selectedGroup}
        agents={filteredAgents}
        groups={groups?.data}
        onAgentChange={onAgentChange}
        onGroupChange={onGroupChange}
        onSortChange={onSortChange}
        orderBy={options.orderBy}
        order={options.order}
        filters={filters}
        setFilters={setFilters}
        view={view}
        onViewChange={onViewChange}
        searchQuery={searchQuery}
        onSearchQueryChange={onSearchQueryChange}
        riskAssigneeFilterValue={riskAssigneeFilterValue}
        onChangeRiskAssignee={setRiskAssigneeFilterValue}
      />
      <BoxParentHeight>
        {view === 'table' && <RiskOverviewHeader />}

        <Stack spacing={2} sx={[view === 'table' && {minWidth: '1570px'}]}>
          {merchantsData.map((merchant: MinMaxBatchInfo, i: number) => (
            <RiskOverviewCard
              key={merchant.id}
              data={merchant}
              view={view}
              ref={merchantsData.length - 2 === i && hasMore ? loadMoreRef : undefined}
              assigneeProfile={
                merchant.riskAssigneeUid ? profiles.dictionary[merchant.riskAssigneeUid] : null
              }
              onFilterableValueClick={onFilterableValueClick}
              onAssociatedApplicationsOpen={setAssociatedApplicationsApplicationId}
            />
          ))}
        </Stack>

        {isMerchantsDataLoading && (
          <Box sx={{display: 'flex', justifyContent: 'center', p: 3}}>
            <CircularProgress color="primary" />
          </Box>
        )}

        {!isMerchantsDataLoading && !merchantsData?.length && <InfoNoData />}
      </BoxParentHeight>
      <AssociatedApplicationsModal
        applicationId={associatedApplicationsApplicationId}
        onClose={() => setAssociatedApplicationsApplicationId(undefined)}
      />
    </Box>
  );
};

export default RiskOverview;
