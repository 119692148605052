import {Button, CircularProgress} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  ApplicationView,
  Collections,
  createAuditNonce,
  Dispositions,
  Firebase,
  Platforms,
} from '@ozark/common';
import React, {useState} from 'react';
import {useNotification} from '../../../hooks/useNotification';
import {useStore} from '../../../store/helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      paddingTop: theme.spacing(2),
    },
    textarea: {
      height: '100%',
      width: '100%',
      resize: 'none',
    },
    actions: {
      paddingTop: theme.spacing(1),
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

type BoardingOverwritesProps = {
  application: ApplicationView;
};

export const BoardingOverwrites = ({application}: BoardingOverwritesProps) => {
  const classes = useStyles();
  const {authUser} = useStore();
  const showNotification = useNotification();
  const [value, setValue] = useState(
    application.overwrites?.join('\n') ||
      (application.platform === Platforms.evolve5846
        ? 'XMLMerchantDemographicsTE.MerchantParametersTE.sic=\nXMLMerchantBasicInfoTE.assocNum=\nXMLMerchantBasicInfoTE.beMerchNum=\nXMLMerchantDemographicsTE.MerchantProductSetupTE.discoverAccountNumber='
        : '')
  );
  const [loading, setLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  const handleSave = async () => {
    try {
      const overwrites = value ? value.split('\n') : Firebase.FieldValue.delete();
      setLoading(true);
      const ref = Firebase.firestore.collection(Collections.applications).doc(application.id);
      const auditNonce = createAuditNonce(authUser.data!.uid);
      await ref.set({overwrites, auditNonce, updatedAt: new Date()}, {merge: true});
      showNotification('success', 'Save was successful.');
    } catch (err) {
      showNotification('error', 'An error occurred while saving.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <textarea
        className={classes.textarea}
        rows={10}
        value={value}
        onChange={handleChange}
        disabled={application.disposition === Dispositions.boarded}
      />
      <div className={classes.actions}>
        {application.disposition !== Dispositions.boarded && (
          <Button variant="outlined" onClick={handleSave} disabled={loading}>
            {loading && <CircularProgress className={classes.buttonProgress} size={24} />}Save
          </Button>
        )}
      </div>
    </div>
  );
};
