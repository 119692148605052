import {DocumentBase} from '.';
import {BoardingTaskStatus} from '../constants/BoardingTaskStatus';
import {UniversalTimestamp} from './compat';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type BoardingTask = DocumentBase & {
  name: string;
  rawRequest: string;
  rawResponse: string;
  result: any;
  status: BoardingTaskStatus;
  createdAt: UniversalTimestamp;
  completedAt: UniversalTimestamp;
};

export type BoardingTaskView = ViewBase<BoardingTask>;

export const selectBoardingTaskView: ViewSelector<BoardingTaskView, BoardingTask> = snapshot => {
  const boardingTask: BoardingTaskView = {
    ...defaultViewSelector(snapshot),
  };
  return boardingTask;
};
