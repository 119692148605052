import {Typography} from '@mui/material';
import {BoxFlexed} from '../../common';
import {BORDER_HEADER, COLUMN_FIRST_WIDTH, ROW_HEIGHT_HEADER} from '../constants';
import {ColumnHoursHeader} from './ColumnHoursHeader';

export const ColumnHours = () => {
  return (
    <BoxFlexed width={COLUMN_FIRST_WIDTH} flexDirection="column" position="relative">
      <BoxFlexed
        height={ROW_HEIGHT_HEADER + 1}
        bgcolor="#ebeff4"
        position="sticky"
        top={0}
        borderBottom={BORDER_HEADER}
      >
        <Typography
          variant="body2"
          component="div"
          sx={{fontSize: '11px', pl: '6px', alignSelf: 'center', color: 'gray'}}
        >
          {getTimezoneFormatted()}
        </Typography>
      </BoxFlexed>
      {[...Array(24).keys()].map(i => (
        <ColumnHoursHeader key={i} hour={i} />
      ))}
    </BoxFlexed>
  );
};

function getTimezoneFormatted() {
  const timezoneOffset = new Date().getTimezoneOffset() / 60;
  const timezoneIsPositive = timezoneOffset > 0;
  const timezoneOffsetAbs = Math.abs(timezoneOffset);
  const timezoneOffsetFormatted = `GMT${timezoneIsPositive ? '-' : '+'}${timezoneOffsetAbs}`;
  return timezoneOffsetFormatted;
}
