import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {Box, Button, Divider, Tooltip} from '@mui/material';
import {format} from 'date-fns';
import {omit, orderBy} from 'lodash';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {generatePath, Link, useHistory} from 'react-router-dom';
import {
  BoxParentHeight,
  ButtonExportCsv,
  currentFormatter,
  Filter,
  formatDate,
  MERCHANT_PORTFOLIO_DETAILS,
  MidSelect,
  Table,
} from '../..';
import {
  AccountStatus,
  AllMIDs,
  BatchMonitoringRecord,
  BatchMonitoringReport,
  SearchCriteria,
  useApiContainer,
  useMidsContainer,
} from '../../..';
import {Column} from '../../../api/Column';
import {LoadingStatus} from '../../Analytics/common/LoadingStatus';
import {FilterOption, FiltersAddButton, FiltersApplied} from '../../Filters';
import {FRAUD_VDMP} from '../FraudAnalysis/routes';
import {useBatchMonitoringFilters} from './hooks';

const DATE_RANGE_IN_DAYS = 30;

const hasMidPreselected = (mid: string) => mid && mid !== AllMIDs;
const getMidFilter = (mid: string): Filter => {
  const filterOption = filtersConfig.find(fo => fo.id === 'mid');
  if (!filterOption) throw new Error('filter option not found');
  return {
    mid: {
      option: filterOption,
      operator: filterOption.operators[0],
      value: mid,
    },
  };
};

export const FraudAnalysisBatchMonitoring = () => {
  const api = useApiContainer();
  const history = useHistory();
  const [pageConfig, setPageConfig] = useState<SearchCriteria>(pageConfigDefault);
  const {mids, handleSelectMid: setSelectedMid, selectedMid} = useMidsContainer();
  const [shouldShowClosed, setShouldShowClosed] = useState(false);
  const [data, setData] = useState<BatchMonitoringReport | null>(null);
  const [loading, setLoading] = useState(true);
  const {dateRangeFilters} = useBatchMonitoringFilters(
    selectedMid,
    setSelectedMid,
    DATE_RANGE_IN_DAYS
  );

  const [filters, setFilters] = useState<Filter>(
    hasMidPreselected(selectedMid) ? {...getMidFilter(selectedMid)} : {}
  );

  const sortedData = useMemo(() => {
    if (!data) {
      return data;
    }

    const orderedData = orderBy(
      data,
      [row => row[pageConfig.orderBy as keyof typeof row] ?? ''],
      [pageConfig.order.toLowerCase() as 'desc' | 'asc']
    );

    if (shouldShowClosed) {
      return orderedData;
    }

    return orderedData.filter(row => row.accountStatus?.status !== AccountStatus.closed);
  }, [data, pageConfig.order, shouldShowClosed, pageConfig.orderBy]);

  const handleSelectMid = useCallback(
    (mid: string) => {
      setSelectedMid(mid);
      if (mid === AllMIDs) {
        setFilters(omit(filters, 'mid'));
      } else {
        setFilters({
          ...filters,
          ...getMidFilter(mid),
        });
      }
    },
    [filters, setSelectedMid]
  );

  useEffect(() => {
    setFilters({...filters, ...dateRangeFilters});
  }, [dateRangeFilters]);

  useEffect(() => {
    if (!api) {
      return;
    }

    setLoading(true);
    let mounted: boolean = true;

    const loadData = async () => {
      try {
        const mid = selectedMid === AllMIDs ? undefined : selectedMid;
        const result = await api.fraud.getFraudBatchMonitoringReport(Object.values(filters), mid);
        if (mounted) {
          setData(result);
        }
      } catch (error) {
        console.log('Failed at loading Batch Monitoring Report', error);
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    loadData();
    return () => {
      mounted = false;
    };
  }, [filters, selectedMid]);

  const getAllDataForExport = useCallback(async () => {
    if (!api) return [];
    const result = await api.fraud.getFraudBatchMonitoringReport(Object.values(filters));
    return result ?? [];
  }, [filters]);

  const onRowClick = (item: BatchMonitoringRecord) => {
    history.push(FRAUD_VDMP);
    handleSelectMid(item.mid);
  };

  const hasData = Boolean(sortedData?.length);

  const exportFileName = useMemo(() => {
    let fileName = 'batch-monitoring-report';
    const values = Object.values(filters);
    const dateRangeValues = values.find(v => v.option.column === 'dateRange');
    if (!dateRangeValues) {
      return fileName;
    }
    const reportStartDate = dateRangeValues.value?.[0] as Date;
    const reportEndDate = dateRangeValues.value?.[1] as Date;
    return `${fileName}-${format(reportStartDate, 'yyyy-MM-dd')}__${format(
      reportEndDate,
      'yyyy-MM-dd'
    )}`;
  }, [filters]);

  const onShowClosedClick = () => {
    setShouldShowClosed(value => !value);
  };

  return (
    <Box mt={2} display="flex" flexDirection="column" flex={1}>
      <Box
        sx={{
          display: 'flex',
          alignItems: {lg: 'flex-start', xl: 'center'},
          flexDirection: {lg: 'column-reverse', xl: 'row'},
          justifyContent: 'space-between',
        }}
      >
        <FiltersApplied filters={filters} setFilters={setFilters} dateFormat={'MM/dd/yyyy'} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: {lg: 'space-between', xl: 'flex-end'},
            width: {lg: '100%', xl: 'auto'},
            mb: {lg: 1.5, xl: 0},
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FiltersAddButton
              filters={filters}
              setFilters={setFilters}
              filtersConfig={filtersConfig}
            />
            <Divider orientation="vertical" flexItem sx={{mx: 2}} />
            <Tooltip title="Show all open and closed applications">
              <Button
                size="small"
                onClick={onShowClosedClick}
                startIcon={
                  shouldShowClosed ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon />
                }
              >
                Show closed
              </Button>
            </Tooltip>
            <Divider orientation="vertical" flexItem sx={{mx: 2}} />
            <MidSelect
              mids={mids}
              handleSelectMid={handleSelectMid}
              selectedMid={selectedMid}
              width={250}
            />
            <Divider orientation="vertical" flexItem sx={{mx: 2}} />
          </Box>

          <ButtonExportCsv
            filename={exportFileName}
            columnsConfig={columnsConfig}
            rows={sortedData ?? undefined}
            getRows={getAllDataForExport}
          />
        </Box>
      </Box>
      <LoadingStatus loading={loading} hasData={hasData} />

      {!loading && hasData && (
        <BoxParentHeight my={2} sx={{'& table': {backgroundColor: '#fff'}}}>
          <Table
            columns={columnsConfig}
            data={{
              sort: [[pageConfig.orderBy, pageConfig.order as 'DESC' | 'ASC']],
              limit: pageConfig.limit,
              offset: pageConfig.offset,
              totalCount: sortedData?.length ?? 0,
              data: sortedData ?? [],
            }}
            onRetrieveData={setPageConfig}
            onRowClick={onRowClick}
            useOffsetAsPage
            stickyHeader
          />
        </BoxParentHeight>
      )}
    </Box>
  );
};

const pageConfigDefault: SearchCriteria = {
  limit: 100, // pageSize
  offset: 1, // page (offset = page * pageSize 1 based) 0 will produce negative offset
  order: 'asc',
  orderBy: 'doingBusinessAs',
};

const columnsConfig: Column<BatchMonitoringRecord>[] = [
  {
    id: 'mid',
    numeric: false,
    sortable: true,
    export: true,
    label: 'MID',
  },
  {
    id: 'doingBusinessAs',
    numeric: false,
    sortable: true,
    export: true,
    label: 'DBA',
    selector: row => {
      const value = row.doingBusinessAs;
      if (row.applicationId !== null) {
        const route = generatePath(MERCHANT_PORTFOLIO_DETAILS, {id: row.applicationId});
        return (
          <Link to={route} onClick={e => e.stopPropagation()}>
            {value}
          </Link>
        );
      }
      return value;
    },
  },
  {
    id: 'legalBusinessName',
    numeric: false,
    sortable: true,
    export: true,
    label: 'Legal Name',
    width: 180,
  },
  {
    id: 'agentName',
    numeric: false,
    sortable: true,
    export: true,
    label: 'Agent',
    width: 120,
  },
  {
    id: 'isClosed',
    numeric: false,
    sortable: true,
    label: 'Account Status',
    selector: row => row.accountStatus?.status ?? 'N/A',
  },
  {
    id: 'batchCount',
    numeric: true,
    sortable: true,
    export: true,
    label: 'Batch Count',
    headingTooltip: 'Batch count of selected date range',
  },
  {
    id: 'batchAmount',
    numeric: true,
    sortable: true,
    export: true,
    label: 'Batch Amount',
    selector: row => (row.batchAmount === null ? '-' : currentFormatter.format(row.batchAmount)),
    headingTooltip: 'Sum of all batches of selected date range',
  },
  {
    id: 'dateBoarded',
    numeric: false,
    sortable: true,
    export: true,
    label: 'Date Boarded',
    selector: row => formatDate(row.dateBoarded),
  },
  {
    id: 'firstBatchDate',
    numeric: false,
    sortable: true,
    export: true,
    label: 'First Batch Date',
    selector: row => formatDate(row.firstBatchDate),
    headingTooltip: 'First batch date for the life of the account',
  },
  {
    id: 'recentBatchDate',
    numeric: false,
    sortable: true,
    export: true,
    label: 'Recent 30 Day Batch Date',
    selector: row => formatDate(row.recentBatchDate),
    headingTooltip: 'Last batch date of selected date range',
  },
  {
    id: 'lastBatchDate',
    numeric: false,
    sortable: true,
    export: true,
    label: 'Last Batch Date',
    selector: row => formatDate(row.lastBatchDate),
    headingTooltip: 'Last batch date for the life of the account',
  },
  {
    id: 'numberOfDaysSinceLastBatch',
    numeric: true,
    sortable: true,
    export: true,
    width: 150,
    label: 'Number of Days Since Last Batch',
    headingTooltip: 'Today - Last Batch Date in general',
  },
];

const filtersConfig: FilterOption[] = [
  {
    id: 'dateRange',
    column: 'dateRange',
    autoSelect: true,
    label: 'Date Range',
    type: 'dateRange',
    dateFormat: 'MMM dd, yyyy',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'mid',
    column: 'mid',
    label: 'Merchant MID',
    type: 'text',
    hidden: true,
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
];
