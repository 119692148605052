import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton, Link, ListItem, Typography} from '@mui/material';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import {ConfirmationDialog} from '@ozark/common/components';
import {Ref, useState} from 'react';
import {generatePath} from 'react-router';
import {Link as RouteLink} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

type ApplicationData = {
  id: string;
  mid?: string;
  doingBusinessAs: string;
  distinguishableId: string;
};

type Props<T extends ApplicationData> = {
  applications?: T[];
  onRemove?: (application: T) => Promise<void>;
  readOnly?: boolean;
  getItemLinkTitle?: (application: T) => string;
  getItemDetailsRoute?: (application: T) => string;
  listRef?: Ref<HTMLUListElement>;
};

const getApplicationDetailsRoute = (application: {id: string}) =>
  generatePath(ROUTES.APPLICATIONS_EDIT, {id: application.id});

export const ApplicationAssociationsList = <T extends ApplicationData>({
  applications,
  onRemove,
  readOnly,
  getItemLinkTitle,
  getItemDetailsRoute = getApplicationDetailsRoute,
  listRef,
}: Props<T>) => {
  const [applicationToRemove, setApplicationToRemove] = useState<T | null>();

  const onConfirmRemove = applicationToRemove
    ? async () => {
        try {
          await onRemove?.(applicationToRemove);
        } finally {
          setApplicationToRemove(null);
        }
      }
    : null;

  return (
    <>
      <List dense sx={{pt: 1}} ref={listRef}>
        {applications?.map(app => (
          <ListItem
            key={app.id}
            secondaryAction={
              !readOnly &&
              onRemove && (
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => setApplicationToRemove(app)}
                >
                  <DeleteIcon />
                </IconButton>
              )
            }
            sx={{paddingLeft: 0}}
          >
            <ListItemText
              primary={
                <>
                  <Link component={RouteLink} to={getItemDetailsRoute(app)} sx={{mr: 1}}>
                    {getItemLinkTitle
                      ? getItemLinkTitle(app)
                      : `${app.doingBusinessAs ?? ''} (${app?.distinguishableId})`}
                  </Link>
                  {app.mid && (
                    <Typography color="text.secondary" variant="body2">
                      MID: {app.mid}
                    </Typography>
                  )}
                </>
              }
            />
          </ListItem>
        ))}
      </List>

      <ConfirmationDialog
        title="Confirmation"
        message="Are you sure you want to remove association with the application?"
        onClose={() => setApplicationToRemove(null)}
        onConfirm={onConfirmRemove}
      />
    </>
  );
};
