import {ScorecardExecutionStatus} from '@ozark/common';
import {useEffect, useState} from 'react';
import {useRunScores} from './useRunScores';

export function useRunScore(
  setLoading: (loading: boolean) => void,
  handleRunClick: () => void,
  loading: boolean,
  scorecardStatus?: ScorecardExecutionStatus,
  scorecardReportId?: string
) {
  const [reportStatus, setReportStatus] = useState(
    scorecardStatus || ScorecardExecutionStatus.neverRan
  );

  const {onRunnerStatusChange, runAllTicket} = useRunScores();

  // notify global state
  useEffect(() => {
    scorecardReportId && onRunnerStatusChange(scorecardReportId, reportStatus);
    // eslint-disable-next-line
  }, [onRunnerStatusChange, reportStatus]);

  useEffect(() => {
    if (scorecardStatus && reportStatus !== scorecardStatus) {
      console.info(scorecardReportId, 'new status:', scorecardStatus);
      setReportStatus(scorecardStatus);
      if (loading && scorecardStatus !== ScorecardExecutionStatus.running) {
        setLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [reportStatus, scorecardStatus]);

  useEffect(() => {
    if (runAllTicket > 0) {
      console.info(scorecardReportId, 'starting...');
      handleRunClick();
    }
    // eslint-disable-next-line
  }, [runAllTicket, handleRunClick]);

  const reportStatusActual = loading ? ScorecardExecutionStatus.running : reportStatus;
  const isLoading = reportStatusActual === ScorecardExecutionStatus.running;

  return {
    reportStatus: reportStatusActual,
    isLoading,
  };
}
