import {isAfter} from 'date-fns';
import {orderBy} from 'lodash';
import {useEffect, useRef, useState} from 'react';
import {Route} from 'react-router-dom';
import {CalendarEvent, Collections, Firebase} from '../../..';
import {useUserInfo} from '../../../hooks/useUserInfo';
import {BoxFlexed} from '../../common';
import {CalendarEventFormEdit} from '../CalendarEventFormEdit';
import {ROW_HEIGHT, ROW_HEIGHT_HEADER} from '../constants';
import {useWeekDatesContainer} from '../hooks/useWeekDatesContainer';
import {CALENDAR_REMINDER_DETAILS} from '../routes';
import {mapCalendarEvents} from '../utils';
import {ColumnDate} from './ColumnDate';
import {ColumnHours} from './ColumnHours';

export const CalendarViewWeek = () => {
  const {uid} = useUserInfo();
  const {weekDates} = useWeekDatesContainer();
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const needFocus = useRef(false);
  useEffect(() => {
    return Firebase.firestore
      .collection(Collections.calendar)
      .where('createdBy', '==', uid)
      .where('dateTime', '>=', weekDates[0])
      .where('dateTime', '<=', weekDates[weekDates.length - 1])
      .onSnapshot(
        snap => {
          needFocus.current = true;
          if (snap.empty) {
            setEvents([]);
          } else {
            setEvents(mapCalendarEvents(snap));
          }
        },
        err => {
          console.error('useNotificationsData error:', err.message);
          setEvents([]);
        }
      );
  }, [weekDates]);
  useEffect(() => {
    if (needFocus.current) {
      needFocus.current = false;
      autoScrollTo(getImportantEvent(events));
    }
  }, [events]);
  return (
    <>
      <BoxFlexed
        sx={{
          backgroundPositionY: '65px',
          backgroundSize: '100% 60px',
          backgroundImage: 'linear-gradient(to bottom, #e2e2e2 1px, transparent 1px)',
        }}
      >
        <ColumnHours />
        {weekDates.map(date => (
          <ColumnDate key={date.toISOString()} date={date} events={events} />
        ))}
      </BoxFlexed>
      <Route exact path={CALENDAR_REMINDER_DETAILS}>
        <CalendarEventFormEdit />
      </Route>
    </>
  );
};

const scrollTargetIdDefault = 'hour_6';

const getImportantEvent = (events: CalendarEvent[]) => {
  let scrollTargetId: string | undefined = scrollTargetIdDefault;
  if (events.length) {
    const now = new Date();
    const eventsNext = events.filter(event => event.dateTime && isAfter(event.dateTime, now));

    if (eventsNext.length) {
      scrollTargetId = orderBy(eventsNext, 'dateTime')[0]?.id;
    } else {
      scrollTargetId = orderBy(events, 'dateTime', 'desc')[0]?.id;
    }
  }
  return scrollTargetId;
};

const autoScrollTo = (scrollTargetId?: string) => {
  const scrollTarget = scrollTargetId && document.getElementById(scrollTargetId);
  if (!scrollTarget) return;
  const scrollTargetOffset =
    scrollTarget.offsetTop -
    (scrollTargetIdDefault === scrollTargetId ? ROW_HEIGHT_HEADER : ROW_HEIGHT);
  const scrollBox = document.getElementById('calendar-view');
  scrollBox?.scroll({top: scrollTargetOffset, behavior: 'smooth'});
};
