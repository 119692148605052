import {yupResolver} from '@hookform/resolvers/yup';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import {Box, Button, CircularProgress, IconButton, Paper, Tooltip, Typography} from '@mui/material';
import {useUserInfo} from '@ozark/common';
import {Title} from '@ozark/common/components';
import {useForm} from 'react-hook-form';
import {NavLink} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {AnnouncementForm, defaultValues, Schema} from './AnnouncementForm';
import {AnnouncementFormData, useAnnouncementActions} from './hooks';

export const AnnouncementCreate = (): JSX.Element => {
  const {isErpAdmin} = useUserInfo();
  const {loading, sendTestAnnouncement, submitAnnouncementForm} = useAnnouncementActions();

  const hookForm = useForm<AnnouncementFormData>({
    resolver: yupResolver(Schema) as any,
    defaultValues,
    shouldUnregister: true,
  });

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Title
        breadcrumbs={[
          <NavLink to={ROUTES.ANNOUNCEMENTS}>Announcements</NavLink>,
          <Typography>Add new announcement</Typography>,
        ]}
      />
      <Paper sx={{p: 2, position: 'relative'}}>
        <Box sx={{position: 'absolute', right: '10px'}}>
          <IconButton
            onClick={hookForm.handleSubmit(data =>
              sendTestAnnouncement(data as AnnouncementFormData)
            )}
            size="small"
          >
            <Tooltip title="Send test announcement to your user">
              <SendAndArchiveIcon />
            </Tooltip>
          </IconButton>
        </Box>
        <AnnouncementForm hookForm={hookForm} isReadOnly={!isErpAdmin} />
        <Box sx={{textAlign: 'right', pt: 1}}>
          <Button
            variant="outlined"
            color="primary"
            onClick={hookForm.handleSubmit(submitAnnouncementForm)}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : <>Send Announcement</>}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};
