import {LoadingButton} from '@mui/lab';
import {Box, Button, Dialog, DialogContent, DialogTitle} from '@mui/material';
import {Dispositions} from '@ozark/functions/src/constants';
import {useEffect, useState} from 'react';
import {
  ApplicationAutocomplete,
  ApplicationAutocompleteItem,
} from '../Search/ApplicationAutocomplete';

type Props = {
  open: boolean;
  loading?: boolean;
  onSave: (selectedApplication: ApplicationAutocompleteItem) => void;
  onClose: () => void;
  dispositions?: Dispositions[];
  getOptionLabel?: (application: ApplicationAutocompleteItem) => string;
};

export const AddApplicationAssociationDialog = ({
  open,
  loading,
  onSave,
  onClose,
  dispositions,
  getOptionLabel,
}: Props) => {
  const [selectedApplication, setSelectedApplication] =
    useState<ApplicationAutocompleteItem | null>(null);

  const onSaveClick = () => {
    if (!selectedApplication) {
      return;
    }

    onSave(selectedApplication);
  };

  const onCancelClick = () => {
    onClose();
  };

  useEffect(() => {
    if (!open) {
      setSelectedApplication(null);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="application-association-dialog"
      maxWidth="lg"
    >
      <DialogTitle id="application-association-dialog">Application Association</DialogTitle>
      <DialogContent sx={{minWidth: '550px'}}>
        <ApplicationAutocomplete
          selected={selectedApplication}
          onChange={setSelectedApplication}
          textFieldProps={{
            label: 'Application',
          }}
          dispositions={dispositions}
          getOptionLabel={getOptionLabel}
        />
        <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
          <Button onClick={onCancelClick}>Cancel</Button>
          <LoadingButton onClick={onSaveClick} loading={loading} disabled={!selectedApplication}>
            Add
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
