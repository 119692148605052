/* eslint-disable react-hooks/exhaustive-deps */
import {Loading, Resources as ResourcesPage} from '@ozark/common/components';
import {ResourcesApplicationType} from '@ozark/common/components/Resources/types';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';

const Resources = () => {
  const {authProfile, isUserAdmin} = useStore();

  const isAdmin = isUserAdmin();

  if (authProfile.promised) return <Loading />;

  return (
    <ResourcesPage
      appType={ResourcesApplicationType.erp}
      allowEdit={isAdmin}
      historyRoute={ROUTES.RESOURCES}
    />
  );
};

export default Resources;
