import {Box, Divider} from '@mui/material';
import {omit} from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {generatePath, Link} from 'react-router-dom';
import {
  ButtonExportCsv,
  Filter,
  FilterOption,
  MERCHANT_PORTFOLIO_DETAILS,
  MERCHANT_PORTFOLIO_DETAILS_TAB,
  MidSelect,
  Table,
} from '../..';
import {
  AllMIDs,
  formatAsPercent,
  formatterNumber,
  MidAuthDeclines,
  MidAuthDeclinesResult,
  SearchCriteria,
  useApiContainer,
  useMidsContainer,
  useNotification,
} from '../../..';
import {Column} from '../../../api/Column';
import {LoadingStatus} from '../../Analytics/common/LoadingStatus';
import {accountStatusFilter} from '../../Filters/common';
import {FiltersAddButton} from '../../Filters/FiltersAddButton';
import {FiltersApplied} from '../../Filters/FiltersApplied';

const hasMidPreselected = (mid: string) => mid && mid !== AllMIDs;
const getMidFilter = (mid: string): Filter => {
  const filterOption = filtersConfig.find(fo => fo.id === 'mid');
  if (!filterOption) throw new Error('filter option not found');
  return {
    mid: {
      option: filterOption,
      operator: filterOption.operators[0],
      value: mid,
    },
  };
};

export const FraudAnalysisAuthDeclines = () => {
  const api = useApiContainer();
  const [pageConfig, setPageConfig] = useState<SearchCriteria>(pageConfigDefault);
  const {mids, handleSelectMid: setSelectedMid, selectedMid} = useMidsContainer();
  const [filters, setFilters] = useState<Filter>(
    hasMidPreselected(selectedMid) ? {...getMidFilter(selectedMid)} : {}
  );
  const [data, setData] = useState<MidAuthDeclinesResult | null>(null);
  const [loading, setLoading] = useState(true);

  const showNotification = useNotification();

  const getAllDataForExport = useCallback(async () => {
    if (!api) {
      return [];
    }

    try {
      const pageConfigFull: SearchCriteria = {...pageConfigDefault, offset: 0, limit: 0};

      const result = await api.fraud.getFraudAuthorizationsDeclines(pageConfigFull, []);

      return result?.data ?? [];
    } catch (error) {
      console.error('getFraudAuthorizationsDeclines error', error);

      showNotification('error', 'Error while loading report occurred.');

      return [];
    }
  }, [api]);

  const handleSelectMid = useCallback(
    (mid: string) => {
      setSelectedMid(mid);
      if (mid === AllMIDs) {
        setFilters(omit(filters, 'mid'));
      } else {
        setFilters({
          ...filters,
          ...getMidFilter(mid),
        });
      }
    },
    [filters, setSelectedMid]
  );

  useEffect(() => {
    if (!api) return;
    setLoading(true);
    let running: boolean | undefined = true;
    api.fraud
      .getFraudAuthorizationsDeclines(pageConfig, Object.values(filters))
      .then(result => running && setData(result))
      .catch(err => {
        console.error(err);
        running && setData(null);
      })
      .finally(() => running && setLoading(false));
    return () => (running = undefined);
  }, [pageConfig, filters]);

  const hasData = Boolean(data?.data.length);

  return (
    <Box mt={2}>
      <Box display="flex" alignItems="center" justifyContent="flex-end" pb={2}>
        <FiltersApplied filters={filters} setFilters={setFilters} />
        <Box flex={1} />
        <FiltersAddButton filters={filters} setFilters={setFilters} filtersConfig={filtersConfig} />
        <Divider orientation="vertical" flexItem sx={{mx: 2}} />
        <MidSelect mids={mids} handleSelectMid={handleSelectMid} selectedMid={selectedMid} />
        <Divider orientation="vertical" flexItem sx={{mx: 2}} />
        <ButtonExportCsv
          useSelectorMapping
          columnsConfig={columnsConfig}
          filename="auth-declines-report"
          getRows={getAllDataForExport}
          rows={data?.data}
        />
      </Box>
      <LoadingStatus loading={loading} hasData={hasData} />

      {!loading && hasData && (
        <Table
          columns={columnsConfig}
          data={{
            sort: [[pageConfig.orderBy, pageConfig.order as 'DESC' | 'ASC']],
            limit: pageConfig.limit,
            offset: pageConfig.offset,
            totalCount: data?.totalCount ?? 0,
            data: data?.data ?? [],
          }}
          onRetrieveData={setPageConfig}
          paginate
          useOffsetAsPage
        />
      )}
    </Box>
  );
};

const pageConfigDefault: SearchCriteria = {
  limit: 20, // pageSize
  offset: 1, // page (offset = page * pageSize 1 based) 0 will produce negative offset
  order: 'desc',
  orderBy: 'authDeclineRatio',
};
const filtersConfig: FilterOption[] = [
  {
    id: 'dateRange',
    column: 'transactionDate',
    autoSelect: true,
    label: 'Date Range',
    type: 'dateRange',
    dateFormat: 'MMM dd, yyyy',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  accountStatusFilter,
  {
    id: 'mid',
    column: 'mid',
    label: 'Merchant MID',
    type: 'text',
    hidden: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
];

const columnsConfig: Column<MidAuthDeclines>[] = [
  {
    id: 'mid',
    numeric: false,
    sortable: false,
    export: true,
    label: 'MID',
  },
  {
    id: 'dba',
    numeric: false,
    sortable: false,
    export: row => row.dba,
    label: 'DBA',
    selector: row => {
      const value = row.dba;
      if (row.applicationId) {
        const route = generatePath(MERCHANT_PORTFOLIO_DETAILS, {id: row.applicationId});
        return <Link to={route}>{value}</Link>;
      }
      return value;
    },
  },
  {
    id: 'declines',
    numeric: true,
    sortable: true,
    export: row => {
      const declines = Number(row.declines);
      return declines || '-';
    },
    label: 'Declines',
    selector: row => {
      const declines = Number(row.declines);
      if (declines) {
        const value = formatterNumber.format(declines);
        if (row.applicationId) {
          const route = generatePath(MERCHANT_PORTFOLIO_DETAILS_TAB, {
            id: row.applicationId,
            tabValue: 'authorizations',
          });
          return <Link to={`${route}?approvalCode=`}>{value}</Link>;
        }
        return value;
      }
      return '-';
    },
  },
  {
    id: 'authorizations',
    numeric: true,
    sortable: true,
    export: row => Number(row.authorizations) || '-',
    label: 'Authorizations',
    selector: row => {
      const authorizations = Number(row.authorizations);
      if (authorizations) {
        const value = formatterNumber.format(authorizations);
        if (row.applicationId) {
          const route = generatePath(MERCHANT_PORTFOLIO_DETAILS_TAB, {
            id: row.applicationId,
            tabValue: 'authorizations',
          });
          return <Link to={route}>{value}</Link>;
        }
        return value;
      }
      return '-';
    },
  },
  {
    id: 'authDeclineRatio',
    numeric: true,
    sortable: true,
    export: true,
    label: 'Auth Decline Ratio',
    selector: row => {
      const ratio = Number(row.authDeclineRatio);
      return ratio ? formatAsPercent(Number(row.authDeclineRatio)) : 'n/a';
    },
  },
];
