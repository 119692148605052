import DeleteIcon from '@mui/icons-material/Delete';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import {Alert, Box, Button, CircularProgress, IconButton, Tooltip, Typography} from '@mui/material';
import {
  Attachment,
  AttachmentInput,
  Collections,
  Firebase,
  getSanitizedAttachmentFields,
  prepareTextPreview,
  SearchCriteria,
  selectAnnouncementView,
  UniversalSnapshot,
  useInfiniteData,
  useInfiniteSnapshots,
  useUserInfo,
} from '@ozark/common';
import {Column} from '@ozark/common/api/Column';
import {BoxParentHeight, formatDateTime, Loading, Title} from '@ozark/common/components';
import {BoxEllipsis} from '@ozark/common/components/common/BoxEllipsis';
import {NotificationTypeChip} from '@ozark/common/components/Notifications/NotificationTypeChip';
import {Table} from '@ozark/common/components/Table';
import {AnnouncementView} from '@ozark/functions/src/documents';
import React, {Fragment, useCallback, useMemo, useState} from 'react';
import {generatePath, useHistory} from 'react-router';
import {Link} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {ConfirmDeleteAnnouncement} from './Dialogs/ConfirmDeleteAnnouncement';
import {useAnnouncementActions} from './hooks';

export const Announcements = (): JSX.Element => {
  const history = useHistory();
  const {isErpAdmin} = useUserInfo();
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>({
    limit: 20, // page size
    offset: 1, // page
    orderBy: 'createdAt',
    order: 'desc',
  });
  const {documents: announcements, next} = useInfiniteSnapshots(
    Collections.announcements,
    selectAnnouncementView,
    {
      limit: 20,
      order: searchCriteria.order as 'asc' | 'desc',
      orderBy: searchCriteria.orderBy,
      disableUsingCache: true,
    }
  );

  const {
    sendTestAnnouncement,
    announcementToDelete,
    onDeleteAnnouncement,
    deleteAnnouncement,
    onCancelDelete,
  } = useAnnouncementActions();

  const onRowClick = (announcement: AnnouncementView) => {
    history.push(generatePath(ROUTES.ANNOUNCEMENT_DETAILS, {announcementId: announcement.id}));
  };

  const onDelete = () => {
    if (!announcementToDelete) return;

    deleteAnnouncement(announcementToDelete);
    onCancelDelete();
  };

  const announcementsList = useMemo(
    () => Object.values(announcements.data ?? {}),
    [announcements.data]
  );
  const onLoadMore = useCallback(() => {
    const lastAnnouncementInList = announcementsList[announcementsList.length - 1];

    next({startAfter: lastAnnouncementInList?.ref});
  }, [announcementsList, next]);
  const loadMoreRef = useInfiniteData(onLoadMore, announcements.hasNextPage);

  const onSendTestAnnouncement = async (
    event: React.MouseEvent<HTMLButtonElement>,
    announcement: AnnouncementView
  ) => {
    event.stopPropagation();
    let attachments: AttachmentInput[] = [];

    try {
      const announcementAttachments = await Firebase.firestore
        .collection(Collections.announcements)
        .doc(announcement.id)
        .collection(Collections.attachments)
        .get();

      if (!announcementAttachments.empty) {
        attachments = announcementAttachments.docs.map(attachment =>
          getSanitizedAttachmentFields((attachment as UniversalSnapshot<Attachment>).data())
        );
      }
    } catch (e) {
      console.error(
        `Failed to get attachments for announcement ${announcement.id} with an error:`,
        e
      );
    }

    sendTestAnnouncement(announcement, attachments);
  };

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Title breadcrumbs={[<Typography>Announcements</Typography>]} />
      {isErpAdmin && (
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Link to={ROUTES.ANNOUNCEMENT_CREATE}>
            <Button variant="outlined">Add announcement</Button>
          </Link>
        </Box>
      )}
      {!isErpAdmin && (
        <Alert severity="info">
          You currently have <b>read only</b> access to this page.
        </Alert>
      )}
      <Box display="flex" flex="1">
        {announcements.promised && <Loading />}
        {!announcements.promised && !!announcementsList?.length && (
          <BoxParentHeight overflow="unset">
            <Box sx={{overflowY: 'auto', maxHeight: '100%'}}>
              <Table
                columns={columns}
                rows={announcementsList}
                sort={[[searchCriteria.orderBy, searchCriteria.order as 'ASC' | 'DESC']]}
                stickyHeader
                onRowClick={onRowClick}
                onRetrieveData={setSearchCriteria}
                actions={(row: AnnouncementView) => (
                  <Box sx={{textAlign: 'end'}}>
                    {isErpAdmin && (
                      <>
                        <IconButton onClick={e => onSendTestAnnouncement(e, row)} size="small">
                          <Tooltip title="Send test announcement to your user">
                            <SendAndArchiveIcon />
                          </Tooltip>
                        </IconButton>
                        <IconButton
                          onClick={e => {
                            e.stopPropagation();
                            onDeleteAnnouncement(row);
                          }}
                          size="small"
                        >
                          <Tooltip title="Delete announcement">
                            <DeleteIcon />
                          </Tooltip>
                        </IconButton>
                      </>
                    )}
                  </Box>
                )}
                getRowProps={(announcement, index) => ({
                  ref: index === announcementsList.length - 10 ? loadMoreRef : null,
                })}
                getCellProps={column => ({
                  sx: [
                    ellipsisFields.includes(column.id) && {
                      maxWidth: 0,
                    },
                    column.id === 'type' && {
                      width: '150px',
                    },
                    column.id === 'status' && {
                      width: '150px',
                    },
                    column.id === 'sendDate' && {
                      width: '200px',
                    },
                    column.id === 'actions' && {
                      width: '100px',
                    },
                  ],
                })}
              />
            </Box>
          </BoxParentHeight>
        )}

        {!announcements.promised && !announcementsList?.length && (
          <Box
            sx={{
              minHeight: 300,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            {announcements.hasNextPage ? (
              <CircularProgress sx={{ml: 1}} size={28} />
            ) : (
              <>
                <Typography sx={{mb: 2}}>There are no announcements</Typography>
                {isErpAdmin && (
                  <Fragment>
                    <Typography sx={{mb: 3}}>
                      You can create a new announcement for users by using the following button:
                    </Typography>
                    <Link to={ROUTES.ANNOUNCEMENT_CREATE}>
                      <Button variant="outlined">Add announcement</Button>
                    </Link>
                  </Fragment>
                )}
              </>
            )}
          </Box>
        )}
      </Box>
      <ConfirmDeleteAnnouncement
        open={Boolean(announcementToDelete)}
        onCancel={onCancelDelete}
        onDelete={onDelete}
      />
    </Box>
  );
};

const ellipsisFields = ['title', 'text'];

const columns: Column<AnnouncementView>[] = [
  {
    id: 'title',
    label: 'Title',
    sortable: true,
    selector: announcement => (
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        {announcement.isImportantNotification && (
          <Tooltip title="Important notification">
            <PriorityHighIcon
              aria-label="Important notification"
              color="error"
              sx={{mt: '-3px', ml: '-6px'}}
            />
          </Tooltip>
        )}
        <BoxEllipsis sx={{display: 'inline-block'}}>{announcement.title}</BoxEllipsis>
      </Box>
    ),
  },
  {
    id: 'text',
    label: 'Text',
    sortable: true,
    selector: announcement => <BoxEllipsis>{prepareTextPreview(announcement.text)}</BoxEllipsis>,
  },
  {
    id: 'status',
    label: 'Status',
    sortable: true,
  },
  {
    id: 'sendDate',
    label: 'Send Date',
    sortable: true,
    selector: announcement =>
      announcement.sendDate ? formatDateTime(announcement.sendDate.toDate().toUTCString()) : '-',
  },
  {
    id: 'type',
    label: 'Type',
    sortable: true,
    align: 'center',
    selector: announcement => (
      <Box sx={{pr: 2}}>
        <NotificationTypeChip type={announcement.type} />
      </Box>
    ),
  },
];
