import {Box, Divider, Grid, ListSubheader, MenuItem, Typography} from '@mui/material';
import {AutocompleteRenderGroupParams} from '@mui/material/Autocomplete/Autocomplete';
import {
  AgentStatus,
  AgentView,
  AllMIDs,
  AnnouncementView,
  AttachmentView,
  copyWithoutRef,
  getFileNameWithoutExt,
  GroupRole,
  handleAttachmentRemove,
  NotificationType,
  OzarkUserRoles,
  StoragePath,
  uploadFileToStorage,
  useAgents,
  useMidsContainer,
} from '@ozark/common';
import {
  AttachmentsList,
  Checkbox,
  Select,
  TextField,
  TextFieldRichEditor,
} from '@ozark/common/components';
import * as React from 'react';
import {useCallback, useEffect, useMemo} from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';
import {v4 as uuidv4} from 'uuid';
import * as yup from 'yup';
import {useActiveProfiles} from '../../hooks/useActiveProfiles';
import {useStore} from '../../store/helpers';
import {AttachmentInput} from './AttachmentInput';
import {AnnouncementFormData, FormAttachmentItem, MIDOption} from './hooks';
import {SelectUsers} from './SelectUsers';

export const Schema = yup.object().shape({
  title: yup.string().trim().required('Title is required'),
  text: yup.string().trim().required('Text is required'),
  type: yup.string().required('Type is required'),
  link: yup.string().matches(/^(\/)|(https?:\/\/)/, {
    message: 'Link should be internal (/internal-link) or external (https://external.com)',
    excludeEmptyString: true,
  }),
  isImportantNotification: yup.boolean(),
  agents: yup.array().of(yup.object()),
  agentsAllSelected: yup.boolean(),
  merchants: yup.array().of(yup.object()),
  merchantsAllSelected: yup.boolean(),
  groupAdmins: yup.array().of(yup.object()),
  groupAdminsAllSelected: yup.boolean(),
  erpUsers: yup.array().of(yup.object()),
  erpAllSelected: yup.boolean(),
  destinationERPUsersRoles: yup.array().of(yup.string()),
});

export const defaultValues: AnnouncementFormData = {
  title: '',
  text: '',
  type: NotificationType.information,
  link: '',
  isImportantNotification: false,
  agents: [],
  agentsAllSelected: false,
  mids: [],
  midsAllSelected: false,
  groupAdmins: [],
  groupAdminsAllSelected: false,
  erpUsers: [],
  erpUsersAllSelected: false,
  destinationERPUsersRoles: [],
  attachments: [],
};

const notificationTypeOptions = [
  NotificationType.information,
  NotificationType.success,
  NotificationType.warning,
  NotificationType.error,
];

type Props = {
  announcement?: AnnouncementView;
  attachments?: AttachmentView[];
  hookForm: UseFormReturn<AnnouncementFormData, any>;
  isReadOnly: boolean;
};

export const AnnouncementForm = ({
  hookForm,
  announcement,
  attachments,
  isReadOnly,
}: Props): JSX.Element => {
  const {documents: agents} = useAgents();
  const {mids} = useMidsContainer();

  const midsOptions: MIDOption[] = useMemo(() => {
    if (!mids.data) {
      return [];
    }

    return Array.from((mids.data ?? new Map()).keys())
      .filter(mid => mid !== AllMIDs)
      .map(mid => ({
        id: mid,
        dbaName: mids.data?.get(mid) ?? '',
      }));
  }, [mids.data]);

  const {activeAgents, groupAdmins} = useMemo(() => {
    if (!agents.data?.length) {
      return {
        activeAgents: [],
        groupAdmins: [],
      };
    }
    // According to the MUI docs to display agents grouped by group in Autocomplete component, items should be sorted by groupId
    // Docs: https://mui.com/material-ui/react-autocomplete/#grouped
    const activeAgents = agents.data
      .filter(agent => {
        return agent.agentStatus === AgentStatus.active;
      })
      .sort((a, b) => -b.group.name.localeCompare(a.group.name))
      .map(copyWithoutRef);
    const groupAdmins = activeAgents.filter(agent => agent.role === GroupRole.administrator);

    return {
      activeAgents,
      groupAdmins,
    };
  }, [agents.data]);
  const {profiles} = useStore();
  const activeProfiles = useActiveProfiles(profiles);
  const sanitizedERPProfilesOptions = useMemo(
    () => activeProfiles.data?.map(copyWithoutRef),
    [activeProfiles]
  );

  const {
    formState: {errors},
    control,
    register,
    watch,
    reset,
  } = hookForm;

  useEffect(() => {
    if (!announcement) {
      return;
    }
    const {
      erpUsersIds = [],
      agentsIds = [],
      groupAdminsIds = [],
      mids: selectedMids = [],
      ...other
    } = announcement;
    const erpUsersSet = new Set([...erpUsersIds]);
    const agentsSet = new Set([...agentsIds]);
    const groupAdminsSet = new Set([...groupAdminsIds]);

    reset({
      ...copyWithoutRef(defaultValues),
      ...copyWithoutRef(other),
      mids:
        selectedMids.map(mid => ({
          id: mid,
          dbaName: mids.data?.get(mid) ?? '',
        })) ?? [],
      agents:
        activeAgents?.filter(user => agentsSet.has(user.id)).map(user => copyWithoutRef(user)) ??
        [],
      groupAdmins:
        groupAdmins
          ?.filter(user => groupAdminsSet.has(user.id))
          .map(user => copyWithoutRef(user)) ?? [],
      erpUsers:
        activeProfiles.data
          ?.filter(user => erpUsersSet.has(user.id))
          .map(user => copyWithoutRef(user)) ?? [],
      attachments: attachments?.map(attachment => copyWithoutRef(attachment)) ?? [],
    });
  }, [reset, attachments, announcement, mids.data, activeAgents, groupAdmins, activeProfiles.data]);

  const uploadAttachment = useCallback((file: File) => {
    const fileUid = uuidv4();
    return uploadFileToStorage({
      file,
      cloudPath: StoragePath.notifications,
      storageFileName: `${fileUid}-${file.name}`,
      newFileName: getFileNameWithoutExt(file),
    });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="caption" gutterBottom sx={{fontSize: 20}}>
          Announcement
        </Typography>
        <Divider />

        <TextField
          name="title"
          label="Title"
          required
          errors={errors}
          control={control}
          disabled={isReadOnly}
          autoFocus
        />

        <TextFieldRichEditor
          name="text"
          label="Content"
          errors={errors}
          control={control}
          enabled={!isReadOnly}
          required
        />

        <Select
          name="type"
          label="Type"
          required
          disabled={isReadOnly}
          errors={errors}
          control={control}
        >
          {notificationTypeOptions.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>

        <TextField
          name="link"
          label="Link"
          placeholder="External (https://***) or internal link (/internal-link)"
          errors={errors}
          control={control}
          disabled={isReadOnly}
          sx={{mb: 2}}
        />

        <Controller
          name="isImportantNotification"
          control={control}
          render={({field}) => (
            <Checkbox
              {...field}
              register={register}
              errors={errors}
              label="Important Notification"
              disabled={isReadOnly}
              checked={field.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                field.onChange(event.target.checked)
              }
              sx={{mb: 2}}
            />
          )}
        />

        <Box>
          <Typography variant="caption" sx={{display: 'block', fontSize: 16, mb: 2, lineHeight: 1}}>
            Attachments
          </Typography>
          <Controller
            name="attachments"
            control={control}
            render={({field: {onChange, value}}) => (
              <>
                {!isReadOnly && (
                  <AttachmentInput
                    onChange={attachments =>
                      onChange([...(value as FormAttachmentItem[]), ...attachments])
                    }
                    uploadFile={uploadAttachment}
                  />
                )}
                <AttachmentsList
                  attachments={value as FormAttachmentItem[]}
                  isReadOnly={isReadOnly}
                  onDelete={attachment => {
                    onChange(
                      (value as FormAttachmentItem[])?.filter(
                        item => item.cloudPath !== attachment.cloudPath
                      )
                    );
                    if (!(attachment as AttachmentView).id) {
                      handleAttachmentRemove({cloudPath: attachment.cloudPath});
                    }
                  }}
                />
              </>
            )}
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="caption" gutterBottom sx={{fontSize: 20}}>
          Destination Users
        </Typography>
        <Divider sx={{mb: 2}} />

        <SelectUsers
          control={control}
          options={midsOptions ?? []}
          getOptionLabel={option => `${option.dbaName} - ${option.id}`}
          getOptionKey={getOptionKey}
          name="mids"
          title="Merchants"
          isReadOnly={isReadOnly}
          errors={errors}
          watch={watch}
          register={register}
        />

        <SelectUsers
          name="agents"
          title="Agents"
          isReadOnly={isReadOnly}
          control={control}
          options={activeAgents}
          getOptionLabel={option => getOptionLabel(option as {firstName: string; lastName: string})}
          getOptionKey={option => getOptionKey(option as {id: string})}
          groupBy={groupAgentsBy}
          renderGroup={renderAgentGroup}
          errors={errors}
          watch={watch}
          register={register}
        />

        <SelectUsers
          name="groupAdmins"
          title="Group Admins"
          isReadOnly={isReadOnly}
          control={control}
          options={groupAdmins}
          getOptionLabel={option => getOptionLabel(option as {firstName: string; lastName: string})}
          getOptionKey={option => getOptionKey(option as {id: string})}
          groupBy={groupAgentsBy}
          renderGroup={renderAgentGroup}
          errors={errors}
          watch={watch}
          register={register}
        />

        <SelectUsers
          control={control}
          options={sanitizedERPProfilesOptions ?? []}
          getOptionLabel={getOptionLabel}
          getOptionKey={getOptionKey}
          name="erpUsers"
          title="ERP Users"
          isReadOnly={isReadOnly}
          errors={errors}
          watch={watch}
          register={register}
        />

        <SelectUsers
          control={control}
          options={OzarkUserRoles}
          getOptionLabel={option => option}
          getOptionKey={option => option}
          name="destinationERPUsersRoles"
          title="ERP Roles"
          isReadOnly={isReadOnly}
          errors={errors}
          watch={watch}
          register={register}
          hideSelectAll
        />
      </Grid>
    </Grid>
  );
};

const getOptionLabel = (option: {firstName: string; lastName: string}) =>
  `${option.firstName ?? ''} ${option.lastName ?? ''}`;

const getOptionKey = (option: {id: string}) => option.id;

const groupAgentsBy = (agent: AgentView) => `${agent.group.id}:::${agent.group.name}`;

const renderAgentGroup = (params: AutocompleteRenderGroupParams): React.ReactNode => {
  const [groupId, groupName] = params.group.split(':::');

  return (
    <Box key={groupId}>
      <ListSubheader>{groupName}</ListSubheader>
      {params.children}
    </Box>
  );
};
