import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {Box, Button, Divider, Typography} from '@mui/material';
import {addDays} from 'date-fns';
import {useUserInfo} from '../../hooks/useUserInfo';
import {BoxCentered, BoxFlexed} from '../common';
import {SelectViewType} from './CalendarBar/SelectViewType';
import {SelectWeekRange} from './CalendarBar/SelectWeekRange';
import {CalendarEventNewButton} from './CalendarEventNewButton';
import {useWeekDatesContainer} from './hooks/useWeekDatesContainer';
import {getWeekDatesFromDate} from './utils';

export const CalendarViewToolbar = () => {
  const {isPortal} = useUserInfo();
  const {weekDates, setWeekDates} = useWeekDatesContainer();

  return (
    <>
      <BoxFlexed alignItems="center" minHeight="4em" borderBottom="1px solid #0000001f">
        <Box flex={1}>
          <Typography variant="h6" color="secondary">
            Calendar
          </Typography>
        </Box>
        <BoxCentered>
          <SelectWeekRange />
          <Box ml={1}>
            <Button
              sx={{minWidth: 50}}
              variant="text"
              color="primary"
              onClick={() => setWeekDates(getWeekDatesFromDate(addDays(weekDates[0], -7)))}
            >
              <KeyboardArrowLeftIcon />
            </Button>
            <Button
              sx={{minWidth: 50}}
              variant="text"
              color="primary"
              onClick={() => setWeekDates(getWeekDatesFromDate(addDays(weekDates[0], 7)))}
            >
              <KeyboardArrowRightIcon />
            </Button>
          </Box>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ml: 2}}
            onClick={() => setWeekDates(getWeekDatesFromDate(new Date()))}
          >
            Today
          </Button>
          <Box ml={2}>
            <SelectViewType />
          </Box>
        </BoxCentered>
        <Divider flexItem variant="inset" orientation="vertical" sx={{m: 2}} />
        <CalendarEventNewButton />
      </BoxFlexed>
    </>
  );
};
