import {parseWorkingHoursString, WorkingHoursTime} from '@ozark/common';
import {emptyStringToNull} from '@ozark/common/helpers';
import {isAfter} from 'date-fns';
import * as yup from 'yup';

const getTodayTime = (timeString: WorkingHoursTime | undefined) => {
  const today = new Date();
  const dateTime = parseWorkingHoursString(timeString, false);
  if (!dateTime) {
    return null;
  }
  return new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    dateTime.getHours(),
    dateTime.getMinutes()
  );
};

const validTimeYup = yup
  .string()
  .nullable()
  .transform(emptyStringToNull)
  .test({
    name: `isValidTime`,
    message: 'Must be a valid time. Format is hh:mm (a|p)m',
    test: function (value) {
      if (!value) {
        return true;
      }

      const dateTime = parseWorkingHoursString(value, false);
      return !!dateTime;
    },
  });

const getValidEndTimeYup = (startName: string, endName: string) => {
  return validTimeYup.test({
    name: `isValidEndTime`,
    message: 'End time should be greater than start time',
    test: function () {
      const start = this.parent[startName] as WorkingHoursTime | undefined;
      const end = this.parent[endName] as WorkingHoursTime | undefined;

      const timeStart = getTodayTime(start);
      const timeEnd = getTodayTime(end);

      if (!timeStart && !timeEnd) {
        //both values are not set - that is correct state
        return true;
      }
      if (!timeStart || !timeEnd) {
        ////only one value is set - that is not correct
        return false;
      }
      //check time only
      return isAfter(timeEnd, timeStart);
    },
  });
};

export const workingHoursSchema = yup.object({
  mondayStart: validTimeYup,
  mondayEnd: getValidEndTimeYup('mondayStart', 'mondayEnd'),
  tuesdayStart: validTimeYup,
  tuesdayEnd: getValidEndTimeYup('tuesdayStart', 'tuesdayEnd'),
  wednesdayStart: validTimeYup,
  wednesdayEnd: getValidEndTimeYup('wednesdayStart', 'wednesdayEnd'),
  thursdayStart: validTimeYup,
  thursdayEnd: getValidEndTimeYup('thursdayStart', 'thursdayEnd'),
  fridayStart: validTimeYup,
  fridayEnd: getValidEndTimeYup('fridayStart', 'fridayEnd'),
  saturdayStart: validTimeYup,
  saturdayEnd: getValidEndTimeYup('saturdayStart', 'saturdayEnd'),
  sundayStart: validTimeYup,
  sundayEnd: getValidEndTimeYup('sundayStart', 'sundayEnd'),
  timeZoneId: yup.string().required('Time Zone is required'),
  hasNotWorkDays: yup.string().test({
    name: 'hasAnyWorkDay',
    message: 'Please file time for at least one day',
    test: function () {
      const atLeastOneTimeSet =
        !this.parent['mondayStart'] &&
        !this.parent['mondayEnd'] &&
        !this.parent['tuesdayStart'] &&
        !this.parent['tuesdayEnd'] &&
        !this.parent['wednesdayStart'] &&
        !this.parent['wednesdayEnd'] &&
        !this.parent['thursdayStart'] &&
        !this.parent['thursdayEnd'] &&
        !this.parent['fridayStart'] &&
        !this.parent['fridayEnd'] &&
        !this.parent['saturdayStart'] &&
        !this.parent['saturdayEnd'] &&
        !this.parent['sundayStart'] &&
        !this.parent['sundayEnd'];
      return !atLeastOneTimeSet;
    },
  }),
});
