import {Link} from '@mui/material';
import {Title} from '@ozark/common/components';
import {useHistory} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {UtilityList} from './UtilityList';

const Utilities = () => {
  const history = useHistory();

  return (
    <div>
      <Title
        breadcrumbs={[
          <Link component="button" variant="body1" onClick={() => history.push(ROUTES.UTILITIES)}>
            Super Admin Utilities
          </Link>,
        ]}
      ></Title>
      <UtilityList />
    </div>
  );
};

export default Utilities;
