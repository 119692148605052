import {format} from 'date-fns';
import {useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useUrlQuery} from '../../../hooks/useUrlQuery';

const QUERY_DATE_KEY = 'date';

export function useQueryDate() {
  const history = useHistory();
  const urlQuery = useUrlQuery();
  const dateFromUrl = urlQuery.get(QUERY_DATE_KEY);
  const date = useMemo(() => (dateFromUrl ? new Date(dateFromUrl) : undefined), [dateFromUrl]);

  const setDate = (value: Date) => {
    urlQuery.set(QUERY_DATE_KEY, format(value, 'yyyy-MM-dd'));

    history.push({
      search: urlQuery.toString(),
    });
  };

  return {
    date,
    setDate,
  };
}
