import {rename} from '@ozark/common/util';
import {v4 as uuidv4} from 'uuid';
import {
  EquipmentAvailability,
  EquipmentDurationType,
  EquipmentOptions,
  EquipmentViewableBy,
  Platforms,
  ProcessingTypes,
} from '../..';
import {Firebase} from '../../firebase';

export type EquipmentFormData = {
  name: string;
  price: number;
  duration: EquipmentDurationType;
  platform: Platforms;
  isGateway?: boolean;
  viewableBy: EquipmentViewableBy;
  availability?: EquipmentAvailability;
  processingTypes: ProcessingTypes[];
  defaultsAccessories?: string[];
  defaultsAutoBatch?: boolean;
  defaultsAutoBatchTime?: string;
  defaultsCashDiscount?: boolean;
  defaultsCashDiscountPercent?: number;
  defaultsContactless?: boolean;
  defaultsConnectionType?: string;
  defaultsFileType?: string;
  defaultsTipMode?: string;
  defaultsShippingSpeed?: string;
  options?: EquipmentOptions;
  image?: {file: File; dataUrl: string};
  imageUrl: string;
  programs: string[] | null;
  features: string[];
  description: string;
};

export const uploadLogo = (path: string, image: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const file = rename(image, uuidv4());
    const task = Firebase.storage.ref(`${path}/${file.name}`).put(file);
    task.on(
      'state_changed',
      (snapshot: any) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        console.log(`uploading: ${progress}`);
      },
      (err: any) => {
        console.error(err);
        reject(err);
      },
      () => {
        Firebase.storage
          .ref(path)
          .child(file.name)
          .getDownloadURL()
          .then(url => {
            resolve(url);
          });
      }
    );
  });
};
