import {Box, Divider, Typography} from '@mui/material';
import {ApplicationView} from '@ozark/common';

type Props = {
  application: ApplicationView;
};

export const BarTitle = ({application}: Props) => {
  return (
    <Box display="inline-flex" alignItems="center">
      <Box>
        <Typography variant="body1" color="secondary">
          <strong>{application.legalBusinessName}</strong>
        </Typography>
        <Typography variant="caption" color="secondary">
          Legal Business Name
        </Typography>
      </Box>
      <Divider flexItem orientation="vertical" sx={{mx: 2}} />
      <Box>
        <Typography variant="body1" color="secondary">
          <strong>{application.doingBusinessAs}</strong>
        </Typography>
        <Typography variant="caption" color="secondary">
          DBA
        </Typography>
      </Box>
    </Box>
  );
};
