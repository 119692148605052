import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Fade,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import {
  Collections,
  Firebase,
  Group,
  GroupView,
  SubmittedPageSettings as SubmittedPageSettingsType,
  useNotification,
  useUserInfo,
} from '@ozark/common';
import {Fragment, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Form, SubmittedPageSettingsSchema} from './Form';
import {Preview} from './Preview';

type Props = {
  group: GroupView;
};

export const SubmittedPageSettings = ({group}: Props) => {
  const {isErpAdmin} = useUserInfo();
  const showNotification = useNotification();
  const [saving, setSaving] = useState(false);
  const hookForm = useForm<SubmittedPageSettingsType>({
    resolver: yupResolver(SubmittedPageSettingsSchema),
    defaultValues: group.applicationSettings?.submittedPage,
  });

  const {formState, handleSubmit, watch} = hookForm;
  const {isDirty} = formState;
  const [title, subTitle, footerText] = watch(['title', 'subTitle', 'footerText']);

  const onSuccess = async (data: SubmittedPageSettingsType) => {
    setSaving(true);

    try {
      const updatedGroup: Partial<Group> = {
        applicationSettings: {
          submittedPage: data,
        },
      };

      await Firebase.firestore
        .collection(Collections.groups)
        .doc(group.id)
        .set(updatedGroup, {merge: true});

      showNotification('success', 'Submitted page settings are successfully saved');
      setSaving(false);
    } catch (error) {
      console.error('Failed to update group submitted page settings with an error', error);
      showNotification('error', 'Failed to save submitted page settings');
    }
  };

  const readOnly = group.applicationSettings?.enableRegistration || !isErpAdmin;

  return (
    <Box>
      {group.applicationSettings?.enableRegistration && isErpAdmin && (
        <Alert severity="info" sx={{mb: 2}}>
          Merchant registration is <b>enabled</b> for the group. Currently, customization of the
          submitted application page is not supported for the registration mode.
        </Alert>
      )}

      {!isErpAdmin && (
        <Alert severity="info" sx={{mb: 2}}>
          You currently have <b>read only</b> access to this page.
        </Alert>
      )}

      <Grid container spacing={2}>
        <Grid item xl={6} sx={{width: '100%'}}>
          <Paper sx={{p: 2, width: '100%', height: '100%'}}>
            <Box>
              <Typography sx={{fontSize: '1.2rem'}} variant="caption" gutterBottom>
                Submitted Page Settings
              </Typography>
              <Divider />
            </Box>

            <Box>
              <Form group={group} hookForm={hookForm} readOnly={readOnly} />
            </Box>

            <Fade in={isDirty} unmountOnExit={false}>
              <Grid item xs={12}>
                <Divider sx={{mb: 2}} />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={saving}
                    onClick={handleSubmit(onSuccess)}
                  >
                    {saving ? <CircularProgress size={24} /> : <Fragment>Save Changes</Fragment>}
                  </Button>
                </Box>
              </Grid>
            </Fade>
          </Paper>
        </Grid>
        <Grid item xl={6} sx={{width: '100%'}}>
          <Paper
            sx={{p: 2, display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}
          >
            <Box>
              <Typography sx={{fontSize: '1.2rem'}} variant="caption" gutterBottom>
                Preview
              </Typography>
              <Divider />
            </Box>

            <Preview group={group} submittedPageSettings={{title, subTitle, footerText}} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
