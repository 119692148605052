import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import NotesIcon from '@mui/icons-material/Notes';
import PaymentIcon from '@mui/icons-material/Payment';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TollIcon from '@mui/icons-material/Toll';
import {Box, Button, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import {MERCHANT_PORTFOLIO_DETAILS, MERCHANT_PORTFOLIO_DETAILS_TAB} from '@ozark/common/components';
import {ApplicationsIcon} from '@ozark/common/icons';
import {useMidsContainer} from '@ozark/common/store';
import React from 'react';
import {generatePath} from 'react-router';
import {Link} from 'react-router-dom';
import {FRAUD_ANALYSIS} from '../../../../constants/routes';

type Props = {
  applicationId: string;
  mid: string;
  contained?: boolean;
};

export const InformationButton = ({applicationId, mid, contained}: Props): JSX.Element => {
  const {handleSelectMid} = useMidsContainer();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setMenuAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls="information"
        aria-haspopup
        variant={contained ? 'contained' : 'outlined'}
        color="primary"
        onClick={handleMenuClick}
        sx={{mt: 1}}
      >
        Information
      </Button>
      <Menu
        id="information-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        sx={{mt: 0.5}}
      >
        <Box sx={{display: 'flex'}}>
          <Box>
            <MenuItem
              component={Link}
              to={generatePath(MERCHANT_PORTFOLIO_DETAILS, {id: applicationId})}
            >
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </MenuItem>
            <MenuItem
              component={Link}
              to={generatePath(MERCHANT_PORTFOLIO_DETAILS_TAB, {
                id: applicationId,
                tabValue: 'authorizations',
              })}
            >
              <ListItemIcon>
                <AccountBalanceWalletIcon />
              </ListItemIcon>
              <ListItemText primary="Authorizations" />
            </MenuItem>
            <MenuItem
              component={Link}
              to={generatePath(MERCHANT_PORTFOLIO_DETAILS_TAB, {
                id: applicationId,
                tabValue: 'batches',
              })}
            >
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText primary="Batches" />
            </MenuItem>
            <MenuItem
              component={Link}
              to={generatePath(MERCHANT_PORTFOLIO_DETAILS_TAB, {
                id: applicationId,
                tabValue: 'statements',
              })}
            >
              <ListItemIcon>
                <PaymentIcon />
              </ListItemIcon>
              <ListItemText primary="Statements" />
            </MenuItem>
            <MenuItem
              component={Link}
              to={generatePath(MERCHANT_PORTFOLIO_DETAILS_TAB, {
                id: applicationId,
                tabValue: 'attachments',
              })}
            >
              <ListItemIcon>
                <AttachFileIcon />
              </ListItemIcon>
              <ListItemText primary="Attachments" />
            </MenuItem>
            <MenuItem
              component={Link}
              to={generatePath(MERCHANT_PORTFOLIO_DETAILS_TAB, {
                id: applicationId,
                tabValue: 'tickets',
              })}
            >
              <ListItemIcon>
                <AssignmentTurnedInIcon />
              </ListItemIcon>
              <ListItemText primary="Tickets" />
            </MenuItem>
          </Box>
          <Box>
            <MenuItem
              component={Link}
              to={generatePath(MERCHANT_PORTFOLIO_DETAILS_TAB, {
                id: applicationId,
                tabValue: 'applications',
              })}
            >
              <ListItemIcon>
                <ApplicationsIcon />
              </ListItemIcon>
              <ListItemText primary="Applications" />
            </MenuItem>
            <MenuItem
              component={Link}
              to={generatePath(MERCHANT_PORTFOLIO_DETAILS_TAB, {
                id: applicationId,
                tabValue: 'transactions',
              })}
            >
              <ListItemIcon>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText primary="Transactions" />
            </MenuItem>
            <MenuItem
              component={Link}
              to={generatePath(MERCHANT_PORTFOLIO_DETAILS_TAB, {
                id: applicationId,
                tabValue: 'deposits',
              })}
            >
              <ListItemIcon>
                <PaymentIcon />
              </ListItemIcon>
              <ListItemText primary="Deposits" />
            </MenuItem>
            <MenuItem
              component={Link}
              to={generatePath(MERCHANT_PORTFOLIO_DETAILS_TAB, {
                id: applicationId,
                tabValue: 'chargebacks',
              })}
            >
              <ListItemIcon>
                <TollIcon />
              </ListItemIcon>
              <ListItemText primary="Disputes" />
            </MenuItem>
            <MenuItem
              component={Link}
              to={generatePath(MERCHANT_PORTFOLIO_DETAILS_TAB, {
                id: applicationId,
                tabValue: 'notes',
              })}
            >
              <ListItemIcon>
                <NotesIcon />
              </ListItemIcon>
              <ListItemText primary="Notes" />
            </MenuItem>
            <MenuItem
              component={Link}
              to={generatePath(FRAUD_ANALYSIS, {
                id: applicationId,
                tabValue: 'notes',
              })}
              onClick={() => handleSelectMid(mid)}
            >
              <ListItemIcon>
                <PersonSearchIcon />
              </ListItemIcon>
              <ListItemText primary="Fraud Analysis Tools" />
            </MenuItem>
          </Box>
        </Box>
      </Menu>
    </>
  );
};
