import {Box, Divider} from '@mui/material';
import {
  FraudMidBinRecord,
  FraudMidBinReport,
} from '@ozark/functions/src/functions/express/private/types';
import {useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
  ButtonExportCsv,
  Filter,
  FilterOption,
  FiltersAddButton,
  FiltersApplied,
  Table,
} from '../..';
import {SearchCriteria, useApiContainer} from '../../..';
import {Column} from '../../../api/Column';
import {LoadingStatus} from '../../Analytics/common/LoadingStatus';
import {useBreadcrumbsContainer} from '../../common/Breadcrumbs';

export const FraudAnalysisSameBinDetails = () => {
  const api = useApiContainer();
  const {mid} = useParams<{mid: string}>();
  const [loading, setLoading] = useState(true);
  const [pageConfig, setPageConfig] = useState<SearchCriteria>(pageConfigDefault);
  const [report, setReport] = useState<FraudMidBinReport | null>(null);
  const [filters, setFilters] = useState<Filter>({});
  const hasData = Boolean(report?.data.length);
  const {setBreadcrumbs} = useBreadcrumbsContainer();

  const getAllDataForExport = useCallback(async () => {
    if (!api) return [];
    const pageConfigFull: SearchCriteria = {...pageConfigDefault, offset: 0, limit: 0};
    const result = await api.fraud.getFraudSameBinReportDetails(mid, pageConfigFull, []);
    return result?.data ?? [];
  }, [pageConfig, mid]);

  useEffect(() => {
    if (!api) return;
    setLoading(true);
    let isMounted: boolean | undefined = true;
    api.fraud
      .getFraudSameBinReportDetails(mid, pageConfig, Object.values(filters))
      .then(result => isMounted && setReport(result))
      .catch(err => {
        console.error(err);
        isMounted && setReport(null);
      })
      .finally(() => isMounted && setLoading(false));
    return () => (isMounted = undefined);
  }, [pageConfig, filters]);

  useEffect(() => {
    setBreadcrumbs([
      {text: 'Fraud Analysis', url: '/fraud-analysis'},
      {text: 'Same BIN Report', url: '/fraud-analysis/same-bin'},
      {text: mid, url: ''},
    ]);
  }, []);

  return (
    <Box mt={2}>
      <Box display="flex" alignItems="center" justifyContent="flex-end" pb={2}>
        <FiltersApplied filters={filters} setFilters={setFilters} />
        <Box flex={1} />
        <FiltersAddButton filters={filters} setFilters={setFilters} filtersConfig={filtersConfig} />
        <Divider orientation="vertical" flexItem sx={{mx: 2}} />
        <ButtonExportCsv
          filename="fraud-same-bin-report"
          rows={report?.data}
          getRows={getAllDataForExport}
          columnsConfig={columnsConfig}
          useSelectorMapping
        />
      </Box>
      <LoadingStatus loading={loading} hasData={hasData} />

      {!loading && hasData && (
        <Table
          columns={columnsConfig}
          data={{
            sort: [[pageConfig.orderBy, pageConfig.order as 'DESC' | 'ASC']],
            limit: pageConfig.limit,
            offset: pageConfig.offset,
            totalCount: report?.totalCount ?? 0,
            data: report?.data ?? [],
          }}
          onRetrieveData={setPageConfig}
          paginate
          useOffsetAsPage
        />
      )}
    </Box>
  );
};

const pageConfigDefault: SearchCriteria = {
  limit: 20, // pageSize
  offset: 1, // page (offset = page * pageSize 1 based) 0 will produce negative offset
  order: 'desc',
  orderBy: 'transactionsCount',
};
const filtersConfig: FilterOption[] = [
  {
    id: 'dateRange',
    column: 'transactionDate',
    autoSelect: true,
    label: 'Date Range',
    type: 'dateRange',
    dateFormat: 'MMM dd, yyyy',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'date',
    column: 'transactionDate',
    label: 'Date',
    type: 'date',
    operators: [
      {
        id: '__between',
        label: 'equals',
      },
    ],
  },
  {
    id: 'prepaidCard',
    column: 'prepaidCard',
    label: 'Prepaid Card',
    type: 'list',
    options: [
      {
        key: 'Yes',
        value: 'Yes',
      },
      {
        key: 'No',
        value: 'No',
      },
    ],
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'international',
    column: 'international',
    label: 'International',
    type: 'list',
    options: [
      {
        key: 'Yes',
        value: 'Yes',
      },
      {
        key: 'No',
        value: 'No',
      },
    ],
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'prohibited',
    column: 'prohibited',
    label: 'Prohibited',
    type: 'list',
    options: [
      {
        key: 'Yes',
        value: 'Yes',
      },
      {
        key: 'No',
        value: 'No',
      },
    ],
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
];
const columnsConfig: Column<FraudMidBinRecord>[] = [
  {
    id: 'bin',
    label: 'BIN',
    numeric: false,
    sortable: true,
    export: true,
  },
  {
    id: 'bankName',
    label: 'Bank Name',
    numeric: false,
    sortable: true,
    export: true,
    selector: row => row.bankName ?? 'n/a',
  },
  {
    id: 'prepaidCard',
    label: 'Prepaid Card',
    numeric: false,
    sortable: true,
    export: true,
    selector: row => (row.prepaidCard ? 'Yes' : 'No'),
  },
  {
    id: 'international',
    label: 'International',
    numeric: false,
    sortable: true,
    export: true,
    selector: row => (row.international ? 'Yes' : 'No'),
  },
  {
    id: 'prohibited',
    label: 'Prohibited',
    numeric: true,
    sortable: true,
    export: true,
    selector: row => (row.prohibited ? 'Yes' : 'No'),
  },
  {
    id: 'transactionsCount',
    label: 'Number of Transactions',
    numeric: true,
    sortable: true,
    export: true,
  },
];
