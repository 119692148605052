import {TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {AutocompleteProps} from '@mui/material/Autocomplete/Autocomplete';
import {TextFieldProps} from '@mui/material/TextField/TextField';
import * as React from 'react';
import {useMemo} from 'react';

type Option = {
  id: string;
  firstName: string;
  lastName: string;
};

type Props<T extends Option> = {
  options: T[];
  value: T | null;
  onChange: (event: React.SyntheticEvent, newValue: T | null) => void;
  textFieldProps?: Partial<TextFieldProps>;
} & Partial<AutocompleteProps<T, undefined, undefined, undefined>>;

const USER_FILTER_DEFAULT_OPTION: Option = {id: 'all', firstName: 'All', lastName: ''};

export const UserFilter = <T extends Option>({
  options,
  value,
  onChange,
  textFieldProps,
  ...otherProps
}: Props<T>) => {
  const autocompleteOptions = useMemo(() => {
    return [USER_FILTER_DEFAULT_OPTION, ...options] as T[];
  }, [options]);

  const onAutocompleteChange = (event: React.SyntheticEvent, newValue: T | null) => {
    onChange(event, newValue?.id === USER_FILTER_DEFAULT_OPTION.id ? null : newValue);
  };

  return (
    <Autocomplete
      id="user-filter"
      onChange={onAutocompleteChange}
      value={(value ?? USER_FILTER_DEFAULT_OPTION) as T | null}
      options={autocompleteOptions}
      autoHighlight
      getOptionLabel={option => `${option.firstName ?? ''} ${option.lastName ?? ''}`}
      size="small"
      blurOnSelect
      renderInput={params => (
        <TextField
          {...params}
          variant="standard"
          sx={{
            '&&& .MuiInput-underline:before': {
              borderBottom: 'none',
            },
            '&& .MuiInput-underline:after': {
              borderBottom: 'none',
            },
          }}
          {...(textFieldProps ?? {})}
        />
      )}
      {...otherProps}
    />
  );
};
