import PhoneNumber from 'awesome-phonenumber';
import * as yup from 'yup';

export const getAgentPhoneSchema = () => {
  return yup.string().when('phone', value => {
    if (value) {
      return yup
        .string()
        .min(12, 'Must be a valid phone number') // validating against e164 format (+18002333333)
        .transform(value => {
          return value ? new PhoneNumber(value, 'US').getNumber('e164') : value;
        })
        .required('Phone is required');
    }
    return yup
      .string()
      .transform(value => {
        return value ? new PhoneNumber(value, 'US').getNumber('e164') : value;
      })
      .nullable();
  });
};
