import {List} from '@mui/material';
import {useSalesLeadsEvents} from '../../../../hooks/useSalesLeadsEvents';
import {CalendarEventInfo} from './CalendarEventInfo';

export const CalendarEventsInfo = () => {
  const {calendarEvents} = useSalesLeadsEvents();
  return (
    <List dense sx={{pt: 1}}>
      {calendarEvents?.map(event => (
        <CalendarEventInfo key={event.id} calendarEvent={event} />
      ))}
    </List>
  );
};
