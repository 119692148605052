import {Grid} from '@mui/material';
import {LoadingContainer, Square} from '@ozark/common/components';
import {LeadsClosingRatios} from '@ozark/functions/src/functions/express/private/types';

interface Props {
  isLoading: boolean;
  ratios: LeadsClosingRatios | null | undefined;
}
export const RatioTiles = ({isLoading, ratios}: Props) => {
  return (
    <LoadingContainer loading={isLoading} minHeight={250}>
      <Grid container spacing={2} direction="row" alignItems="stretch">
        <Grid item xs={4}>
          <Square lines={{'Leads Received': ratios ? ratios.leadsAmount : 'N/A'}} center />
        </Grid>
        <Grid item xs={4}>
          <Square
            lines={{'Submitted Apps': ratios ? ratios.submittedApplicationsAmount : 'N/A'}}
            center
          />
        </Grid>
        <Grid item xs={4}>
          <Square
            lines={{'Boarded Apps': ratios ? ratios.boardedApplicationsAmount : 'N/A'}}
            center
          />
        </Grid>
        <Grid item xs={6}>
          <Square
            lines={{'Submitted to UW': ratios ? `${ratios.submittedClosingRatio}%` : 'N/A'}}
            center
          />
        </Grid>
        <Grid item xs={6}>
          <Square lines={{Boarded: ratios ? `${ratios.boardedClosingRatio}%` : 'N/A'}} center />
        </Grid>
      </Grid>
    </LoadingContainer>
  );
};
