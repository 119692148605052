import Hidden from '@mui/material/Hidden';
import makeStyles from '@mui/styles/makeStyles';
import {LayoutComponent} from '@ozark/common';
import {Copyright} from '@ozark/common/components';
import {useState} from 'react';
import {DEFAULT_GROUP} from '../../constants/group';
import Header from './Header';
import Navigator from './Navigator';

export const drawerWidth = 256;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    padding: theme.spacing(1, 3, 3),
    flex: 1,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  },
}));

export const MainLayout: LayoutComponent = ({children}) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        <Hidden mdUp implementation="js">
          <Navigator
            PaperProps={{style: {width: drawerWidth}}}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        <Hidden mdDown implementation="css">
          <Navigator PaperProps={{style: {width: drawerWidth}}} />
        </Hidden>
      </nav>
      <div className={classes.app}>
        <Header onDrawerToggle={handleDrawerToggle} />
        <main className={classes.main}>{children}</main>
        <footer className={classes.footer}>
          <Copyright groupName={DEFAULT_GROUP.name} groupDomain={DEFAULT_GROUP.portalDomain} />
        </footer>
      </div>
    </div>
  );
};
