import {
  Avatar,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ApplicationView, AuditView, EncryptedField, TSYS_ACQ_AUDIT_ID} from '@ozark/common';
import {format} from 'date-fns';
import React, {Fragment, useCallback, useState} from 'react';
import {DEFAULT_GROUP} from '../../../constants/group';
import {useAudits} from '../../../hooks/useAudits';
import {useStore} from '../../../store/helpers';
import {Diff} from './Diff';

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      width: '100%',
    },
    empty: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

type ChangeLogListProps = {
  application: ApplicationView;
};

type UserInfo = {
  displayName: string;
  photoUrl?: string;
  role: string;
};

const getEncryptedFieldHumanName = (encryptedField: EncryptedField) => {
  switch (encryptedField) {
    case EncryptedField.socialSecurityNumber:
      return 'Social Security #';
    case EncryptedField.bankAccountNumber:
      return 'Bank Account #';
    case EncryptedField.routingNumber:
      return 'Bank Routing #';
    case EncryptedField.federalTaxId:
      return 'Tax ID';
    default:
      return encryptedField;
  }
};

const getActionDescription = (audit: AuditView): string => {
  if (audit.action === 'Call' && audit.resource === 'decryptApplicationField') {
    return `Viewed Field "${getEncryptedFieldHumanName(audit.meta.encryptedField)}"`;
  }
  return `${audit.action}${audit.resource ? audit.resource : ''}`;
};

export const ChangeLogList = ({application}: ChangeLogListProps) => {
  const classes = useStyles();
  const {profiles, agents, merchants} = useStore();
  const {audits} = useAudits(application.id);
  const [currentAudit, setCurrentAudit] = useState<AuditView | null>(null);
  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null);

  const getUserInfo = useCallback(
    (audit: AuditView): UserInfo | null => {
      if (!audit.uid) {
        return null;
      }
      const profile = profiles.dictionary[audit.uid];
      if (profile) {
        return {
          displayName: profile.displayName,
          photoUrl: profile.photoUrl,
          role: `ERP ${profile.role}`,
        };
      }

      const agent = agents.dictionary[audit.uid];
      if (agent) {
        return {
          displayName: `${agent.firstName ?? ''} ${agent.lastName ?? ''}`.trim(),
          photoUrl: agent.photoUrl,
          role: `Agent ${agent.role}`,
        };
      }

      const merchant = merchants.dictionary[audit.uid];
      if (merchant) {
        return {
          displayName: `${merchant.firstName ?? ''} ${merchant.lastName ?? ''}`.trim(),
          photoUrl: merchant.photoUrl,
          role: `Merchant`,
        };
      }

      return null;
    },
    [agents.dictionary, merchants.dictionary, profiles.dictionary]
  );

  const handleClick =
    (audit: AuditView) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setAnchorElement(event.currentTarget);
      setCurrentAudit(audit);
    };

  const handleClose = () => {
    setAnchorElement(null);
  };

  if (audits.promised)
    return (
      <div className={classes.empty}>
        <CircularProgress />
      </div>
    );

  return (
    <Fragment>
      {audits.data && audits.data?.length > 0 ? (
        <List className={classes.list} dense>
          {audits.data?.map((audit: AuditView) => {
            const userInfo = getUserInfo(audit);
            return (
              <ListItem
                button
                disabled={Boolean(audit.action !== 'Update')}
                onClick={audit.action === 'Update' ? handleClick(audit) : undefined}
              >
                <ListItemIcon>
                  {userInfo ? (
                    <Avatar alt={userInfo.displayName} src={userInfo.photoUrl}></Avatar>
                  ) : (
                    <Avatar>?</Avatar>
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={`${getActionDescription(audit)} at ${format(
                    audit.createdAt?.toDate?.(),
                    'MM/dd/yyyy h:mm a'
                  )}`}
                  secondary={
                    userInfo
                      ? `by ${userInfo.displayName} - ${userInfo.role}`
                      : audit.uid === TSYS_ACQ_AUDIT_ID
                      ? 'TSYS ACQ File'
                      : `by ${DEFAULT_GROUP.name} System`
                  }
                />
                <ListItemSecondaryAction></ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <div className={classes.empty}>
          <Typography>No Change History</Typography>
        </div>
      )}
      <Diff anchorElement={anchorElement} auditView={currentAudit} onClose={handleClose} />
    </Fragment>
  );
};
