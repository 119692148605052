import {AllMIDs} from '@ozark/common';
import {addDays, subDays} from 'date-fns';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useQueryDate} from '../../Analytics/common/useQueryDate';
import {getMidFilter, useMidFilter} from '../../MidSelect/useMidFilter';
import {DATE_FORMAT, Filter} from '../../reports';

export const useFixedDateRangeFilters = (dateRangeInDays: number) => {
  const {date: startDate, setDate: setStartDate} = useQueryDate();

  const appliedFilters = useMemo<Filter>(() => {
    if (!startDate) {
      return {} as Filter;
    }

    const endDate = addDays(startDate, dateRangeInDays);

    return getAppliedFilters(startDate, endDate);
  }, [startDate, dateRangeInDays]);

  const resetStartDate = useCallback(() => {
    setStartDate(subDays(new Date(), dateRangeInDays));
  }, [startDate, dateRangeInDays]);

  useEffect(() => {
    if (!startDate) {
      resetStartDate();
    }
  }, [startDate, resetStartDate]);

  return {
    startDate,
    fixedDateRangeFilters: appliedFilters,
    resetStartDate,
  };
};

const getAppliedFilters = (startDate: Date, endDate: Date): Filter => ({
  dateRange: {
    option: {
      id: 'dateRange',
      column: 'dateRange',
      autoSelect: true,
      label: 'Date Range',
      type: 'dateRange',
      dateFormat: DATE_FORMAT,
      operators: [
        {
          id: '__between',
          label: 'is between',
        },
      ],
    },
    operator: {
      id: '__between',
      label: 'is between',
    },
    value: [startDate, endDate],
  },
});

export const useBatchMonitoringFilters = (
  selectedMid: string,
  setSelectedMid: (value: string) => void,
  dateRangeInDays: number
) => {
  const [filters, setFilters] = useState<Filter>(
    selectedMid !== AllMIDs ? getMidFilter(selectedMid) : {}
  );
  const handleSelectMid = useMidFilter(setFilters, setSelectedMid);
  const {startDate, fixedDateRangeFilters, resetStartDate} =
    useFixedDateRangeFilters(dateRangeInDays);

  const onResetFilters = (newFilters: Filter) => {
    if (!newFilters.dateRange) {
      resetStartDate();
      return;
    }

    if (!newFilters.mid) {
      handleSelectMid(AllMIDs);
      return;
    }

    setFilters(filters);
  };

  const allFilters = {...fixedDateRangeFilters, ...filters};

  return {
    startDate,
    filters: allFilters,
    dateRangeFilters: fixedDateRangeFilters,
    onResetFilters,
    handleSelectMid,
  };
};
