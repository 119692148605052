import {SearchableProfileView} from '@ozark/common';
import {Column} from '@ozark/common/api/Column';
import {DATETIME_FORMAT} from '@ozark/common/components';
import {LeadExportRow} from '@ozark/functions/src/functions/express/private/types';
import {format, utcToZonedTime} from 'date-fns-tz';
import {getAssigneeText} from '../utils';

const getFormattedDate = (date: Date) => {
  const localTime = utcToZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
  return format(localTime, DATETIME_FORMAT);
};
export const getSalesLeadsExportConfig = (profilesDictionary: {
  [p: string]: SearchableProfileView;
}): Column<LeadExportRow>[] => [
  {
    id: 'createdAt',
    numeric: false,
    sortable: false,
    label: 'Date Lead Created',
    export: ({createdAt}) => getFormattedDate(createdAt),
  },
  {
    id: 'source',
    numeric: false,
    sortable: false,
    label: 'Source',
    export: ({source}) => source ?? '',
  },
  {
    id: 'campaign',
    numeric: false,
    sortable: false,
    label: 'Campaign',
    export: ({campaign}) => campaign ?? '',
  },
  {
    id: 'salesDisposition',
    numeric: false,
    sortable: false,
    label: 'Disposition',
    export: true,
  },
  {
    id: 'firstName',
    numeric: false,
    sortable: false,
    label: 'First Name',
    export: ({firstName}) => firstName ?? '',
  },
  {
    id: 'lastName',
    numeric: false,
    sortable: false,
    label: 'Last Name',
    export: ({lastName}) => lastName ?? '',
  },
  {
    id: 'email',
    numeric: false,
    sortable: false,
    label: 'Email',
    export: ({email}) => email ?? '',
  },
  {
    id: 'phone',
    numeric: false,
    sortable: false,
    label: 'Phone',
    export: ({phone}) => phone ?? '',
  },
  {
    id: 'businessName',
    numeric: false,
    sortable: false,
    label: 'Business Name',
    export: ({businessName}) => businessName ?? '',
  },
  {
    id: 'appDistinguishableId',
    numeric: false,
    sortable: false,
    label: 'Associated Application',
    export: ({appDistinguishableIds}) => appDistinguishableIds ?? '',
  },
  {
    id: 'assigneeId',
    numeric: false,
    sortable: false,
    label: 'Assignee',
    export: row => getAssigneeText(profilesDictionary, row.assigneeId),
  },
];
