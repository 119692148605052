import {Grid, Paper, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {AttachmentView} from '@ozark/common';
import {format} from 'date-fns';
import prettyBytes from 'pretty-bytes';
import {AttachmentExporter} from '../AttachmentExporter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    title: {
      flex: '1 1 100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
  })
);

export const AttachmentExplorer = ({
  applicationId,
  attachments,
}: {
  applicationId: string;
  attachments: (AttachmentView & {downloadUrl: string; size: number; contentType: string})[];
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Table className={classes.table} aria-label="attachments table">
            <TableHead>
              <TableRow>
                <TableCell style={{width: '66%'}}>File Name</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Date Created</TableCell>
                <TableCell>Uploaded By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attachments.map(e => (
                <TableRow>
                  <TableCell>
                    <a href={e.downloadUrl} target="_blank" rel="noreferrer">
                      {e.name}
                    </a>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">{prettyBytes(e.size)}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">{e.contentType}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">
                      {format(e.createdAt.toDate(), 'MM/dd/yyyy h:mm a')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">
                      {e.author ? e.author.name : 'System Generated'}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <AttachmentExporter applicationId={applicationId} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  );
};
