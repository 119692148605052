import {Button} from '@mui/material';
import {useState} from 'react';
import {
  CalendarEvent,
  CalendarEventAssociation,
  CalendarEventParticipant,
  CalendarEventType,
  Collections,
  Firebase,
  useNotification,
} from '../..';
import {useUserInfo} from '../../hooks/useUserInfo';
import {CalendarEventForm} from './CalendarEventForm';
import {calendarEventAdd, getNewCalendarEventDateTime} from './utils';

type Props = {
  title?: string;
  type?: CalendarEventType;
  association?: CalendarEventAssociation;
};

export const CalendarEventNewButton = ({
  title = 'New Reminder',
  type = 'Reminder',
  association,
}: Props) => {
  const [open, setOpen] = useState(false);
  const {uid, userRoleName, userDisplayName} = useUserInfo();
  const showNotification = useNotification();

  // creator participant
  const defaultParticipants: CalendarEventParticipant[] = [
    {id: uid, name: userDisplayName, role: userRoleName, isOrganizer: true},
  ];

  const event: CalendarEvent = {
    participants: defaultParticipants,
    reminderOffset: 0,
    dateTime: getNewCalendarEventDateTime(),
    type,
    association,
  };

  const handleCalendarEventAdd = async (calendarEvent: CalendarEvent) => {
    setOpen(false);
    try {
      // get associationId
      if (calendarEvent.association?.distinguishableId && !calendarEvent.association?.id) {
        switch (calendarEvent.association.type) {
          case 'Ticket':
            const ticketsSnap = await Firebase.firestore
              .collection(Collections.tickets)
              .where('distinguishableId', '==', calendarEvent.association.distinguishableId)
              .get();

            if (ticketsSnap.size) {
              calendarEvent.association.id = ticketsSnap.docs[0].id;
            }

            break;
          case 'Application':
            const appsSnap = await Firebase.firestore
              .collection(Collections.applications)
              .where('distinguishableId', '==', calendarEvent.association.distinguishableId)
              .get();

            if (appsSnap.size) {
              calendarEvent.association.id = appsSnap.docs[0].id;
            }
            break;

          case 'User':
            calendarEvent.association.id = calendarEvent.association.distinguishableId;
            break;

          case 'Lead':
            calendarEvent.association.id = calendarEvent.association.distinguishableId;
            break;
          default:
            throw new Error(`invalid association type ${calendarEvent.association.type}`);
        }
      }

      const eventId = await calendarEventAdd({
        ...calendarEvent,
        createdBy: uid,
        createdAt: new Date(),
      });
      showNotification('success', 'New Event has been successfully added.');
      return eventId;
    } catch (error) {
      console.error(error);
      showNotification(
        'error',
        'Some error has occurred while adding new event. Please try again later.'
      );
    }
  };

  return (
    <>
      <Button variant="contained" onClick={() => setOpen(true)}>
        {title}
      </Button>
      <CalendarEventForm
        title={title}
        open={open}
        setOpen={setOpen}
        onSubmit={handleCalendarEventAdd}
        event={event}
      />
    </>
  );
};
