import {ScorecardExecutionStatus} from '@ozark/common';
import {useCallback, useRef, useState} from 'react';
import {createContainer} from 'unstated-next';

const statusesStopped = [
  ScorecardExecutionStatus.actionRequired,
  ScorecardExecutionStatus.complete,
  ScorecardExecutionStatus.failed,
];

function useRunAllScores() {
  const [runAllTicket, setRunAllTicket] = useState(0);
  const [isAllRunning, setIsAllRunning] = useState(false);
  const runnersRegister = useRef(new Map<string, ScorecardExecutionStatus>([]));

  const handleRunnerStatusChange = useCallback(
    (serviceId: string, status: ScorecardExecutionStatus) => {
      runnersRegister.current.set(serviceId, status);
      if (Array.from(runnersRegister.current).every(([_, v]) => statusesStopped.includes(v))) {
        setIsAllRunning(false);
        setRunAllTicket(0);
      }
    },
    []
  );

  const handleRunAll = useCallback(() => {
    setIsAllRunning(true);
    setRunAllTicket(counter => counter + 1);
  }, []);

  return {
    runAllTicket,
    isAllRunning,
    onRunAll: handleRunAll,
    onRunnerStatusChange: handleRunnerStatusChange,
  };
}

export const ScoreRunAllState = createContainer(useRunAllScores);
export const useRunScores = ScoreRunAllState.useContainer;
