import {Checkbox, FormControlLabel} from '@mui/material';
import {useUrlQuery} from '@ozark/common/hooks/useUrlQuery';
import {useHistory} from 'react-router-dom';

export const SHOW_INACTIVE_USERS = 'showInactiveUsers';

export const ShowInactiveUsers = () => {
  const history = useHistory();
  const search = useUrlQuery();

  const handleOnChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    search.set(SHOW_INACTIVE_USERS, checked.toString());
    history.push(`${history.location.pathname}?${search}`);
  };

  return (
    <FormControlLabel
      control={<Checkbox onChange={handleOnChange} />}
      label="Show Inactive Users"
    />
  );
};
