import {ErrorBoundary} from '@ozark/common/components/common/ErrorBoundary';
import {ErrorState} from '@ozark/common/components/common/ErrorState';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import LogRocket from 'logrocket';
import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './components/App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Store} from './store/Store';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: process.env.REACT_APP_SENTRY_RELEASE
      ? `ozark-crm@${process.env.REACT_APP_SENTRY_RELEASE}`
      : undefined,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

if (process.env.REACT_APP_LOGROCKET_APP_ID) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID);
}

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <ErrorBoundary errorStateComponent={ErrorState}>
      <Store.Provider>
        <App />
      </Store.Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
