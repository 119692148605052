import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@mui/icons-material/Visibility';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {GroupView} from '@ozark/common';
import {useHistory} from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#1c252c',
    },
    media: {
      position: 'relative',
      height: 0,
      paddingTop: '56.25%', // 16:9
      borderTop: 'solid 1px rgba(0,0,0,0.1)',
      borderBottom: 'solid 1px rgba(0,0,0,0.1)',
      backgroundSize: 'contain',
    },
    content: {},
    line: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    inactiveBackdrop: {
      position: 'absolute',
      top: 0,
      height: 'calc(100% + 2px)',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.5)',
      color: 'rgba(255,255,255,0.6)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 2,
    },
    icon: {
      height: 24,
      width: 24,
      marginRight: theme.spacing(2),
      fill: '#1c252c',
    },
  })
);

export const GroupCard = ({
  group,
  isAuthUserAdmin,
}: {
  group: GroupView;
  isAuthUserAdmin?: boolean;
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    history.push(`/groups/${group.id}/edit`);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        subheader={<div className={classes.line}>{group.name}</div>}
        action={
          <IconButton onClick={handleClick} size="large">
            {isAuthUserAdmin ? <EditIcon /> : <ViewIcon />}
          </IconButton>
        }
      />
      <CardMedia
        className={classes.media}
        sx={{
          margin: theme => (!group.isActive ? 0 : theme.spacing(0, 1)),
        }}
        image={group.logoUrl}
        title={group.name}
      >
        {!group.isActive && (
          <div className={classes.inactiveBackdrop}>
            <Typography variant="h4">Inactive</Typography>
          </div>
        )}
      </CardMedia>
      <CardContent className={classes.content}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              target="_blank"
              href={`${window.location.protocol}//${group.appDomain}`}
              style={{backgroundColor: group.hexColorPrimary, color: group.hexColorTextPrimary}}
              fullWidth
            >
              App
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              target="_blank"
              href={`${window.location.protocol}//${group.portalDomain}`}
              style={{borderColor: group.hexColorPrimary, color: group.hexColorPrimary}}
              fullWidth
            >
              Portal
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
