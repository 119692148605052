/* eslint-disable react-hooks/exhaustive-deps */
import {
  AsyncState,
  Collections,
  Firebase,
  Profile,
  SearchableProfileView,
  selectSearchableProfileView,
  UniversalSnapshot,
} from '@ozark/common';
import {groupBy, keyBy} from '@s-libs/micro-dash';
import firebase from 'firebase/compat/app';
import {useEffect, useState} from 'react';
import {useAuthUser} from './useAuthUser';

export type Options = {
  filter: (
    query: firebase.firestore.Query<firebase.firestore.DocumentData>
  ) => firebase.firestore.Query<firebase.firestore.DocumentData>;
};

export const useSearchableProfiles = (
  currentUserStore: ReturnType<typeof useAuthUser>,
  _options?: Options
) => {
  const [profiles, setProfiles] = useState<
    AsyncState<SearchableProfileView[]> & {
      dictionary: {[_: string]: SearchableProfileView};
      byRole: {[_: string]: SearchableProfileView[]};
    }
  >({promised: true, dictionary: {}, byRole: {}});
  const {authUser} = currentUserStore;
  const options: Options = {
    filter: firestoreQuery => firestoreQuery,
    ..._options,
  };
  useEffect(() => {
    const authUid = authUser.data?.uid;
    if (!authUid) return;

    const unsub = options
      .filter(Firebase.firestore.collection(Collections.profiles).orderBy('firstName'))
      .onSnapshot(
        querySnap => {
          const data = querySnap.docs.map(docSnap => {
            return selectSearchableProfileView(docSnap as UniversalSnapshot<Profile>);
          });
          const dictionary = keyBy(data, e => e.uid);
          const byRole = groupBy(data, e => e.role as string);
          setProfiles({promised: false, data, dictionary: dictionary, byRole: byRole});
        },
        err => {
          console.error({err});

          if (err.code === 'permission-denied') {
            // This just means the user is probably not logged in.
            setProfiles({promised: false, data: [], dictionary: {}, byRole: {}});
            return;
          }
          console.warn(err, err.code, err.name);
          setProfiles({promised: false, error: err, data: undefined, dictionary: {}, byRole: {}});
        }
      );
    return () => {
      console.log('unusub');
      unsub();
    };
  }, [authUser.data?.uid]);

  return {profiles};
};
