import {Box} from '@mui/material';
import {COLUMN_FIRST_WIDTH} from '../constants';
import {getHourFormat} from '../utils';

type Props = {
  hour: number;
};

export const ColumnHoursHeader = ({hour}: Props) => {
  return (
    <Box
      id={`hour_${hour}`}
      height={60}
      width={COLUMN_FIRST_WIDTH}
      sx={{width: '59px', textAlign: 'right', pr: 2, pt: 0.5, fontSize: '12px', color: 'gray'}}
    >
      {getHourFormat(hour)}
    </Box>
  );
};
