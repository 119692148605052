import {Box, Theme, Typography} from '@mui/material';
import {SxProps} from '@mui/system';
import {useApiContainer} from '@ozark/common';
import {Title} from '@ozark/common/components';
import {LeadsClosingRatios} from '@ozark/functions/src/functions/express/private/types';
import {useCallback, useEffect, useState} from 'react';
import {RatioTiles} from '../components/RatioTiles';

const styles: Record<string, SxProps<Theme>> = {
  root: {
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
};

export const SalesLeadsDashboard = () => {
  const [ratios, setRatios] = useState<LeadsClosingRatios | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const api = useApiContainer();

  const getRatios = useCallback(() => {
    setIsLoading(true);
    api?.leads
      .getLeadsClosingRatios()
      .then(setRatios)
      .finally(() => setIsLoading(false));
  }, [api?.leads]);

  useEffect(() => {
    getRatios();
  }, [getRatios]);

  return (
    <Box sx={styles.root}>
      <Title breadcrumbs={[<Typography>Dashboard</Typography>]} />
      <Typography variant="h5" gutterBottom color="secondary.main" fontSize="1.7rem">
        Total Ratio
      </Typography>
      <RatioTiles isLoading={isLoading} ratios={ratios} />
    </Box>
  );
};
