import {Box} from '@mui/material';
import {isToday} from 'date-fns';
import {CalendarEvent} from '../../..';
import {BORDER, BORDER_HEADER, ROW_HEIGHT} from '../constants';
import {ColumnDateHeader} from './ColumnDateHeader';
import {DateEvent} from './DateEvent';

type Props = {
  date: Date;
  /** calendar events for the current week */
  events?: CalendarEvent[];
};

export const ColumnDate = ({date, events}: Props) => {
  const dayEvents = events?.filter(e => e.dateTime?.toDateString() === date.toDateString()) ?? [];

  return (
    <Box
      flex={1}
      borderLeft={BORDER}
      position="relative"
      bgcolor={isToday(date) ? '#5351f20a' : undefined}
    >
      <Box position="sticky" top={0} borderBottom={BORDER_HEADER} zIndex={500}>
        <ColumnDateHeader date={date} />
      </Box>
      <Box position="relative" height={24 * ROW_HEIGHT}>
        {dayEvents && dayEvents.map(event => <DateEvent key={event.id} event={event} />)}
      </Box>
    </Box>
  );
};
