import {Application} from './Application';
import {UniversalSnapshot, UniversalTimestamp} from './compat';
import {DocumentBase} from './DocumentBase';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type AssociationApplicationData = Pick<
  Application,
  'mid' | 'doingBusinessAs' | 'distinguishableId' | 'legalBusinessName'
> & {
  applicationId: string;
};

export type ApplicationAssociationData = {
  source: AssociationApplicationData;
  destination: AssociationApplicationData;
  applicationIds: [string, string];
  createdAt: UniversalTimestamp;
};

export type ApplicationAssociation = DocumentBase & ApplicationAssociationData;

export type ApplicationAssociationView = ViewBase<ApplicationAssociation>;

export const selectApplicationAssociationView: ViewSelector<
  ApplicationAssociationView,
  ApplicationAssociation
> = (snapshot: UniversalSnapshot<ApplicationAssociation>) => {
  const applicationAssociation: ApplicationAssociationView = {
    ...defaultViewSelector(snapshot),
  };

  return applicationAssociation;
};
