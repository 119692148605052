import {Card} from '@mui/material';
import {getAccountStatusColor} from '@ozark/common';
import React from 'react';
import {CardViewItemContent} from './CardViewItemContent';
import {RiskOverviewItemProps} from './common';
import {TableViewItemContent} from './TableViewItemContent';

export type CardView = 'card' | 'table';

type Props = RiskOverviewItemProps & {
  view?: CardView;
  highlighted?: boolean;
};

export const RiskOverviewCard = React.forwardRef<null, Props>(
  ({data, view = 'card', highlighted, ...other}, ref) => {
    const cardProps = {
      data,
      ...other,
    };
    return (
      <Card
        ref={ref}
        sx={[
          {
            color: '#4d6575',
            borderLeft: theme => `solid 8px ${theme.palette.primary.light}`,
            borderLeftColor: getAccountStatusColor(data.accountStatus?.status),
            minWidth: '900px',
          },
          !!highlighted && {
            border: theme => `solid 3px ${theme.palette.primary.main}`,
            boxShadow: theme => theme.shadows[3],
          },
        ]}
      >
        {view === 'card' && <CardViewItemContent {...cardProps} />}
        {view === 'table' && <TableViewItemContent {...cardProps} />}
      </Card>
    );
  }
);
