import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion, {AccordionProps} from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import {ApplicationDuplicateView} from '@ozark/functions/src/documents';
import {uniq} from '@s-libs/micro-dash';

type ApplicationDuplicateAccordionProps = {
  duplicate: ApplicationDuplicateView;
  whichExpanded: string | boolean;
  onChange: AccordionProps['onChange'];
};

const ApplicationDuplicateAccordion = ({
  duplicate,
  whichExpanded,
  onChange,
}: ApplicationDuplicateAccordionProps) => {
  return (
    <Accordion
      key={`application-duplicate-${duplicate.id}`}
      expanded={whichExpanded === duplicate.id}
      onChange={onChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${duplicate.id}-content`}
        id={`panel-${duplicate.id}-header`}
      >
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <a href={`/applications/${duplicate.id}`}>{duplicate.distinguishableId}</a>
          </Grid>
          <Grid item xs={2}>
            <strong>Group/Agent</strong>
            <br />
            {duplicate.groupName}
            <br />
            {duplicate.agentFullName}
          </Grid>
          <Grid item xs={3}>
            <strong>DBA/Legal Name</strong>
            <br />
            {duplicate.doingBusinessAs}
            <br />
            {duplicate.legalBusinessName}
          </Grid>
          <Grid item xs={2}>
            <strong>Signer</strong>
            <br />
            {duplicate.signerFullName}
          </Grid>
          <Grid item xs={4}>
            Matching Data:{' '}
            {uniq(
              Object.values(duplicate.matchingData)
                .map(fieldData => fieldData?.label)
                .filter(Boolean)
            ).join(', ')}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} flexDirection="column">
          {Object.entries(duplicate.matchingData).map(
            ([field, fieldData]) =>
              !!fieldData && (
                <Grid key={`${duplicate.id}-${field}`} item container>
                  <Grid item xs={2}>
                    {fieldData.label}
                  </Grid>

                  <Grid item xs={4} dangerouslySetInnerHTML={{__html: fieldData.value}} />

                  <Grid item xs={6}>
                    {fieldData.comment}
                  </Grid>
                </Grid>
              )
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ApplicationDuplicateAccordion;
