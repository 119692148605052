import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Box, Divider, Fade, IconButton, Menu, MenuItem, Paper, Typography} from '@mui/material';
import {
  getSalesGroupByDisposition,
  Lead,
  LeadView,
  SalesDisposition,
  useNotification,
  UserRoles,
} from '@ozark/common';
import {ConfirmationDialog} from '@ozark/common/components';
import {format} from 'date-fns';
import React, {Fragment, useMemo, useState} from 'react';
import {useActiveProfiles} from '../../../hooks/useActiveProfiles';
import {useStore} from '../../../store/helpers';
import {AssigneeSelector} from './AssigneeSelector';
import {DispositionSelector} from './DispositionSelector';

type Props = {
  lead: LeadView;
  setLeadData: (data: Partial<Lead>) => Promise<void>;
};

export const SalesLeadBar = ({lead, setLeadData}: Props) => {
  const {profiles} = useStore();
  const activeProfiles = useActiveProfiles(profiles);
  const {firstName, lastName, businessName, assigneeId, createdAt} = lead;
  const [moreMenuEl, setMoreMenuEl] = useState<null | HTMLElement>(null);
  const [confirmationAction, setConfirmationAction] = useState<(() => Promise<void>) | null>(null);
  const showNotification = useNotification();
  const [isActiveLead, setIsActiveLead] = useState<boolean>(
    lead.salesDisposition !== SalesDisposition.deleted
  );

  const updateLeadAssignee = (userId: string | null) =>
    setLeadData({
      assigneeId: userId,
    });

  const updateLeadDisposition = async (disposition: SalesDisposition) => {
    const salesDispositionGroup = getSalesGroupByDisposition(disposition);
    await setLeadData({
      salesDisposition: disposition,
      salesDispositionGroup,
    });
    setTimeout(() => {
      //set small delay before setting IsActiveLead state to let confirmation popup close.
      //without the delay popup text will be changed from "delete" to "restore" and that might confuse user.
      setIsActiveLead(disposition !== SalesDisposition.deleted);
    }, 500);
  };

  const assigneeOptions = useMemo(
    () => [
      {
        groupName: UserRoles.agentSupport,
        profiles: activeProfiles.byRole[UserRoles.agentSupport],
      },
      {
        groupName: UserRoles.sales,
        profiles: activeProfiles.byRole[UserRoles.sales],
      },
      {
        groupName: UserRoles.admin,
        profiles: activeProfiles.byRole[UserRoles.admin],
      },
    ],
    [activeProfiles]
  );

  const handleOpenMoreMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreMenuEl(event.currentTarget);
  };

  const handleDelete = (nextIsActive: boolean) => async () => {
    const nextSalesDisposition = nextIsActive ? SalesDisposition.new : SalesDisposition.deleted;
    try {
      await updateLeadDisposition(nextSalesDisposition);
      setConfirmationAction(null);
      showNotification(
        'success',
        nextIsActive ? `Sales lead has been restored.` : 'Sales lead has been deleted'
      );
    } catch (err: any) {
      const opName = getDeleteActionName(nextIsActive);
      console.error(`failed to ${opName}. ${err.toString()}`);
      showNotification(
        'error',
        nextIsActive ? `Error restoring sales lead` : 'Error deleting sales lead'
      );
    } finally {
      setMoreMenuEl(null);
    }
  };

  const getDeleteActionName = (isLeadActive: boolean | null) =>
    isLeadActive ? 'Delete' : 'Restore';
  return (
    <>
      <Paper sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2}}>
        <Typography>
          {firstName ?? ''} {lastName ?? ''}
          {businessName && <> &bull; {businessName}</>}
          {createdAt && <> &bull; {format(createdAt.toDate(), 'MM/dd/yyyy h:mm a')}</>}
        </Typography>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <DispositionSelector
            disposition={lead.salesDisposition}
            updateDisposition={updateLeadDisposition}
          />
          <Divider orientation="vertical" flexItem light sx={{mx: 2}} />
          <AssigneeSelector
            assigneeId={assigneeId}
            updateAssignee={updateLeadAssignee}
            options={assigneeOptions}
          />
          <Fragment>
            <Divider orientation="vertical" flexItem light sx={{mx: 2}} />
            <IconButton onClick={handleOpenMoreMenu} size="large">
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="more-menu"
              anchorEl={moreMenuEl}
              onClose={() => setMoreMenuEl(null)}
              keepMounted
              open={Boolean(moreMenuEl)}
              anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
              transformOrigin={{horizontal: 'right', vertical: 'top'}}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={() => setConfirmationAction(() => handleDelete(!isActiveLead))}>
                <Typography>{getDeleteActionName(isActiveLead)}</Typography>
              </MenuItem>
            </Menu>
          </Fragment>
        </Box>
      </Paper>
      <ConfirmationDialog
        title="Confirmation"
        message={`Are you sure you want to ${getDeleteActionName(isActiveLead).toLowerCase()}?`}
        onClose={() => setConfirmationAction(null)}
        onConfirm={confirmationAction}
      />
    </>
  );
};
