/* eslint-disable react-hooks/exhaustive-deps */
import {
  AsyncState,
  Collections,
  Firebase,
  Merchant,
  SearchableMerchantView,
  selectSearchableMerchantView,
  UniversalSnapshot,
} from '@ozark/common';
import {keyBy} from '@s-libs/micro-dash';
import firebase from 'firebase/compat/app';
import {useEffect, useState} from 'react';
import {useAuthUser} from './useAuthUser';

export type Options = {
  filter: (
    query: firebase.firestore.Query<firebase.firestore.DocumentData>
  ) => firebase.firestore.Query<firebase.firestore.DocumentData>;
};

export const useSearchableMerchants = (
  currentUserStore: ReturnType<typeof useAuthUser>,
  _options?: Options
) => {
  const [merchants, setMerchants] = useState<
    AsyncState<SearchableMerchantView[]> & {
      dictionary: {[_: string]: SearchableMerchantView};
    }
  >({promised: true, dictionary: {}});
  const {authUser} = currentUserStore;
  const options: Options = {
    filter: firestoreQuery => firestoreQuery,
    ..._options,
  };
  useEffect(() => {
    const authUid = authUser.data?.uid;
    if (!authUid) return;

    const unsub = options.filter(Firebase.firestore.collection(Collections.merchants)).onSnapshot(
      querySnap => {
        const data = querySnap.docs.map(docSnap => {
          return selectSearchableMerchantView(docSnap as UniversalSnapshot<Merchant>);
        });
        const dictionary = keyBy(data, e => e.id);
        setMerchants({promised: false, data, dictionary: dictionary});
      },
      err => {
        console.error({err});

        if (err.code === 'permission-denied') {
          setMerchants({promised: false, data: [], dictionary: {}});
          return;
        }
        console.warn(err, err.code, err.name);
        setMerchants({promised: false, error: err, data: undefined, dictionary: {}});
      }
    );
    return () => {
      console.log('unusub');
      unsub();
    };
  }, [authUser.data?.uid]);

  return {merchants};
};
