import {Collections, Firebase, LeadSubCollection} from '@ozark/common';
import {Notes} from '@ozark/common/components/Notes';
import {useMemo} from 'react';

export type Props = {
  leadId: string;
};

export const SalesLeadNotes = ({leadId}: Props) => {
  const leadFirebaseRef = useMemo(() => {
    return Firebase.firestore.collection(Collections.leads).doc(leadId);
  }, [leadId]);

  return (
    <Notes
      allowAddComments
      firestoreDocumentRef={leadFirebaseRef}
      notesSubCollection={LeadSubCollection.notes}
      title="Notes"
    />
  );
};
