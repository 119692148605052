export const PortfolioIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <path
          opacity={0.4}
          d="M640,112v224c0,26.5-21.5,48-48,48H176c-26.5,0-48-21.5-48-48V48c0-26.5,21.5-48,48-48h160l64,64h192
		C618.5,64,640,85.5,640,112z"
        />
        <path
          d="M48,512c-26.5,0-48-21.5-48-48l0,0V176c0-26.5,21.5-48,48-48h48v208c0,44.2,35.8,80,80,80h336v48
		c0,26.5-21.5,48-48,48l0,0H48z"
        />
      </g>
    </svg>
  );
};
