import {yupResolver} from '@hookform/resolvers/yup';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
} from '@mui/material';
import {Theme, useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Department, Platforms, useCallable, UserRoles} from '@ozark/common';
import {Select, TextField} from '@ozark/common/components';
import PhoneNumber from 'awesome-phonenumber';
import {SubmitHandler, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {useNotification} from '../../hooks/useNotification';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      [theme.breakpoints.up('md')]: {
        width: 600,
      },
    },
  })
);

const schema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().required('Email Address is required'),
  cellPhone: yup
    .string()
    .min(12, 'Must be a valid phone number') // validating against e164 format (+18002333333)
    .matches(/^\+1[2-9]{1}[0-9]{9}$/, 'Must be a valid phone number')
    .transform(value => {
      const phone = value ? new PhoneNumber(value, 'US').getNumber('e164') : value;
      return phone;
    })
    .required('Cell Phone is required'),
  phoneNumber: yup
    .string()
    .min(12, 'Must be a valid phone number') // validating against e164 format (+18002333333)
    .matches(/^\+1[2-9]{1}[0-9]{9}$/, 'Must be a valid phone number')
    .transform(value => {
      const phone = value ? new PhoneNumber(value, 'US').getNumber('e164') : value;
      return phone;
    })
    .required('Phone Number is required'),
  role: yup.string().required('Role is required'),
  jobTitle: yup.string().required('Job Title is required'),
  department: yup.string().when('role', (role: UserRoles, schema: any) => {
    if (role !== UserRoles.auditor) {
      return schema.required('Department is required');
    }
  }),
  platform: yup.string().when('role', (role: UserRoles, schema: any) => {
    if (role === UserRoles.auditor) {
      return schema.required('Platform is required');
    }
  }),
});

type FormData = {
  email: string;
  cellPhone: string;
  phoneNumber: string;
  phoneExt: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  role: UserRoles;
  department: Department;
  platform: Platforms;
};

export const CreateDialog = ({onClose}: {onClose: () => void}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const {createUser} = useCallable();
  const showNotification = useNotification();

  const {
    control,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  const onSuccess: SubmitHandler<FormData> = async data => {
    const result = await createUser(data);
    if (result.status === 'ok') {
      onClose();
      showNotification('success', 'User successfully created.');
      return;
    }
    const message =
      typeof result.message === 'string' ? result.message : JSON.stringify(result.message);
    showNotification('error', message);
  };

  const beforeSubmit = async () => {
    await handleSubmit(onSuccess)();
  };

  const watchRole = watch('role');

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="create-dialog-title"
      fullScreen={fullScreen}
      maxWidth={'lg'}
    >
      <DialogTitle id="create-dialog-title">Create User</DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                name="firstName"
                label="First Name"
                errors={errors}
                control={control}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField name="lastName" label="Last Name" errors={errors} control={control} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="jobTitle" label="Title" errors={errors} control={control} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                label="Email"
                errors={errors}
                control={control}
                placeholder="user@ozarkfi.com"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AlternateEmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="cellPhone"
                label="Cell Phone"
                errors={errors}
                control={control}
                placeholder="(999) 999-9999"
                transform={{
                  pattern: '(999) 999-9999',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIphoneIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                name="phoneNumber"
                label="Phone Number"
                errors={errors}
                control={control}
                placeholder="(999) 999-9999"
                transform={{
                  pattern: '(999) 999-9999',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="phoneExt"
                label="Extension"
                errors={errors}
                control={control}
                InputProps={{
                  startAdornment: <InputAdornment position="start">x</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Select name="role" label="Role" errors={errors} control={control}>
                {[
                  UserRoles.auditor,
                  UserRoles.operations,
                  UserRoles.underwriter,
                  UserRoles.agentSupport,
                  UserRoles.sales,
                  UserRoles.support,
                  UserRoles.admin,
                ].sortAndMap(e => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            {watchRole !== UserRoles.auditor && (
              <Grid item xs={12}>
                <Select name="department" label="Department" errors={errors} control={control}>
                  {Object.values(Department).sortAndMap(e => (
                    <MenuItem key={e} value={e}>
                      {e}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
            {watchRole && watchRole === UserRoles.auditor && (
              <Grid item xs={12}>
                <Select name="platform" label="Platform" errors={errors} control={control}>
                  {Object.values(Platforms).sortAndMap(e => (
                    <MenuItem key={e} value={e}>
                      {e}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={beforeSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
