import {Collections, createAuditNonce, Firebase, Profile} from '@ozark/common';

export const saveProfile = async (id: string, profile: Partial<Profile>) => {
  try {
    const auditNonce = createAuditNonce(Firebase.auth.currentUser!.uid);
    const data = {...profile, auditNonce};
    await Firebase.firestore.collection(Collections.profiles).doc(id).set(data, {merge: true});
  } catch (err: any) {
    console.error('Failed to set profile.', id, profile, err);
    throw Error(err);
  }
};
