import EditIcon from '@mui/icons-material/Edit';
import {IconButton, ListItem, Typography} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import {CalendarEvent} from '@ozark/common';
import {CalendarEventForm, calendarEventUpdate, formatDateTime} from '@ozark/common/components';
import {isBefore} from 'date-fns';
import {useState} from 'react';
import {EventFieldData} from './EventFieldData';

type Props = {
  calendarEvent: CalendarEvent;
};

export const CalendarEventInfo = ({calendarEvent}: Props) => {
  const [open, setOpen] = useState(false);
  const isMeetingOccurred = calendarEvent.dateTime && isBefore(calendarEvent.dateTime, new Date());
  const onClick = () => {
    if (isMeetingOccurred) {
      return;
    }
    setOpen(true);
  };
  return (
    <>
      <ListItem
        key={calendarEvent.id}
        sx={{paddingLeft: 0}}
        secondaryAction={
          <>
            {!isMeetingOccurred && (
              <IconButton edge="end" aria-label="edit" onClick={onClick}>
                <EditIcon />
              </IconButton>
            )}
          </>
        }
      >
        <ListItemText
          primary={
            <Typography
              variant="body2"
              component="span"
              sx={{
                display: 'inline-block',
                color: theme => (isMeetingOccurred ? theme.palette.grey[600] : 'primary'),
              }}
            >
              <EventFieldData
                caption="Date:"
                value={
                  calendarEvent.dateTime ? formatDateTime(calendarEvent.dateTime?.toString()) : ''
                }
              />
              <EventFieldData caption="Title:" value={calendarEvent.subject} />
              <EventFieldData caption="Description:" value={calendarEvent.description} />
            </Typography>
          }
        />
      </ListItem>
      <CalendarEventForm
        title="Edit Event"
        open={open}
        setOpen={setOpen}
        onSubmit={calendarEventUpdate}
        event={calendarEvent}
      />
    </>
  );
};
