import {Firebase} from '@ozark/common';
import {Column} from '@ozark/common/api/Column';
import {TopBusinessReportResponse} from '@ozark/functions/src/lib/integrations/lexis/TopBusinessReportResponse';
import {Address} from '@ozark/functions/src/lib/integrations/lexis/TopBusinessSearchRequest';
import {
  BusinessIDS,
  TopBusinessSearchResponse,
} from '@ozark/functions/src/lib/integrations/lexis/TopBusinessSearchResponse';
import {AccordionProps} from '..';
import {TwoStepScorecardReportAccordion} from './TwoStepScorecardReportAccordion';

const formatAddress = (address: Address | undefined) => {
  if (!address) return undefined;
  let result: string = '';
  if (address.StreetNumber) {
    result += ' ' + address.StreetNumber;
  }
  if (address.StreetName) {
    result += ' ' + address.StreetName;
  }
  if (address.StreetSuffix) {
    result += ' ' + address.StreetSuffix;
  }
  if (address?.UnitDesignation) {
    result += ' ' + address.UnitDesignation;
  }
  if (address?.UnitNumber) {
    result += ' ' + address.UnitNumber;
  }
  if (address?.City) {
    result += ' ' + address.City;
  }
  if (address?.State) {
    result += ' ' + address.State;
  }
  if (address?.Zip5) {
    result += ' ' + address.Zip5;
  }

  return result;
};

type BusinessSearchResult = {
  id: string;
  companyName?: string;
  address?: string;
} & BusinessIDS;

const formatResults = (searchResults: TopBusinessSearchResponse[]) => {
  const results = searchResults.reduce((accumulator, result) => {
    const response = result.TopBusinessSearchResponseEx.response;
    if (response.RecordCount > 0) {
      const result: BusinessSearchResult[] = response.Records.Record.map(record => ({
        id: record.BusinessId!,
        companyName: record.Best?.CompanyNameInfo?.CompanyName,
        address: formatAddress(record.Best?.AddressInfo?.Address),
        ...record.BusinessIds,
      }));

      accumulator.push(...result);
    }

    return accumulator;
  }, [] as BusinessSearchResult[]);
  return results;
};

const columns: Column<{}>[] = [
  {id: 'companyName', label: 'Company Name', width: 300},
  {
    id: 'address',
    label: 'Address',
    width: 450,
  },
  {
    id: 'EmpID',
    label: 'Emp Id',
    width: 120,
  },
  {
    id: 'POWID',
    label: 'POW Id',
    width: 120,
  },
  {
    id: 'ProxID',
    label: 'Prox Id',
    width: 120,
  },
  {
    id: 'SeleID',
    label: 'Sele Id',
    width: 120,
  },
  {
    id: 'OrgID',
    label: 'Org Id',
    width: 120,
  },
  {
    id: 'UltID',
    label: 'Ult Id',
    width: 120,
  },
];

export const LexisNexisBusinessReportAccordion = ({
  application,
  scorecardReport,
  scores,
  whichExpanded,
  onChange,
}: AccordionProps) => {
  return (
    <TwoStepScorecardReportAccordion
      application={application}
      scorecardReport={scorecardReport}
      scores={scores}
      whichExpanded={whichExpanded}
      onChange={onChange}
      columns={columns}
      formatResults={results => formatResults(results as TopBusinessSearchResponse[])}
      resolveActionPayload={(selectedRow: BusinessSearchResult) => ({
        type: 'business',
        ...selectedRow,
      })}
      resolveDownloadReportLink={async result => {
        const storageRef = Firebase.storage.ref(
          (result as TopBusinessReportResponse).TopBusinessReportResponseEx.response.Pdf
        );
        return await storageRef.getDownloadURL();
      }}
      confirmationDialogTitle={selectedRow =>
        `Please confirm that you would like to request a Lexis Nexis business report for ${selectedRow.companyName}.`
      }
      step1Action="businessSearch"
      step1Name="Perform Business Search"
      step2Action="businessReport"
      step2Name="Select Business"
    />
  );
};
