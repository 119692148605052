import {useEffect} from 'react';
import {AuthUser} from '../@types/AuthUser';
import {Store} from './Store';

export const useOnLogin = (cb: (user: AuthUser) => void) => {
  const {onLogin} = useStore();
  useEffect(() => {
    const unsub = onLogin(u => {
      console.log('got login event');
      cb(u);
    });
    return () => {
      console.log('unsubscribing onLogin');
      unsub();
    };
  }, [onLogin, cb]);
};

export const useOnLogout = (cb: (user: AuthUser) => void) => {
  const {onLogout} = useStore();
  useEffect(() => {
    const unsub = onLogout(u => {
      console.log('got logout event');
      cb(u);
    });
    return () => {
      console.log('unsubscribing onLogout');
      unsub();
    };
  }, [onLogout, cb]);
};

export const useStore = () => {
  return Store.useContainer();
};
