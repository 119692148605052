import {useAgents} from '@ozark/common';
import {AgentMidAssociation} from '@ozark/common/components';

const AgentMidAssociationPage = () => {
  const {documents: agents} = useAgents();

  return <AgentMidAssociation agents={agents.data} />;
};

export default AgentMidAssociationPage;
