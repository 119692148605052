import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Box,
  Checkbox as MUICheckbox,
  TextField as MUITextField,
  Typography,
} from '@mui/material';
import {AutocompleteRenderGroupParams} from '@mui/material/Autocomplete/Autocomplete';
import {Checkbox} from '@ozark/common/components';
import * as React from 'react';
import {Control, Controller, FieldErrors, UseFormRegister, UseFormWatch} from 'react-hook-form';
import {AnnouncementFormData} from './hooks';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type SelectAllUserTypes = 'agents' | 'mids' | 'groupAdmins' | 'erpUsers';
type AllSelectedUserType = `${SelectAllUserTypes}AllSelected`;

interface Props<T, B extends boolean> {
  options: T[];
  getOptionLabel: (option: T) => string;
  getOptionKey: (option: T) => string;
  groupBy?: (option: T) => string;
  renderGroup?: (params: AutocompleteRenderGroupParams) => React.ReactNode;
  name: B extends true ? 'destinationERPUsersRoles' : SelectAllUserTypes;
  title: string;
  control: Control<AnnouncementFormData, any>;
  errors: FieldErrors<AnnouncementFormData>;
  watch: UseFormWatch<AnnouncementFormData>;
  register: UseFormRegister<AnnouncementFormData>;
  hideSelectAll?: B;
  isReadOnly?: boolean;
}

export const SelectUsers = <T, B extends boolean>({
  options,
  getOptionLabel,
  getOptionKey,
  groupBy,
  renderGroup,
  title,
  name,
  control,
  errors,
  hideSelectAll,
  isReadOnly = false,
  watch,
  register,
}: Props<T, B>) => {
  const watchSelectAll = hideSelectAll
    ? watch(name)
    : watch(`${name}AllSelected` as AllSelectedUserType);

  return (
    <Box sx={{mb: 3}}>
      <Typography variant="caption" sx={{display: 'block', fontSize: 16, mb: 1, lineHeight: 1}}>
        {title}
      </Typography>

      {!hideSelectAll && (
        <Controller
          name={`${name}AllSelected` as AllSelectedUserType}
          control={control}
          render={({field}) => (
            <Checkbox
              {...field}
              disabled={isReadOnly}
              errors={errors}
              label={`Select All ${title}`}
              checked={field.value || false}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                field.onChange(event.target.checked)
              }
              sx={{mb: 1.75}}
              register={register}
            />
          )}
        />
      )}

      <Controller
        name={name}
        control={control}
        render={({field}) => {
          const isSelectAllEnabled =
            (watchSelectAll === undefined || Boolean(watchSelectAll)) && !hideSelectAll;
          return (
            <Autocomplete
              {...field}
              value={isSelectAllEnabled ? ([] as any) : field.value}
              multiple
              onChange={(_, selected: T[]) => field.onChange(selected)}
              id={`${name}-autocomplete`}
              options={options}
              disableCloseOnSelect
              getOptionLabel={getOptionLabel}
              disabled={isSelectAllEnabled || isReadOnly}
              groupBy={groupBy}
              renderGroup={renderGroup}
              isOptionEqualToValue={(option, value) => getOptionKey(option) === getOptionKey(value)}
              renderOption={(props, option, {selected}) => (
                <li {...props} key={getOptionKey(option)}>
                  <MUICheckbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{marginRight: 8}}
                    checked={selected}
                  />
                  {getOptionLabel(option)}
                </li>
              )}
              renderInput={params => (
                <MUITextField {...params} label={title} placeholder={`Select ${title}`} />
              )}
            />
          );
        }}
      />
    </Box>
  );
};
