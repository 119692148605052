import {Grid} from '@mui/material';
import {GroupView} from '@ozark/common';
import {GroupCard} from './GroupCard';

export const GroupGrid = ({
  groups,
  isAuthUserAdmin,
}: {
  groups: GroupView[];
  isAuthUserAdmin?: boolean;
}) => {
  return (
    <Grid container spacing={2}>
      {groups.map(group => (
        <Grid key={group.id} item xs={12} sm={6} md={4} lg={3} xl={3}>
          <GroupCard group={group} isAuthUserAdmin={isAuthUserAdmin} />
        </Grid>
      ))}
    </Grid>
  );
};
