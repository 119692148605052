/* eslint-disable react-hooks/exhaustive-deps */
import {
  Agent,
  AsyncState,
  Collections,
  Firebase,
  SearchableAgentView,
  selectSearchableAgentView,
  UniversalSnapshot,
} from '@ozark/common';
import {groupBy, keyBy} from '@s-libs/micro-dash';
import firebase from 'firebase/compat/app';
import {useEffect, useState} from 'react';
import {useAuthUser} from './useAuthUser';

export type Options = {
  filter: (
    query: firebase.firestore.Query<firebase.firestore.DocumentData>
  ) => firebase.firestore.Query<firebase.firestore.DocumentData>;
};

export const useSearchableAgents = (
  currentUserStore: ReturnType<typeof useAuthUser>,
  _options?: Options
) => {
  const [agents, setAgents] = useState<
    AsyncState<SearchableAgentView[]> & {
      dictionary: {[_: string]: SearchableAgentView};
      byRole: {[_: string]: SearchableAgentView[]};
    }
  >({promised: true, dictionary: {}, byRole: {}});
  const {authUser} = currentUserStore;
  const options: Options = {
    filter: firestoreQuery => firestoreQuery,
    ..._options,
  };
  useEffect(() => {
    const authUid = authUser.data?.uid;
    if (!authUid) return;

    const unsub = options.filter(Firebase.firestore.collection(Collections.agents)).onSnapshot(
      querySnap => {
        const data = querySnap.docs.map(docSnap => {
          return selectSearchableAgentView(docSnap as UniversalSnapshot<Agent>);
        });
        const dictionary = keyBy(data, e => e.id);
        const byRole = groupBy(data, e => e.role as string);
        setAgents({promised: false, data, dictionary: dictionary, byRole: byRole});
      },
      err => {
        console.error({err});

        if (err.code === 'permission-denied') {
          setAgents({promised: false, data: [], dictionary: {}, byRole: {}});
          return;
        }
        console.warn(err, err.code, err.name);
        setAgents({promised: false, error: err, data: undefined, dictionary: {}, byRole: {}});
      }
    );
    return () => {
      console.log('unusub');
      unsub();
    };
  }, [authUser.data?.uid]);

  return {agents};
};
