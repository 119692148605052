import {yupResolver} from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  styled,
  Typography,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {FeeModifierView, ScheduleAFeeDefinitions} from '@ozark/common';
import {Select} from '@ozark/common/components/Select';
import {TextField} from '@ozark/common/components/TextField';
import {SubmitHandler, useForm} from 'react-hook-form';
import * as yup from 'yup';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      '& > *': {
        margin: theme.spacing(0, 1),
      },
    },
  })
);

export const Schema = yup.object().shape({
  operator: yup.string().required('Required'),
  feeName: yup.string().required('Required'),
  symbol: yup.string().required('Required'),
  amount: yup.number().typeError('Required').required('Required'),
});

type Props = {
  modifier: Partial<FeeModifierView>;
  open?: boolean;
  onSubmit(data: FormData): void;
  onClose(): void;
};

type FormData = {
  operator: string;
  feeName: string;
  symbol: string;
  amount: number;
};

export const ModifierDialog = ({modifier, onSubmit, onClose}: Props) => {
  const classes = useStyles();

  const hookForm = useForm<FormData>({
    resolver: yupResolver(Schema),
    defaultValues: {
      ...modifier,
    },
    shouldUnregister: true,
  });

  const {
    formState: {errors},
    handleSubmit,
    control,
  } = hookForm;

  const onSuccess: SubmitHandler<FormData> = data => {
    onSubmit(data);
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="fee-modifier-dialog-title"
      maxWidth={'lg'}
    >
      <DialogTitle id="fee-modifier-dialog-title">Fee Modifier</DialogTitle>
      <Divider />
      <DialogContentStyled>
        <Box
          py={1}
          px={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
          className={classes.box}
        >
          <Select
            variant="outlined"
            name="operator"
            label="Modify"
            errors={errors}
            control={control}
            fullWidth={false}
            disableErrorText={true}
            style={{minWidth: 120}}
          >
            <MenuItem value="increase">Increase</MenuItem>
            <MenuItem value="reduce">Reduce</MenuItem>
          </Select>
          <Box>
            <Typography>the</Typography>
          </Box>
          <Select
            variant="outlined"
            name="feeName"
            label="Fee"
            errors={errors}
            control={control}
            fullWidth={false}
            disableErrorText={true}
            disabled={modifier.id}
            style={{minWidth: 200}}
          >
            {Object.keys(ScheduleAFeeDefinitions).map(e => {
              const key = e as keyof typeof ScheduleAFeeDefinitions;
              return <MenuItem value={e}>{ScheduleAFeeDefinitions[key].label}</MenuItem>;
            })}
          </Select>
          <Box>
            <Typography>by</Typography>
          </Box>

          <Select
            variant="outlined"
            name="symbol"
            label=""
            errors={errors}
            control={control}
            fullWidth={false}
            disableErrorText={true}
            style={{minWidth: 60}}
          >
            <MenuItem value="dollars">$</MenuItem>
            <MenuItem value="percent">%</MenuItem>
          </Select>
          <TextField
            variant="outlined"
            name="amount"
            label="Amount"
            type="number"
            errors={errors}
            control={control}
            fullWidth={false}
            disableErrorText={true}
            InputProps={{
              style: {width: 120},
            }}
          />
        </Box>
      </DialogContentStyled>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit(onSuccess)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogContentStyled = styled(DialogContent)({
  minWidth: 500,
});
