import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import makeStyles from '@mui/styles/makeStyles';
import {useCallable, useNotification, UtilityFunction} from '@ozark/common';
import {ConfirmationDialog} from '@ozark/common/components';
import {useState} from 'react';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

export const UtilityList = () => {
  const classes = useStyles();
  const {executeUtility} = useCallable();
  const showNotification = useNotification();
  const [actionName, setActionName] = useState<string>();
  const [confirmationAction, setConfirmationAction] = useState<(() => Promise<void>) | null>(null);
  const setPendingAction = (utility: UtilityFunction) => async () => {
    setActionName(utility);
    setConfirmationAction(() => () => handleExecute(utility));
  };
  const handleExecute = async (utility: UtilityFunction) => {
    await executeUtility({utility});
    showNotification('info', `Sucessfully executed '${utility}'`);
  };
  return (
    <>
      <List subheader={<ListSubheader>Algolia</ListSubheader>} className={classes.root}>
        <ListItem button onClick={setPendingAction(UtilityFunction.reindexDocuments)}>
          <ListItemText primary="Re-Index All Applications" />
        </ListItem>

        <ListItem button onClick={setPendingAction(UtilityFunction.reindexLeads)}>
          <ListItemText primary="Re-Index All Leads" />
        </ListItem>

        <ListItem button onClick={setPendingAction(UtilityFunction.reindexAlgoliaTickets)}>
          <ListItemText primary="Re-Index All Tickets" />
        </ListItem>
      </List>
      <List subheader={<ListSubheader>Redis</ListSubheader>} className={classes.root}>
        <ListItem button onClick={setPendingAction(UtilityFunction.purgeRedisCache)}>
          <ListItemText primary="Purge Cache" />
        </ListItem>
      </List>
      <ConfirmationDialog
        title="Confirmation"
        message={`Are you sure you want to ${actionName}?`}
        onClose={() => setConfirmationAction(null)}
        onConfirm={confirmationAction}
      />
    </>
  );
};
