import {Button} from '@mui/material';
import {ConfirmationDialog} from '@ozark/common/components';
import {useState} from 'react';
import {RiskOverviewItemProps} from '../common';

type Props = Pick<RiskOverviewItemProps, 'data' | 'onRemoveAssociation'>;

export const RemoveAssociationButton = ({data, onRemoveAssociation}: Props): JSX.Element => {
  const [applicationToRemove, setApplicationToRemove] = useState<
    RiskOverviewItemProps['data'] | null
  >();

  const onConfirmRemove = applicationToRemove
    ? async () => {
        try {
          await onRemoveAssociation?.(applicationToRemove);
        } finally {
          setApplicationToRemove(null);
        }
      }
    : null;

  return (
    <>
      <Button variant="outlined" color="error" onClick={() => setApplicationToRemove(data)}>
        Remove association
      </Button>
      <ConfirmationDialog
        title="Confirmation"
        message="Are you sure you want to remove association with the application?"
        onClose={() => setApplicationToRemove(null)}
        onConfirm={onConfirmRemove}
      />
    </>
  );
};
