import {ResetPassword as CommonResetPassword} from '@ozark/common/components';
import {DEFAULT_GROUP} from '../../constants/group';
import * as ROUTES from '../../constants/routes';

export const ResetPassword = () => (
  <CommonResetPassword
    groupName={DEFAULT_GROUP.name}
    groupLogoUrl={DEFAULT_GROUP.logoUrl}
    groupDomain={DEFAULT_GROUP.portalDomain}
    loginPageUrl={ROUTES.LOGIN}
  />
);
