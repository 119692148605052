import {
  AsyncState,
  BoardingSubCollection,
  BoardingTask,
  BoardingTaskStatus,
  BoardingTaskView,
  Collections,
  Firebase,
  selectBoardingTaskView,
  UniversalSnapshot,
} from '@ozark/common';
import {useEffect, useState} from 'react';

export const usePostBoardingTasks = (applicationId: string) => {
  const [boardedTaskId, setBoardedTaskId] = useState<string>();
  const [postBoardingTasks, setPostBoardingTasks] = useState<AsyncState<BoardingTaskView[]>>({
    promised: true,
  });

  useEffect(() => {
    (async () => {
      const boardedTasks = await Firebase.firestore
        .collection(Collections.applications)
        .doc(applicationId)
        .collection(Collections.boardingTasks)
        .where('status', '==', BoardingTaskStatus.done)
        .get();

      if (boardedTasks.empty) return;

      console.log('boardedTasks', boardedTasks.docs[0].id);

      setBoardedTaskId(boardedTasks.docs[0].id);
    })();
  }, [applicationId, setBoardedTaskId]);

  useEffect(() => {
    if (!boardedTaskId) return;

    const unsubscribe = Firebase.firestore
      .collection(Collections.applications)
      .doc(applicationId)
      .collection(Collections.boardingTasks)
      .doc(boardedTaskId)
      .collection(BoardingSubCollection.postBoardingTasks)
      .onSnapshot(
        async snapshot => {
          if (snapshot.size === 0) {
            setPostBoardingTasks({promised: false});
            return;
          }

          const postBoardingTasks = snapshot.docs.map(doc =>
            selectBoardingTaskView(doc as UniversalSnapshot<BoardingTask>)
          );

          setPostBoardingTasks({promised: false, data: postBoardingTasks});
        },
        err => {
          console.error(err);
          setPostBoardingTasks({promised: false, error: err});
        }
      );
    return () => {
      unsubscribe();
    };
  }, [boardedTaskId, applicationId]);

  return {postBoardingTasks};
};
