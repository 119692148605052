import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ScorecardExecutionStatus} from '@ozark/common';
import {ScorecardServiceLogo} from '@ozark/common/components';
import clsx from 'clsx';
import {AccordionProps, StatusColorMap} from '..';

export const FinicityScorecardAccordion = ({
  scorecardReport,
  application,
  whichExpanded,
  onChange,
}: AccordionProps) => {
  const classes = useStyles();

  const {finicityAccount, bankAccountNumber, routingNumber} = application;

  const reportStatus = finicityAccount
    ? ScorecardExecutionStatus.complete
    : ScorecardExecutionStatus.neverRan;

  const linkedWithFinicity = !!finicityAccount;

  const resultColor = () => {
    if (finicityAccount) {
      return StatusColorMap[ScorecardExecutionStatus.complete];
    }

    return StatusColorMap[ScorecardExecutionStatus.neverRan];
  };

  const resultText = () => {
    if (finicityAccount) {
      return 'Linked';
    }

    if (bankAccountNumber && routingNumber) {
      return 'Manually entered';
    } else {
      return 'Never';
    }
  };

  return (
    <Accordion
      TransitionProps={{unmountOnExit: true}}
      expanded={whichExpanded === scorecardReport.id}
      onChange={onChange(scorecardReport.id)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${scorecardReport.id}-content`}
        id={`panel-${scorecardReport.id}-header`}
      >
        <div className={classes.logo}>
          <ScorecardServiceLogo scorecardService={scorecardReport.serviceName} />
          <div className={classes.panelName}>
            <Typography variant="body1">{scorecardReport.title}</Typography>
          </div>
        </div>
        <div className={classes.column} style={{justifyContent: 'space-between'}}>
          <div>
            <Typography variant="body1">
              Status:&nbsp;&nbsp;
              <span
                style={{
                  color: StatusColorMap[reportStatus],
                }}
              >
                {reportStatus}
              </span>
            </Typography>
          </div>
        </div>
        <div className={clsx(classes.column, classes.grow)}>
          <Typography variant="body1">
            {application.finicityAccount ||
            (application.bankAccountNumber && application.routingNumber) ? (
              <>
                <span>Result:&nbsp;&nbsp;</span>
                <span
                  style={{
                    color: resultColor(),
                    textTransform: 'capitalize',
                  }}
                >
                  {resultText()}
                </span>
              </>
            ) : (
              <span>Last Update:&nbsp;&nbsp; Never</span>
            )}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.details}>
          {finicityAccount && linkedWithFinicity && (
            <Typography variant="h6" component="h2">
              {finicityAccount.bankName} - {bankAccountNumber}
            </Typography>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    column: {
      display: 'flex',
      flexDirection: 'row',
      flexBasis: '20%',
      alignItems: 'center',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '20%',
      alignItems: 'center',
    },
    grow: {
      flexGrow: 1,
      '& > *': {
        margin: theme.spacing(0, 0.5),
      },
    },
    logo: {
      display: 'flex',
      flexDirection: 'row',
      flexBasis: '25%',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    panelName: {
      width: 200,
    },
  })
);
