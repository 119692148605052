import {
  ApplicationEquipment,
  ApplicationView,
  PlacementsValues,
  PlacementType,
  UserRoles,
} from '@ozark/common';
import {Column} from '@ozark/common/api/Column';
import {ButtonExportCsv, ExportProps, getApplicationDate} from '@ozark/common/components';
import {isBefore} from 'date-fns';
import {useMemo} from 'react';
import {useActiveProfiles} from '../hooks/useActiveProfiles';
import {useStore} from '../store/helpers';

const sortApplicationsByDate = (a: ApplicationView, b: ApplicationView) => {
  const {dateRaw: aDate} = getApplicationDate(a);
  const {dateRaw: bDate} = getApplicationDate(b);

  return isBefore(aDate, bDate) ? 1 : -1;
};

const applicationsExportConfig: Column<ApplicationView>[] = [
  {
    id: 'applicationDateLabel',
    numeric: false,
    sortable: false,
    label: 'Date Label',
    export: applicationView => getApplicationDate(applicationView).label,
  },
  {
    id: 'applicationDateValue',
    numeric: false,
    sortable: false,
    label: 'Application Date',
    export: applicationView => getApplicationDate(applicationView).dateString,
  },
  {
    id: 'applicationGroup',
    numeric: false,
    sortable: false,
    label: 'Application Group',
    export: ({group}) => group?.name ?? 'N/A',
  },
  {
    id: 'agentGroupAndName',
    numeric: false,
    sortable: false,
    label: 'Agent Name',
    export: ({agent}) => (agent ? `${agent.firstName} ${agent.lastName}` : ''),
  },
  {
    id: 'distinguishableId',
    numeric: false,
    sortable: false,
    label: 'Application ID',
    export: true,
  },
  {
    id: 'disposition',
    numeric: false,
    sortable: false,
    label: 'Application Disposition',
    export: true,
  },
  {
    id: 'legalBusinessName',
    numeric: false,
    sortable: false,
    label: 'Legal Name',
    export: ({legalBusinessName}) => legalBusinessName ?? '',
  },
  {
    id: 'doingBusinessAs',
    numeric: false,
    sortable: false,
    label: 'DBA Name',
    export: ({doingBusinessAs}) => doingBusinessAs ?? '',
  },
  {
    id: 'signerName',
    numeric: false,
    sortable: false,
    label: 'Signer Name',
    export: ({firstName, lastName}) => `${firstName ?? ''} ${lastName ?? ''}`,
  },
  {
    id: 'businessPhone',
    numeric: false,
    sortable: false,
    label: 'Business Phone Number',
    export: ({businessPhone}) => businessPhone ?? '',
  },
  {
    id: 'customerServiceEmail',
    numeric: false,
    sortable: false,
    label: 'Customer Service Email',
    export: ({customerServiceEmail}) => customerServiceEmail ?? '',
  },
  {
    id: 'mid',
    numeric: false,
    sortable: false,
    label: 'MID',
    export: ({mid}) => mid ?? '',
  },
];

const getGatewayFee = (equipment: ApplicationEquipment | undefined, fee: number | undefined) => {
  if (
    !equipment ||
    !equipment.isGateway ||
    equipment.placementType !== PlacementType.gatewaySetupByProcessor ||
    !fee
  ) {
    return '';
  }
  return fee;
};

function getBoardedApplicationsExportConfig(
  baseColumns: Column<ApplicationView>[]
): Column<ApplicationView>[] {
  return [
    ...baseColumns,
    {
      id: 'platform',
      numeric: false,
      sortable: false,
      label: 'Platform',
      export: ({platform}) => platform ?? '',
    },
    {
      id: 'legalAddress1',
      numeric: false,
      sortable: false,
      label: 'Legal Address 1',
      export: ({businessAddress1}) => businessAddress1 ?? '',
    },
    {
      id: 'legalAddress2',
      numeric: false,
      sortable: false,
      label: 'Legal Address 2',
      export: ({businessAddress2}) => businessAddress2 ?? '',
    },
    {
      id: 'legalCity',
      numeric: false,
      sortable: false,
      label: 'Legal City',
      export: ({businessCity}) => businessCity ?? '',
    },
    {
      id: 'legalState',
      numeric: false,
      sortable: false,
      label: 'Legal State',
      export: ({businessState}) => businessState ?? '',
    },
    {
      id: 'legalZip',
      numeric: false,
      sortable: false,
      label: 'Legal ZIP',
      export: ({businessZip}) => businessZip ?? '',
    },

    {
      id: 'mailingAddress1',
      numeric: false,
      sortable: false,
      label: 'Mailing Address 1',
      export: ({mailingAddress1}) => mailingAddress1 ?? '',
    },
    {
      id: 'mailingAddress2',
      numeric: false,
      sortable: false,
      label: 'Mailing Address 2',
      export: ({mailingAddress2}) => mailingAddress2 ?? '',
    },
    {
      id: 'mailingCity',
      numeric: false,
      sortable: false,
      label: 'Mailing City',
      export: ({mailingCity}) => mailingCity ?? '',
    },
    {
      id: 'mailingState',
      numeric: false,
      sortable: false,
      label: 'Mailing State',
      export: ({mailingState}) => mailingState ?? '',
    },
    {
      id: 'mailingZip',
      numeric: false,
      sortable: false,
      label: 'Mailing ZIP',
      export: ({mailingZip}) => mailingZip ?? '',
    },
    {
      id: 'applicationPricingType',
      numeric: false,
      sortable: false,
      label: 'Pricing Type',
      export: ({rateSet}) => rateSet?.applicationType ?? '',
    },
    {
      id: 'applicationDiscountMethod',
      numeric: false,
      sortable: false,
      label: 'Discount Method',
      export: ({rateSet}) => rateSet?.discountDuration ?? '',
    },
    {
      id: 'equipmentType',
      numeric: false,
      sortable: false,
      label: 'Equipment Type',
      export: ({equipment}) => equipment?.name ?? '',
    },
    {
      id: 'placementType',
      numeric: false,
      sortable: false,
      label: 'Placement Type',
      export: ({equipment}) => PlacementsValues[equipment?.placementType as PlacementType] ?? '',
    },
    {
      id: 'gatewayMonthlyFee',
      numeric: false,
      sortable: false,
      label: 'Gateway Monthly Fee',
      export: ({equipment}) => getGatewayFee(equipment, equipment?.virtualMonthlyFee),
    },
    {
      id: 'gatewayTransFee',
      numeric: false,
      sortable: false,
      label: 'Gateway Trans Fee',
      export: ({equipment}) => getGatewayFee(equipment, equipment?.virtualTransactionFee),
    },
    {
      id: 'industryVertical',
      numeric: false,
      sortable: false,
      label: 'Industry Vertical',
      export: ({industryVertical}) => industryVertical ?? '',
    },
    {
      id: 'riskLevel',
      numeric: false,
      sortable: false,
      label: 'Risk Level',
      export: ({riskLevel}) => riskLevel ?? '',
    },
    {
      id: 'processingType',
      numeric: false,
      sortable: false,
      label: 'Processing Type',
      export: ({processingType}) => processingType ?? '',
    },
    {
      id: 'businessType',
      numeric: false,
      sortable: false,
      label: 'Business Type',
      export: ({businessType}) => businessType ?? '',
    },
    {
      id: 'mcc',
      numeric: false,
      sortable: false,
      label: 'MCC',
      export: ({mcc}) => mcc ?? '',
    },
    {
      id: 'salesResponsibilityCode',
      numeric: false,
      sortable: false,
      label: 'Sales Responsibility Code',
      export: ({salesResponsibilityCode}) => salesResponsibilityCode ?? '',
    },
    {
      id: 'website',
      numeric: false,
      sortable: false,
      label: 'Website',
      export: ({website}) => website ?? '',
    },
    {
      id: 'estimatedMonthlyCreditCardSales',
      numeric: false,
      sortable: false,
      label: 'V/MC/DISC Monthly Sales',
      export: ({estimatedMonthlyCreditCardSales}) => estimatedMonthlyCreditCardSales ?? '',
    },
    {
      id: 'estimatedMonthlyCreditCardSalesAmex',
      numeric: false,
      sortable: false,
      label: 'AMEX Monthly Sales',
      export: ({estimatedMonthlyCreditCardSalesAmex}) => estimatedMonthlyCreditCardSalesAmex ?? '',
    },
    {
      id: 'estimatedAverageSale',
      numeric: false,
      sortable: false,
      label: 'Average Ticket',
      export: ({estimatedAverageSale}) => estimatedAverageSale ?? '',
    },
    {
      id: 'estimatedHighestSale',
      numeric: false,
      sortable: false,
      label: 'High Ticket',
      export: ({estimatedHighestSale}) => estimatedHighestSale ?? '',
    },
  ];
}

type Props = ExportProps & {
  isBoardedAppsExport?: boolean;
};

const ApplicationsExportButton = ({filename, getRows, rows, sx, isBoardedAppsExport}: Props) => {
  const {profiles} = useStore();
  const activeProfiles = useActiveProfiles(profiles);

  const columns: Column<ApplicationView>[] = useMemo(() => {
    const uwNamesById: Record<string, string> = {};
    const underwriters = activeProfiles.byRole[UserRoles.underwriter] ?? [];
    underwriters.forEach(uw => (uwNamesById[uw.id] = uw.displayName));

    const baseColumns = [...applicationsExportConfig];
    baseColumns.push({
      id: 'uwName',
      numeric: false,
      sortable: false,
      label: 'UW Name',
      export: ({uid}) => (uid ? uwNamesById[uid] ?? '' : ''),
    });

    if (!isBoardedAppsExport) {
      return baseColumns;
    }

    return getBoardedApplicationsExportConfig(baseColumns);
  }, [activeProfiles, isBoardedAppsExport]);

  return (
    <ButtonExportCsv
      columnsConfig={columns}
      filename={filename}
      getRows={getRows}
      rows={rows}
      sx={sx}
      sortFn={sortApplicationsByDate}
    />
  );
};

export {ApplicationsExportButton};
