import {Dispositions, SalesDisposition} from '@ozark/common';
import {FilterOption} from '@ozark/common/components';
export const DATETIME_FORMAT = 'MMM dd, yyyy h:mm:ss a';

export const salesDispositionOptions = Object.entries(SalesDisposition).map(([_, value]) => ({
  key: value,
  value: value,
}));
export const applicationsDispositionOptions = Object.entries(Dispositions).map(([_, value]) => ({
  key: value,
  value: value,
}));

export const createdAtDTOptions = [
  {
    key: 'MTD',
    value: 'MTD',
  },
  {
    key: 'YTD',
    value: 'YTD',
  },
];

export const LeadDispositionLabel = 'Lead Disposition';
export const AppDispositionLabel = 'App Disposition';
export const AssigneeLabel = 'Assignee';
export const CreatedAtDT = 'Created To Date';

export const MetricsFilters: FilterOption[] = [
  {
    id: 'source',
    column: 'source',
    label: 'Source',
    type: 'text',
    operators: [
      {
        id: '__like',
        label: 'contains',
      },
    ],
  },
  {
    id: 'campaign',
    column: 'campaign',
    label: 'Campaign',
    type: 'text',
    operators: [
      {
        id: '__like',
        label: 'contains',
      },
    ],
  },
  {
    id: 'leadDisposition',
    column: 'salesDisposition',
    label: LeadDispositionLabel,
    type: 'list',
    options: salesDispositionOptions,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'applicationDisposition',
    column: 'applicationsDisposition',
    label: AppDispositionLabel,
    type: 'list',
    options: applicationsDispositionOptions,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'createdAtDate',
    column: 'createdAtDate',
    label: 'Created Date',
    type: 'date',
    operators: [
      {
        id: '__between',
        label: 'equals',
      },
    ],
  },
  {
    id: 'createdAtDateRange',
    column: 'createdAtDateRange',
    label: 'Created Date Range',
    type: 'dateRange',
    dateFormat: DATETIME_FORMAT,
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'createdAtDateTD',
    column: 'createdAtDateTD',
    label: CreatedAtDT,
    type: 'list',
    options: createdAtDTOptions,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
];
