import {Box, Grow, Stack} from '@mui/material';
import {LoadingStatus} from '../../Analytics/common/LoadingStatus';
import {BoxCentered, BoxParentHeight} from '../../common';
import {useTicketsMerchant} from '../hooks/useTicketsMerchant';
import {TicketsListCard} from './TicketsListCard';

type Props = {
  mid: string;
};

export const TicketsListViewMerchant = ({mid}: Props) => {
  const {tickets} = useTicketsMerchant(mid);
  const loading = Boolean(tickets?.promised);
  const hasData = Boolean(tickets?.data?.length);

  return (
    <>
      {!hasData && (
        <BoxCentered flex={1}>
          <LoadingStatus loading={loading} hasData={hasData} />
        </BoxCentered>
      )}
      {hasData && (
        <BoxParentHeight ml={2}>
          <Stack pt={0.5}>
            {tickets?.data &&
              tickets.data.map(ticket => (
                <Grow in key={ticket.id}>
                  <Box mr={2} mb={2}>
                    {ticket.name}
                    <TicketsListCard ticket={ticket} />
                  </Box>
                </Grow>
              ))}
          </Stack>
        </BoxParentHeight>
      )}
    </>
  );
};
