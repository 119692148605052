import {Grid} from '@mui/material';
import {ChooseTimeControl} from './ChooseTimeControl';

interface Props {
  control: any;
  weekDayName: 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';
  setValue: any;
  errors: any;
  isReadOnly?: boolean;
}

export const DayWorkingHours = ({
  control,
  weekDayName,
  setValue,
  errors,
  isReadOnly = false,
}: Props) => {
  const startLabel = `${weekDayName} start time`;
  const endLabel = `${weekDayName} end time`;
  const startFieldName = `${weekDayName.toLowerCase()}Start`;
  const endFieldName = `${weekDayName.toLowerCase()}End`;
  const workingHoursStartFieldName = `workingHours.${startFieldName}`;
  const workingHoursEndFieldName = `workingHours.${endFieldName}`;
  return (
    <>
      <Grid item xs={12} md={6}>
        <ChooseTimeControl
          control={control}
          isReadOnly={isReadOnly}
          label={startLabel}
          fieldName={workingHoursStartFieldName}
          setValue={setValue}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ChooseTimeControl
          control={control}
          isReadOnly={isReadOnly}
          label={endLabel}
          fieldName={workingHoursEndFieldName}
          setValue={setValue}
          errors={errors}
        />
      </Grid>
    </>
  );
};
