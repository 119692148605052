import {Box, Button, Divider, Paper, Typography} from '@mui/material';
import {
  ApplicationView,
  Collections,
  createAuditNonce,
  Firebase,
  useLeadAssociatedApplications,
  useNotification,
} from '@ozark/common';
import {useState} from 'react';
import {ApplicationAssociationsList} from '../../../ApplicationAssociations';
import {AddApplicationAssociationDialog} from '../../../ApplicationAssociations/AddApplicationAssociationDialog';
import {ApplicationAutocompleteItem} from '../../../Search/ApplicationAutocomplete';

export type Props = {
  leadId: string;
};

export const SalesLeadApplicationAssociations = ({leadId}: Props) => {
  const {documents: associatedApplications} = useLeadAssociatedApplications(leadId);
  const showNotification = useNotification();
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const closeAddAssociation = () => {
    setIsDialogOpen(false);
  };

  const onAddApplicationAssociation = async (application: ApplicationAutocompleteItem) => {
    try {
      setLoading(true);

      const applicationRef = Firebase.firestore
        .collection(Collections.applications)
        .doc(application.id);

      const applicationSnap = await applicationRef.get();
      const hasApplicationAlreadyAssociatedWithLead = !!applicationSnap.get('leadId');

      if (hasApplicationAlreadyAssociatedWithLead) {
        showNotification('error', 'This application has already been associated with a lead');
        setLoading(false);
        return;
      }

      const auditNonce = createAuditNonce(Firebase.auth.currentUser!.uid);
      await applicationRef.update({
        auditNonce,
        leadId,
      });

      setLoading(false);
      closeAddAssociation();
      showNotification('success', 'The application is associated with the lead');
    } catch (err) {
      console.error('Failed to associate the lead with application with an error:', err);
      showNotification('error', 'Failed to associate the lead with application');
      setLoading(false);
    }
  };

  const removeApplicationAssociation = async (applicationToRemove: ApplicationView | null) => {
    if (!applicationToRemove?.id) {
      return;
    }

    try {
      const auditNonce = createAuditNonce(Firebase.auth.currentUser!.uid);
      await Firebase.firestore
        .collection(Collections.applications)
        .doc(applicationToRemove.id)
        .update({
          auditNonce,
          leadId: Firebase.FieldValue.delete(),
        });
      showNotification('success', 'The application association is removed');
    } catch (err) {
      console.error('Failed to remove application association with an error:', err);
      showNotification('error', 'Failed to remove application association');
    }
  };

  return (
    <Paper sx={{mt: 0, mb: 2, p: 2}}>
      <Box>
        <Typography sx={{fontSize: '1.2rem'}} variant="caption" gutterBottom>
          Application Associations
        </Typography>
        <Divider />
      </Box>
      <Box>
        <ApplicationAssociationsList
          applications={associatedApplications.data}
          onRemove={removeApplicationAssociation}
        />
        <Button variant="outlined" onClick={() => setIsDialogOpen(true)}>
          Add Application
        </Button>
      </Box>
      <AddApplicationAssociationDialog
        open={isDialogOpen}
        loading={loading}
        onSave={onAddApplicationAssociation}
        onClose={closeAddAssociation}
      />
    </Paper>
  );
};
