import {Box} from '@mui/material';
import {Group, SubmittedPageSettings} from '@ozark/common';
import {
  SubmittedPageContent,
  SubmittedPageFooter,
  SubmittedPageHead,
} from '@ozark/common/components';

type Props = {
  submittedPageSettings?: SubmittedPageSettings;
  group: Group;
};

export const Preview = ({group, submittedPageSettings}: Props) => {
  if (group.applicationSettings?.enableRegistration) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
          py: 8,
          color: theme => theme.palette.grey[700],
        }}
      >
        Submitted page customization is not available when merchant registration is enabled for the
        group
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        pt: 4,
        maxWidth: 900,
        margin: 'auto',
      }}
    >
      <SubmittedPageHead group={group} />
      <SubmittedPageContent
        title={submittedPageSettings?.title}
        subTitle={submittedPageSettings?.subTitle}
        applicationDistinguishableId="EX12MP34"
      />
      <SubmittedPageFooter group={group} customFooterText={submittedPageSettings?.footerText} />
    </Box>
  );
};
