import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {Box, Button, CircularProgress, Divider, Fade, Grid, Paper} from '@mui/material';
import {Lead, LeadView, useNotification} from '@ozark/common';
import {Fragment, useState} from 'react';
import {useForm} from 'react-hook-form';
import {SalesLeadForm, SalesLeadFormData, SalesLeadSchema} from '../components/SalesLeadForm';

export type Props = {
  lead: LeadView;
  setLeadData: (data: Partial<Lead>) => Promise<void>;
};

export const SalesLeadEdit = ({lead, setLeadData}: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const showNotification = useNotification();

  const hookForm = useForm<SalesLeadFormData>({
    resolver: yupResolver(SalesLeadSchema),
    shouldUnregister: true,
  });

  const {formState, handleSubmit} = hookForm;

  const {isDirty} = formState;

  const onSuccess = async (data: SalesLeadFormData) => {
    try {
      setLoading(true);
      await setLeadData(data);

      showNotification('success', 'Lead successfully updated.');
    } catch (err) {
      showNotification('error', 'Failed to Save Changes.');
    } finally {
      setLoading(false);
    }
  };

  const onError = async () => {
    showNotification('error', 'Failed to Save Changes.');
  };

  return (
    <Paper sx={{mb: 2}}>
      <Box sx={{mt: 0, p: [1, 2, 2]}}>
        <Grid container spacing={2}>
          <SalesLeadForm hookForm={hookForm} lead={lead} editMode />
          <Fade in={isDirty} unmountOnExit={false}>
            <Grid item xs={12}>
              <Divider sx={{mb: 2}} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={loading}
                  onClick={handleSubmit(onSuccess, onError)}
                >
                  {loading ? <CircularProgress size={24} /> : <Fragment>Save Changes</Fragment>}
                </Button>
              </Box>
            </Grid>
          </Fade>
        </Grid>
      </Box>
    </Paper>
  );
};
