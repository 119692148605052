import {MenuItem, TextField} from '@mui/material';
import {getSalesLeadColor, SalesDisposition} from '@ozark/common';
import React from 'react';

const salesDispositionsOptions = Object.values(SalesDisposition);

type Props = {
  disposition: SalesDisposition;
  updateDisposition: (value: SalesDisposition) => void;
};

export const DispositionSelector = ({disposition, updateDisposition}: Props) => {
  const onDispositionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateDisposition(event.target.value as SalesDisposition);
  };

  return (
    <TextField
      value={String(disposition)}
      onChange={onDispositionChange}
      variant="standard"
      InputProps={{
        disableUnderline: true,
        sx: {
          '&.Mui-focused .MuiSelect-select': {
            backgroundColor: 'transparent',
          },
        },
      }}
      select
    >
      {salesDispositionsOptions.map(item => (
        <MenuItem key={item} value={item}>
          <span style={{color: getSalesLeadColor(item)}}>&bull;&bull;&bull;&nbsp;&nbsp;</span>
          {item}
        </MenuItem>
      ))}
    </TextField>
  );
};
