import {
  Collections,
  Dictionary,
  Firebase,
  selectDictionaryView,
  UniversalSnapshot,
} from '@ozark/common';
import {useCallback} from 'react';

export const useDictionaries = () => {
  const riskLabelsDictionaryName = 'riskLabels';

  const getDictionaryList = useCallback(async (dictionaryName: string): Promise<string[]> => {
    const snapshot = await Firebase.firestore
      .collection(Collections.dictionaries)
      .doc(dictionaryName)
      .get();

    if (!snapshot.exists) return [];

    const dictionary = selectDictionaryView(snapshot as UniversalSnapshot<Dictionary>);
    return dictionary.values;
  }, []);

  const addDictionaryItems = useCallback(
    async (dictionaryName: string, items: string[]): Promise<void> => {
      if (items.length === 0) {
        return;
      }
      const ref = await Firebase.firestore.collection(Collections.dictionaries).doc(dictionaryName);
      await ref.set(
        {
          values: Firebase.FieldValue.arrayUnion(...items),
        },
        {merge: true}
      );
    },
    []
  );

  const getRiskLabels = useCallback(async () => {
    const defaultLabels = [
      'First Batch Review',
      'Conditionally Approved',
      '90 Day Watchlist',
      'Sister MID/Multiple MID Indicator',
      'Risk Review',
      'Funding Restriction',
      'Reserve Release Eligible',
    ];
    const customLabels = await getDictionaryList(riskLabelsDictionaryName);
    return [...new Set([...defaultLabels, ...customLabels])];
  }, [getDictionaryList]);

  const addRiskLabels = useCallback(
    (labels: string[]) => {
      return addDictionaryItems(riskLabelsDictionaryName, labels);
    },
    [addDictionaryItems]
  );

  return {
    getRiskLabels,
    addRiskLabels,
  };
};
