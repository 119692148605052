import {yupResolver} from '@hookform/resolvers/yup';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ImageIcon from '@mui/icons-material/Image';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import {
  Alert,
  Button,
  CardMedia,
  CircularProgress,
  Divider,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import MuiTextField from '@mui/material/TextField';
import {
  AgentView,
  Department,
  Platforms,
  ProfileView,
  useAutoAssignLeadsConfig,
  useCallable,
  UserRoles,
  useUserInfo,
} from '@ozark/common';
import {Loading, Select, TextField, Title, UploadImageButton} from '@ozark/common/components';
import {useAgents} from '@ozark/common/hooks/useAgents';
import {pick} from '@s-libs/micro-dash';
import PhoneNumber from 'awesome-phonenumber';
import {format} from 'date-fns';
import {ChangeEvent, ReactNode, useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Redirect, useHistory} from 'react-router';
import * as yup from 'yup';
import * as ROUTES from '../../constants/routes';
import {useNotification} from '../../hooks/useNotification';
import {useProfileView} from '../../hooks/useProfileView';
import {Tab, Tabs} from '../Tabs';
import {WorkingHours, workingHoursSchema} from '../WorkingHours';

const getAgentLabel = (agent: AgentView) =>
  `${agent.firstName} ${agent.lastName} (${agent.group?.name ?? '-'})`;

const InteractiveBackdrop = ({children}: {children: ReactNode}) => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      height: 'calc(100% + 2px)',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.5)',
      color: 'rgba(255,255,255,0.6)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
    }}
  >
    {children}
  </Box>
);

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
  cellPhone: yup
    .string()
    .matches(/^\+1[2-9]{1}[0-9]{9}$/, 'Must be a valid phone number')
    .transform(value => {
      return value ? new PhoneNumber(value, 'US').getNumber('e164') : value;
    })
    .required(),
  phoneNumber: yup
    .string()
    .matches(/^\+1[2-9]{1}[0-9]{9}$/, 'Must be a valid phone number')
    .transform(value => {
      return value ? new PhoneNumber(value, 'US').getNumber('e164') : value;
    })
    .required(),
  phoneExt: yup.string(),
  role: yup.string().required(),
  department: yup.string().when('role', (role: UserRoles, schema: any) => {
    if (role !== UserRoles.auditor) {
      return schema.required('Department is required');
    }
  }),
  platform: yup.string().when('role', (role: UserRoles, schema: any) => {
    if (role === UserRoles.auditor) {
      return schema.required('Platform is required');
    }
  }),
  jobTitle: yup.string().required(),
  workingHours: yup.object().when('role', (role: UserRoles, schema: any) => {
    if (role === UserRoles.sales) {
      return workingHoursSchema.required();
    }
    return schema.optional();
  }),
});

enum UserProfileTab {
  Edit = 'Edit',
  Statistics = 'Statistics',
  ActivityLog = 'ActivityLog',
}

const getFormData = (profile: ProfileView) => {
  const result = pick(
    profile,
    'firstName',
    'lastName',
    'email',
    'cellPhone',
    'phoneNumber',
    'phoneExt',
    'role',
    'department',
    'jobTitle',
    'platform',
    'associatedAgents',
    'workingHours'
  );
  if (!result.workingHours) {
    result.workingHours = {};
  }

  return result;
};

type Props = {
  uid: string | undefined;
};

const UsersDetail = ({uid}: Props) => {
  const {profile, set} = useProfileView(uid as string); // We are sure that the user is authorized here
  const [formProfile, setFormProfile] = useState(profile.data);

  const [tab, setTab] = useState(UserProfileTab.Edit);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const history = useHistory();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('lg'));
  const {toggleUserActive, setProfileImage} = useCallable();
  const showNotification = useNotification();
  const {isErpAdmin, authUser} = useUserInfo();
  const {agents} = useAgents(true);

  const isOwnProfile = useMemo(() => authUser.data?.uid === uid, [authUser, uid]);

  const {
    document: {data: autoAssignLeadsConfig},
    toggleUid,
  } = useAutoAssignLeadsConfig();

  const {
    formState: {errors, isDirty},
    control,
    reset,
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const associatedAgents = watch('associatedAgents') ?? [];

  const watchRole = watch('role');

  useEffect(() => {
    if (profile.promised || formProfile) {
      return;
    }

    setFormProfile(profile.data);
    reset(getFormData(profile.data as ProfileView));
  }, [profile.promised, profile.data, formProfile, reset]);

  const handleToggle =
    (field: 'isActive' | 'autoAssignLeads') =>
    async (_event: ChangeEvent<HTMLInputElement>, _isChecked: boolean) => {
      if (!profile?.data) {
        return;
      }

      if (field === 'isActive') {
        await toggleUserActive({uid: profile.data.uid});
      }

      if (field === 'autoAssignLeads') {
        try {
          await toggleUid(profile.data.uid);
          showNotification('success', 'Auto Assign Leads setting was saved successfully');
        } catch (err) {
          showNotification('error', 'An error occurred while saving Auto Assign Leads setting');
        }
      }
    };

  const handleTabChange = (_event: ChangeEvent<{}>, newTab: UserProfileTab) => {
    setTab(newTab);
  };

  const onSuccess = async (data: any) => {
    if (!profile.data?.uid) {
      return;
    }

    setLoading(true);

    try {
      await set(profile.data?.uid, data);

      reset({...getFormData(profile.data), ...data});

      showNotification('success', 'User successfully updated.');
    } catch (err) {
      console.error(err);
      showNotification('error', 'Failed to Save Changes.');
    } finally {
      setLoading(false);
    }
  };

  if (profile.promised) {
    return <Loading />;
  }

  if (!profile.data) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      {isErpAdmin && (
        <Title
          breadcrumbs={
            smDown
              ? [
                  <IconButton onClick={() => history.goBack()} size="large">
                    <ChevronLeftIcon />
                  </IconButton>,
                ]
              : [
                  <Link
                    component="button"
                    variant="body1"
                    onClick={() => history.push(ROUTES.USERS)}
                  >
                    Users
                  </Link>,
                  <Typography variant="body1">{profile.data.displayName}</Typography>,
                ]
          }
        >
          <Tabs value={tab} onChange={handleTabChange} centered>
            <Tab label="Edit Profile" value={UserProfileTab.Edit} />

            <Tab disabled label="Statistics" value={UserProfileTab.Statistics} />

            <Tab disabled label="Activity Log" value={UserProfileTab.ActivityLog} />
          </Tabs>
        </Title>
      )}
      {!isErpAdmin && !isOwnProfile && (
        <Alert severity="info">
          You currently have <b>read only</b> access to this page.
        </Alert>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={5} lg={4} xl={2}>
          <CardMedia
            image={profile.data.photoUrl}
            title={profile.data.displayName}
            sx={{
              position: 'relative',
              height: 0,
              paddingTop: '100%',
              borderRadius: '50%',
            }}
          >
            {uploading && (
              <InteractiveBackdrop>
                <CircularProgress size={24} />
              </InteractiveBackdrop>
            )}

            {!profile.data.isActive && (
              <InteractiveBackdrop>
                <Typography variant="h4">Inactive</Typography>
              </InteractiveBackdrop>
            )}

            {!profile.data.photoUrl && !uploading && (
              <InteractiveBackdrop>
                <Typography variant="h4">No photo</Typography>
              </InteractiveBackdrop>
            )}
          </CardMedia>

          <UploadImageButton
            fullWidth
            color="secondary"
            disabled={uploading || (!isErpAdmin && !isOwnProfile)}
            startIcon={<ImageIcon />}
            sx={{margin: theme.spacing(1.5, 0, 0)}}
            onError={() => {
              setUploading(false);
            }}
            onProgress={(progress: number) => {
              if (progress < 100) {
                setUploading(true);
              }
            }}
            onSuccess={async (photoUrl: string) => {
              const result = await setProfileImage({
                uid: profile!.data!.uid,
                photoUrl,
              });
              if (result.status === 'error') {
                showNotification('error', 'Something went wrong, please try again later');
              }
              setUploading(false);
            }}
          >
            Upload Profile Picture
          </UploadImageButton>

          <List sx={{marginBottom: 0, paddingBottom: 0}}>
            {isErpAdmin && (
              <>
                <Divider />

                <ListItem>
                  <ListItemText
                    id="switch-list-label-isActive"
                    primary={profile.data.isActive ? 'Active' : 'Inactive'}
                    secondary="Account Status"
                  />

                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      color="secondary"
                      onChange={handleToggle('isActive')}
                      checked={profile.data.isActive}
                      inputProps={{
                        'aria-labelledby': 'switch-list-label-isActive',
                      }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </>
            )}

            <Divider />

            {isErpAdmin && profile.data.department === Department.sales && (
              <>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-autoAssignLeads"
                    primary={
                      autoAssignLeadsConfig?.uids?.includes(profile.data.uid)
                        ? 'Enabled'
                        : 'Disabled'
                    }
                    secondary="Sales Campaign Auto Assign Leads"
                  />
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      color="secondary"
                      onChange={handleToggle('autoAssignLeads')}
                      checked={autoAssignLeadsConfig?.uids?.includes(profile.data.uid)}
                      inputProps={{
                        'aria-labelledby': 'switch-list-label-autoAssignLeads',
                      }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </>
            )}

            <ListItem>
              <ListItemText
                primary={format(profile.data.lastLoginAt.toDate(), 'MM/dd/yyyy h:mm a')}
                secondary="Last Login"
              />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12} md={7} lg={8} xl={10}>
          <Paper sx={{marginTop: 0, padding: theme.spacing(1, 2, 2), position: 'relative'}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    height: '48px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                  }}
                >
                  <Typography variant="h6">Edit Profile</Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  name="firstName"
                  label="First Name"
                  errors={errors}
                  control={control}
                  disabled={!isErpAdmin}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  name="lastName"
                  label="Last Name"
                  errors={errors}
                  control={control}
                  disabled={!isErpAdmin}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="jobTitle"
                  label="Title"
                  errors={errors}
                  control={control}
                  disabled={!isErpAdmin}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="email"
                  label="Email"
                  errors={errors}
                  control={control}
                  disabled={!isErpAdmin && !isOwnProfile}
                  placeholder="user@ozarkfi.com"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AlternateEmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="cellPhone"
                  label="Cell Phone"
                  errors={errors}
                  control={control}
                  placeholder="(999) 999-9999"
                  disabled={!isErpAdmin && !isOwnProfile}
                  transform={{
                    pattern: '(999) 999-9999',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIphoneIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} md={8}>
                <TextField
                  name="phoneNumber"
                  label="Phone Number"
                  errors={errors}
                  control={control}
                  placeholder="(999) 999-9999"
                  disabled={!isErpAdmin && !isOwnProfile}
                  transform={{
                    pattern: '(999) 999-9999',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  name="phoneExt"
                  label="Extension"
                  errors={errors}
                  control={control}
                  disabled={!isErpAdmin && !isOwnProfile}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">x</InputAdornment>,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Select
                  name="role"
                  label="Role"
                  errors={errors}
                  control={control}
                  disabled={!isErpAdmin}
                >
                  {Object.values(UserRoles).sortAndMap(e => (
                    <MenuItem key={e} value={e}>
                      {e}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              {watchRole !== UserRoles.auditor && (
                <Grid item xs={12}>
                  <Select
                    name="department"
                    label="Department"
                    errors={errors}
                    control={control}
                    disabled={!isErpAdmin}
                  >
                    {Object.values(Department).sortAndMap(e => (
                      <MenuItem key={e} value={e}>
                        {e}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {watchRole && watchRole === UserRoles.auditor && (
                <Grid item xs={12}>
                  <Select name="platform" label="Platform" errors={errors} control={control}>
                    {Object.values(Platforms).sortAndMap(e => (
                      <MenuItem key={e} value={e}>
                        {e}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}
              {isErpAdmin && (
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth
                    multiple
                    getOptionLabel={getAgentLabel}
                    options={agents.data ?? []}
                    value={
                      agents.data ? agents.data.filter(x => associatedAgents.includes(x.id)) : []
                    }
                    renderInput={params => <MuiTextField {...params} label="Associated Agents" />}
                    renderOption={(props, agent: AgentView, {selected}) => (
                      <MenuItem {...props} key={agent.id}>
                        <Checkbox checked={selected} />
                        <ListItemText primary={getAgentLabel(agent)} />
                      </MenuItem>
                    )}
                    onChange={(_event, selectedAgents) => {
                      setValue(
                        'associatedAgents',
                        selectedAgents.map(x => x.id),
                        {shouldDirty: true}
                      );
                    }}
                  />
                </Grid>
              )}

              {watchRole === UserRoles.sales && (
                <>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        height: '48px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Typography variant="h6">Working hours</Typography>
                    </Box>
                  </Grid>

                  <WorkingHours
                    isReadOnly={!isErpAdmin && !isOwnProfile}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                  />
                </>
              )}

              <Fade in={isDirty}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Fade>

              <Fade in={isDirty} unmountOnExit={false}>
                <Grid item xs={12}>
                  <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                    <Button
                      color="primary"
                      disabled={loading}
                      sx={{width: '150px'}}
                      variant="outlined"
                      onClick={handleSubmit(onSuccess)}
                    >
                      {loading ? <CircularProgress size={24} /> : <>Save Changes</>}
                    </Button>
                  </Box>
                </Grid>
              </Fade>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default UsersDetail;
