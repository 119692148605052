import {useCallback, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {CALENDAR} from '..';
import {CalendarEvent, Collections, Firebase} from '../..';
import {CalendarEventForm} from './CalendarEventForm';
import {mapCalendarEvent} from './utils';

export const CalendarEventFormEdit = () => {
  const {id} = useParams<{id: string}>();
  const history = useHistory();
  const [event, setEvent] = useState<CalendarEvent>();
  const setOpen = useCallback((open: boolean) => !open && history.push(CALENDAR), []);
  const handleSubmit = useCallback((data: CalendarEvent) => {
    if (data.association && !data.association.type) {
      delete data.association;
    }
    handleEventSave(data);
    setOpen(false);
  }, []);
  useEffect(() => {
    if (id && id !== event?.id) {
      getEvent(id).then(ev => ev && setEvent(ev));
    }
  }, [id]);

  if (!event) return null;

  return (
    <CalendarEventForm
      title={`Edit ${event.type}`}
      onSubmit={handleSubmit}
      event={event}
      open
      setOpen={setOpen}
    />
  );
};

const handleEventSave = async (event?: CalendarEvent) => {
  if (!event?.id) return;
  try {
    await Firebase.firestore
      .collection(Collections.calendar)
      .doc(event.id)
      .set(event, {merge: true});
  } catch (error) {
    console.error(error);
  }
};

const getEvent = async (eventId: string): Promise<CalendarEvent | null> => {
  try {
    if (!eventId) throw new Error('eventId is null');

    const snap = await Firebase.firestore.collection(Collections.calendar).doc(eventId).get();
    if (snap.exists) {
      const event = mapCalendarEvent(snap);
      return event;
    }
  } catch (error) {
    console.error(error);
  }
  return null;
};
