import {
  ApplicationData,
  ApplicationSubCollection,
  ApplicationView,
  AsyncState,
  Collections,
  Department,
  Firebase,
  RISK_STATUS_OPTIONS,
  useDictionaries,
  useNotification,
} from '@ozark/common';
import {useNotes} from '@ozark/common/hooks/useNotes';
import {useUserInfo} from '@ozark/common/hooks/useUserInfo';
import {FirebaseDocumentUpdates} from '@ozark/common/types/utils';
import {isEqual} from '@s-libs/micro-dash';
import {format} from 'date-fns';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {SubmitHandler, UseFormReturn} from 'react-hook-form';
import {useStore} from '../../../store/helpers';
import {ApplicationRiskInfoFormModel} from './types';

export const useRiskModuleIsReadOnly = (): boolean => {
  const {isErpAdmin} = useUserInfo();
  const {authProfile} = useStore();

  return !isErpAdmin && authProfile.data?.department !== Department.risk;
};

const useRiskLabels = () => {
  const {getRiskLabels, addRiskLabels} = useDictionaries();
  const [riskLabelDictionaryValues, setRiskLabelDictionaryValues] = useState<AsyncState<string[]>>({
    promised: true,
  });

  const loadRiskLabels = useCallback(async () => {
    try {
      const data = await getRiskLabels();
      setRiskLabelDictionaryValues({promised: false, data});
    } catch (err) {
      console.error('Failed to get risk labels with an error:', err);
    }
  }, [getRiskLabels]);

  useEffect(() => {
    loadRiskLabels();
  }, [loadRiskLabels]);

  const handleRiskLabelsChanged = useCallback(
    async (riskLabels: string[]) => {
      if (riskLabelDictionaryValues.promised || !riskLabelDictionaryValues.data) {
        return;
      }
      const newRiskLabels = riskLabels.filter(x => !riskLabelDictionaryValues.data?.includes(x));

      if (!newRiskLabels.length) {
        return;
      }

      await addRiskLabels(newRiskLabels);
      loadRiskLabels();
    },
    [addRiskLabels, loadRiskLabels, riskLabelDictionaryValues]
  );

  return {
    riskLabelDictionaryValues,
    handleRiskLabelsChanged,
  };
};

const getNoteMsg = (statusName: string, statusValue: string | undefined | null) => {
  return !statusValue ? `${statusName} was cleared` : `${statusName} changed to "${statusValue}"`;
};

export const useRiskSection = (
  application: ApplicationView,
  hookForm: UseFormReturn<ApplicationRiskInfoFormModel>
) => {
  const showNotification = useNotification();
  const [loading, setLoading] = useState(false);
  const {reset, setValue} = hookForm;
  const {riskLabelDictionaryValues, handleRiskLabelsChanged} = useRiskLabels();
  const applicationFirebaseRef = useMemo(() => {
    return Firebase.firestore.collection(Collections.applications).doc(application.id);
  }, [application]);
  const {addNote} = useNotes({
    docFirebaseRef: applicationFirebaseRef,
    notesSubCollection: ApplicationSubCollection.uwRiskNotes,
  });

  const addChangesNote = useCallback(
    async (data: ApplicationRiskInfoFormModel) => {
      const isValueChanged = (
        initialValue: string | string[] | null | undefined,
        value: string | string[] | null | undefined
      ): boolean => {
        if (!initialValue && !value) {
          return false;
        }

        return !isEqual(initialValue, value);
      };

      const changes = [
        isValueChanged(application.accountStatus?.status, data.accountStatus)
          ? getNoteMsg('Account Status', data.accountStatus)
          : null,
        isValueChanged(application.accountStatus?.subCategory, data.accountStatusSubCategory)
          ? getNoteMsg('Account Sub Category', data.accountStatusSubCategory)
          : null,
        isValueChanged(
          application.accountStatus?.eligibleReleaseDate?.toDate()?.toString(),
          data.eligibleReleaseDate?.toString()
        )
          ? getNoteMsg(
              'Eligible Release Date',
              data.eligibleReleaseDate ? format(data.eligibleReleaseDate, 'MM/dd/yyyy') : null
            )
          : null,
        isValueChanged(application.riskStatus, data.riskStatus)
          ? getNoteMsg('Risk Status', data.riskStatus)
          : null,
        isValueChanged(application.riskLabels, data.riskLabels)
          ? getNoteMsg('Risk Labels', data.riskLabels?.join(', ') ?? null)
          : null,
      ];
      const noteText = changes.filter(item => !!item).join('<br />');

      if (!noteText) {
        return;
      }
      await addNote(noteText);
    },
    [
      application.accountStatus?.status,
      application.accountStatus?.subCategory,
      application.accountStatus?.eligibleReleaseDate,
      application.riskStatus,
      application.riskLabels,
      addNote,
    ]
  );

  const handleSaveChanges: SubmitHandler<ApplicationRiskInfoFormModel> = useCallback(
    async data => {
      try {
        const {
          accountStatus,
          accountStatusSubCategory,
          riskLabels,
          riskStatus,
          eligibleReleaseDate,
          statusChangeDate,
        } = data;

        setLoading(true);

        const applicationData: FirebaseDocumentUpdates<ApplicationData> = {
          accountStatus: {
            status: accountStatus ?? Firebase.FieldValue.delete(),
            subCategory: accountStatusSubCategory ?? Firebase.FieldValue.delete(),
            eligibleReleaseDate: eligibleReleaseDate
              ? Firebase.Timestamp.fromDate(eligibleReleaseDate)
              : Firebase.FieldValue.delete(),
            statusChangeDate: statusChangeDate,
          },
          riskLabels,
          riskStatus: riskStatus ?? Firebase.FieldValue.delete(),
          riskAssigneeUid:
            application.riskAssigneeUid && application.riskStatus && !riskStatus
              ? null
              : application.riskAssigneeUid,
        };

        await Firebase.firestore
          .collection(Collections.applications)
          .doc(application.id)
          .set(applicationData, {merge: true});
        await addChangesNote(data);
        if (riskLabels) {
          await handleRiskLabelsChanged(riskLabels);
        }
        reset();

        showNotification('success', 'Risk information was successfully updated');
      } catch (err) {
        showNotification('error', 'Failed to Save Changes.');
      } finally {
        setLoading(false);
      }
    },
    [
      application.id,
      application.riskAssigneeUid,
      application.riskStatus,
      showNotification,
      reset,
      addChangesNote,
      handleRiskLabelsChanged,
    ]
  );

  const handleAccountStatusChanged = useCallback(() => {
    setValue('accountStatusSubCategory', null);
    setValue('eligibleReleaseDate', null);
    setValue('statusChangeDate', Firebase.Timestamp.now());
  }, [setValue]);

  useEffect(() => {
    reset({
      accountStatus: application.accountStatus?.status ?? null,
      accountStatusSubCategory: application.accountStatus?.subCategory ?? null,
      eligibleReleaseDate: application.accountStatus?.eligibleReleaseDate?.toDate() ?? null,
      riskStatus: application.riskStatus ?? null,
      riskLabels: application.riskLabels,
    });
  }, [
    application.accountStatus?.status,
    application.accountStatus?.subCategory,
    application.accountStatus?.eligibleReleaseDate,
    application.riskStatus,
    application.riskLabels,
    reset,
  ]);

  return {
    loading,
    handleAccountStatusChanged,
    handleSaveChanges,
    riskLabelDictionaryValues,
    allRiskStatuses: RISK_STATUS_OPTIONS,
  };
};
