import {AgentSubCollection, Collections, Firebase} from '@ozark/common';
import {Notes} from '@ozark/common/components/Notes';
import {useUserInfo} from '@ozark/common/hooks/useUserInfo';
import {useMemo} from 'react';

export const AgentNotes = ({
  agentId,
  allowAddComments = true,
}: {
  agentId: string;
  allowAddComments: boolean;
}) => {
  const {isErpAdmin} = useUserInfo();

  const applicationFirebaseRef = useMemo(() => {
    return Firebase.firestore.collection(Collections.agents).doc(agentId);
  }, [agentId]);

  return (
    <Notes
      allowAddComments={allowAddComments}
      allowEditMessages={isErpAdmin}
      allowDeleteMessages={isErpAdmin}
      firestoreDocumentRef={applicationFirebaseRef}
      notesSubCollection={AgentSubCollection.notes}
    />
  );
};
