import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ScorecardAction, UniversalTimestamp, useCallable} from '@ozark/common';
import {ScorecardServiceLogo} from '@ozark/common/components';
import clsx from 'clsx';
import {format} from 'date-fns';
import {useCallback, useState} from 'react';
import ReactJson from 'react-json-view';
import {AccordionProps, StatusColorMap} from '..';
import {useRunScore} from './hooks/useRunScore';

type Props = {action: ScorecardAction} & AccordionProps;

export const SingleStepScorecardAccordion = ({
  scorecardReport,
  scores: score,
  application,
  whichExpanded,
  onChange,
  onSubmit,
  action,
}: Props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const {runScorecardService} = useCallable();

  const scorecard = score && score[action];
  const handleRunClick = useCallback(async () => {
    setLoading(true);
    await runScorecardService({
      applicationId: application.id,
      scorecardName: scorecardReport.serviceName,
      scorecardReportId: scorecardReport.id,
      scorecardAction: action,
    });
    onSubmit?.();
    // eslint-disable-next-line
  }, []);

  const {isLoading, reportStatus} = useRunScore(
    setLoading,
    handleRunClick,
    loading,
    scorecard?.status,
    scorecardReport.id
  );

  return (
    <Accordion
      TransitionProps={{unmountOnExit: true}}
      expanded={whichExpanded === scorecardReport.id}
      onChange={onChange(scorecardReport.id)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${scorecardReport.id}-content`}
        id={`panel-${scorecardReport.id}-header`}
      >
        <div className={classes.logo}>
          <ScorecardServiceLogo scorecardService={scorecardReport.serviceName} />
          <div className={classes.panelName}>
            <Typography variant="body1">{scorecardReport.title}</Typography>
          </div>
        </div>
        <div className={classes.column} style={{justifyContent: 'space-between'}}>
          <div>
            <Typography variant="body1">
              Status:&nbsp;&nbsp;
              <span
                style={{
                  color: StatusColorMap[reportStatus],
                }}
              >
                {reportStatus}
              </span>
            </Typography>
          </div>
          {isLoading && <CircularProgress style={{color: '#1877f2', marginRight: 20}} size={25} />}
        </div>
        <div className={clsx(classes.column, classes.grow)}>
          <Typography variant="body1">
            Last Update:&nbsp;&nbsp;
            <span>
              {scorecard && scorecard.updatedAt
                ? format((scorecard.updatedAt as UniversalTimestamp).toDate(), 'MM/dd/yyyy h:mm a')
                : 'Never'}
            </span>
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {scorecard && (
              <ReactJson name={application.id} src={scorecard.results!} sortKeys collapsed={3} />
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
      <Divider />
      <AccordionActions className={classes.actionsRoot}>
        <Button color="primary" variant="contained" disabled={loading} onClick={handleRunClick}>
          Run
        </Button>
      </AccordionActions>
    </Accordion>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      verticalAlign: 'bottom',
      height: 20,
      width: 20,
    },
    column: {
      display: 'flex',
      flexDirection: 'row',
      flexBasis: '20%',
      alignItems: 'center',
    },
    grow: {
      flexGrow: 1,
      '& > *': {
        margin: theme.spacing(0, 0.5),
      },
    },
    logo: {
      display: 'flex',
      flexDirection: 'row',
      flexBasis: '25%',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    processingTypeTitle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      '& > *': {
        marginRight: theme.spacing(2),
      },
    },
    primaryColor: {
      color: theme.palette.primary.main,
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    panelName: {
      width: 200,
    },
    actionsRoot: {
      justifyContent: 'center',
      paddingBottom: theme.spacing(2),
    },
  })
);
