export const DisputesIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <path
          opacity={0.4}
          d="M448,0H64C28.7,0,0,28.7,0,64v288c0,35.3,28.7,64,64,64h96v84c0,6.6,5.3,12,12,12c2.6,0,5.1-0.8,7.1-2.3
		L304,416h144c35.3,0,64-28.7,64-64V64C512,28.7,483.3,0,448,0z M256,336c-17.7,0-32-14.3-32-32s14.3-32,32-32s32,14.3,32,32
		S273.7,336,256,336z M294.2,97.6l-12.8,128c-0.8,8.2-7.7,14.4-15.9,14.4h-19c-8.2,0-15.1-6.2-15.9-14.4l-12.8-128
		c-0.9-8.8,5.6-16.7,14.4-17.5c0.5,0,1-0.1,1.5-0.1h44.6c8.8,0,16,7.2,16,16C294.3,96.5,294.3,97.1,294.2,97.6L294.2,97.6z"
        />
        <path
          d="M256,272c-17.7,0-32,14.3-32,32s14.3,32,32,32s32-14.3,32-32S273.7,272,256,272z M278.3,80h-44.6
		c-8.9,0-16,7.3-16,16.1c0,0.5,0,1,0.1,1.5l12.8,128c0.8,8.2,7.7,14.4,15.9,14.4h19c8.2,0,15.1-6.2,15.9-14.4l12.8-128
		c0.9-8.8-5.5-16.6-14.3-17.5C279.4,80,278.8,80,278.3,80z"
        />
      </g>
    </svg>
  );
};
