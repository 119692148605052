import {Application, MinMaxBatchInfo} from '@ozark/common';
import {useNotification} from '@ozark/common/hooks';
import {useCallback, useEffect, useState} from 'react';
import {useApplicationAssociations} from '../../../../hooks/useApplicationAssociations';
import {useStore} from '../../../../store/helpers';
import {ApplicationAutocompleteItem} from '../../../Search/ApplicationAutocomplete';

type Props = {
  application: Application;
};
export const useApplicationsAssociationsActions = ({application}: Props) => {
  const applicationId = application.id;
  const showNotification = useNotification();
  const [addAssociationLoading, setAddAssociationLoading] = useState(false);
  const {addApplicationAssociation, removeApplicationAssociation} = useApplicationAssociations({
    applicationId,
  });
  const [isAddNewApplicationDialogOpen, setIsAddNewApplicationDialogOpen] = useState(false);

  const openAddAssociationDialog = () => {
    setIsAddNewApplicationDialogOpen(true);
  };

  const closeAddAssociationDialog = () => {
    setIsAddNewApplicationDialogOpen(false);
  };

  const onSaveAddNewAssociationDialog = async (
    selectedApplication: ApplicationAutocompleteItem
  ) => {
    if (selectedApplication.id === applicationId) {
      showNotification(
        'info',
        "It's not allowed to associate the application with the same application"
      );
      return;
    }

    const hasAlreadyAssociated = !!application?.associatedApplications?.find(
      app => app.id === selectedApplication.id
    );
    if (hasAlreadyAssociated) {
      showNotification(
        'info',
        `Application ${selectedApplication.mid} is already associated with this application`
      );
      return;
    }

    if (!selectedApplication.mid) {
      showNotification(
        'info',
        "It's not allowed to associate the application with the application without MID"
      );
      return;
    }

    try {
      setAddAssociationLoading(true);

      await addApplicationAssociation(selectedApplication.id);

      closeAddAssociationDialog();
      showNotification('success', 'Successfully associated');
    } catch (error) {
      console.error('Failed to associate 2 applications with an error:', error);
      const message =
        (error as Error).message === 'DELETED_APPLICATIONS'
          ? 'Failed to associate 2 applications because one of the applications is deleted'
          : 'Failed to associate 2 applications';
      showNotification('error', message);
    } finally {
      setAddAssociationLoading(false);
    }
  };

  const onRemoveAssociation = async ({id: applicationId}: {id: string}) => {
    try {
      await removeApplicationAssociation(applicationId);
      showNotification('success', 'Successfully removed association');
    } catch (error) {
      showNotification('error', 'Failed to remove association');
      console.error('Failed to remove association with an error:', error);
    }
  };

  return {
    addAssociationLoading,
    isAddNewApplicationDialogOpen,
    openAddAssociationDialog,
    closeAddAssociationDialog,
    onSaveAddNewAssociationDialog,
    onRemoveAssociation,
  };
};

export const useAssociatedApplicationsBatchReport = ({application}: Props) => {
  const showNotification = useNotification();
  const {apiClient} = useStore();
  const [associatedApplicationsBatchReport, setAssociatedApplicationsBatchReport] = useState<
    MinMaxBatchInfo[]
  >([]);
  const [applicationBatchReport, setApplicationBatchReport] = useState<MinMaxBatchInfo | null>(
    null
  );

  const [
    isAssociatedApplicationsBatchReportLoading,
    setIsAssociatedApplicationsBatchReportLoading,
  ] = useState(true);
  const applicationMid = application.mid;

  const getAssociatedMidsBatchesInfo = useCallback(
    async (mids?: string[]) => {
      if (!mids?.length) {
        setIsAssociatedApplicationsBatchReportLoading(false);
        setAssociatedApplicationsBatchReport([]);
        return;
      }

      setIsAssociatedApplicationsBatchReportLoading(true);

      try {
        const result = await apiClient.transactions.getMinMaxBatches(
          {limit: 0, offset: 0, orderBy: 'lastBatchDate', order: 'asc'},
          mids
        );

        if (!result) {
          throw new Error('getMinMaxBatches result is null or undefined');
        }

        const currentApplicationReport = result.data.find(row => row.mid === applicationMid);
        setApplicationBatchReport(currentApplicationReport ?? null);
        setAssociatedApplicationsBatchReport(result.data.filter(row => row.mid !== applicationMid));
      } catch (error) {
        showNotification('error', 'Failed to load associated applications data');
        console.error('Failed to load min max batches data with an error:', error);
      } finally {
        setIsAssociatedApplicationsBatchReportLoading(false);
      }
    },
    [apiClient.transactions, showNotification, applicationMid]
  );

  useEffect(() => {
    const mids =
      (application?.associatedApplications
        ?.map(item => item.mid)
        .filter(mid => !!mid) as string[]) ?? [];

    if (applicationMid) {
      mids.push(applicationMid);
    }

    getAssociatedMidsBatchesInfo(mids);
  }, [applicationMid, application?.associatedApplications, getAssociatedMidsBatchesInfo]);

  return {
    applicationBatchReport,
    associatedApplicationsBatchReport,
    isAssociatedApplicationsBatchReportLoading,
  };
};
