import SearchIcon from '@mui/icons-material/Search';
import {Typography} from '@mui/material';
import {useApiContainer} from '@ozark/common';
import {AutoCompleteInputBase, Title} from '@ozark/common/components';
import {LeadsClosingRatios} from '@ozark/functions/src/functions/express/private/types';
import {useCallback, useEffect, useState} from 'react';
import {RatioTiles} from '../components/RatioTiles';

export const SalesLeadsCampaignRatio = () => {
  const [campaigns, setCampaigns] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>();
  const [ratios, setRatios] = useState<LeadsClosingRatios | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const api = useApiContainer();

  const getRatios = useCallback(() => {
    setIsLoading(true);
    api?.leads
      .getCampaignRatios(selected)
      .then(setRatios)
      .finally(() => setIsLoading(false));
  }, [api?.leads, selected]);

  const getCampaigns = useCallback(() => {
    setIsLoading(true);
    api?.leads
      .getLeadsCampaignsList()
      .then(res => {
        setCampaigns(res?.campaigns ?? []);
      })
      .finally(() => setIsLoading(false));
  }, [api?.leads]);

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  useEffect(() => {
    getRatios();
  }, [getRatios]);

  return (
    <>
      <Title breadcrumbs={[<Typography>Campaign Ratio</Typography>]}>
        <AutoCompleteInputBase
          selected={selected}
          setSelected={setSelected}
          icon={SearchIcon}
          placeholder="Select campaign..."
          options={campaigns}
          getOptionLabel={(item: string) => item}
          onItemSelect={(item: string | null) => setSelected(item ?? undefined)}
        />
      </Title>
      <RatioTiles isLoading={isLoading} ratios={ratios} />
    </>
  );
};
