import {Stack} from '@mui/material';
import Switch from '@mui/material/Switch';
import {useCallback} from 'react';
import {useAutoAssignLeadsConfig, useNotification, useUserInfo} from '../../hooks';

const SalesCampaigns = () => {
  const {uid} = useUserInfo();

  const {
    document: {data: autoAssignLeadsConfig},
    toggleUid,
  } = useAutoAssignLeadsConfig();

  const isAutoAssignLeadsEnabled = !!uid && autoAssignLeadsConfig?.uids?.includes(uid);

  const showNotification = useNotification();

  const toggleAutoAssignLeads = useCallback(async () => {
    if (!uid) {
      return;
    }

    try {
      await toggleUid(uid);

      showNotification('success', 'Auto Assign Leads setting was saved successfully');
    } catch (err) {
      showNotification('error', 'An error occurred while saving Auto Assign Leads setting');
    }
  }, [autoAssignLeadsConfig?.uids, uid]);

  return (
    <>
      <Stack direction="row" alignItems="center">
        Auto Assign Leads:{' '}
        <Switch
          checked={isAutoAssignLeadsEnabled ?? false}
          disabled={!uid}
          onChange={() => toggleAutoAssignLeads()}
        />
        {isAutoAssignLeadsEnabled ? 'Opt In' : 'Opt Out'}
      </Stack>
    </>
  );
};

export {SalesCampaigns};
