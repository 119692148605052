import {DocumentBase} from '.';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export const AUTO_ASSIGN_LEADS_DOC_ID = 'autoAssignLeads';

export type AutoAssignLeadsConfigData = {
  uids?: string[];
  lastIndex?: number;
};

export type AutoAssignLeadsConfig = DocumentBase & AutoAssignLeadsConfigData;
export type AutoAssignLeadsConfigView = ViewBase<DocumentBase & AutoAssignLeadsConfigData>;

export const selectAutoAssignLeadsConfigView: ViewSelector<
  AutoAssignLeadsConfigView,
  AutoAssignLeadsConfig
> = snapshot => {
  const autoAssignLeadsConfigConfig: AutoAssignLeadsConfigView = {
    ...defaultViewSelector(snapshot),
  };

  return autoAssignLeadsConfigConfig;
};
