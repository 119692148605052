import LinkIcon from '@mui/icons-material/Link';
import {ListItem, ListItemIcon, ListItemText, ListSubheader, Typography} from '@mui/material';
import {AgentView, ApplicationView} from '@ozark/common';
import {getAgentById} from '@ozark/common/firebase/queries/agents';
import {useEffect, useState} from 'react';
import {generatePath, Link} from 'react-router-dom';
import {AGENTS_EDIT} from '../../../constants/routes';

type ApplicationLinksProps = {
  application: ApplicationView;
};

export const StartApplicationInfo = ({application}: ApplicationLinksProps) => {
  const [agentFromStartApplicationLink, setAgentFromStartApplicationLink] =
    useState<AgentView | null>();
  const [isAgentIdInvalid, setIsAgentIdInvalid] = useState(false);
  const {startApplicationLink} = application;

  useEffect(() => {
    if (!startApplicationLink) {
      return;
    }

    const url = new URL(startApplicationLink);
    const agentId = url.searchParams.get('a');

    if (!agentId) {
      return;
    }

    const getAgent = async () => {
      try {
        const agent = await getAgentById(agentId);
        setAgentFromStartApplicationLink(agent);
      } catch (error) {
        setIsAgentIdInvalid(true);
        console.error('Error getting agent from start application link', error);
      }
    };

    getAgent();
  }, [startApplicationLink]);

  if (!startApplicationLink) {
    return null;
  }

  return (
    <>
      <ListSubheader
        sx={{
          color: '#1c252c',
          backgroundColor: '#f5fafc',
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        Starting Link
      </ListSubheader>
      <ListItem>
        <ListItemIcon>
          <LinkIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="body2" noWrap sx={{maxWidth: '300px'}}>
              <a href={startApplicationLink} target="_blank" rel="noreferrer noopener">
                {startApplicationLink}
              </a>
            </Typography>
          }
          secondary={
            <>
              {agentFromStartApplicationLink && (
                <Typography variant="body2" component="p" noWrap>
                  Agent:{' '}
                  <Link to={generatePath(AGENTS_EDIT, {id: agentFromStartApplicationLink.id})}>
                    {agentFromStartApplicationLink.firstName ?? ''}{' '}
                    {agentFromStartApplicationLink.lastName ?? ''}
                  </Link>
                </Typography>
              )}

              {isAgentIdInvalid && (
                <Typography variant="body2" component="p" noWrap>
                  Invalid Agent ID
                </Typography>
              )}
            </>
          }
        />
      </ListItem>
    </>
  );
};
