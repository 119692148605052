import {ForgotPassword as CommonForgotPassword} from '@ozark/common/components';
import {DEFAULT_GROUP} from '../../constants/group';
import * as ROUTES from '../../constants/routes';

const ForgotPassword = () => (
  <CommonForgotPassword
    isErp
    groupName={DEFAULT_GROUP.name}
    groupLogoUrl={DEFAULT_GROUP.logoUrl}
    groupDomain={DEFAULT_GROUP.portalDomain}
    loginPageUrl={ROUTES.LOGIN}
  />
);

export {ForgotPassword};
