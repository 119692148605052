import FactCheckIcon from '@mui/icons-material/FactCheck';
import {ListItem, ListItemIcon, ListItemText, ListSubheader, Typography} from '@mui/material';
import {ApplicationView, TransferStatus} from '@ozark/common';
import {asUniversalTimestamp} from '@ozark/common/helpers';
import {format} from 'date-fns';

interface ItemProps {
  status: TransferStatus;
  date: Date;
}
const TransferApplicationItem = ({status, date}: ItemProps) => {
  return (
    <ListItem>
      <ListItemIcon>
        <FactCheckIcon />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="body2" component="p" noWrap>
            {status}
          </Typography>
        }
        secondary={
          <Typography variant="body2" component="p" noWrap>
            {format(date, 'MM/dd/yyyy hh:mm a')}
          </Typography>
        }
      />
    </ListItem>
  );
};

type Props = {
  application: ApplicationView;
};

export const TransferApplicationInfo = ({application}: Props) => {
  const {transferStatus} = application;

  if (!transferStatus?.transferDate) {
    return null;
  }

  const transferDate = application.transferStatus.transferDate
    ? asUniversalTimestamp(application.transferStatus?.transferDate)!.toDate()
    : undefined;
  const viewedDate = application.transferStatus.viewedDate
    ? asUniversalTimestamp(application.transferStatus?.viewedDate)!.toDate()
    : undefined;

  return (
    <>
      <ListSubheader
        sx={{
          color: '#1c252c',
          backgroundColor: '#f5fafc',
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        Transfer Application Info
      </ListSubheader>
      {transferDate && (
        <TransferApplicationItem date={transferDate} status={TransferStatus.applicationSent} />
      )}
      {viewedDate && (
        <TransferApplicationItem date={viewedDate} status={TransferStatus.applicationViewed} />
      )}
    </>
  );
};
