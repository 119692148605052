import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SortIcon from '@mui/icons-material/Sort';
import {Button, Divider, MenuItem, TextField} from '@mui/material';
import {
  getSalesLeadColor,
  GroupSalesDispositions,
  SalesDisposition,
  SalesGroups,
  SalesGroupsType,
  useLeadSearch,
} from '@ozark/common';
import {BoxEllipsis} from '@ozark/common/components';
import React, {useEffect, useMemo, useState} from 'react';

type Props = {
  onFiltersChange: (
    showAll: boolean,
    group: SalesGroupsType,
    disposition: SalesDisposition | null,
    source: string | null
  ) => void;
  order: 'desc' | 'asc';
  onToggleOrder: () => void;
};

const ALL_SOURCES = 'all-sources';

export const Filters = ({onFiltersChange, order, onToggleOrder}: Props) => {
  const [showAll, setShowAll] = useState(true);
  const [disposition, setDisposition] = useState<SalesDisposition | null>(null);
  const [group, setGroup] = useState(SalesGroups.all as SalesGroupsType);
  const [source, setSource] = useState<string | null>(null);
  const [sources, setSources] = useState<string[]>([ALL_SOURCES]);

  const {isReady, search} = useLeadSearch({
    facets: ['source'],
    hitsPerPage: 0,
  });

  useEffect(() => {
    if (!isReady) {
      return;
    }

    search('*').then(result => {
      if (result?.facets?.source) {
        setSources([ALL_SOURCES, ...Object.keys(result.facets.source).sort()]);
      }
    });
  }, [isReady, search]);

  const toggleShowAll = () => {
    setShowAll(!showAll);
    onFiltersChange(!showAll, group, disposition, source);
  };

  const handleSourceChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    let nextSource = event.target.value === ALL_SOURCES ? null : event.target.value;

    setSource(nextSource);
    onFiltersChange(showAll, group, disposition, nextSource);
  };

  const handleGroupChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    const newGroup = event.target.value as SalesGroupsType;

    setGroup(newGroup);
    setDisposition(null);
    onFiltersChange(showAll, newGroup, null, source);
  };

  const handleDispositionChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    let newDisposition = event.target.value as SalesDisposition | null;
    if (event.target.value === 'null') {
      newDisposition = null;
    }

    setDisposition(newDisposition);
    onFiltersChange(showAll, group, newDisposition, source);
  };

  const dispositionMenuItems = useMemo(() => {
    return [
      <MenuItem key={String(null)} value={String(null)}>
        {group === SalesGroups.all ? 'All Dispositions' : `All ${group} Dispositions`}
      </MenuItem>,
    ].concat(
      GroupSalesDispositions[group].map(item => (
        <MenuItem key={item} value={item}>
          <span style={{color: getSalesLeadColor(item)}}>&bull;&bull;&bull;&nbsp;&nbsp;</span>
          {item}
        </MenuItem>
      ))
    );
  }, [group]);

  const groupsMenuItems = useMemo(() => {
    return Object.values(SalesGroups).sortAndMap(item => (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    ));
  }, []);

  return (
    <>
      <TextField
        value={source === null ? ALL_SOURCES : source}
        onChange={handleSourceChange}
        variant="standard"
        InputProps={{
          sx: {
            '&.Mui-focused .MuiSelect-select': {
              backgroundColor: 'transparent',
            },
          },
          disableUnderline: true,
        }}
        select
        SelectProps={{MenuProps: {PaperProps: {sx: {maxHeight: 400, overflowY: 'auto'}}}}}
      >
        {sources.map(item => (
          <MenuItem key={item} value={item}>
            <BoxEllipsis sx={{maxWidth: '200px'}}>
              {item === ALL_SOURCES ? 'All Sources' : item}
            </BoxEllipsis>
          </MenuItem>
        ))}
      </TextField>

      <Divider orientation="vertical" flexItem sx={{mx: 1}} />

      <TextField
        value={group}
        onChange={handleGroupChange}
        variant="standard"
        InputProps={{
          sx: {
            '&.Mui-focused .MuiSelect-select': {
              backgroundColor: 'transparent',
            },
          },
          disableUnderline: true,
        }}
        select
      >
        {groupsMenuItems}
      </TextField>

      <Divider orientation="vertical" flexItem sx={{mx: 1}} />

      <TextField
        value={String(disposition)}
        onChange={handleDispositionChange}
        variant="standard"
        InputProps={{
          disableUnderline: true,
          sx: {
            '&.Mui-focused .MuiSelect-select': {
              backgroundColor: 'transparent',
            },
          },
        }}
        select
      >
        {dispositionMenuItems}
      </TextField>
      <Divider orientation="vertical" flexItem sx={{mx: 1}} />

      <Button
        size="small"
        onClick={toggleShowAll}
        startIcon={!showAll ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon />}
      >
        Assigned to me
      </Button>
      <Divider orientation="vertical" flexItem sx={{mx: 1}} />

      <Button
        size="small"
        onClick={onToggleOrder}
        endIcon={
          order === 'desc' ? <SortIcon style={{transform: 'rotateX(180deg)'}} /> : <SortIcon />
        }
      >
        {order === 'desc' ? 'Newest at Top' : 'Oldest at Top'}
      </Button>
    </>
  );
};
