import {
  AsyncState,
  AuditReadOnly,
  AuditView,
  Collections,
  Firebase,
  selectAuditView,
  UniversalSnapshot,
} from '@ozark/common';
import {useEffect, useState} from 'react';

export const useAudits = (id: string) => {
  const [audits, setAudits] = useState<AsyncState<AuditView[]>>({promised: true});
  useEffect(() => {
    const unsubscribe = Firebase.firestore
      .collection(Collections.audits)
      .where('documentId', '==', id)
      .orderBy('createdAt', 'desc')
      .onSnapshot(
        async snapshot => {
          if (snapshot.size === 0) {
            setAudits({promised: false});
            return;
          }

          const audits = snapshot.docs.map(doc =>
            selectAuditView(doc as UniversalSnapshot<AuditReadOnly>)
          );

          setAudits({promised: false, data: audits});
        },
        err => {
          console.error(err);
          setAudits({promised: false, error: err});
        }
      );
    return () => {
      unsubscribe();
    };
  }, [id]);

  return {audits};
};
