import {SearchableProfileView} from '@ozark/common';
import {getDictionaryFromArray} from '@ozark/functions/src/functions/express/private/utils/response';
import {useMemo} from 'react';
import {useSearchableProfiles} from './useSearchableProfiles';

const getActiveProfiles = (profilesArr: SearchableProfileView[]): SearchableProfileView[] =>
  profilesArr.filter(profileItem => profileItem.isActive);

type Profiles = ReturnType<typeof useSearchableProfiles>['profiles'];

export const useActiveProfiles = (profiles: Profiles): Profiles => {
  return useMemo(() => {
    const activeProfiles = getActiveProfiles(Object.values(profiles.dictionary));
    const byRole = Object.keys(profiles.byRole).reduce((acc, role) => {
      acc[role] = getActiveProfiles(profiles.byRole[role]);
      return acc;
    }, {} as {[_: string]: SearchableProfileView[]});

    return {
      ...profiles,
      dictionary: getDictionaryFromArray(activeProfiles, (item: SearchableProfileView) => item.uid),
      data: activeProfiles,
      byRole: byRole,
    };
  }, [profiles]);
};
