import {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {useBreadcrumbsContainer} from '../../common/Breadcrumbs';
import {FraudAnalysisSameBinDetails} from './FraudAnalysisSameBinDetails';
import {FraudAnalysisSameBinSummary} from './FraudAnalysisSameBinSummary';
import {FraudAnalysisSameBinTransactions} from './FraudAnalysisSameBinTransactions';

export const FraudAnalysisSameBin = () => {
  const {setBreadcrumbs} = useBreadcrumbsContainer();

  useEffect(
    () => () => {
      setBreadcrumbs([{text: 'Fraud Analysis', url: '/fraud-analysis'}]);
    },
    []
  );
  return (
    <Switch>
      <Route path="/fraud-analysis/same-bin" exact>
        <FraudAnalysisSameBinSummary />
      </Route>
      <Route path="/fraud-analysis/same-bin/:mid" exact>
        <FraudAnalysisSameBinDetails />
      </Route>
      <Route path="/fraud-analysis/same-bin/transactions/:mid">
        <FraudAnalysisSameBinTransactions />
      </Route>
    </Switch>
  );
};
