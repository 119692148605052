import {yupResolver} from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Firebase, PendingReminderSettings} from '@ozark/common';
import {TextField} from '@ozark/common/components';
import {addMinutes} from 'date-fns';
import {FormProvider, useForm} from 'react-hook-form';
import * as yup from 'yup';

interface PendingReminderDialogProps {
  setDialogVisible: (args0: boolean) => void;
  setReminder: (args0: PendingReminderSettings) => void;
}

export type PendingReminderFormInput = {
  timeFrame: number;
};

const pendingReminderSchema = yup.object({
  timeFrame: yup
    .number()
    .min(1, 'Must be greater than 0')
    .required('Time frame is required')
    .typeError('Time frame must be a number 1 or greater'),
});

export const PendingReminderDialog = ({
  setDialogVisible,
  setReminder,
}: PendingReminderDialogProps) => {
  const hookForm = useForm<PendingReminderFormInput>({
    resolver: yupResolver(pendingReminderSchema),
    defaultValues: {
      timeFrame: 1,
    },
    mode: 'onSubmit',
  });

  const {
    handleSubmit,
    formState: {errors},
    control,
  } = hookForm;

  const onSuccess = async (data: any) => {
    try {
      const hoursToMinutes = data.timeFrame * 60;
      setReminder({
        isEnabled: true,
        delay: hoursToMinutes,
        nextCallAt: Firebase.Timestamp.fromDate(addMinutes(new Date(), hoursToMinutes)),
      });
    } catch (_err) {
      console.error(`error setup pending reminder`, _err);
    }
  };

  const onError = () => {};

  const handleClose = () => {
    setDialogVisible(false);
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <FormProvider {...hookForm}>
        <DialogTitle>Pending Reminder</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the number of hours for reminder emails to be sent
          </DialogContentText>
          <TextField
            autoFocus
            required
            id="timeFrame"
            name="timeFrame"
            label="Reminder time frame (hours)"
            type="number"
            errors={errors}
            control={control}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit(onSuccess, onError)} color="primary">
            Enable Reminder
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
