import EmailIcon from '@mui/icons-material/EmailOutlined';
import LoginIcon from '@mui/icons-material/Login';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PhoneIcon from '@mui/icons-material/PhoneOutlined';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ViewIcon from '@mui/icons-material/Visibility';
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ProfileView, useCallable} from '@ozark/common';
import {format} from 'date-fns';
import React from 'react';
import {useHistory} from 'react-router';
import {useNotification} from '../../hooks/useNotification';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#1c252c',
    },
    media: {
      position: 'relative',
      height: 0,
      paddingTop: '56.25%', // 16:9
      borderTop: 'solid 1px rgba(0,0,0,0.1)',
      borderBottom: 'solid 1px rgba(0,0,0,0.1)',
    },
    inactiveBackdrop: {
      position: 'absolute',
      top: 0,
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.5)',
      color: 'rgba(255,255,255,0.6)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    line: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    icon: {
      height: 24,
      width: 24,
      marginRight: theme.spacing(2),
      fill: '#1c252c',
    },
  })
);

export const ProfileCard = ({
  profile,
  isAuthUserAdmin,
}: {
  profile: ProfileView;
  isAuthUserAdmin?: boolean;
}) => {
  const classes = useStyles();
  const {resetPassword} = useCallable();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const showNotification = useNotification();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isAuthUserAdmin) {
      handleEditClick();
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    history.push(`/users/${profile.uid}/edit`);
  };

  const handleResetPassword = async () => {
    try {
      setAnchorEl(null);
      await resetPassword({email: profile.email});
      showNotification('success', 'Reset password email sent to user.');
    } catch (err) {
      showNotification('error', 'An error occurred while updating the password.');
    }
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        subheader={profile.displayName}
        action={
          <IconButton onClick={handleClick} aria-label="menu" size="large">
            {isAuthUserAdmin ? <MoreVertIcon /> : <ViewIcon />}
          </IconButton>
        }
      ></CardHeader>
      <CardMedia className={classes.media} image={profile.photoUrl} title={profile.displayName}>
        {!profile.isActive && (
          <div className={classes.inactiveBackdrop}>
            <Typography variant="h4">Inactive</Typography>
          </div>
        )}
      </CardMedia>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography className={classes.line} variant="body2" align="left" gutterBottom>
              <SupervisorAccountIcon className={classes.icon} />
              {profile.role} ({profile.department || 'No Department'})
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.line} variant="body2" align="left" gutterBottom>
              <EmailIcon className={classes.icon} /> {profile.email}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.line} variant="body2" align="left" gutterBottom>
              <PhoneIcon className={classes.icon} />
              {profile.phoneNumber}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.line} variant="body2" align="left" gutterBottom>
              <LoginIcon className={classes.icon} />
              {format(profile.lastLoginAt.toDate(), 'MM/dd/yyyy h:mm a')}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEditClick}>Edit Profile</MenuItem>
        <MenuItem onClick={handleResetPassword}>Reset Password</MenuItem>
      </Menu>
    </Card>
  );
};
