import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {
  ApplicationSubCollection,
  ApplicationView,
  Collections,
  Firebase,
  Score,
  ScorecardAction,
  ScorecardExecutionStatus,
  ScorecardReportId,
} from '@ozark/common';
import React, {FC, useCallback, useEffect, useState} from 'react';
import ApplicationDuplicateAccordion from './accordions/ApplicationDuplicateAccordion';
import {useRunScores} from './accordions/hooks/useRunScores';
import {ScorecardReport, scorecardReports} from './scorecardReports';

export const StatusColorMap = {
  [ScorecardExecutionStatus.neverRan]: '#1877f2',
  [ScorecardExecutionStatus.running]: '#1877f2',
  [ScorecardExecutionStatus.complete]: '#00D68E',
  [ScorecardExecutionStatus.failed]: '#E60047',
  [ScorecardExecutionStatus.actionRequired]: '#FFD300',
  [ScorecardExecutionStatus.initialized]: '#1877f2',
};

export type AccordionProps = {
  scorecardReport: ScorecardReport;
  scores?: Record<ScorecardAction, Score>;
  application: ApplicationView;
  whichExpanded: string | false;
  onChange: (panel: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  onSubmit?: () => void;
};

export const Scorecard: FC<React.PropsWithChildren<{application: ApplicationView}>> = ({
  application,
}) => {
  const [whichExpanded, setWhichExpanded] = useState<string | false>(false);
  const {isAllRunning, onRunAll} = useRunScores();
  const [scores, setScores] = useState<Record<ScorecardReportId, Record<ScorecardAction, Score>>>();

  const handleExpandChange = useCallback(
    (panel: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setWhichExpanded(isExpanded ? panel : false);
    },
    []
  );

  useEffect(() => {
    const unsubscribe = Firebase.firestore
      .collection(Collections.applications)
      .doc(application.id)
      .collection(ApplicationSubCollection.scorecards)
      .onSnapshot(snapshot => {
        if (snapshot.empty) return;

        const scores = snapshot.docs.reduce((result, value) => {
          return {
            ...result,
            ...(value.data() as Record<ScorecardReportId, Record<ScorecardAction, Score>>),
          };
        }, {} as Record<ScorecardReportId, Record<ScorecardAction, Score>>);
        setScores(scores);
      });

    return () => {
      unsubscribe();
    };
  }, [application.id]);

  const handleExpandClose = useCallback(() => setWhichExpanded(false), []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box textAlign="center">
          <Button
            variant="contained"
            color="primary"
            style={{width: 300}}
            startIcon={<PlaylistPlayIcon style={{fontSize: 30}} />}
            onClick={onRunAll}
            disabled={isAllRunning}
          >
            Run All Checks
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {scorecardReports.map(report =>
          React.createElement(report.accordion, {
            key: report.id,
            scorecardReport: report,
            scores: scores && scores[report.id],
            application: application,
            onChange: handleExpandChange,
            whichExpanded: whichExpanded,
            onSubmit: handleExpandClose,
          })
        )}
      </Grid>

      {!!application.duplicates?.length && (
        <>
          <Typography variant="h5" sx={{marginLeft: '18px', marginTop: '24px'}}>
            Duplicates
          </Typography>

          <Grid item xs={12}>
            {application.duplicates.map(duplicate => (
              <ApplicationDuplicateAccordion
                duplicate={duplicate}
                whichExpanded={whichExpanded}
                onChange={handleExpandChange(duplicate.id)}
              />
            ))}
          </Grid>
        </>
      )}
    </Grid>
  );
};
