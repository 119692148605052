import {Grid, Link, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  Application,
  ApplicationView,
  Attachment,
  AttachmentView,
  Collections,
  Firebase,
  selectApplicationView,
  selectAttachmentView,
  UniversalSnapshot,
} from '@ozark/common';
import {Card, Loading, Title} from '@ozark/common/components';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';
import {AttachmentExplorer} from '../AttachmentExplorer';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    grow: {
      flex: 1,
    },
  })
);

const Audit = () => {
  const classes = useStyles();
  const {profiles} = useStore();
  const history = useHistory<{applicationId: string}>();
  const [loading, setLoading] = useState<boolean>(false);
  const [applicationId, setApplicationId] = useState<string>();
  const [application, setApplication] = useState<ApplicationView>();
  const [attachments, setAttachments] = useState<
    (AttachmentView & {downloadUrl: string; size: number; contentType: string})[]
  >([]);

  useEffect(() => {
    if (history.location?.state?.applicationId) {
      setApplicationId(history.location?.state?.applicationId);
    }
  }, [history.location?.state?.applicationId]);

  useEffect(() => {
    if (applicationId) {
      setLoading(true);
      (async () => {
        const _application = (await getApplication(applicationId)) as ApplicationView;
        if (!_application) {
          setLoading(false);
          return;
        }
        setApplication(_application);
        getAttachments(applicationId)
          .then(results => {
            setAttachments(results);
          })
          .finally(() => {
            setLoading(false);
          });
      })();
    }
    // eslint-disable-next-line
  }, [applicationId]);

  const getApplication = async (applicationId: string) => {
    const snapshot = await Firebase.firestore
      .collection(Collections.applications)
      .doc(applicationId)
      .get();

    const data = selectApplicationView(snapshot as UniversalSnapshot<Application>);
    if (!data) return null;
    return data as ApplicationView;
  };

  const getAttachments = async (applicationId: string) => {
    const snapshot = await Firebase.firestore
      .collection(Collections.applications)
      .doc(applicationId)
      .collection(Collections.attachments)
      .orderBy('createdAt', 'desc')
      .get();

    if (snapshot.empty) {
      return [];
    }

    const attachments: (AttachmentView & {
      downloadUrl: string;
      size: number;
      contentType: string;
    })[] = [];

    for (const doc of snapshot.docs) {
      const attachment = selectAttachmentView(doc as UniversalSnapshot<Attachment>);
      if (attachment.deleted) continue;
      const storageRef = Firebase.storage.ref(attachment.cloudPath);
      const downloadUrl = await storageRef.getDownloadURL();
      const metadata = await storageRef.getMetadata();
      attachments.push({
        ...attachment,
        downloadUrl,
        size: metadata.size,
        contentType: metadata.contentType || '',
      });
    }

    return attachments;
  };

  return (
    <div className={classes.root}>
      <Title
        breadcrumbs={[
          <Link component="button" variant="body1" onClick={() => history.push(ROUTES.AUDIT)}>
            Applications
          </Link>,
          application ? <Typography variant="body1">{application.mid}</Typography> : null,
        ]}
      >
        <div className={classes.grow} />
      </Title>
      <Grid container spacing={2}>
        {application && (
          <Grid item xs={12}>
            <Card application={application} profiles={profiles} />
          </Grid>
        )}
        <Grid item xs={12}>
          {loading && <Loading />}
          {application && (
            <Typography variant="h6" component="div">
              Attachments ({attachments.length ?? 0})
            </Typography>
          )}
          {applicationId && attachments.length > 0 && (
            <AttachmentExplorer applicationId={applicationId} attachments={attachments} />
          )}
          {!loading && !!applicationId && attachments.length === 0 && (
            <Typography variant="body1">
              No documents are available for this application.
            </Typography>
          )}
          {!loading && !applicationId && (
            <Typography variant="body1">
              To look up an application, use the search field in the header above.
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Audit;
