import ContactPageIcon from '@mui/icons-material/ContactPage';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import GroupIcon from '@mui/icons-material/Group';
import WarningIcon from '@mui/icons-material/Warning';
import {Box, CardContent, Tooltip, Typography} from '@mui/material';
import Divider from '@mui/material/Divider';
import {styled} from '@mui/material/styles';
import {TransactionsPendingIcon} from '@ozark/common/icons';
import React from 'react';
import {RiskOverviewItemButtons} from './Buttons';
import {
  ColumnContent,
  getFilterableValueTooltipTitle,
  IconWrapper,
  NotAvailableText,
  renderAccountStatus,
  renderApprovalInformation,
  renderFirstBatchDateAndAmount,
  renderGroupAgentBankInfo,
  renderLastBatchDateAndAmount,
  renderMerchantInformation,
  renderRiskAssignmentInfo,
  renderRiskLabels,
  RiskOverviewItemProps,
  RiskStatusValueButton,
} from './common';

const Column = styled('span')(({theme}) => ({
  display: 'flex',
  alignItems: 'flex-start',
  height: '100%',
  flexBasis: '9%',
  minWidth: '100px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  transition: theme.transitions.create(['flexBasis'], {
    duration: theme.transitions.duration.standard,
  }),
}));

export const TableViewItemContent = ({
  data,
  onFilterableValueClick,
  onAssociatedApplicationsOpen,
  onRemoveAssociation,
  hideViewDetailsButton,
  shouldOpenDetailsInNewTab,
  assigneeProfile,
}: RiskOverviewItemProps) => {
  const getFilterableValueClickHandler = onFilterableValueClick
    ? (key: string, value: string) => (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        onFilterableValueClick(key, value);
      }
    : undefined;

  return (
    <CardContent
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Column>
        <ColumnContent>{renderRiskAssignmentInfo(data, assigneeProfile)}</ColumnContent>
      </Column>

      <Divider orientation="vertical" flexItem sx={{p: 0}} />

      <Column>
        <IconWrapper>
          <GroupIcon color="inherit" />
        </IconWrapper>
        <ColumnContent>
          {renderGroupAgentBankInfo(data, getFilterableValueClickHandler)}
        </ColumnContent>
      </Column>

      <Divider orientation="vertical" flexItem sx={{p: 0}} />

      <Column>
        <IconWrapper>
          <DomainVerificationIcon color="inherit" />
        </IconWrapper>
        <ColumnContent>
          {renderApprovalInformation(data, getFilterableValueClickHandler)}
        </ColumnContent>
      </Column>

      <Divider orientation="vertical" flexItem sx={{p: 0}} />

      <Column>
        <IconWrapper>
          <ContactPhoneIcon color="inherit" />
        </IconWrapper>
        <ColumnContent>
          {renderMerchantInformation(data, getFilterableValueClickHandler)}
        </ColumnContent>
      </Column>

      <Divider orientation="vertical" flexItem sx={{p: 0}} />

      <Column>
        <IconWrapper>
          <TransactionsPendingIcon color="inherit" />
        </IconWrapper>
        <ColumnContent>
          {renderFirstBatchDateAndAmount(data, getFilterableValueClickHandler)}

          {!data.firstBatchDate && NotAvailableText}
        </ColumnContent>
      </Column>

      <Divider orientation="vertical" flexItem sx={{p: 0}} />

      <Column>
        <IconWrapper>
          <TransactionsPendingIcon color="inherit" />
        </IconWrapper>
        <ColumnContent>
          {renderLastBatchDateAndAmount(data, getFilterableValueClickHandler)}

          {!data.lastBatchDate && NotAvailableText}
        </ColumnContent>
      </Column>

      <Divider orientation="vertical" flexItem sx={{p: 0}} />

      <Column>
        <IconWrapper>
          <ContactPageIcon color="inherit" />
        </IconWrapper>
        <ColumnContent>
          {data.accountStatus?.status && (
            <>
              {renderAccountStatus(data, getFilterableValueClickHandler)}

              {data.accountStatus.subCategory && (
                <Typography variant="caption" noWrap>
                  {data.accountStatus.subCategory}
                </Typography>
              )}
            </>
          )}

          {!data.accountStatus?.status && NotAvailableText}
        </ColumnContent>
      </Column>

      <Divider orientation="vertical" flexItem sx={{p: 0}} />

      <Column>
        <IconWrapper>
          <WarningIcon color="inherit" />
        </IconWrapper>
        <Typography variant="body1">
          {data.riskStatus && (
            <>
              {getFilterableValueClickHandler ? (
                <Tooltip title={getFilterableValueTooltipTitle(data.riskStatus)} disableInteractive>
                  <RiskStatusValueButton
                    onClick={getFilterableValueClickHandler('riskStatus', data.riskStatus)}
                  >
                    {data.riskStatus}
                  </RiskStatusValueButton>
                </Tooltip>
              ) : (
                data.riskStatus
              )}
            </>
          )}
          {!data.riskStatus && NotAvailableText}
        </Typography>
      </Column>

      <Divider orientation="vertical" flexItem sx={{p: 0}} />

      <Column sx={{overflow: 'unset', flexWrap: 'wrap'}}>
        {renderRiskLabels(data, getFilterableValueClickHandler)}
      </Column>

      <Divider orientation="vertical" flexItem sx={{p: 0}} />

      <Column sx={{justifyContent: 'flex-end'}}>
        <Box textAlign="end" sx={{'& .MuiButton-root': {width: '100%'}}}>
          <RiskOverviewItemButtons
            data={data}
            onAssociatedApplicationsOpen={onAssociatedApplicationsOpen}
            onRemoveAssociation={onRemoveAssociation}
            hideViewDetailsButton={hideViewDetailsButton}
            shouldOpenDetailsInNewTab={shouldOpenDetailsInNewTab}
          />
        </Box>
      </Column>
    </CardContent>
  );
};
