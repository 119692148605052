import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  ApplicationSubCollection,
  ApplicationView,
  Collections,
  FeeModifierView,
  Firebase,
  ScheduleAFeeDefinitions,
  useFeeModifiers,
} from '@ozark/common';
import {Loading} from '@ozark/common/components/Loading';
import {ChangeEvent, FC, Fragment, useState} from 'react';
import {ModifierDialog} from './ModifierDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
    },
    actions: {
      padding: theme.spacing(0, 1),
    },
    summary: {
      minWidth: '20%',
    },
    grow: {
      flex: 1,
    },
    list: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      padding: 0,
    },
  })
);

export const FeeModifiers: FC<React.PropsWithChildren<{application: ApplicationView}>> = ({
  application,
}) => {
  const classes = useStyles();
  const [modifier, setModifier] = useState<Partial<FeeModifierView> | null>(null);
  const {documents: feeModifiers} = useFeeModifiers(application.id);

  const formatSecondaryLabel = (fee: FeeModifierView) => {
    return `${fee.operator === 'increase' ? '+' : '-'}${
      fee.symbol === 'percent' ? `${fee.amount}%` : `$${fee.amount}`
    }`;
  };

  const handleSubmit = (
    data: Pick<FeeModifierView, 'amount' | 'operator' | 'feeName' | 'symbol'>
  ) => {
    (async () => {
      const document = {
        ...data,
        ...(!modifier?.id && {createdAt: new Date(), active: true}),
      };
      await Firebase.firestore
        .collection(Collections.applications)
        .doc(application.id)
        .collection(ApplicationSubCollection.feeModifiers)
        .doc(data.feeName)
        .set(document, {merge: true});
      setModifier(null);
    })();
  };

  const handleCheckChange =
    (feeModifier: FeeModifierView) => (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      (async () => {
        await Firebase.firestore
          .collection(Collections.applications)
          .doc(application.id)
          .collection(ApplicationSubCollection.feeModifiers)
          .doc(feeModifier.feeName)
          .set({active: checked}, {merge: true});
      })();
    };

  if (feeModifiers.promised) {
    return <Loading />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          className={classes.actions}
        >
          <div className={classes.grow} />
          <Button color={'primary'} variant="outlined" onClick={() => setModifier({})}>
            Add Modifier
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {feeModifiers?.data && feeModifiers?.data?.length > 0 ? (
          <Paper>
            <List className={classes.list}>
              {feeModifiers.data?.map(e => {
                return (
                  <Fragment key={e.feeName}>
                    <ListItem button onClick={() => setModifier(e)}>
                      <ListItemText
                        primary={ScheduleAFeeDefinitions[e.feeName].label}
                        secondary={
                          <span style={{color: e.operator === 'increase' ? 'green' : 'red'}}>
                            {formatSecondaryLabel(e)}
                          </span>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Switch
                          edge="end"
                          onChange={handleCheckChange(e)}
                          color="primary"
                          checked={e.active === true}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </Fragment>
                );
              })}
            </List>
          </Paper>
        ) : (
          <Typography variant="body1" align="center">
            No fee modifiers have been added to this account.
          </Typography>
        )}
      </Grid>
      {modifier && (
        <ModifierDialog
          modifier={modifier}
          onSubmit={handleSubmit}
          onClose={() => setModifier(null)}
        />
      )}
    </Grid>
  );
};
