import {GroupView} from '@ozark/common';
import LogoEvolve from '../static/images/logos/logo-evolve-erp.png';
import LogoLuqra from '../static/images/logos/logo-luqra-erp.png';

const LUQRA_GROUP = {
  id: 'luqra',
  name: 'Luqra',
  groupDomain: '',
  logoUrl: LogoLuqra,
};

const EVOLVE_GROUP = {
  id: 'evolve',
  name: 'Evolve Bank & Trust',
  groupDomain: 'https://getevolved-crm.web.app/',
  logoUrl: LogoEvolve,
};

const defaultGroups = [LUQRA_GROUP, EVOLVE_GROUP];

const defaultGroupId = process.env.REACT_APP_DEFAULT_GROUP;
const groupData = defaultGroups.find(x => x.id === defaultGroupId) ?? LUQRA_GROUP;

export const DEFAULT_GROUP: Pick<GroupView, 'logoUrl' | 'name' | 'portalDomain'> = {
  name: groupData.name,
  portalDomain: groupData.groupDomain,
  logoUrl: groupData.logoUrl,
};
