import {
  ApplicationData,
  Collections,
  Department,
  Firebase,
  useNotification,
  useUserInfo,
} from '@ozark/common';
import {useMemo} from 'react';
import {useDepartmentUsers} from '../../../../hooks/useDepartmentUsers';
import {AssigneeSelector} from '../../../SalesLeads/DetailsPage/AssigneeSelector';

type Props = {
  riskAssigneeUid?: string | null;
  applicationId: string;
};

export const RiskAssigneeSelector = ({applicationId, riskAssigneeUid}: Props) => {
  const showNotification = useNotification();
  const riskDepartmentUsers = useDepartmentUsers(Department.risk);

  const {isErpAdmin, department} = useUserInfo();
  const isRiskDepartment = department === Department.risk;
  const isAllowedToEditRiskAssignee = isErpAdmin || isRiskDepartment;

  const assigneeOptions = useMemo(() => {
    return [
      {
        groupName: 'Risk Department',
        profiles: riskDepartmentUsers,
      },
    ];
  }, [riskDepartmentUsers]);

  const updateRiskAssignee = async (uid: string | null) => {
    const fieldsToUpdate: Partial<ApplicationData> = {
      riskAssigneeUid: uid,
    };

    try {
      await Firebase.firestore
        .collection(Collections.applications)
        .doc(applicationId)
        .update(fieldsToUpdate);
      showNotification('success', 'Risk assignment is successfully updated');
    } catch (err) {
      console.error('Failed to update risk assignment with an error', err);
      showNotification('error', 'Failed to update risk assignment');
    }
  };

  return (
    <AssigneeSelector
      assigneeId={riskAssigneeUid}
      updateAssignee={updateRiskAssignee}
      tooltipTitle="Risk Assignment"
      options={assigneeOptions}
      readOnly={!isAllowedToEditRiskAssignee}
    />
  );
};
