import {ProfileView} from '@ozark/common';
import {GlobalErrorBoundary, Loading} from '@ozark/common/components';
import {FC, useCallback, useMemo} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import {IRoute} from '../../config/routes';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';

const AppRoute = ({route, isLayoutProtected}: {route: IRoute; isLayoutProtected?: boolean}) => {
  const {component, condition} = route;
  const isRouteProtected = route.isProtected || isLayoutProtected;
  const {authUser, authProfile} = useStore();
  const location = useLocation();

  const getComponent = useCallback(
    (profile?: ProfileView) =>
      (isRouteProtected && profile && typeof component === 'object'
        ? component.renderWithProfile(profile)
        : component) as FC<React.PropsWithChildren<unknown> | JSX.Element>,
    [isRouteProtected, component]
  );

  // TODO: investigate how can avoid extra rerenders without memoization here
  // memoization here lets to avoid extra rerenders of layout
  const ProtectedComponent = useMemo(() => {
    return getComponent(authProfile.data);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [authProfile.data]);

  // memoization here lets to avoid extra rerenders of layout
  const Component = useMemo(() => {
    return getComponent();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  if (!isRouteProtected) {
    return (
      <GlobalErrorBoundary>
        <Component />
      </GlobalErrorBoundary>
    );
  }

  if (isRouteProtected && !authUser.data?.uid) {
    const loginRoute =
      location.pathname === '/' ? ROUTES.LOGIN : `${ROUTES.LOGIN}?returnTo=${location.pathname}`;
    return <Redirect to={loginRoute} />;
  }

  if (!authProfile.data) {
    return <Loading />;
  }

  if (condition && !condition(authProfile.data)) return <Redirect to={ROUTES.DASHBOARD} />;

  return (
    <GlobalErrorBoundary>
      <ProtectedComponent />
    </GlobalErrorBoundary>
  );
};

export default AppRoute;
