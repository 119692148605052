import {AssociationType} from '@ozark/common';
import {orderBy} from 'lodash';
import {useEffect, useState} from 'react';
import {stateEmpty, statePromised} from '../constants/constants';
import {whenAllTickets} from '../utils';
import {useTicketsAssociated} from './ticketsSlices/useTicketsAssociated';

export function useTicketsMerchant(mid: string) {
  const [tickets, setTickets] = useState(statePromised);
  const {ticketsAssociated: ticketsMyAssociationMerchant} = useTicketsAssociated(
    AssociationType.merchant,
    'association.merchant.mid',
    mid
  );
  const {ticketsAssociated: ticketsMyAssociationApplication} = useTicketsAssociated(
    AssociationType.application,
    'association.application.mid',
    mid
  );

  useEffect(() => {
    if (!mid) return;
    !tickets.promised && setTickets(statePromised);
    whenAllTickets(
      [ticketsMyAssociationMerchant, ticketsMyAssociationApplication],
      mergedTickets => {
        if (!mergedTickets.length) {
          setTickets(stateEmpty);
          return;
        }
        const ticketsSorted = orderBy(mergedTickets, ['status', 'createdAt'], ['desc', 'desc']);
        setTickets({promised: false, data: ticketsSorted});
      }
    );
  }, [mid, ticketsMyAssociationMerchant, ticketsMyAssociationApplication]);

  return {
    tickets,
  };
}
