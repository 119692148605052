/* eslint-disable react-hooks/exhaustive-deps */
import {Box, CircularProgress} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  MinMaxBatchInfo,
  SearchCriteria,
  useGroupsAndAgents,
  useInfiniteData,
  useNotification,
} from '@ozark/common';
import {
  ExportProps,
  Filter,
  getMerchantPortfolioCardColumnsConfig,
  MerchantPortfolioBar,
  MerchantPortfolioCard,
  MerchantPortfolioHeader,
} from '@ozark/common/components';
import {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    paper: {
      marginBottom: '10px',
    },
    content: {
      marginTop: theme.spacing(0),
      padding: theme.spacing(1, 2, 2),
      position: 'relative',
    },
    divider: {
      padding: 0,
    },
    groupFilter: {
      minWidth: 200,
    },
    filterContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    selectInput: {
      backgroundColor: 'transparent !important',
    },
    icon: {
      height: '100%',
      padding: 0,
      margin: theme.spacing(0, 3),
      '& > *': {
        fill: '#4d6575',
      },
    },
  })
);

const MerchantsPortfolio = () => {
  const showNotification = useNotification();
  const classes = useStyles();
  const history = useHistory();
  const {apiClient} = useStore();
  const [merchantsData, setMerchantsData] = useState<MinMaxBatchInfo[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [search, setSearch] = useState('');
  const [pageNum, setPageNum] = useState(1);
  const [filters, setFilters] = useState<Filter>({});
  const [loading, setLoading] = useState<boolean>(true);

  const [options, setOptions] = useState<{order: 'desc' | 'asc'; orderBy: string}>({
    order: 'asc',
    orderBy: 'lastBatchDate',
  });

  const {selectedGroup, groups, onGroupChange, selectedAgent, filteredAgents, onAgentChange} =
    useGroupsAndAgents();

  const getBatches = async (page: number) => {
    setLoading(true);
    try {
      const result = await apiClient.transactions.getMinMaxBatches(
        {limit: 10, offset: page, ...options} as SearchCriteria,
        null,
        {
          search,
          groupId: selectedGroup,
          agentId: selectedAgent,
          filters: Object.values(filters),
        }
      );

      if (!result) {
        throw new Error('getMinMaxBatches result is null or undefined');
      }
      const merchants = page === 1 ? [...result.data] : [...merchantsData, ...result.data];

      setMerchantsData(merchants);

      setPageNum(page);
      setHasMore(merchants.length < result.totalCount);
    } catch (error) {
      showNotification('error', 'Failed to load data');
      console.error('Failed to load min max batches data with an error:', error);
    } finally {
      setLoading(false);
    }
  };

  const getAllDataForExport = useCallback(async () => {
    if (!apiClient) return [];

    const result = await apiClient.transactions.getMinMaxBatches(
      {limit: 0, offset: 0, ...options} as SearchCriteria,
      null,
      {
        search,
        groupId: selectedGroup,
        agentId: selectedAgent,
        filters: Object.values(filters),
      }
    );

    return result?.data ?? [];
  }, [options, search, selectedGroup, selectedAgent, filters]);

  const exportProps: ExportProps = {
    filename: 'merchants-portfolio-report',
    columnsConfig: getMerchantPortfolioCardColumnsConfig(),
    getRows: getAllDataForExport,
  };

  const onLoadMore = () => {
    if (loading) {
      return;
    }
    getBatches(pageNum + 1);
  };

  const loadMoreRef = useInfiniteData(onLoadMore, hasMore);

  useEffect(() => {
    setMerchantsData([]);
    getBatches(1);
  }, [selectedGroup, selectedAgent, search, options, filters]);

  const onSortChange = (orderBy: string, order: 'desc' | 'asc') => {
    setOptions({...options, order, orderBy});
  };

  const handleSearchChange = useCallback((search: string) => setSearch(search), [setSearch]);

  const route = (m: MinMaxBatchInfo, path?: string) =>
    `${ROUTES.MERCHANTS_PORTFOLIO}/${m.id}${path ? '/' + path : ''}`;

  return (
    <div className={classes.root}>
      <MerchantPortfolioBar
        linkClick={() => history.push(ROUTES.MERCHANTS_PORTFOLIO)}
        agents={filteredAgents}
        groups={groups?.data}
        selectedGroup={selectedGroup}
        selectedAgent={selectedAgent}
        onAgentChange={onAgentChange}
        onGroupChange={onGroupChange}
        onSortChange={onSortChange}
        onSearchChange={handleSearchChange}
        order={options.order}
        orderBy={options.orderBy}
        filters={filters}
        setFilters={setFilters}
        exportProps={exportProps}
      />
      <MerchantPortfolioHeader />

      {merchantsData.map((m: MinMaxBatchInfo, i: number) => (
        <MerchantPortfolioCard
          key={m.id}
          merchant={m}
          isLastElement={merchantsData.length - 1 === i}
          loadMoreRef={merchantsData.length - 1 === i && hasMore ? loadMoreRef : undefined}
          onClick={() => history.push(route(m), {referrer: 'MerchantsPortfolio'})}
          info={{
            profile: {
              name: 'Profile',
              url: () => history.push(route(m), {referrer: 'MerchantsPortfolio'}),
            },
            applications: {
              name: 'Applications',
              url: () =>
                history.push(route(m, 'applications'), {
                  referrer: 'MerchantsPortfolio',
                }),
            },
            transactions: {
              name: 'Transactions',
              url: () =>
                history.push(route(m, 'transactions'), {
                  referrer: 'MerchantsPortfolio',
                }),
            },
            batches: {
              name: 'Batches',
              url: () =>
                history.push(route(m, 'batches'), {
                  referrer: 'MerchantsPortfolio',
                }),
            },
            authorizations: {
              name: 'Authorizations',
              url: () =>
                history.push(route(m, 'authorizations'), {
                  referrer: 'MerchantsPortfolio',
                }),
            },
            statements: {
              name: 'Statements',
              url: () =>
                history.push(route(m, 'statements'), {
                  referrer: 'MerchantsPortfolio',
                }),
            },
            deposits: {
              name: 'Deposits',
              url: () =>
                history.push(route(m, 'deposits'), {
                  referrer: 'MerchantsPortfolio',
                }),
            },
            chargebacks: {
              name: 'Disputes',
              url: () =>
                history.push(route(m, 'chargebacks'), {
                  referrer: 'MerchantsPortfolio',
                }),
            },
            attachments: {
              name: 'Attachments',
              url: () =>
                history.push(route(m, 'attachments'), {
                  referrer: 'MerchantsPortfolio',
                }),
            },
            notes: {
              name: 'Notes',
              url: () =>
                history.push(route(m, 'notes'), {
                  referrer: 'MerchantsPortfolio',
                }),
            },
            tickets: {
              name: 'Tickets',
              url: () =>
                history.push(route(m, 'tickets'), {
                  referrer: 'MerchantsPortfolio',
                }),
            },
          }}
        />
      ))}
      {loading && (
        <Box sx={{display: 'flex', justifyContent: 'center', p: 3}}>
          <CircularProgress color="primary" />
        </Box>
      )}
    </div>
  );
};

export default MerchantsPortfolio;
