import {CalendarViewLayout} from './CalendarViewLayout';
import {WeekDatesContainer} from './hooks/useWeekDatesContainer';

export const CalendarView = () => {
  return (
    <WeekDatesContainer.Provider>
      <CalendarViewLayout />
    </WeekDatesContainer.Provider>
  );
};
