import FactCheckIcon from '@mui/icons-material/FactCheck';
import {ListItem, ListItemIcon, ListItemText, ListSubheader, Typography} from '@mui/material';
import {AgentView, ApplicationView, ProfileView, UserRoles} from '@ozark/common';
import {getAgentById} from '@ozark/common/firebase/queries/agents';
import {getERPUserById} from '@ozark/common/firebase/queries/erpUsers';
import {asUniversalTimestamp} from '@ozark/common/helpers';
import {format} from 'date-fns';
import {useEffect, useState} from 'react';
import {generatePath, Link} from 'react-router-dom';
import {AGENTS_EDIT, USER_EDIT} from '../../../constants/routes';

type ApplicationLinksProps = {
  application: ApplicationView;
};

export const CompleteApplicationByTransferredLinkInfo = ({application}: ApplicationLinksProps) => {
  const [transferredBy, setTransferredBy] = useState<AgentView | ProfileView | null>();
  const [isTransferredByInvalid, setIsTransferredByInvalid] = useState(false);
  const [siteJumpTransferredBy, setSiteJumpTransferredBy] = useState<
    AgentView | ProfileView | null
  >();
  const [isSiteJumpTransferredByInvalid, setIsSiteJumpTransferredByInvalid] = useState(false);
  const {transferStatus} = application;

  useEffect(() => {
    if (!transferStatus) {
      return;
    }

    if (!transferStatus.transferredBy) {
      return;
    }

    const getTransferredBy = async () => {
      if (!transferStatus.transferredBy) {
        return;
      }

      try {
        const {uid, role} = transferStatus.transferredBy;
        const user = role === UserRoles.agent ? await getAgentById(uid) : await getERPUserById(uid);
        setTransferredBy(user);
      } catch (error) {
        setIsTransferredByInvalid(true);
        console.error('Error getting transferred by', error);
      }
    };

    const getSiteJumpTransferredBy = async () => {
      if (!transferStatus.siteJumpTransferredBy) {
        return;
      }

      try {
        const {uid, role} = transferStatus.siteJumpTransferredBy;
        const user = role === UserRoles.agent ? await getAgentById(uid) : await getERPUserById(uid);

        setSiteJumpTransferredBy(user);
      } catch (error) {
        setIsSiteJumpTransferredByInvalid(true);
        console.error('Error getting site jump transferred by', error);
      }
    };

    getTransferredBy();
    getSiteJumpTransferredBy();
  }, [transferStatus]);

  if (!application.isCompletedByTransferredLink) {
    return null;
  }

  return (
    <ListItem>
      <ListItemIcon>
        <FactCheckIcon />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="body2" component="p" noWrap>
            Transferred to Merchant
          </Typography>
        }
        secondary={
          <>
            {transferredBy && transferStatus.transferredBy && (
              <Typography variant="body2" component="p" noWrap>
                by {transferStatus.transferredBy.role}:{' '}
                <Link
                  to={generatePath(
                    transferStatus.transferredBy.role === UserRoles.agent ? AGENTS_EDIT : USER_EDIT,
                    {id: transferredBy.id, uid: transferredBy.id}
                  )}
                >
                  {transferredBy.firstName ?? ''} {transferredBy.lastName ?? ''}
                </Link>
              </Typography>
            )}

            {isTransferredByInvalid && (
              <Typography variant="body2" component="p" noWrap>
                Invalid User ID
              </Typography>
            )}

            {siteJumpTransferredBy && transferStatus.siteJumpTransferredBy && (
              <>
                Site Jump by {transferStatus.siteJumpTransferredBy.role}:{' '}
                <Link
                  to={generatePath(
                    transferStatus.siteJumpTransferredBy.role === UserRoles.agent
                      ? AGENTS_EDIT
                      : USER_EDIT,
                    {id: siteJumpTransferredBy.id, uid: siteJumpTransferredBy.id}
                  )}
                >
                  {siteJumpTransferredBy.firstName ?? ''} {siteJumpTransferredBy.lastName ?? ''}
                </Link>
              </>
            )}
            {isSiteJumpTransferredByInvalid && (
              <Typography variant="body2" component="p" noWrap>
                Site Jump: Invalid User ID
              </Typography>
            )}
          </>
        }
      />
    </ListItem>
  );
};

export const CompleteApplicationDateInfo = ({application}: ApplicationLinksProps) => {
  if (!application.completedAt) {
    return null;
  }

  const completedDate = asUniversalTimestamp(application.completedAt)!.toDate();
  const extraInfo = application.isCompletedByTransferredLink
    ? ` by ${application.firstName} ${application.lastName}`
    : '';
  return (
    <ListItem>
      <ListItemIcon>
        <FactCheckIcon />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="body2" component="p" noWrap>
            Application Signed{application.signedInPerson && ' In Person'}
          </Typography>
        }
        secondary={
          <>
            <Typography variant="body2" component="p" noWrap>
              {`${format(completedDate, 'MM/dd/yyyy hh:mm a')} ${extraInfo}`}
            </Typography>
          </>
        }
      />
    </ListItem>
  );
};

export const CompleteApplicationInfo = ({application}: ApplicationLinksProps) => {
  if (!application.isCompletedByTransferredLink && !application.completedAt) {
    return null;
  }

  return (
    <>
      <ListSubheader
        sx={{
          color: '#1c252c',
          backgroundColor: '#f5fafc',
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        Complete Application Info
      </ListSubheader>
      <CompleteApplicationByTransferredLinkInfo application={application} />
      <CompleteApplicationDateInfo application={application} />
    </>
  );
};
