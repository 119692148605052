import {Avatar, Box, Chip, Link, Tooltip, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {
  AccountStatus,
  getAccountStatusColor,
  MinMaxBatchInfo,
  SearchableProfileView,
} from '@ozark/common';
import {BoxEllipsis, currentFormatter} from '@ozark/common/components';
import {format} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';
import React from 'react';

export type RiskOverviewItemProps = {
  data: MinMaxBatchInfo;
  assigneeProfile?: SearchableProfileView | null;
  onFilterableValueClick?: (key: string, value: string) => void;
  onAssociatedApplicationsOpen?: (applicationId: string) => void;
  onRemoveAssociation?: (application: {id: string; mid?: string}) => void;
  hideViewDetailsButton?: boolean;
  shouldOpenDetailsInNewTab?: boolean;
};

export const IconWrapper = styled('span')(({theme}) => ({
  height: '100%',
  padding: 0,
  marginRight: theme.spacing(0.75),
  '& > *': {
    fill: '#4d6575',
  },
}));

export const FilterableValueButton = styled(Link)(() => ({
  display: 'inline-block',
  minWidth: 'unset',
  maxWidth: '100%',
  textTransform: 'none',
  fontWeight: 400,
  fontSize: 16,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'unset',
  verticalAlign: 'middle',
  cursor: 'pointer',
}));

export const RiskStatusValueButton = styled(FilterableValueButton)(() => ({
  display: '-webkit-box',
  WebkitLineClamp: '3',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
}));

export const RiskStatusValue = styled('span')(() => ({
  display: '-webkit-box',
  WebkitLineClamp: '3',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
}));

export const ColumnContent = styled(Box)(() => ({
  width: 'calc(100% - 30px)',
}));

export const displayDate = (date: Date, dateFormat?: string) => {
  const localTime = utcToZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
  return format(localTime, dateFormat ?? 'P');
};

export const NotAvailableText = (
  <Typography variant="body1" noWrap>
    N/A
  </Typography>
);

export const getFilterableValueTooltipTitle = (value: string) => (
  <>
    <b>{value}</b>
    <br />
    Click to filter by this value.
  </>
);

type CardContentRenderer = (
  data: MinMaxBatchInfo,
  getFilterableValueClickHandler?: (
    key: string,
    value: string
  ) => (event: React.MouseEvent<HTMLElement>) => void
) => JSX.Element | null;

export const renderGroupAgentBankInfo: CardContentRenderer = (
  data,
  getFilterableValueClickHandler
) => {
  const platform = (
    <Typography variant="caption" component="p" noWrap>
      {data.platform}
    </Typography>
  );

  return (
    <>
      <Typography
        variant="body1"
        noWrap
        title={data.groupName ?? ''}
        gutterBottom
        sx={{display: 'block'}}
      >
        {data.groupName}
      </Typography>
      <Typography variant="body2" noWrap title={data.agentName ?? ''} gutterBottom>
        {data.agentName ? data.agentName : 'No Assigned Agent'}
      </Typography>
      <Typography variant="body2" noWrap>
        {getFilterableValueClickHandler ? (
          <Tooltip title={getFilterableValueTooltipTitle(data.platform)} disableInteractive>
            <FilterableValueButton
              onClick={getFilterableValueClickHandler?.('platform', data.platform)}
              sx={{'& > p': {fontSize: '0.875rem'}}}
            >
              {platform}
            </FilterableValueButton>
          </Tooltip>
        ) : (
          platform
        )}
      </Typography>
    </>
  );
};

export const renderApprovalInformation: CardContentRenderer = data => {
  const date = displayDate(data.createdAt, 'MM/dd/yyyy h:mm a');
  return (
    <>
      <Typography variant="body1" noWrap gutterBottom title={data.dbaName}>
        {data.dbaName}
      </Typography>
      <Typography variant="body2" component="p" noWrap gutterBottom title={data.mid}>
        {data.mid}
      </Typography>
      <Typography variant="body2" component="p" noWrap title={date}>
        {date}
      </Typography>
    </>
  );
};

export const renderMerchantInformation: CardContentRenderer = data => {
  const name = `${data.firstName ?? ''} ${data.lastName ?? ''}`;
  const businessAddress = `${data.businessAddress1 ?? ''} ${data.businessAddress2 ?? ''}`;
  const businessCity = `${data.businessCity ?? ''}, ${data.businessState ?? ''} ${
    data.businessZip ?? ''
  }`;

  return (
    <>
      <Typography variant="body1" noWrap gutterBottom title={name}>
        {name}
      </Typography>
      <Typography variant="body2" component="p" noWrap gutterBottom title={businessAddress}>
        {businessAddress}
      </Typography>
      <Typography variant="body2" component="p" noWrap gutterBottom title={businessCity}>
        {businessCity}
      </Typography>
      <Typography variant="body2" component="p" noWrap gutterBottom>
        <a href={`mailto:${data.customerServiceEmail}`}>{data.customerServiceEmail}</a>
      </Typography>
      <Typography variant="body2" component="p" noWrap>
        <a href={`tel:${data.businessPhone}`}>{data.businessPhone}</a>
      </Typography>
    </>
  );
};

export const renderFirstBatchDateAndAmount: CardContentRenderer = data => {
  if (!data.firstBatchDate || !data.firstBatchAmount) {
    return null;
  }

  const date = displayDate(data.firstBatchDate);
  const amount = currentFormatter.format(data.firstBatchAmount);

  return (
    <>
      <Typography variant="body1" noWrap title={date}>
        {date}
      </Typography>
      <Typography variant="body1" noWrap title={amount}>
        {amount}
      </Typography>
    </>
  );
};

export const renderLastBatchDateAndAmount: CardContentRenderer = data => {
  if (!data.lastBatchDate || !data.lastBatchAmount) {
    return null;
  }

  const date = displayDate(data.lastBatchDate);
  const amount = currentFormatter.format(data.lastBatchAmount);

  return (
    <>
      <Typography variant="body1" noWrap title={date}>
        {date}
      </Typography>
      <Typography variant="body1" noWrap title={amount}>
        {amount}
      </Typography>
    </>
  );
};

export const renderRiskLabels: CardContentRenderer = (data, getFilterableValueClickHandler) => {
  if (!getFilterableValueClickHandler) {
    return (
      <>
        {data.riskLabels?.map(label => (
          <Chip key={label} label={label} sx={{mr: 1, mb: 0.5}} size="small" />
        ))}
      </>
    );
  }

  return (
    <>
      {data.riskLabels?.map(label => (
        <Tooltip key={label} title={getFilterableValueTooltipTitle(label)} disableInteractive>
          <Chip
            label={label}
            sx={{mr: 1, mb: 0.5, maxWidth: 140}}
            size="small"
            onClick={getFilterableValueClickHandler('riskLabels', label)}
          />
        </Tooltip>
      ))}
    </>
  );
};

export const renderAccountStatus: CardContentRenderer = (data, getFilterableValueClickHandler) => {
  const {accountStatus} = data;

  if (!accountStatus?.status) {
    return null;
  }

  const statusColor =
    accountStatus.status === AccountStatus.closed
      ? 'primary'
      : getAccountStatusColor(accountStatus.status);
  const status = (
    <Chip
      label={accountStatus?.status}
      variant="outlined"
      size="small"
      sx={[
        {cursor: getFilterableValueClickHandler ? 'pointer' : 'inherit', fontWeight: '500'},
        accountStatus.status !== AccountStatus.closed && {
          color: statusColor,
          borderColor: statusColor,
        },
      ]}
    />
  );

  return (
    <>
      {getFilterableValueClickHandler ? (
        <Tooltip title={getFilterableValueTooltipTitle(accountStatus?.status)} disableInteractive>
          <FilterableValueButton
            onClick={getFilterableValueClickHandler('accountStatus', accountStatus?.status)}
            sx={{mr: 0.5}}
          >
            {status}
          </FilterableValueButton>
        </Tooltip>
      ) : (
        <Box component="span" sx={{pr: 0.5}}>
          {status}
        </Box>
      )}
    </>
  );
};

export const renderRiskAssignmentInfo = (
  data: MinMaxBatchInfo,
  assigneeProfile?: SearchableProfileView | null
) => {
  return (
    <>
      {data.riskStatus && (
        <Box sx={{display: 'flex', justifyContent: 'center', mb: 0.5}}>
          <Tooltip
            title={
              assigneeProfile
                ? `${assigneeProfile.firstName} ${assigneeProfile.lastName}`
                : 'Unassigned'
            }
          >
            {assigneeProfile ? (
              <Avatar src={assigneeProfile.photoUrl}></Avatar>
            ) : (
              <Avatar>?</Avatar>
            )}
          </Tooltip>
        </Box>
      )}

      <BoxEllipsis useLinesEllipsis>
        <Tooltip title={data.riskStatus ?? data.accountStatus?.status ?? ''}>
          <Typography variant="body2" align="center" sx={{mt: 0.5, fontWeight: 500}}>
            {data.riskStatus ?? data.accountStatus?.status ?? ''}
          </Typography>
        </Tooltip>
      </BoxEllipsis>
    </>
  );
};
