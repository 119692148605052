import {Box, Button, Typography} from '@mui/material';
import {
  Collections,
  Firebase,
  LeadView,
  SalesDisposition,
  SalesGroups,
  SalesGroupsType,
  selectLeadView,
  useApiContainer,
} from '@ozark/common';
import {ButtonExportCsvExt, InfiniteDocuments, InfoNoData, Title} from '@ozark/common/components';
import {InfiniteSnapshotOptions, useInfiniteSnapshots} from '@ozark/common/hooks';
import {LeadExportRow} from '@ozark/functions/src/functions/express/private/types';
import firebase from 'firebase/compat';
import {useCallback, useState} from 'react';
import {useLocation} from 'react-router';
import {useStore} from '../../../store/helpers';
import {CreateSalesLeadDialog} from './CreateSalesLeadDialog';
import {Filters} from './Filters';
import {LeadCard} from './LeadCard';
import {getSalesLeadsExportConfig} from './salesLeadsExportConfig';

type LocationState = {
  startDate: Date | null;
  endDate: Date;
};

export const SalesLeadsListPage = (): JSX.Element => {
  const {profiles, authProfile, isUserAdmin} = useStore();
  const location = useLocation<LocationState>();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const api = useApiContainer();
  const [options, setOptions] = useState<InfiniteSnapshotOptions>({
    order: 'desc',
    orderBy: 'createdAt',
    limit: 50,
  });

  const {documents: leads, next} = useInfiniteSnapshots<LeadView>(
    Collections.leads,
    selectLeadView,
    options
  );

  const fetchExportReport = async () => {
    const rows = await api?.leads.getExportReport();
    return rows ?? [];
  };

  const getCurrentRows = async () => {
    if (!leads.data) {
      return [];
    }
    const allLeads = await fetchExportReport();
    const currentRows = Object.values(leads.data ?? {});
    const result: LeadExportRow[] = [];
    for (const currentRow of currentRows) {
      const leads = allLeads.filter(lead => lead.leadId === currentRow.id);
      result.push(...leads);
    }
    return result;
  };

  const onToggleOrder = () =>
    setOptions({...options, order: options.order === 'asc' ? 'desc' : 'asc'});

  const getFilter = useCallback(
    (
        showAll: boolean,
        group: SalesGroupsType,
        disposition: SalesDisposition | null,
        source: string | null
      ) =>
      (
        query:
          | firebase.firestore.Query<firebase.firestore.DocumentData>
          | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
      ): firebase.firestore.Query<firebase.firestore.DocumentData> => {
        let _query = query;

        if (!showAll && authProfile.data?.uid) {
          _query = _query.where('assigneeId', '==', authProfile.data.uid);
        }

        if (source) {
          _query = _query.where('source', '==', source);
        }

        if (disposition) {
          _query = _query.where('salesDisposition', '==', disposition);
        }

        if (group !== SalesGroups.all && !disposition) {
          // Number of dispositions in the SalesGroups.open is more than 10.
          // Use salesDispositionGroup with '==' operator instead of filtering by salesDisposition using 'in' operator
          _query = _query.where('salesDispositionGroup', '==', group);
        }

        if (location.state?.startDate) {
          _query = _query.where(
            'createdAt',
            '>',
            Firebase.Timestamp.fromDate(location.state.startDate)
          );
        }
        if (location.state?.endDate) {
          _query = _query.where(
            'createdAt',
            '<=',
            Firebase.Timestamp.fromDate(location.state.endDate)
          );
        }

        return _query;
      },
    [authProfile.data?.uid, location.state]
  );

  const salesLeadsExportConfig = getSalesLeadsExportConfig(profiles.dictionary);

  const onFiltersChange = (
    showAll: boolean,
    group: SalesGroupsType,
    disposition: SalesDisposition | null,
    source: string | null
  ) => setOptions({...options, filter: getFilter(showAll, group, disposition, source)});

  return (
    <Box
      sx={{
        height: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Title breadcrumbs={[<Typography>Sales Leads</Typography>]} />

      <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 2}}>
        <Box>
          <Button onClick={() => setCreateDialogOpen(true)} variant="outlined" sx={{mr: 1}}>
            Create New Lead
          </Button>
          <CreateSalesLeadDialog
            open={createDialogOpen}
            onClose={() => setCreateDialogOpen(false)}
          />
        </Box>

        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <Filters
            order={options.order}
            onToggleOrder={onToggleOrder}
            onFiltersChange={onFiltersChange}
          />

          {isUserAdmin() && (
            <ButtonExportCsvExt
              columnsConfig={salesLeadsExportConfig}
              filename="sales-leads"
              getAllRows={fetchExportReport}
              getCurrentRows={getCurrentRows}
              sx={{ml: 2}}
            />
          )}
        </Box>
      </Box>

      {leads.size === 0 && Object.values(leads?.data ?? {}).length === 0 && !leads.hasNextPage ? (
        <InfoNoData />
      ) : (
        <InfiniteDocuments
          documents={leads}
          next={next}
          onDocumentRender={document => <LeadCard lead={document} />}
        />
      )}
    </Box>
  );
};
