import LockResetIcon from '@mui/icons-material/LockReset';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {useCallable} from '@ozark/common';
import {useState} from 'react';
import {useNotification} from '../../hooks/useNotification';

type Props = {
  merchantEmail: string;
};

export const MerchantResetPasswordDialog = ({merchantEmail}: Props) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const showNotification = useNotification();
  const {resetPassword} = useCallable();

  const handleResetPassword = async () => {
    setConfirmationOpen(false);

    try {
      await resetPassword({email: merchantEmail, isErp: false});

      showNotification('success', `Reset password email successfully sent`);
    } catch (err) {
      console.log('Failed to reset password for merchant with an error:', err);
      showNotification('error', 'Failed to reset password');
    }
  };
  const message = `Do you want to send reset password email to ${merchantEmail}?`;

  return (
    <div>
      <Button size="large" startIcon={<LockResetIcon />} onClick={() => setConfirmationOpen(true)}>
        Reset Password
      </Button>
      <Dialog
        PaperProps={{
          sx: {
            width: '80%',
            maxHeight: 435,
          },
        }}
        maxWidth="xs"
        open={confirmationOpen}
      >
        <DialogTitle>Merchant Password Reset</DialogTitle>
        <DialogContent dividers>{message}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setConfirmationOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleResetPassword}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
