import {CalendarEvent, Collections, Firebase} from '@ozark/common';
import {mapCalendarEvents} from '@ozark/common/components';
import {useUserInfo} from '@ozark/common/hooks/useUserInfo';
import {useEffect, useState} from 'react';
import {useSalesLeadId} from './useSalesLeadId';

export function useSalesLeadsEvents() {
  const {uid, isErpAdmin} = useUserInfo();
  const {leadId} = useSalesLeadId();
  const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>([]);

  useEffect(() => {
    if (!leadId) return;
    let query = Firebase.firestore
      .collection(Collections.calendar)
      .where('association.type', '==', 'Lead')
      .where('association.id', '==', leadId);
    if (!isErpAdmin) {
      query = query.where('createdBy', '==', uid);
    }
    return query.orderBy('dateTime', 'desc').onSnapshot(
      snap => {
        setCalendarEvents(mapCalendarEvents(snap));
      },
      err => {
        console.error(err);
      }
    );
  }, [uid, isErpAdmin, leadId]);

  return {
    calendarEvents,
  };
}
