import GetAppIcon from '@mui/icons-material/GetApp';
import {Box, Button, Divider, Typography} from '@mui/material';
import {Title} from '@ozark/common/components/Title';
import {ReactElement} from 'react';

type BarProps = {
  title?: string;
  exportToCsv?: () => Promise<void>;
  Filters?: ReactElement;
};

export const Bar = ({title = '', exportToCsv, Filters}: BarProps) => {
  return (
    <Title breadcrumbs={[<Typography variant="body1">{title}</Typography>]}>
      <Box sx={{flexGrow: 1}} />
      {Filters && <>{Filters}</>}
      {exportToCsv && (
        <>
          <Divider
            orientation="vertical"
            sx={{
              margin: theme => theme.spacing(2, 2),
            }}
            flexItem
          />
          <Button
            startIcon={<GetAppIcon />}
            onClick={exportToCsv}
            variant="contained"
            color="primary"
          >
            Export
          </Button>
        </>
      )}
    </Title>
  );
};
