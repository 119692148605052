import {yupResolver} from '@hookform/resolvers/yup';
import {Button, CircularProgress, Divider, Grid, Paper, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Firebase, getSetPasswordShape, useSetPassword} from '@ozark/common';
import {
  InputPasswordAdornment,
  PasswordCheckList,
  TextField,
  Title,
} from '@ozark/common/components';
import {Fragment} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {useNotification} from '../../hooks/useNotification';
import {useStore} from '../../store/helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    outside: {
      flex: 1,
      overflow: 'scroll',
      scrollbarWidth: 'none',
      paddingTop: theme.spacing(0.5),
      position: 'relative',
    },
    inside: {
      height: '100%',
    },
    alert: {
      marginBottom: theme.spacing(1),
    },
    paper: {
      marginTop: theme.spacing(0),
      padding: theme.spacing(1, 2, 2),
      position: 'relative',
    },
    heading: {
      height: 48,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    saveButton: {
      width: 150,
    },
  })
);

type FormInput = {
  currentPassword: string;
  newPassword: string;
  passwordConfirmation: string;
};

const passwordChangeSchema = yup.object().shape({
  currentPassword: yup.string().required('Current password is required'),
  ...getSetPasswordShape<FormInput>('newPassword', 'passwordConfirmation'),
});

const ChangePassword = () => {
  const classes = useStyles();
  const showNotification = useNotification();
  const {authUser} = useStore();

  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: {isSubmitting, errors, dirtyFields},
  } = useForm<FormInput>({
    resolver: yupResolver(passwordChangeSchema),
  });

  const {
    showPassword,
    showConfirmation,
    displayIsValidPassword,
    displayPasswordCheckList,
    handleClickShowPassword,
    handleClickShowConfirmation,
    passwordValue,
    passwordConfirmationValue,
  } = useSetPassword<FormInput>({
    watch,
    passwordFieldName: 'newPassword',
    passwordConfirmationFieldName: 'passwordConfirmation',
    dirtyFields,
  });

  const handlePasswordChangeSubmit = async (data: FormInput) => {
    try {
      if (!authUser.data?.email) {
        return;
      }

      await Firebase.auth.signInWithEmailAndPassword(authUser.data.email, data.currentPassword);
      await Firebase.auth.currentUser!.updatePassword(data.newPassword);
      showNotification('success', 'Password successfully changed.');
    } catch (err: any) {
      const errorCode = err.code;
      if (errorCode === 'auth/wrong-password') {
        setError('currentPassword', {type: 'custom', message: 'Invalid password'});
        return;
      }
      showNotification('error', err.message || 'Failed to Save Changes.');
    }
  };

  return (
    <div className={classes.root}>
      <Title breadcrumbs={[<Typography variant="body1">My Profile</Typography>]} />
      <div className={classes.outside}>
        <div className={classes.inside}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <form noValidate onSubmit={handleSubmit(handlePasswordChangeSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className={classes.heading}>
                      <Typography variant="h6">Change Password</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      label="Current Password"
                      name="currentPassword"
                      type="password"
                      errors={errors}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      label="New Password"
                      name="newPassword"
                      type={showPassword ? 'text' : 'password'}
                      errors={errors}
                      control={control}
                      InputProps={{
                        endAdornment: (
                          <InputPasswordAdornment
                            showPassword={showPassword}
                            displayIsValidPassword={displayIsValidPassword}
                            handleClickShowPassword={handleClickShowPassword}
                          />
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      name="passwordConfirmation"
                      label="Confirm Password"
                      type={showConfirmation ? 'text' : 'password'}
                      errors={errors}
                      control={control}
                      InputProps={{
                        endAdornment: (
                          <InputPasswordAdornment
                            showPassword={showConfirmation}
                            displayIsValidPassword={displayIsValidPassword}
                            handleClickShowPassword={handleClickShowConfirmation}
                          />
                        ),
                      }}
                    />
                    {displayPasswordCheckList && (
                      <PasswordCheckList
                        password={passwordValue}
                        confirmation={passwordConfirmationValue}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.footer}>
                      <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                        className={classes.saveButton}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <CircularProgress size={24} />
                        ) : (
                          <Fragment>Save Changes</Fragment>
                        )}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
