import {Alert, Box, Button, Divider, Hidden, Link} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {SearchInput, Title} from '@ozark/common/components';
import {filterListByInput} from '@ozark/common/helpers';
import {useUrlQuery} from '@ozark/common/hooks/useUrlQuery';
import {useMemo, useState} from 'react';
import debounceRender from 'react-debounce-render';
import {useHistory} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';
import {CreateDialog} from './CreateDialog';
import {ProfileGrid} from './ProfileGrid';
import {ShowInactiveUsers, SHOW_INACTIVE_USERS} from './ShowInactiveUsers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      color: '#586069',
      padding: theme.spacing(0, 1, 0, 0),
    },
    space: {
      flexGrow: 1,
    },
    searchContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(2),
    },
    divider: {
      margin: theme.spacing(1, 0, 2),
    },
  })
);

const DebouncedProfileGrid = debounceRender(ProfileGrid, 300);

const Users = () => {
  const showInactiveUsers = useUrlQuery().get(SHOW_INACTIVE_USERS) === 'true';
  const classes = useStyles();
  const {profiles, isUserAdmin} = useStore();
  const history = useHistory();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [filterText, setFilterText] = useState('');
  const isAdmin = isUserAdmin();

  const onTextChange = (value: string) => {
    setFilterText(value);
  };

  const filteredProfiles = useMemo(() => {
    return {
      ...profiles,
      data: filterListByInput(profiles.data, filterText).filter(profile =>
        profile && showInactiveUsers ? profile : profile?.isActive
      ),
    };
  }, [profiles, filterText, showInactiveUsers]);

  return (
    <div>
      <Title
        breadcrumbs={[
          <Link component="button" variant="body1" onClick={() => history.push(ROUTES.USERS)}>
            Users
          </Link>,
        ]}
      >
        <Hidden lgDown>
          <SearchInput filterText={filterText} onTextChange={onTextChange} />
        </Hidden>
        <Box pl={2}>
          <ShowInactiveUsers />
        </Box>
        {isAdmin && (
          <>
            <Divider orientation="vertical" flexItem variant="middle" />
            <Box pl={2}>
              <Button onClick={() => setCreateDialogOpen(true)} variant="contained">
                Create New User
              </Button>
            </Box>
          </>
        )}
      </Title>
      <Hidden lgUp>
        <div className={classes.searchContainer}>
          <SearchInput filterText={filterText} onTextChange={onTextChange} inputSmallWidth="100%" />
        </div>
      </Hidden>
      {!isAdmin && (
        <Alert severity="info">
          You currently have <b>read only</b> access to this page.
        </Alert>
      )}
      <DebouncedProfileGrid profiles={filteredProfiles} isAuthUserAdmin={isAdmin} />
      {createDialogOpen && <CreateDialog onClose={() => setCreateDialogOpen(false)} />}
    </div>
  );
};

export default Users;
