import {Box} from '@mui/material';
import {useEffect, useState} from 'react';
import {Control, Controller, FieldValues, Path, useController} from 'react-hook-form';
import RichTextEditor, {EditorValue, ToolbarConfig} from 'react-rte';
import {getErrorMessage} from '../../util';
import {getTextAlignBlockMetadata, getTextAlignClassName, getTextAlignStyles} from './utils';

interface Props<T extends FieldValues, TName> {
  name: TName;
  label?: string;
  required?: boolean;
  enabled?: boolean;
  isActive?: boolean;
  errors: any;
  control: Control<T, any>;
  autoFocus?: boolean;
}

export const TextFieldRichEditor = <T extends FieldValues, TName extends Path<T>>({
  name,
  label,
  required,
  enabled = true,
  isActive = true,
  errors,
  control,
  autoFocus,
}: Props<T, TName>) => {
  const {
    field: {onChange, value},
  } = useController({name, control});

  const [editorValue, setEditorValue] = useState<EditorValue | undefined>(undefined);

  useEffect(() => {
    if (Boolean(value) && !Boolean(editorValue)) {
      setEditorValue(
        RichTextEditor.createValueFromString(value || '', 'html', {
          customBlockFn: getTextAlignBlockMetadata,
        })
      );
    }
  }, [value]);

  const handleEditorValueChange = (newValue: EditorValue) => {
    setEditorValue(newValue);

    const hasText = newValue.getEditorState().getCurrentContent().hasText();
    const text = hasText
      ? newValue.toString('html', {
          blockStyleFn: getTextAlignStyles,
        })
      : '';

    onChange(text);
  };

  const emptyValue = RichTextEditor.createEmptyValue();
  const errorMessage = getErrorMessage(name, errors) || false;
  const borderColor = errorMessage ? '#D32F2F' : '#ccc';

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{required: required}}
        render={() => (
          <Box
            style={{border: `1px solid ${borderColor}`, borderRadius: '4px'}}
            sx={{
              '& .DraftEditor-root': {
                minHeight: enabled ? '138px' : 'initial',
              },
            }}
          >
            <RichTextEditor
              disabled={!enabled}
              value={editorValue || emptyValue}
              onChange={handleEditorValueChange}
              placeholder={label || 'Enter description'}
              toolbarConfig={toolbarConfig}
              editorStyle={{
                minHeight: enabled ? '138px' : 'initial',
                fontFamily: 'Rubik, sans-serif',
              }}
              rootStyle={{
                background: 'none',
                border: 'none',
                borderRadius: 'none',
              }}
              toolbarStyle={{
                display: isActive ? 'block' : 'none',
              }}
              autoFocus={autoFocus}
              blockStyleFn={getTextAlignClassName}
            />
          </Box>
        )}
      />
      {errorMessage && (
        <span
          style={{
            color: '#d32f2f',
            fontFamily: 'Rubik, sans-serif',
            fontSize: '0.75rem',
            fontWeight: '400',
            margin: '3px 14px 0px 14px',
          }}
        >
          {errorMessage?.message}
        </span>
      )}
    </>
  );
};

const toolbarConfig: ToolbarConfig = {
  display: [
    'INLINE_STYLE_BUTTONS',
    'BLOCK_TYPE_BUTTONS',
    'LINK_BUTTONS',
    'BLOCK_TYPE_DROPDOWN',
    'BLOCK_ALIGNMENT_BUTTONS',
    'HISTORY_BUTTONS',
  ],
  INLINE_STYLE_BUTTONS: [
    {label: 'Bold', style: 'BOLD'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'},
  ],
  BLOCK_TYPE_BUTTONS: [
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'},
    {label: 'BLOCKQUOTE', style: 'blockquote'},
  ],
  BLOCK_TYPE_DROPDOWN: [
    {label: 'Normal', style: 'unstyled'},
    {label: 'Heading Large', style: 'header-one'},
    {label: 'Heading Medium', style: 'header-two'},
    {label: 'Heading Small', style: 'header-three'},
  ],
  BLOCK_ALIGNMENT_BUTTONS: [
    {label: 'Align Left', style: 'ALIGN_LEFT'},
    {label: 'Align Center', style: 'ALIGN_CENTER', className: 'center'},
    {label: 'Align Right', style: 'ALIGN_RIGHT'},
    {label: 'Align Justify', style: 'ALIGN_JUSTIFY'},
  ],
};
