import {Dictionary} from '../types/utils';

export const getPaginatedData = <T>(
  data: T[],
  paginate?: {limit: number; offset: number}
): {data: T[]; totalCount: number} => {
  const totalCount = data.length;

  if (!paginate || data.length === 0) {
    return {
      data,
      totalCount,
    };
  }

  const {offset, limit} = paginate;

  if (limit >= totalCount || limit === 0) {
    return {
      data,
      totalCount,
    };
  }

  return {
    data: data.slice(offset, Math.min(offset + limit, totalCount)),
    totalCount,
  };
};

export const getDictionaryFromArray = <T>(
  data: T[],
  getKeyByItem: (item: T) => string | undefined
): Dictionary<T> => {
  return data.reduce((acc, item) => {
    const key = getKeyByItem(item);
    if (key !== undefined) {
      acc[key] = item;
    }

    return acc;
  }, {} as Dictionary<T>);
};
