import {Link} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {useResidual} from '@ozark/common';
import {Loading, Title} from '@ozark/common/components';
import {useHistory, useParams} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {PayoutReport} from './PayoutReport';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    grow: {
      flex: 1,
    },
  })
);

const ResidualPayouts = () => {
  const classes = useStyles();
  const history = useHistory();
  const {id} = useParams<{id: string}>();
  const {document: residualView} = useResidual(id);

  if (residualView.promised) return <Loading />;

  return (
    <div className={classes.root}>
      <Title
        breadcrumbs={[
          <Link component="button" variant="body1" onClick={() => history.push(ROUTES.RESIDUALS)}>
            Residuals
          </Link>,
        ]}
      >
        <div className={classes.grow} />
      </Title>
      {residualView.data && <PayoutReport residualView={residualView.data} />}
    </div>
  );
};

export default ResidualPayouts;
