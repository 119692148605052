import FilterListIcon from '@mui/icons-material/FilterList';
import {Box, Chip, Grid, IconButton, Theme, Typography} from '@mui/material';
import {SxProps} from '@mui/system';
import {SearchableProfileView, SearchCriteria, useApiContainer} from '@ozark/common';
import {Column} from '@ozark/common/api/Column';
import {
  ActiveFilter,
  BoxEllipsis,
  DATETIME_FORMAT,
  DATE_FORMAT,
  Filter,
  FilterOption,
  Filters,
  Table,
  useExportToCsv,
} from '@ozark/common/components';
import {TimeZonedDate} from '@ozark/common/components/reports/Authorizations/timezone/TimeZonedDate';
import {
  TimeZoneInfo,
  TimeZoneTitle,
  useTimeZoneByMerchant,
} from '@ozark/common/components/reports/Authorizations/timezone/useTimeZoneByMerchant';
import {
  LeadOverview,
  PaginatedLeadsResponse,
} from '@ozark/functions/src/functions/express/private/types';
import {startOfMonth, startOfYear} from 'date-fns';
import {format, utcToZonedTime} from 'date-fns-tz';
import {isEmpty} from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {useActiveProfiles} from '../../../hooks/useActiveProfiles';
import {useStore} from '../../../store/helpers';
import {getAssigneeText} from '../utils';
import {Bar} from './Bar';
import {AssigneeLabel, MetricsFilters} from './Types';

const DefaultCriteria: SearchCriteria = {
  limit: 20, // page size
  offset: 1, // page
  order: 'desc',
  orderBy: 'createdAt',
};

const styles: Record<string, SxProps<Theme>> = {
  root: {
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  noContent: {
    top: '40%',
    position: 'relative',
    textAlign: 'center',
  },
  chip: {
    marginLeft: theme => theme.spacing(0.5),
    marginRight: theme => theme.spacing(0.5),
  },
};

export const SalesLeadsMetrics = () => {
  const {profiles} = useStore();
  const activeProfiles = useActiveProfiles(profiles);
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>(MetricsFilters);
  const [leads, setLeads] = useState<PaginatedLeadsResponse | null>();
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>(DefaultCriteria);
  const [filters, setFilters] = useState<Filter>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {timeZoneInfo, setTimeZone} = useTimeZoneByMerchant('undefined', undefined, undefined);
  const api = useApiContainer();

  const columnsConfig = getColumnsConfig(timeZoneInfo, setTimeZone, profiles.dictionary);

  const exportToCsv = useExportToCsv({
    filename: 'leads-metrics-report',
    rows: leads?.data,
    columnsConfig,
  });

  const handleRetrieveData = (searchCriteria: SearchCriteria) => {
    setSearchCriteria(searchCriteria);
  };

  const setFirstPage = () => {
    setSearchCriteria({
      ...searchCriteria,
      offset: 1,
    });
  };

  const handleDeleteFilter = (id: string) => () => {
    const _filters = {...filters};
    delete _filters[id];
    setFilters(_filters);
    setFirstPage();
  };

  const handleApplyFilter = (filter: ActiveFilter) => {
    setFilters(filters => ({...filters, [filter.option.column]: filter}));
    setFirstPage();
  };

  const getLeads = useCallback(() => {
    const tmpFilters = {...filters};
    let activeCreatedAtDateTDFilter = tmpFilters.createdAtDateTD;
    if (activeCreatedAtDateTDFilter && activeCreatedAtDateTDFilter.value === 'MTD') {
      //send start of the month to the server instead of MTD
      activeCreatedAtDateTDFilter = {
        ...activeCreatedAtDateTDFilter,
        value: startOfMonth(new Date()).toISOString(),
      };
    }
    if (activeCreatedAtDateTDFilter && activeCreatedAtDateTDFilter.value === 'YTD') {
      //send start of the year to the server instead of YTD
      activeCreatedAtDateTDFilter = {
        ...activeCreatedAtDateTDFilter,
        value: startOfYear(new Date()).toISOString(),
      };
    }
    if (activeCreatedAtDateTDFilter) {
      tmpFilters.createdAtDateTD = activeCreatedAtDateTDFilter;
    }
    setIsLoading(true);
    api?.leads
      .getLeads(searchCriteria, Object.values(tmpFilters))
      .then(setLeads)
      .finally(() => setIsLoading(false));
  }, [api?.leads, filters, searchCriteria]);

  useEffect(() => {
    if (!activeProfiles.data) {
      return;
    }
    const assigneeOptions = activeProfiles.data.map(x => ({
      key: x.id,
      value: getProfileDisplayName(x),
    }));
    const fOptions = [...MetricsFilters];
    fOptions.push({
      id: 'assigneeId',
      column: 'assigneeId',
      label: AssigneeLabel,
      type: 'list',
      options: assigneeOptions,
      operators: [
        {
          id: '__eq',
          label: 'equals',
        },
      ],
    });
    setFilterOptions(fOptions);
  }, [activeProfiles.data]);

  useEffect(() => {
    getLeads();
  }, [getLeads]);

  const getListItemName = (optionLabel: string, filterValue: string) => {
    if (optionLabel === AssigneeLabel) {
      const profile = activeProfiles.data?.find(x => x.id === filterValue);
      if (!profile) {
        return '';
      }
      return getProfileDisplayName(profile);
    }
    return filterValue;
  };

  return (
    <Box sx={styles.root}>
      <Bar
        title="Metrics"
        Filters={<Filters options={filterOptions} onApplyFilter={handleApplyFilter} />}
        exportToCsv={exportToCsv}
      />
      {!isLoading && leads && isEmpty(leads) && (
        <Typography sx={styles.noContent}>No Leads</Typography>
      )}
      {isLoading && <Typography sx={styles.noContent}>Loading...</Typography>}
      {!isLoading && leads && !isEmpty(leads) && (
        <Grid container spacing={2} direction="row" alignItems="stretch">
          <Grid item xs={12}>
            {filters && !isEmpty(filters) && (
              <IconButton disabled size="large">
                <FilterListIcon />
              </IconButton>
            )}
            {filters &&
              Object.keys(filters).map(key => {
                const filter = filters[key];
                if (filter.option.type === 'dateRange' && filter.operator.id === '__between') {
                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      sx={styles.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label} '
                          <b>{format(filter.value?.[0] as Date, DATETIME_FORMAT)}</b>' and '
                          <b>{format(filter.value?.[1] as Date, DATETIME_FORMAT)}</b>'
                        </span>
                      }
                      variant="outlined"
                      onDelete={handleDeleteFilter(key)}
                    />
                  );
                }
                if (filter.option.type === 'date' && filter.operator.id === '__between') {
                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      sx={styles.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label} '
                          <b>{format(filter.value?.[0] as Date, DATE_FORMAT)}</b>'
                        </span>
                      }
                      variant="outlined"
                      onDelete={handleDeleteFilter(key)}
                    />
                  );
                }
                if (filter.option.type === 'list') {
                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      sx={styles.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label} '
                          <b>{`${getListItemName(filter.option.label, filter.value as string)}`}</b>
                          '
                        </span>
                      }
                      variant="outlined"
                      onDelete={filter.option.force ? undefined : handleDeleteFilter(key)}
                    />
                  );
                }
                return (
                  <Chip
                    key={`${key}-${filter.operator.id}`}
                    sx={styles.chip}
                    label={
                      <span>
                        <b>{filter.option.label}</b> {filter.operator.label} '
                        <b>
                          {filter.option.type === 'currency'
                            ? `$${filter.value}`
                            : `${filter.value}`}
                        </b>
                        '
                      </span>
                    }
                    variant="outlined"
                    onDelete={filter.option.force ? undefined : handleDeleteFilter(key)}
                  />
                );
              })}
          </Grid>
          <Grid item xs={12}>
            <Table
              columns={columnsConfig}
              data={leads}
              onRetrieveData={handleRetrieveData}
              paginate
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

const getProfileDisplayName = (profile: SearchableProfileView) => {
  return `${profile.firstName || ''} ${profile.lastName || ''}`.trim();
};

const getFormattedDate = (date: Date, timeZoneId: string) => {
  const localTime = utcToZonedTime(date, timeZoneId);
  return format(localTime, DATETIME_FORMAT);
};

const getFormattedDateStr = (dateStr: string | undefined, timeZoneId: string) => {
  if (!dateStr) {
    return '';
  }
  const date = new Date(dateStr);
  return getFormattedDate(date, timeZoneId);
};

const displayTruncatedText = (str: string | undefined, maxMidth: number = 200) => {
  return <BoxEllipsis sx={{maxWidth: `${maxMidth}px`}}>{str ?? ''}</BoxEllipsis>;
};

const getColumnsConfig = (
  timeZoneInfo: TimeZoneInfo,
  setTimeZone: (timeZoneName: TimeZoneTitle) => void,
  profilesDictionary: {[p: string]: SearchableProfileView}
): Column<LeadOverview>[] => [
  {
    id: 'createdAt',
    numeric: false,
    sortable: true,
    label: 'Date Created',
    info: (
      <TimeZonedDate
        timeZoneInfo={timeZoneInfo}
        setTimeZone={setTimeZone}
        merchantTimeZoneExists={false}
      />
    ),
    selector: row => getFormattedDate(row.createdAt, timeZoneInfo.timeZoneId),
    export: row => getFormattedDate(row.createdAt, timeZoneInfo.timeZoneId),
  },
  {
    id: 'source',
    label: 'Source',
    numeric: false,
    sortable: false,
    selector: row => displayTruncatedText(row.source, 120),
    export: row => row.source ?? '',
  },
  {
    id: 'campaign',
    numeric: false,
    sortable: false,
    label: 'Campaign',
    selector: row => displayTruncatedText(row.campaign, 120),
    export: row => row.campaign ?? '',
  },

  {
    id: 'businessName',
    numeric: false,
    sortable: false,
    label: 'Business Name',
    selector: row => displayTruncatedText(row.businessName),
    export: row => row.businessName ?? '',
  },
  {
    id: 'contactName',
    numeric: false,
    sortable: false,
    label: 'Contact Name',
    selector: row => displayTruncatedText(`${row.firstName} ${row.lastName}`),
    export: row => `${row.firstName} ${row.lastName}`,
  },
  {
    id: 'externalSalesRepEmail',
    numeric: false,
    sortable: false,
    label: 'Sales Rep',
    selector: row => displayTruncatedText(row.externalSalesRepEmail),
    export: row => row.externalSalesRepEmail ?? '',
  },
  {
    id: 'assigneeId',
    numeric: false,
    sortable: false,
    label: 'Assignee',
    selector: row => displayTruncatedText(getAssigneeText(profilesDictionary, row.assigneeId)),
    export: row => getAssigneeText(profilesDictionary, row.assigneeId),
  },
  {
    id: 'salesDisposition',
    numeric: false,
    sortable: false,
    label: 'Lead Disposition',
    export: row => row.salesDisposition ?? '',
  },
  {
    id: 'appCreatedAt',
    numeric: false,
    sortable: false,
    label: 'Date Converted',
    selector: row => getFormattedDateStr(row.appCreatedAt, timeZoneInfo.timeZoneId),
    export: row => getFormattedDateStr(row.appCreatedAt, timeZoneInfo.timeZoneId),
  },
  {
    id: 'appDispositionUpdatedAt',
    numeric: false,
    sortable: false,
    label: 'Date Boarded',
    selector: row => getFormattedDateStr(row.appDispositionUpdatedAt, timeZoneInfo.timeZoneId),
    export: row => getFormattedDateStr(row.appDispositionUpdatedAt, timeZoneInfo.timeZoneId),
  },
  {
    id: 'appDisposition',
    numeric: false,
    sortable: false,
    label: 'Application Disposition',
    export: row => row.appDisposition ?? '',
  },
  {
    id: 'appDistinguishableId',
    numeric: false,
    sortable: false,
    label: 'Application Association',
    export: row => row.appDistinguishableId ?? '',
  },
];
