import {yupResolver} from '@hookform/resolvers/yup';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Agent, useAgents, useCallable, useGroups, ViewBase} from '@ozark/common';
import {Loading, Select} from '@ozark/common/components';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {useNotification} from '../../hooks/useNotification';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      [theme.breakpoints.up('md')]: {
        width: 400,
      },
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

export interface EditAgentDialogDataModel {
  applicationId: string;
  groupId: string;
  agentId?: string;
  merchantUid?: string;
  merchantLastFirstName?: string;
}

export const EditGroupAgentFormSchema = yup.object().shape({
  groupId: yup.string().required('Group is required'),
  agentId: yup.string().required('Agent is required'),
});

type Props = {
  dialogData: EditAgentDialogDataModel;
  onClose: () => void;
};

export const EditAgentDialog = ({dialogData, onClose}: Props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const showNotification = useNotification();
  const [filteredAgents, setFilteredAgents] = useState<ViewBase<Agent>[]>([]);
  const {applicationTransferToGroup} = useCallable();

  const defaultValues: any = {
    groupId: dialogData.groupId,
    agentId: dialogData.agentId ?? 'null',
  };

  const hookForm = useForm({
    defaultValues,
    resolver: yupResolver(EditGroupAgentFormSchema),
  });

  const {
    formState: {errors},
    control,
    handleSubmit,
    watch,
    setValue,
  } = hookForm;

  const {documents: groups} = useGroups();
  const {documents: agents} = useAgents();

  const watchGroupId = watch('groupId', dialogData.groupId);
  const isTransferMerchant = !!dialogData.merchantUid && dialogData.groupId !== watchGroupId;

  const onSuccess = async (formData: any) => {
    try {
      setLoading(true);
      const transferResponse = await applicationTransferToGroup({
        applicationId: dialogData.applicationId,
        newAgentId: formData.agentId === 'null' ? null : formData.agentId,
        newGroupId: formData.groupId,
        transferMerchant: isTransferMerchant,
      });

      if (transferResponse.status !== 'ok') {
        showNotification('error', transferResponse.message);
        return;
      }
      showNotification('success', 'Group & Agent successfully updated.');
      onClose();
      return;
    } catch (_err) {
      showNotification('error', 'Failed to update Group & Agent.');
    } finally {
      setLoading(false);
    }
  };

  const onError = (data: any) => {
    console.error(`Error updating Group & Agent ${JSON.stringify(data)}`);
    setLoading(false);
  };

  useEffect(() => {
    if (!agents.data) {
      return;
    }

    const filteredAgents = agents.data.filter(
      (agent: Agent) => agent.group && agent.group.id === watchGroupId && !agent.deleted
    );
    setFilteredAgents(filteredAgents);
    setValue('agentId', '' as any);
  }, [agents, watchGroupId, setValue]);

  const generateGroupOptions = () =>
    groups.data?.sortAndMap(
      group => <MenuItem value={group.id}>{group.name}</MenuItem>,
      group => group.name
    );

  const generateAgentOptions = () => [
    <MenuItem value={'null'}>Unassigned</MenuItem>,
    <Divider />,
    filteredAgents.sortAndMap(
      agent => (
        <MenuItem value={agent.id}>
          {agent.firstName} {agent.lastName}
        </MenuItem>
      ),
      agent => `${agent.firstName} ${agent.lastName}`
    ),
  ];

  if (agents.promised || groups.promised) {
    return <Loading />;
  }

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="edit-group-dialog-title" maxWidth={'lg'}>
      <DialogTitle id="edit-group-dialog-title">Edit Group/Agent</DialogTitle>
      <DialogContent dividers>
        <div className={classes.content}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Select
                variant="outlined"
                name="groupId"
                label="Group"
                errors={errors}
                control={control}
              >
                {generateGroupOptions()}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Select
                variant="outlined"
                name="agentId"
                label="Agent"
                required
                displayEmpty
                errors={errors}
                control={control}
              >
                {generateAgentOptions()}
              </Select>
            </Grid>
            {isTransferMerchant && (
              <Grid item xs={12}>
                <Typography variant="body1" sx={{fontWeight: 'bold', fontSize: 15}}>
                  Merchant {dialogData.merchantLastFirstName} and all associated applications will
                  be transferred to the new group
                </Typography>
              </Grid>
            )}
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit(onSuccess, onError)} color="primary" disabled={loading}>
          {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
