import {CircularProgress, Paper, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {AllMIDs, MonthlySalesSummary, useApiContainer, useMidsContainer} from '@ozark/common';
import {
  MidSelect,
  Title,
  TwelveMonthProcessingGraph,
  TwelveMonthProcessingTable,
} from '@ozark/common/components';
import {useEffect, useState} from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
  },
  menuPaper: {
    minWidth: 200,
  },
  midListItem: {
    padding: theme.spacing(0, 2),
  },
  noContent: {
    top: '40%',
    position: 'relative',
    textAlign: 'center',
  },
  grow: {
    flex: 1,
  },
  midButton: {
    textTransform: 'none !important' as any,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const TransactionsSummary = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [yearlySalesSummary, setYearlySalesSummary] = useState<MonthlySalesSummary[] | null>();

  const api = useApiContainer();
  const {mids, handleSelectMid, selectedMid} = useMidsContainer();

  useEffect(() => {
    if (!api || mids.promised) return;

    setLoading(true);
    api.reports
      .getYearlySummary(selectedMid === AllMIDs ? undefined : selectedMid)
      .then(result => {
        setYearlySalesSummary(result);
        setLoading(false);
      })
      .catch(_ => {
        // TODO: log as error to sentry?
        setLoading(false);
      });
  }, [mids, selectedMid, api]);

  return (
    <Paper className={classes.root}>
      <Title
        breadcrumbs={[
          <Typography variant="body1">Trailing 12-Month Processing Summary</Typography>,
        ]}
      >
        <div className={classes.grow} />
        <MidSelect mids={mids} handleSelectMid={handleSelectMid} selectedMid={selectedMid} />
      </Title>

      {!mids.promised && !mids.data && (
        <Typography className={classes.noContent}>
          There are no MIDs associated with your account
        </Typography>
      )}

      {loading || !yearlySalesSummary ? (
        <div className={classes.loading}>
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <div>
          <TwelveMonthProcessingGraph yearlySalesSummary={yearlySalesSummary} />
          <TwelveMonthProcessingTable yearlySalesSummary={yearlySalesSummary} />
        </div>
      )}
    </Paper>
  );
};

export default TransactionsSummary;
