import {Box, CircularProgress} from '@mui/material';
import {useApplicationAssociationsList} from '../../hooks/useApplicationAssociations';
import {ApplicationAssociationsList} from '../ApplicationAssociations';
import {getRiskApplicationDetailsRoute} from './common';

type Props = {
  applicationId: string;
};

export const AssociatedApplications = ({applicationId}: Props): JSX.Element => {
  const associatedApplications = useApplicationAssociationsList({applicationId});

  if (associatedApplications.promised) {
    return (
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 70}}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ApplicationAssociationsList
      applications={associatedApplications.data}
      getItemDetailsRoute={getRiskApplicationDetailsRoute}
      getItemLinkTitle={app => `${app.legalBusinessName ?? ''} / ${app.doingBusinessAs}`}
      readOnly
    />
  );
};
