import {Button, CircularProgress} from '@mui/material';
import {Firebase, useCallable} from '@ozark/common';
import {Fragment, useState} from 'react';

export const AttachmentExporter = ({applicationId}: {applicationId: string}) => {
  const {archivateAttachments} = useCallable();
  const [loading, setLoading] = useState<boolean>(false);

  const exportAll = () => {
    setLoading(true);
    archivateAttachments({applicationId}).then(response => {
      setLoading(false);
      if (response.status === 'ok') {
        Firebase.storage
          .ref(response.cloudPath)
          .getDownloadURL()
          .then(downloadUrl => window.open(downloadUrl, '_blank', 'noopener'));
      }
    });
  };

  return (
    <Button size="small" onClick={exportAll} variant="contained" color="primary" disabled={loading}>
      {loading ? (
        <>
          <CircularProgress size={24} />
          <Fragment>Please wait. Loading...</Fragment>
        </>
      ) : (
        <Fragment>Export All</Fragment>
      )}
    </Button>
  );
};
