import {Box, Divider} from '@mui/material';
import {
  FraudMidBinTransactionRecord,
  FraudMidBinTransactionReport,
} from '@ozark/functions/src/functions/express/private/types';
import {add} from 'date-fns';
import {useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
  ButtonExportCsv,
  Filter,
  FilterOption,
  FiltersAddButton,
  FiltersApplied,
  forceActiveFilter,
  Table,
} from '../..';
import {SearchCriteria, useApiContainer} from '../../..';
import {Column} from '../../../api/Column';
import {LoadingStatus} from '../../Analytics/common/LoadingStatus';
import {useBreadcrumbsContainer} from '../../common/Breadcrumbs';

const getDefaultFilter = () => {
  const dateCurrent = new Date();
  const dateStarting = new Date(dateCurrent.getFullYear(), dateCurrent.getMonth(), 1);
  const transactionDate = forceActiveFilter(filtersConfig, 'transactionDate', '__between', [
    dateStarting,
    add(dateStarting, {months: 1}),
  ]);
  return transactionDate;
};

export const FraudAnalysisSameBinTransactions = () => {
  const api = useApiContainer();
  const [loading, setLoading] = useState(true);
  const {mid} = useParams<{mid: string}>();
  const [pageConfig, setPageConfig] = useState<SearchCriteria>(pageConfigDefault);
  const [report, setReport] = useState<FraudMidBinTransactionReport | null>(null);
  const hasData = Boolean(report?.data.length);
  const [filters, setFilters] = useState<Filter>({
    transactionDate: getDefaultFilter(),
  });
  const {setBreadcrumbs} = useBreadcrumbsContainer();

  useEffect(() => {
    setBreadcrumbs([
      {text: 'Fraud Analysis', url: '/fraud-analysis'},
      {text: 'Same BIN Report', url: '/fraud-analysis/same-bin'},
      {text: mid, url: ''},
    ]);
  }, []);

  useEffect(() => {
    if (!api) return;
    setLoading(true);
    let isMounted: boolean | undefined = true;
    api.fraud
      .getFraudSameBinTransactionsReport(mid, pageConfig, Object.values(filters))
      .then(result => isMounted && setReport(result))
      .catch(err => {
        console.error(err);
        isMounted && setReport(null);
      })
      .finally(() => isMounted && setLoading(false));
    return () => (isMounted = undefined);
  }, [filters, pageConfig]);

  const getAllDataForExport = useCallback(async () => {
    if (!api) return [];
    const pageConfigFull: SearchCriteria = {...pageConfig, offset: 0, limit: 0};
    const result = await api.fraud.getFraudSameBinTransactionsReport(
      mid,
      pageConfigFull,
      Object.values(filters)
    );
    return result?.data ?? [];
  }, [pageConfig]);

  return (
    <Box mt={2}>
      <Box display="flex" alignItems="center" justifyContent="flex-end" pb={2}>
        <FiltersApplied filters={filters} setFilters={setFilters} />
        <Box flex={1} />
        <FiltersAddButton filters={filters} setFilters={setFilters} filtersConfig={filtersConfig} />
        <Divider orientation="vertical" flexItem sx={{mx: 2}} />
        <ButtonExportCsv
          filename="fraud-same-bin-report"
          rows={report?.data}
          getRows={getAllDataForExport}
          columnsConfig={columnsConfig}
          useSelectorMapping
        />
      </Box>

      <LoadingStatus loading={loading} hasData={hasData} />

      {!loading && hasData && (
        <Table
          columns={columnsConfig}
          data={{
            sort: [[pageConfig.orderBy, pageConfig.order as 'DESC' | 'ASC']],
            limit: pageConfig.limit,
            offset: pageConfig.offset,
            totalCount: report?.totalCount ?? 0,
            data: report?.data ?? [],
          }}
          onRetrieveData={setPageConfig}
          paginate
          useOffsetAsPage
        />
      )}
    </Box>
  );
};

const pageConfigDefault: SearchCriteria = {
  limit: 20, // pageSize
  offset: 1, // page (offset = page * pageSize 1 based) 0 will produce negative offset
  order: 'desc',
  orderBy: 'transactionDate',
};

const filtersConfig: FilterOption[] = [
  {
    id: 'transactionDate',
    column: 'transactionDate',
    autoSelect: true,
    label: 'Date Range',
    type: 'dateRange',
    dateFormat: 'MMM dd, yyyy',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
];

const columnsConfig: Column<FraudMidBinTransactionRecord>[] = [
  {
    id: 'transactionAmount',
    label: 'Amount',
    numeric: false,
    sortable: true,
    export: true,
  },
  {
    id: 'accountNumberLast4',
    label: 'Account Number Last 4',
    numeric: false,
    sortable: true,
    export: true,
  },
  {
    id: 'transactionDate',
    label: 'Transaction Date',
    numeric: false,
    sortable: true,
    export: true,
  },
];
