import {Button, Fade, Menu, MenuItem} from '@mui/material';
import {ResidualStatus, ResidualView} from '@ozark/common';
import {MouseEventHandler, useState} from 'react';

interface Props {
  selected: ResidualView | null;
  setShowErrors: (show: boolean) => void;
  handlePayoutReportClick: () => void;
  handlePublish: () => void;
  handleUndoPublish: () => void;
  handleRefreshResidualClick: () => Promise<void>;
  handleDownloadClick: () => void;
  exportToCsv: () => Promise<void>;
  handleDelete: () => void;
}

export const ActionButton = ({
  selected,
  setShowErrors,
  handlePayoutReportClick,
  handlePublish,
  handleUndoPublish,
  handleRefreshResidualClick,
  handleDownloadClick,
  exportToCsv,
  handleDelete,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleMenuClick: MouseEventHandler<HTMLButtonElement> = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button color="inherit" onClick={handleMenuClick} variant="text" sx={{p: 0, lineHeight: 0}}>
        actions
      </Button>
      <Menu
        id="option-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        TransitionComponent={Fade}
      >
        {selected?.status === ResidualStatus.failed && (
          <MenuItem onClick={() => setShowErrors(true)}>View Errors</MenuItem>
        )}
        {selected?.status !== ResidualStatus.failed && (
          <MenuItem onClick={handlePayoutReportClick}>Payout Report</MenuItem>
        )}
        {(selected?.status === ResidualStatus.imported ||
          selected?.status === ResidualStatus.readyForReview) && (
          <MenuItem onClick={handlePublish}>Publish</MenuItem>
        )}
        {selected?.status === ResidualStatus.published && (
          <MenuItem onClick={handleUndoPublish}>Undo Publish</MenuItem>
        )}
        {selected?.status !== ResidualStatus.failed && (
          <MenuItem onClick={handleRefreshResidualClick}>Refresh</MenuItem>
        )}
        <MenuItem onClick={handleDownloadClick}>Download Original File</MenuItem>
        <MenuItem onClick={exportToCsv}>Export</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </>
  );
};
