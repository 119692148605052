import {
  AsyncState,
  Collections,
  Firebase,
  Profile,
  ProfileView,
  selectProfileView,
  UniversalSnapshot,
} from '@ozark/common';
import {useEffect, useState} from 'react';
import {saveProfile} from '../helpers/saveProfile';

export const useProfileView = (uid: string) => {
  const [profile, setProfile] = useState<AsyncState<ProfileView>>({promised: true});
  useEffect(() => {
    const unsubscribe = Firebase.firestore
      .collection(Collections.profiles)
      .doc(uid)
      .onSnapshot(
        snapshot => {
          if (!snapshot.exists) {
            setProfile({promised: false});
            return;
          }
          const data = selectProfileView(snapshot as UniversalSnapshot<Profile>);
          if (!data) return;
          setProfile({promised: false, data});
        },
        err => {
          console.error(err);
          setProfile({promised: false, error: err});
        }
      );
    return () => {
      unsubscribe();
    };
  }, [uid]);

  const set = saveProfile;

  return {profile, set};
};
