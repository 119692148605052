import {RiskOverviewItemProps} from '../common';
import {AssociatedMidsButton} from './AssociatedMidsButton';
import {DetailsButton} from './DetailsButton';
import {InformationButton} from './InformationButton';
import {RemoveAssociationButton} from './RemoveAssociationButton';

type Props = Pick<
  RiskOverviewItemProps,
  | 'data'
  | 'onAssociatedApplicationsOpen'
  | 'onRemoveAssociation'
  | 'hideViewDetailsButton'
  | 'shouldOpenDetailsInNewTab'
>;

export const RiskOverviewItemButtons = ({
  data,
  onAssociatedApplicationsOpen,
  onRemoveAssociation,
  hideViewDetailsButton,
  shouldOpenDetailsInNewTab,
}: Props): JSX.Element => {
  return (
    <>
      {!hideViewDetailsButton && (
        <DetailsButton
          applicationId={data.id}
          shouldOpenDetailsInNewTab={shouldOpenDetailsInNewTab}
        />
      )}
      <InformationButton
        applicationId={data.id}
        mid={data.mid}
        contained={!!hideViewDetailsButton}
      />
      {onAssociatedApplicationsOpen && (
        <AssociatedMidsButton
          data={data}
          onAssociatedApplicationsOpen={onAssociatedApplicationsOpen}
        />
      )}
      {onRemoveAssociation && (
        <RemoveAssociationButton data={data} onRemoveAssociation={onRemoveAssociation} />
      )}
    </>
  );
};
