import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';

type Props = {
  open: boolean;
  onUpdateAnnouncement: () => void;
  onSendNewAnnouncement: () => void;
  onCancel: () => void;
};

export const SendOrUpdateAnnouncement = ({
  open,
  onUpdateAnnouncement,
  onSendNewAnnouncement,
  onCancel,
}: Props): JSX.Element => {
  return (
    <Dialog open={open}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <Typography>This announcement is already sent or scheduled to be sent to users.</Typography>
        <Typography>
          You can update the existing announcement or send a new announcement to users.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onUpdateAnnouncement}>Update existing announcement</Button>
        <Button onClick={onSendNewAnnouncement}>Send new announcement</Button>
      </DialogActions>
    </Dialog>
  );
};
