import BusinessIcon from '@mui/icons-material/Business';
import ComputerIcon from '@mui/icons-material/Computer';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import MessageIcon from '@mui/icons-material/Message';
import TodayIcon from '@mui/icons-material/Today';
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {getSalesLeadColor, LeadView} from '@ozark/common';
import {format, formatDistanceToNow} from 'date-fns';
import {generatePath} from 'react-router';
import {Link as RouterLink} from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import {useStore} from '../../../store/helpers';

const Column = styled('span')(({theme}) => ({
  display: 'flex',
  alignItems: 'flex-start',
  height: '100%',
  flexBasis: '20%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  transition: theme.transitions.create(['flexBasis'], {
    duration: theme.transitions.duration.standard,
  }),
}));

const IconWrapper = styled('span')(({theme}) => ({
  height: '100%',
  padding: 0,
  marginRight: theme.spacing(1),
  '& > *': {
    fill: '#4d6575',
  },
}));

type Props = {
  lead: LeadView;
};

export const LeadCard = ({lead}: Props) => {
  const {profiles} = useStore();
  const profile = lead.assigneeId ? profiles.dictionary[lead.assigneeId] : null;

  const lastNoteInfoAuthor = lead.lastNoteInfo?.author;
  const lastNoteInfoElapsed =
    lead.lastNoteInfo?.createdAt &&
    formatDistanceToNow(lead.lastNoteInfo.createdAt?.toDate(), {
      addSuffix: false,
    });
  const lastNoteInfo = lastNoteInfoAuthor
    ? `${lastNoteInfoElapsed ?? ''} (by ${lastNoteInfoAuthor ?? ''})`
    : `${lastNoteInfoElapsed ?? ''}`;

  return (
    <Card
      sx={{
        color: '#4d6575',
        borderLeft: theme => `solid 8px ${theme.palette.primary.light}`,
        borderLeftColor: getSalesLeadColor(lead.salesDisposition),
      }}
    >
      <CardActionArea
        component={RouterLink}
        to={generatePath(ROUTES.SALES_LEADS_DETAILS, {leadId: lead.id})}
      >
        <CardContent sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Box
            sx={{
              height: '100%',
              flexBasis: '10%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: theme => theme.spacing(0, 2),
            }}
          >
            <Tooltip title={profile ? `${profile.firstName} ${profile.lastName}` : 'Unassigned'}>
              {profile ? (
                <Avatar sx={{mb: 1}} src={profile.photoUrl} />
              ) : (
                <Avatar sx={{mb: 1}}>?</Avatar>
              )}
            </Tooltip>
            <Tooltip title={lead.salesDisposition}>
              <Typography
                variant="caption"
                align="center"
                noWrap
                sx={{fontWeight: 500, textTransform: 'uppercase', maxWidth: '125px'}}
              >
                {lead.salesDisposition}
              </Typography>
            </Tooltip>
          </Box>

          <Divider orientation="vertical" flexItem sx={{p: 0}} />

          <Column>
            <Stack>
              <Stack direction="row">
                <IconWrapper>
                  <TodayIcon color="inherit" />
                </IconWrapper>
                <Typography variant="body1" noWrap gutterBottom>
                  {lead.createdAt && format(lead.createdAt.toDate(), 'MM/dd/yyyy h:mm a')}
                </Typography>
              </Stack>
              {lead.lastNoteInfo && (
                <Stack direction="row">
                  <IconWrapper>
                    <MessageIcon color="inherit" />
                  </IconWrapper>
                  <Tooltip title={lastNoteInfo}>
                    <Typography variant="body1" noWrap gutterBottom>
                      {lastNoteInfo}
                    </Typography>
                  </Tooltip>
                </Stack>
              )}
            </Stack>
          </Column>

          <Divider orientation="vertical" flexItem sx={{p: 0}} />

          <Column>
            <IconWrapper>
              <BusinessIcon color="inherit" />
            </IconWrapper>
            <Typography variant="body1" noWrap gutterBottom>
              {lead.businessName}
            </Typography>
          </Column>

          <Divider orientation="vertical" flexItem sx={{p: 0}} />

          <Column>
            <IconWrapper>
              <ContactPhoneIcon color="inherit" />
            </IconWrapper>
            <div>
              <Typography variant="body1" noWrap gutterBottom>
                {lead.firstName ?? ''} {lead.lastName ?? ''}
              </Typography>
              <Typography variant="caption" component="p" noWrap gutterBottom>
                {lead.phone}
              </Typography>
              <Typography variant="caption" component="p" noWrap>
                {lead.email}
              </Typography>
            </div>
          </Column>

          <Divider orientation="vertical" flexItem sx={{p: 0}} />

          <Column>
            <IconWrapper>
              <ComputerIcon color="inherit" />
            </IconWrapper>
            <div>
              <Typography variant="body1" noWrap gutterBottom>
                Source: {lead.source}
              </Typography>
              {lead.ipAddress && (
                <Typography variant="body1" noWrap gutterBottom>
                  IP: {lead.ipAddress}
                </Typography>
              )}
            </div>
          </Column>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
