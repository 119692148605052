import {Popover} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {AuditView} from '@ozark/common';
import ReactDiffViewer from 'react-diff-viewer';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      width: 800,
    },
  })
);

type DiffProps = {
  anchorElement: HTMLDivElement | null;
  onClose: () => void;
  auditView: AuditView | null;
};

export const Diff = ({anchorElement, onClose, auditView}: DiffProps) => {
  const classes = useStyles();
  if (!auditView) return null;
  return (
    <Popover
      open={Boolean(anchorElement)}
      anchorEl={anchorElement}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <div className={classes.content}>
        <ReactDiffViewer
          oldValue={JSON.stringify(auditView.meta.delta.before, null, '  ')}
          newValue={JSON.stringify(auditView.meta.delta.after, null, '  ')}
          splitView={true}
          disableWordDiff={true}
          hideLineNumbers={true}
        />
      </div>
    </Popover>
  );
};
