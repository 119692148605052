import {
  conditionallyApprovedBoardedRaw,
  CreateDisputeReasonProps,
  Dispositions,
  Markdown,
} from '@ozark/common';
import {createDisputeReason} from '../createDisputeReason';
import {DisputeReason} from '../index';

export const conditionallyApproved = {
  [Dispositions.boarded]: conditionallyApprovedBoardedRaw.map(reason => {
    const {name, description, type} = reason as CreateDisputeReasonProps;

    return createDisputeReason({
      name: name,
      description: Markdown?.render(description) || '',
      type: type,
    });
  }) as DisputeReason[],
} as {[key in Dispositions]?: DisputeReason[]};
