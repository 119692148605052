import {DocumentBase} from '.';
import {ResidualStatus} from '../constants';
import {ResidualPayout} from '../functions/express/private/types/Residual';
import {Overwrite} from '../helpers/type';
import {UniversalTimestamp} from './compat';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type LineItem = {
  description: string;
  amount: number;
};

export type ManualLineItem = LineItem & {
  revShare?: string;
};

export type Totals = {
  net: number;
  group: number;
  gross: number;
  agent: number;
  override: number;
  masterAgentPayout: number;
  subAgentPayout: number;
};

export type ResidualPayoutWithNumbers = Overwrite<
  ResidualPayout,
  {
    profit: number;
    groupNetSplit: number;
    groupProfit: number;
    grossProfit: number;
    agentNetSplit: number;
    agentProfit: number;
    subAgentNetSplit: number;
    subAgentProfit: number;
    lineItems: LineItem[];
    sumOfLineItems: number;
    agentOverride?: number;
  }
>;

export type Payouts = {
  [groupId: string]: {
    payoutMethod: string;
    payouts: {[agentId: string]: ResidualPayoutWithNumbers};
    lineItems: LineItem[];
  };
};

export type Residual = DocumentBase & {
  columns: {[_: string]: string};
  totals: {[_: string]: number};
  payouts?: Payouts;
  yearMonth: string;
  fileUrl: string;
  status: ResidualStatus;
  errors?: string[];
  createdAt: UniversalTimestamp;
  updatedAt: UniversalTimestamp;
};

export type ResidualView = ViewBase<Residual>;

export const selectResidualView: ViewSelector<ResidualView, Residual> = snapshot => {
  const residual: ResidualView = {
    ...defaultViewSelector(snapshot),
  };
  return residual;
};
