export const GroupsIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <g>
          <path
            d="M96,224c35.3,0,64-28.7,64-64s-28.7-64-64-64s-64,28.7-64,64C32,195.3,60.7,224,96,224z M576,256h-64
			c-16.9,0-33.1,6.7-45.1,18.6c41.1,22.4,69,63,75.1,109.4h66c17.7,0,32-14.3,32-32v-32C640,284.7,611.3,256,576,256z M64,256
			c-35.3,0-64,28.7-64,64v32c0,17.7,14.3,32,32,32h65.9c6.2-46.4,34.1-87,75.2-109.4c-12-11.9-28.2-18.6-45.1-18.6H64z M544,224
			c35.3,0,64-28.7,64-64s-28.7-64-64-64s-64,28.7-64,64C480,195.3,508.7,224,544,224z"
            opacity={props.fill || 0.4}
          />
        </g>
        <g>
          <path
            d="M291.4,394.9L306,333l-28-49h84l-28,49l14.6,61.9L320,452L291.4,394.9z M320,256c61.9,0,112-50.1,112-112
			S381.9,32,320,32S208,82.1,208,144S258.1,256,320,256z"
            opacity={props.fill || 0.4}
          />
          <path
            d="M403.8,284.5L320,452l-83.8-167.5c-62.4,3-112.2,54-112.2,117.1V438c0,23.2,18.8,42,42,42l0,0h308
			c23.2,0,42-18.8,42-42l0,0v-36.4C516,338.5,466.2,287.5,403.8,284.5L403.8,284.5z"
          />
        </g>
      </g>
    </svg>
  );
};
