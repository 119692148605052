import {Collections, UploadDocument} from '@ozark/common';
import {Attachments, AttachmentsRef} from '@ozark/common/components';
import {useRef, useState} from 'react';
import {useStore} from '../../store/helpers';

type Props = {
  agentId: string;
};

export const AgentAttachments = ({agentId}: Props) => {
  const {authUser, isUserAdmin} = useStore();
  const attachmentsRef = useRef<AttachmentsRef | null>(null);
  const [pendingDocument, setPendingDocument] = useState<UploadDocument | null>(null);
  const defaultNames = ['Boarded', 'Ongoing'];
  const handleAttachClick = () => {
    attachmentsRef?.current?.onUploadButtonClick();
  };

  return (
    <Attachments
      hardDelete
      allowDelete={isUserAdmin()}
      attachmentsRef={attachmentsRef}
      entityId={agentId}
      collection={Collections.agents}
      handleAttachClick={handleAttachClick}
      pendingDocument={pendingDocument}
      setPendingDocument={setPendingDocument}
      userId={authUser.data?.uid}
      defaultFolderNames={defaultNames}
    />
  );
};
