import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Box,
  Divider,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {ApplicationSubCollection, Dispositions, useCallable} from '@ozark/common';
import {ApplicationTickets, Loading, Title} from '@ozark/common/components';
import {ApplicationEdit, PricingEquipment} from '@ozark/common/components/Application';
import {useApplicationView} from '@ozark/common/hooks/useApplicationView';
import React, {ReactNode, useEffect, useState} from 'react';
import {matchPath, Redirect, useHistory, useParams, useRouteMatch} from 'react-router';
import {generatePath} from 'react-router-dom';
import {RISK_OVERVIEW_APPLICATION_DETAILS} from '../../constants/routes';
import {useStore} from '../../store/helpers';
import {Tab, Tabs} from '../Tabs';
import {ApplicationAttachments} from './Attachments';
import {FeeModifiers} from './FeeModifiers';
import {Json} from './Json';
import {ApplicationNotes} from './Notes/Notes';
import {Overview} from './Overview';
import {RiskDetails} from './RiskDetails';
import {Scorecard} from './Scorecards';
import {ScoreRunAllState} from './Scorecards/accordions/hooks/useRunScores';

enum ApplicationTabs {
  Overview,
  RiskDetails,
  AppPages,
  Pricing,
  ScoreCard,
  Attachments,
  UWRiskNotes,
  SupportNotes,
  Fee,
  Json,
  Tickets,
}

const TabDivider = (): JSX.Element => <Divider sx={{mx: 0, my: 1}} />;

const Application = () => {
  const {authUser, isAllowedToEditApplication} = useStore();
  const history = useHistory<{referrer: string}>();
  const match = useRouteMatch();
  const {tab: tabFromUrl} = useParams<{tab?: string}>();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('lg'));
  const {id} = useParams<{id: string}>();
  const {application} = useApplicationView(id);
  const isRiskPage = matchPath(history.location.pathname, RISK_OVERVIEW_APPLICATION_DETAILS);
  const [tab, setTab] = useState(
    isRiskPage ? ApplicationTabs.RiskDetails : ApplicationTabs.Overview
  );
  const [token, setToken] = useState<string | null>();
  const {getPortalToken} = useCallable();

  const [readonly, setReadonly] = useState<boolean>(true);

  let scoreCardTabLabel: ReactNode = 'Score Card';
  const scoreCardTabSx: any = {};
  if (!!application.data?.duplicates?.length) {
    scoreCardTabLabel = (
      <>
        Score card
        <Tooltip title={`Duplicate(s) found`} sx={{position: 'absolute', right: 0}}>
          <WarningIcon color="warning" />
        </Tooltip>
      </>
    );
    scoreCardTabSx['pr'] = '30px';
  }

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    setTab(newTab);

    if (tabFromUrl) {
      // TODO: Remove it when tabs will be connected to router in TG-1393
      // remove tab from url after tab change
      history.replace(generatePath(match.path, {id}));
    }
  };

  useEffect(() => {
    if (!application.data) {
      return;
    }

    getPortalToken({applicationId: application.data!.id}).then(result => {
      if (!result || result.status !== 'ok' || !result.token) return;
      setToken(result.token);
    });

    const isReadonly = !isAllowedToEditApplication(application.data);
    setReadonly(isReadonly);

    // eslint-disable-next-line
  }, [application]);

  useEffect(() => {
    if (!application.data || !tabFromUrl) return;
    tabsMap.has(tabFromUrl) && setTab(tabsMap.get(tabFromUrl) as number);
  }, [application, tabFromUrl]);

  if (application.promised) return <Loading />;

  if (!application.data) return <Redirect to="/" />;

  return (
    <div>
      <Title
        noMargins
        breadcrumbs={
          smDown
            ? [
                <IconButton onClick={() => history.goBack()} size="large">
                  <ChevronLeftIcon />
                </IconButton>,
              ]
            : [
                <Link
                  component="button"
                  variant="body1"
                  onClick={() =>
                    history.location?.state?.referrer
                      ? history.push(
                          `../${history.location?.state?.referrer.toLowerCase().replace(' ', '-')}`
                        )
                      : history.goBack()
                  }
                >
                  {history.location?.state?.referrer || 'Applications'}
                </Link>,
                <Typography variant="body1">{application.data.doingBusinessAs}</Typography>,
              ]
        }
      />
      <Box
        display="flex"
        sx={{
          mt: 0,
          mx: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Tabs
          value={tab}
          onChange={handleTabChange}
          variant="scrollable"
          sx={{[theme.breakpoints.down('xl')]: {maxWidth: 1000}}}
        >
          {isRiskPage && <Tab label="Risk Details" value={ApplicationTabs.RiskDetails} />}

          <Tab label="Overview" value={ApplicationTabs.Overview} />

          <TabDivider />

          <Tab label="App Pages" value={ApplicationTabs.AppPages} />

          <TabDivider />

          <Tab label="Pricing & Equipment" value={ApplicationTabs.Pricing} />

          <TabDivider />

          <Tab label={scoreCardTabLabel} value={ApplicationTabs.ScoreCard} sx={scoreCardTabSx} />

          <TabDivider />

          <Tab label="Attachments" value={ApplicationTabs.Attachments} />

          <TabDivider />

          <Tab label="UW & Risk Notes" value={ApplicationTabs.UWRiskNotes} />

          {application.data.disposition === Dispositions.boarded && (
            <Tab label="Support Notes" value={ApplicationTabs.SupportNotes} />
          )}

          <TabDivider />

          <Tab label="Fee Modifiers" value={ApplicationTabs.Fee} />

          <TabDivider />

          <Tab label="JSON" value={ApplicationTabs.Json} />

          {/* ApplicationTickets should be available for boarded application; only ERP users can see them */}
          {application.data?.disposition === Dispositions.boarded && [
            <TabDivider key="divider-before-tickets-tab" />,
            <Tab key="tickets-tab" label="Tickets" value={ApplicationTabs.Tickets} />,
          ]}
        </Tabs>
      </Box>
      <Divider sx={{mb: 2}} />

      {tab === ApplicationTabs.Overview && <Overview application={application.data} key={id} />}

      {tab === ApplicationTabs.RiskDetails && (
        <RiskDetails application={application.data} key={id} />
      )}

      {tab === ApplicationTabs.AppPages && (
        <ApplicationEdit application={application.data} token={token} />
      )}

      {tab === ApplicationTabs.Pricing && (
        <PricingEquipment application={application.data} authUser={authUser} readonly={readonly} />
      )}

      {tab === ApplicationTabs.ScoreCard && (
        <ScoreRunAllState.Provider>
          <Scorecard application={application.data} />
        </ScoreRunAllState.Provider>
      )}

      {tab === ApplicationTabs.Attachments && (
        <ApplicationAttachments application={application.data} />
      )}

      {tab === ApplicationTabs.UWRiskNotes && (
        <ApplicationNotes
          applicationId={id}
          notesSubCollection={ApplicationSubCollection.uwRiskNotes}
        />
      )}

      {tab === ApplicationTabs.SupportNotes && (
        <ApplicationNotes
          applicationId={id}
          notesSubCollection={ApplicationSubCollection.supportNotes}
        />
      )}

      {tab === ApplicationTabs.Fee && <FeeModifiers application={application.data} />}

      {tab === ApplicationTabs.Json && <Json application={application.data} />}

      {tab === ApplicationTabs.Tickets && <ApplicationTickets application={application.data} />}
    </div>
  );
};

export default Application;

const tabsMap = new Map([
  ['notes', ApplicationTabs.UWRiskNotes],
  ['supportNotes', ApplicationTabs.SupportNotes],
  ['attachments', ApplicationTabs.Attachments],
  ['riskDetails', ApplicationTabs.RiskDetails],
]);
