import {Box, Divider} from '@mui/material';
import {
  FraudSameBinRecord,
  FraudSameBinReport,
} from '@ozark/functions/src/functions/express/private/types';
import {add} from 'date-fns';
import {omit} from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {generatePath, Link, useHistory} from 'react-router-dom';
import {
  ButtonExportCsv,
  Filter,
  FilterOption,
  FiltersAddButton,
  FiltersApplied,
  forceActiveFilter,
  MidSelect,
  Table,
} from '../..';
import {
  AllMIDs,
  formatAsPercent,
  formatterNumber,
  SearchCriteria,
  useApiContainer,
  useMidsContainer,
} from '../../..';
import {Column} from '../../../api/Column';
import {LoadingStatus} from '../../Analytics/common/LoadingStatus';
import {useBreadcrumbsContainer} from '../../common/Breadcrumbs';
import {accountStatusFilter} from '../../Filters/common';
import {FRAUD_SAME_BIN_DETAILS, FRAUD_SAME_BIN_TRANSACTIONS} from '../FraudAnalysis/routes';

const getDefaultFilter = () => {
  const dateCurrent = new Date();
  const dateStarting = new Date(dateCurrent.getFullYear(), dateCurrent.getMonth(), 1);
  const transactionDate = forceActiveFilter(filtersConfig, 'dateRange', '__between', [
    dateStarting,
    add(dateStarting, {months: 1}),
  ]);
  return transactionDate;
};

export const FraudAnalysisSameBinSummary = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const {mids, handleSelectMid: setSelectedMid, selectedMid} = useMidsContainer();
  const [filters, setFilters] = useState<Filter>({
    transactionDate: getDefaultFilter(),
    ...getMidFilter(selectedMid),
  });
  const [pageConfig, setPageConfig] = useState<SearchCriteria>(pageConfigDefault);
  const [report, setReport] = useState<FraudSameBinReport | null>(null);
  const api = useApiContainer();
  const hasData = Boolean(report?.data.length);
  const {setBreadcrumbs} = useBreadcrumbsContainer();

  const handleSelectMid = useCallback(
    (mid: string) => {
      setSelectedMid(mid);
      if (mid === AllMIDs) {
        setFilters(prev => omit(prev, 'mid'));
      } else {
        setFilters(prev => ({
          ...prev,
          ...getMidFilter(mid),
        }));
      }
    },
    [setSelectedMid]
  );

  const getAllDataForExport = useCallback(async () => {
    if (!api) return [];
    const pageConfigFull: SearchCriteria = {...pageConfig, offset: 0, limit: 0};
    const result = await api.fraud.getFraudSameBinReport(pageConfigFull, []);
    return result?.data ?? [];
  }, [pageConfig]);

  useEffect(() => {
    setBreadcrumbs([
      {text: 'Fraud Analysis', url: '/fraud-analysis'},
      {text: 'Same BIN Report', url: '/fraud-analysis/same-bin'},
    ]);
  }, []);

  useEffect(() => {
    if (!api) return;
    setLoading(true);
    let isMounted: boolean | undefined = true;
    api.fraud
      .getFraudSameBinReport(pageConfig, Object.values(filters))
      .then(result => isMounted && setReport(result))
      .catch(err => {
        console.error(err);
        isMounted && setReport(null);
      })
      .finally(() => isMounted && setLoading(false));
    return () => (isMounted = undefined);
  }, [pageConfig, filters]);

  return (
    <Box mt={2}>
      <Box display="flex" alignItems="center" justifyContent="flex-end" pb={2}>
        <FiltersApplied filters={filters} setFilters={setFilters} />
        <Box flex={1} />
        <FiltersAddButton filters={filters} setFilters={setFilters} filtersConfig={filtersConfig} />
        <Divider orientation="vertical" flexItem sx={{mx: 2}} />
        <MidSelect mids={mids} handleSelectMid={handleSelectMid} selectedMid={selectedMid} />
        <Divider orientation="vertical" flexItem sx={{mx: 2}} />
        <ButtonExportCsv
          filename="fraud-same-bin-report"
          rows={report?.data}
          getRows={getAllDataForExport}
          columnsConfig={columnsConfig}
          useSelectorMapping
        />
      </Box>
      <LoadingStatus loading={loading} hasData={hasData} />

      {!loading && hasData && (
        <Table
          columns={columnsConfig}
          data={{
            sort: [[pageConfig.orderBy, pageConfig.order as 'DESC' | 'ASC']],
            limit: pageConfig.limit,
            offset: pageConfig.offset,
            totalCount: report?.totalCount ?? 0,
            data: report?.data ?? [],
          }}
          onRetrieveData={setPageConfig}
          onRowClick={() => {}}
          paginate
          useOffsetAsPage
        />
      )}
    </Box>
  );
};

const pageConfigDefault: SearchCriteria = {
  limit: 20, // pageSize
  offset: 1, // page (offset = page * pageSize 1 based) 0 will produce negative offset
  order: 'desc',
  orderBy: 'transactionsCountTotal',
};
const filtersConfig: FilterOption[] = [
  {
    id: 'date',
    column: 'transactionDate',
    label: 'Date',
    type: 'date',
    operators: [
      {
        id: '__between',
        label: 'equals',
      },
    ],
  },
  {
    id: 'dateRange',
    column: 'transactionDate',
    autoSelect: true,
    label: 'Date Range',
    type: 'dateRange',
    dateFormat: 'MMM dd, yyyy',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'mid',
    column: 'mid',
    label: 'Merchant MID',
    type: 'text',
    hidden: true,
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  accountStatusFilter,
];

const columnsConfig: Column<FraudSameBinRecord>[] = [
  {
    id: 'mid',
    label: 'MID',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'dba',
    label: 'DBA',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'legalName',
    label: 'Legal Name',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'agent',
    label: 'Agent',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'binsDistinctCount',
    numeric: true,
    sortable: true,
    export: row => {
      const distinctBins = Number(row.binsDistinctCount);
      return distinctBins ? formatterNumber.format(distinctBins) : '-';
    },
    label: 'Number of Distinct BINs',
    selector: (row, _id) => {
      const distinctBins = Number(row.binsDistinctCount);
      const value = distinctBins ? formatterNumber.format(distinctBins) : '-';

      const route = generatePath(FRAUD_SAME_BIN_DETAILS, {mid: row.mid});
      return (
        <Link to={route} onClick={e => e.stopPropagation()}>
          {value}
        </Link>
      );
    },
  },
  {
    id: 'transactionsCountTotal',
    label: 'Total Number of Transactions',
    numeric: true,
    sortable: true,
    export: row => formatterNumber.format(Number(row.transactionsCountTotal)),
    selector: (row, _id) => {
      const value = formatterNumber.format(Number(row.transactionsCountTotal));

      const route = generatePath(FRAUD_SAME_BIN_TRANSACTIONS, {mid: row.mid});
      return (
        <Link to={route} onClick={e => e.stopPropagation()}>
          {value}
        </Link>
      );
    },
  },
  {
    id: 'binToTransactionsAverage',
    numeric: true,
    sortable: true,
    export: true,
    label: 'Average Transactions / BIN',
    selector: row => {
      const ratio =
        row.transactionsCountTotal &&
        Number(row.binsDistinctCount) / Number(row.transactionsCountTotal);
      return ratio ? formatAsPercent(Number(ratio)) : 'n/a';
    },
  },
  {
    id: 'binsInternationalRatio',
    numeric: true,
    sortable: true,
    export: true,
    label: '% of International BINs',
    selector: row => {
      const ratio =
        row.binsInternational && Number(row.binsInternational) / Number(row.transactionsCountTotal);
      return ratio ? formatAsPercent(Number(ratio)) : 'n/a';
    },
  },
  {
    id: 'binsProhibitedRatio',
    numeric: true,
    sortable: true,
    export: true,
    label: '% of Prohibited BINs',
    selector: row => {
      const ratio =
        row.binsProhibited && Number(row.binsProhibited) / Number(row.transactionsCountTotal);
      return ratio ? formatAsPercent(Number(ratio)) : 'n/a';
    },
  },
];

const getMidFilter = (mid: string): Filter => {
  if (!mid || mid === AllMIDs) return {};
  const filterOption = filtersConfig.find(fo => fo.id === 'mid');
  if (!filterOption) throw new Error('filter option not found');
  return {
    mid: {
      option: filterOption,
      operator: filterOption.operators[0],
      value: mid,
    },
  };
};
