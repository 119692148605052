import LinearScaleIcon from '@mui/icons-material/LinearScale';
import UpdateIcon from '@mui/icons-material/Update';
import {Divider, Paper, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ApplicationView, Dispositions, getColor, getGroup} from '@ozark/common';
import {format, formatDistanceToNow} from 'date-fns';
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {Tab, Tabs} from '../../Tabs';
import {BoardingOverwrites} from './BoardingOverwrites';
import {BoardingTaskList} from './BoardingTaskList';
import {ChangeLogList} from './ChangeLogList';
import {DispositionHistoryList} from './DispositionHistoryList';
import {PostBoardingTaskList} from './PostBoardingTaskList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
      color: '#4d6575',
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      margin: theme.spacing(0, 0, 2),
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexGrow: 1,
      flexBasis: '50%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    columnHeader: {
      margin: theme.spacing(0.5, 0, 1),
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    tabs: {
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    tabContent: {
      maxHeight: 600,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflow: 'scroll',
    },
    divider: {
      margin: theme.spacing(0, 2),
    },
  })
);

type ActivityProps = {
  application: ApplicationView;
};

enum ActivityTab {
  Dispositions,
  AuditLog,
  Boarding,
  PostBoarding,
  Overwrites,
}

export const Activity = ({application}: ActivityProps) => {
  const classes = useStyles();

  const {hash} = useLocation();

  const activeTabKey = !!hash && (hash.replace(/#/g, '') as keyof typeof ActivityTab);
  const activityContentRef = useRef<HTMLDivElement | null>(null);

  const [tab, setTab] = useState(ActivityTab.Dispositions);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: ActivityTab) => {
    setTab(newTab);
  };

  useEffect(() => {
    if (!!activeTabKey && typeof ActivityTab[activeTabKey] !== 'undefined') {
      setTab(ActivityTab[activeTabKey]);
      activityContentRef.current?.scrollIntoView();
    }
  }, [activeTabKey]);

  return (
    <Paper className={classes.paper}>
      <div className={classes.title}>
        <div className={classes.column}>
          <LinearScaleIcon
            style={{fill: getColor(application.disposition || Dispositions.incomplete)}}
          />
          <Typography className={classes.columnHeader} variant="body1" component="div" noWrap>
            Current Status
          </Typography>

          <Typography variant="h6" noWrap gutterBottom>
            {application.disposition}
          </Typography>
          <Typography variant="body2" component="p" noWrap>
            {getGroup(application.disposition)}
          </Typography>
        </div>
        <Divider className={classes.divider} orientation="vertical" flexItem />
        <div className={classes.column}>
          <UpdateIcon />
          <Typography className={classes.columnHeader} variant="body1" component="div" noWrap>
            Last Updated
          </Typography>

          {application.dispositionUpdatedAt ? (
            <Fragment>
              <Typography variant="h6" noWrap gutterBottom>
                {format(application.dispositionUpdatedAt.toDate(), 'MM/dd/yyyy h:mm a')}
              </Typography>
              <Typography variant="body2" component="p" noWrap>
                {formatDistanceToNow(application.dispositionUpdatedAt.toDate())} ago
              </Typography>
            </Fragment>
          ) : (
            <Typography variant="body2">-</Typography>
          )}
        </div>
      </div>
      <div className={classes.tabs}>
        <Tabs value={tab} onChange={handleTabChange} centered>
          <Tab label="Dispositions" value={ActivityTab.Dispositions} />
          <Tab label="Audit Log" value={ActivityTab.AuditLog} />
          <Tab label="Boarding" value={ActivityTab.Boarding} />
          <Tab label="Post Boarding" value={ActivityTab.PostBoarding} />
          <Tab label="Overwrites" value={ActivityTab.Overwrites} />
        </Tabs>
      </div>
      <div className={classes.tabContent} ref={activityContentRef}>
        {tab === ActivityTab.Dispositions && <DispositionHistoryList application={application} />}
        {tab === ActivityTab.AuditLog && <ChangeLogList application={application} />}
        {tab === ActivityTab.Boarding && <BoardingTaskList application={application} />}
        {tab === ActivityTab.PostBoarding && <PostBoardingTaskList application={application} />}
        {tab === ActivityTab.Overwrites && <BoardingOverwrites application={application} />}
      </div>
      <Divider />
    </Paper>
  );
};
