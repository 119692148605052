import {Department, SearchableProfileView} from '@ozark/common';
import {UserFilter} from '@ozark/common/components/UserFilter';
import {useDepartmentUsers} from '../../hooks/useDepartmentUsers';

export type RiskAssigneeFilterProps = {
  value: SearchableProfileView | null;
  onChange: (value: SearchableProfileView | null) => void;
};

export const RiskAssigneeFilter = ({value, onChange}: RiskAssigneeFilterProps) => {
  const riskDepartmentUsers = useDepartmentUsers(Department.risk);

  return (
    <UserFilter
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      options={riskDepartmentUsers}
      sx={{minWidth: 200, width: '100%'}}
      textFieldProps={{
        label: 'Filter by Risk Assignee',
      }}
    />
  );
};
