import {Button} from '@mui/material';
import {RiskOverviewItemProps} from '../common';

type Props = Pick<RiskOverviewItemProps, 'data' | 'onAssociatedApplicationsOpen'>;

export const AssociatedMidsButton = ({
  data,
  onAssociatedApplicationsOpen,
}: Props): JSX.Element | null => {
  if (!data.associatedApplications?.length) {
    return null;
  }

  return (
    <Button
      variant="outlined"
      onClick={() => onAssociatedApplicationsOpen?.(data.id)}
      sx={{mt: 1, p: {xl: 0.5}}}
    >
      Associated MIDs
    </Button>
  );
};
