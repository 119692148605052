import {SearchableProfileView} from '@ozark/common';

export const getAssigneeText = (
  profilesDictionary: {[p: string]: SearchableProfileView},
  assigneeId: string | undefined
) => {
  const profile = assigneeId ? profilesDictionary[assigneeId] : null;
  if (profile) {
    return `${profile.firstName} ${profile.lastName}`;
  }
  return '';
};
