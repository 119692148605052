import SearchIcon from '@mui/icons-material/Search';
import {Typography} from '@mui/material';
import {SearchableProfileView, useApiContainer} from '@ozark/common';
import {AutoCompleteInputBase, Title} from '@ozark/common/components';
import {LeadsClosingRatios} from '@ozark/functions/src/functions/express/private/types';
import {useCallback, useEffect, useState} from 'react';
import {useActiveProfiles} from '../../../hooks/useActiveProfiles';
import {useStore} from '../../../store/helpers';
import {RatioTiles} from '../components/RatioTiles';

export const SalesRepRatio = () => {
  const [assignees, setAssignees] = useState<SearchableProfileView[]>([]);
  const {profiles} = useStore();
  const activeProfiles = useActiveProfiles(profiles);
  const [selected, setSelected] = useState<SearchableProfileView>();
  const [ratios, setRatios] = useState<LeadsClosingRatios | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const api = useApiContainer();

  const getRatios = useCallback(() => {
    setIsLoading(true);
    api?.leads
      .getSalesRepRatios(selected?.id)
      .then(setRatios)
      .finally(() => setIsLoading(false));
  }, [api?.leads, selected]);

  useEffect(() => {
    if (!activeProfiles.data) {
      return;
    }

    setAssignees(activeProfiles.data);
  }, [activeProfiles.data]);

  useEffect(() => {
    getRatios();
  }, [getRatios]);

  return (
    <>
      <Title breadcrumbs={[<Typography>Sales Rep</Typography>]}>
        <AutoCompleteInputBase
          selected={selected}
          setSelected={setSelected}
          icon={SearchIcon}
          placeholder="Select sales rep..."
          options={assignees}
          getOptionLabel={(profile: SearchableProfileView) =>
            `${profile.firstName || ''} ${profile.lastName || ''}`.trim()
          }
          onItemSelect={(item: SearchableProfileView | null) => setSelected(item ?? undefined)}
        />
      </Title>
      <RatioTiles isLoading={isLoading} ratios={ratios} />
    </>
  );
};
