import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {Box, Chip, Grow} from '@mui/material';
import {isBefore} from 'date-fns';
import {useHistory} from 'react-router-dom';
import {CalendarEvent} from '../../..';

type Props = {
  event: CalendarEvent;
};

export const DateEvent = ({event}: Props) => {
  if (!event.dateTime) throw new Error('dateTime is null');

  const eventHour = event.dateTime.getHours();
  const eventMinutes = event.dateTime.getMinutes();
  const top = eventHour * 60 + eventMinutes;
  const eventHeight = event.durationInMinutes ?? 30;

  return (
    <Box
      id={event.id}
      position="absolute"
      top={top}
      left={0}
      px={0.5}
      height={eventHeight ?? 30}
      width="100%"
    >
      <DateEventCard event={event} eventHeight={eventHeight} />
    </Box>
  );
};

function DateEventCard({event, eventHeight}: Props & {eventHeight?: number}) {
  const history = useHistory();
  return (
    <Grow in>
      <Chip
        onClick={() => history.push(`/calendar/id/${event.id}`)}
        sx={{
          borderRadius: 1,
          width: '100%',
          justifyContent: 'flex-start',
          pl: 0.3,
          height: eventHeight,
        }}
        color={event.dateTime && isBefore(event.dateTime, new Date()) ? 'default' : 'primary'}
        label={event?.subject}
        icon={<NotificationsActiveIcon fontSize="small" sx={{width: '0.8em', height: '0.8em'}} />}
      />
    </Grow>
  );
}
