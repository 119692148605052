import {yupResolver} from '@hookform/resolvers/yup';
import {LoadingButton} from '@mui/lab';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useCallable, useNotification} from '@ozark/common';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {SalesLeadForm, SalesLeadFormData, SalesLeadSchema} from '../components/SalesLeadForm';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const CreateSalesLeadDialog = ({open, onClose}: Props) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const {createLead} = useCallable();
  const showNotification = useNotification();

  const hookForm = useForm<SalesLeadFormData>({
    resolver: yupResolver(SalesLeadSchema),
    shouldUnregister: true,
  });
  const {handleSubmit} = hookForm;

  const onSuccess = async (data: SalesLeadFormData) => {
    try {
      setLoading(true);

      await createLead({lead: data});
      showNotification('success', 'Lead successfully created.');
      setLoading(false);
      onClose();
    } catch (err) {
      showNotification('error', 'Failed to create new sales lead');
      console.error('Failed to create new sales lead with an error:', err);
      setLoading(false);
    }
  };

  const onError = (_data: any) => {
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="Create sales lead"
      fullScreen={fullScreen}
      maxWidth={'md'}
    >
      <DialogTitle id="create-dialog-title">Create Sales Lead</DialogTitle>
      <DialogContent>
        <Box sx={{width: {md: 600}}}>
          <Grid container spacing={1}>
            <SalesLeadForm hookForm={hookForm} />
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton onClick={handleSubmit(onSuccess, onError)} color="primary" loading={loading}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
