export const ImposterIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      width="36px"
      height="24px"
      style={{marginBottom: -6}}
      {...props}
    >
      <path
        fill="#4d6575"
        d="m497 245.914062c-8.285156 0-15 6.71875-15 15v12.992188c0 7.347656-5.992188 13.324219-13.359375 13.324219-8.570313 0-16.914063-3.53125-22.902344-9.699219-10.234375-10.523438-23.390625-24.003906-35.988281-36.96875-22.214844-22.851562-53.222656-35.960938-85.144531-35.960938-1.066407 0-42.402344-1.660156-68.605469 35.832032-26.226562-37.527344-67.632812-35.832032-68.6875-35.832032-31.84375 0-62.847656 13.109376-85.0625 35.964844-12.613281 12.980469-25.578125 26.261719-35.996094 36.972656-5.976562 6.160157-14.324218 9.691407-22.894531 9.691407-7.367187 0-13.359375-5.976563-13.359375-13.324219v-12.988281c0-8.285157-6.714844-15-15-15-8.28125 0-15 6.714843-15 15v22.949219c0 52.566406 42.769531 95.335937 95.335938 95.335937h88.363281c31.117187 0 57.242187-16.246094 72.296875-38.375 15.15625 22.277344 41.414062 38.375 72.304687 38.375h88.367188c52.566406 0 95.332031-42.769531 95.332031-95.335937v-22.949219c0-8.285157-6.714844-15.003907-15-15.003907zm0 0"
      />
      <path
        fill="#4d6575"
        d="m15 94.265625h17.132812v17.136719c0 35.351562 28.773438 63.199218 63.199219 63.199218h64.269531c34.441407 0 63.199219-27.859374 63.199219-63.199218v-9.382813c9.359375-7.457031 21.164063-11.65625 33.199219-11.65625s23.839844 4.199219 33.199219 11.65625v9.382813c0 35.351562 28.773437 63.199218 63.199219 63.199218h64.269531c34.441406 0 63.199219-27.859374 63.199219-63.199218v-17.136719h17.132812c8.285156 0 15-6.714844 15-15 0-8.28125-6.714844-15-15-15h-17.132812c0-38.0625-30.039063-64.265625-63.199219-64.265625h-64.269531c-34.441407 0-63.199219 27.859375-63.199219 63.199219v4.152343c-10.417969-4.535156-21.738281-6.988281-33.199219-6.988281s-22.78125 2.453125-33.199219 6.988281v-4.152343c0-35.351563-28.773437-63.199219-63.199219-63.199219h-64.269531c-33.121093 0-63.199219 26.160156-63.199219 64.265625h-17.132812c-8.285156 0-15 6.71875-15 15 0 8.285156 6.714844 15 15 15zm304.199219-31.066406c0-18.566407 15.132812-33.199219 33.199219-33.199219h64.269531c18.003906 0 33.199219 14.566406 33.199219 33.199219v48.203125c0 18.570312-15.136719 33.199218-33.199219 33.199218h-64.269531c-18.007813 0-33.199219-14.574218-33.199219-33.199218zm-257.066407 0c0-18.566407 15.136719-33.199219 33.199219-33.199219h64.269531c18.007813 0 33.199219 14.574219 33.199219 33.199219v48.203125c0 18.566406-15.132812 33.199218-33.199219 33.199218h-64.269531c-18.003906 0-33.199219-14.566406-33.199219-33.199218zm0 0"
      />
    </svg>
  );
};
