import {Autocomplete, TextField} from '@mui/material';
import {TextFieldProps} from '@mui/material/TextField/TextField';
import {Dispositions} from '@ozark/common';
import {useGlobalSearch} from '@ozark/common/hooks';
import {useRef, useState} from 'react';

export type ApplicationAutocompleteItem = {
  id: string;
  distinguishableId: string;
  name: string;
  mid?: string;
  legalBusinessName: string;
};

type Props = {
  selected?: ApplicationAutocompleteItem | null;
  onChange: (value: ApplicationAutocompleteItem | null) => void;
  textFieldProps?: TextFieldProps;
  allowEmptyOption?: boolean;
  dispositions?: Dispositions[];
  getOptionLabel?: (application: ApplicationAutocompleteItem) => string;
};

export const getSearchResultsAsync = (
  searchFunction: ReturnType<typeof useGlobalSearch>['search_DEPRECATED'],
  inputValue: string
): Promise<ApplicationAutocompleteItem[]> => {
  return new Promise((resolve, reject) => {
    try {
      searchFunction(
        {input: inputValue},
        (
          results?: {
            id: string;
            distinguishableId: string;
            doingBusinessAs: string;
            mid?: string;
            legalBusinessName: string;
          }[]
        ) => {
          let newOptions = [] as ApplicationAutocompleteItem[];
          if (results) {
            newOptions = results.map(a => ({
              id: a.id,
              distinguishableId: a.distinguishableId,
              name: a.doingBusinessAs,
              mid: a.mid,
              legalBusinessName: a.legalBusinessName,
            }));
          }
          resolve(newOptions);
        }
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const ApplicationAutocomplete = ({
  selected,
  onChange,
  textFieldProps = {},
  dispositions,
  getOptionLabel,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [applications, setApplications] = useState<ApplicationAutocompleteItem[]>(
    selected ? [selected] : []
  );
  const [inputValue, setInputValue] = useState('');
  const search = useGlobalSearch({
    applicationSearchOptions: {
      attributesToRetrieve: [
        'id',
        'distinguishableId',
        'doingBusinessAs',
        'legalBusinessName',
        'mid',
        'disposition',
      ],
      ...(dispositions
        ? {filters: dispositions.map(disposition => `disposition:"${disposition}"`).join(' OR ')}
        : {}),
    },
  }).search_DEPRECATED;
  const lastSearchQueryRef = useRef<string>('');

  const onInputValueChange = async (_: React.SyntheticEvent<Element, Event>, value: string) => {
    setInputValue(value);
    if (selected) {
      onChange(null);
    }

    if (value === '' || value.length < 3) {
      setApplications(selected ? [selected] : []);
      setLoading(false);
      return;
    }

    try {
      lastSearchQueryRef.current = value;
      setLoading(true);

      const options = await getSearchResultsAsync(search, value);

      if (lastSearchQueryRef.current !== value) {
        return;
      }
      setApplications(options);
    } catch (err) {
      console.error('Failed to get search results with an error:', err);
    } finally {
      setLoading(false);
    }
  };

  const autocompleteChangeHandler = async (
    _: React.SyntheticEvent<Element, Event>,
    value: ApplicationAutocompleteItem | null
  ) => {
    onChange(value);
  };

  return (
    <Autocomplete
      id="application-search"
      loading={loading}
      inputValue={inputValue}
      onInputChange={onInputValueChange}
      options={applications}
      value={selected}
      filterOptions={options => options}
      onChange={autocompleteChangeHandler}
      noOptionsText="no matches found"
      getOptionLabel={(item: ApplicationAutocompleteItem) =>
        `${
          getOptionLabel ? getOptionLabel(item) : `${item?.name ?? ''} (${item?.distinguishableId})`
        } ${item?.mid ?? ''}`
      }
      renderInput={params => (
        <TextField {...params} label="Application" variant="standard" {...textFieldProps} />
      )}
      clearOnBlur={false}
    />
  );
};
