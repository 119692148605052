import {yupResolver} from '@hookform/resolvers/yup';
import DeleteIcon from '@mui/icons-material/Delete';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import {Collections} from '@ozark/common';
import {Loading, Title} from '@ozark/common/components';
import {useAnnouncement, useAttachments, useUserInfo} from '@ozark/common/hooks';
import {useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useHistory, useParams} from 'react-router';
import {NavLink} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {AnnouncementForm, defaultValues, Schema} from './AnnouncementForm';
import {ConfirmDeleteAnnouncement} from './Dialogs/ConfirmDeleteAnnouncement';
import {SendOrUpdateAnnouncement} from './Dialogs/SendOrUpdateAnnouncement';
import {AnnouncementFormData, useAnnouncementActions} from './hooks';

export const AnnouncementDetails = (): JSX.Element => {
  const {announcementId} = useParams<{announcementId: string}>();
  const history = useHistory();
  const {isErpAdmin} = useUserInfo();
  const {document: announcement} = useAnnouncement(announcementId);
  const {attachments} = useAttachments(announcementId, Collections.announcements);

  const {
    sendTestAnnouncement,
    submitAnnouncementForm,
    updateAnnouncement,
    deleteAnnouncement,
    onDeleteAnnouncement,
    onCancelDelete,
    announcementToDelete,
    loading,
  } = useAnnouncementActions(attachments.data);
  const [isSendOrUpdateDialogVisible, setIsSendOrUpdateDialogVisible] = useState(false);
  const [formDataToSave, setFormDataToSave] = useState<AnnouncementFormData | null>(null);

  const hookForm = useForm<AnnouncementFormData>({
    resolver: yupResolver(Schema) as any,
    defaultValues,
    shouldUnregister: true,
  });

  const onSendAnnouncement: SubmitHandler<AnnouncementFormData> = data => {
    setIsSendOrUpdateDialogVisible(true);
    setFormDataToSave(data as AnnouncementFormData);
  };

  const onUpdateAnnouncement = () => {
    if (!formDataToSave) return;

    updateAnnouncement(announcementId, formDataToSave);
    setIsSendOrUpdateDialogVisible(false);
  };

  const onSendNewAnnouncement = () => {
    if (!formDataToSave) return;

    submitAnnouncementForm(formDataToSave);
    setIsSendOrUpdateDialogVisible(false);
  };

  const onCancelSubmit = () => {
    setFormDataToSave(null);
    setIsSendOrUpdateDialogVisible(false);
  };

  const onDelete = async () => {
    if (!announcementToDelete) return;

    await deleteAnnouncement(announcementToDelete);
    onCancelDelete();
    history.push(ROUTES.ANNOUNCEMENTS);
  };

  if (announcement.promised || attachments.promised) {
    return <Loading />;
  }

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Title
        breadcrumbs={[
          <NavLink to={ROUTES.ANNOUNCEMENTS}>Announcements</NavLink>,
          <Typography>Details</Typography>,
        ]}
      />
      {!isErpAdmin && (
        <Alert severity="info">
          You currently have <b>read only</b> access to this page.
        </Alert>
      )}
      <Paper sx={{p: 2, position: 'relative'}}>
        {isErpAdmin && (
          <Box sx={{position: 'absolute', right: '10px'}}>
            <IconButton
              onClick={hookForm.handleSubmit(data =>
                sendTestAnnouncement(data as AnnouncementFormData)
              )}
              size="small"
            >
              <Tooltip title="Send test announcement to your user">
                <SendAndArchiveIcon />
              </Tooltip>
            </IconButton>
            {announcement.data && (
              <IconButton onClick={() => onDeleteAnnouncement(announcement.data!)} size="small">
                <Tooltip title="Delete announcement">
                  <DeleteIcon />
                </Tooltip>
              </IconButton>
            )}
          </Box>
        )}
        {announcement.data && !attachments.promised && (
          <AnnouncementForm
            hookForm={hookForm}
            announcement={announcement.data}
            attachments={attachments.data}
            isReadOnly={!isErpAdmin}
          />
        )}
        {isErpAdmin && (
          <Box sx={{textAlign: 'right', pt: 1}}>
            <Button
              variant="outlined"
              color="primary"
              onClick={hookForm.handleSubmit(onSendAnnouncement)}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : <>Send Announcement</>}
            </Button>
          </Box>
        )}
      </Paper>
      <SendOrUpdateAnnouncement
        open={isSendOrUpdateDialogVisible}
        onUpdateAnnouncement={onUpdateAnnouncement}
        onSendNewAnnouncement={onSendNewAnnouncement}
        onCancel={onCancelSubmit}
      />
      <ConfirmDeleteAnnouncement
        open={Boolean(announcementToDelete)}
        onCancel={onCancelDelete}
        onDelete={onDelete}
      />
    </Box>
  );
};
