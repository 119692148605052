import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@mui/material';
import {Theme, useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  ApplicationDisputeAdditionalEmail,
  ApplicationDisputeReason,
  ApplicationView,
  Dispositions,
  dispositionsForSendingEmailToAgent,
  dispositionsForSendingEmailToMerchant,
  PendingReasonsRaw,
  ReasonInputType,
  useCallable,
  useDisputeEmails,
  useDisputeHistory,
} from '@ozark/common';
import {DisputeReason, getReasonsByDisposition} from '@ozark/common/components';
import {getAgentByUid} from '@ozark/common/hooks/useAgents';
import {useCallback, useMemo, useState} from 'react';
import RichTextEditor, {EditorValue} from 'react-rte';
import {useNotification} from '../../../hooks/useNotification';

const isRteValueEmpty = (html: string): boolean => {
  const div = document.createElement('div');
  div.innerHTML = html;

  return !!(div.textContent || div.innerText);
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        width: 600,
      },
      [theme.breakpoints.up('lg')]: {
        width: '100%',
      },
    },
    reasonInputText: {
      paddingTop: theme.spacing(1),
    },
    reasonInputCheckbox: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
    },
    expanded: {
      margin: '0 !important' as any,
    },
    rootAccordionDetails: {
      flexDirection: 'column',
      alignContent: 'flex-start',
      justifyContent: 'flex-start',
    },
    richTextEditorReason: {
      '& button[title="Align Left"]': {
        display: 'none',
      },
      '& button[title="Align Center"]': {
        display: 'none',
      },
      '& button[title="Align Right"]': {
        display: 'none',
      },
      '& button[title="Align Justify"]': {
        display: 'none',
      },
      '& button[title="Link"]': {
        display: 'none',
      },
      '& button[title="Remove Link"]': {
        display: 'none',
      },
      '& button[title="Image"]': {
        display: 'none',
      },
    },
  })
);

interface DisputeDialogProps {
  setDialogVisible: (args0: boolean) => void;
  application: ApplicationView;
  disposition: Dispositions;
  setDisposition: (args0: Dispositions) => void;
}

type disputeField = {
  key: string;
  value: null | string | string[] | boolean | EditorValue;
};

export const DisputeDialog = ({
  setDialogVisible,
  application,
  disposition,
  setDisposition,
}: DisputeDialogProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const {sendApplicationDispute} = useCallable();
  const [loading, setLoading] = useState(false);
  const showNotification = useNotification();
  const disputeReasons = getReasonsByDisposition(disposition).sort((a, b) =>
    a.title.toLowerCase().localeCompare(b.title.toLowerCase())
  );
  const applicationId = application.id;

  const {uploadButtonMarkup} = useDisputeEmails({
    application,
    disputeReasons: [], // we can use an empty array only for uploadButtonMarkup method
    pendingReasonSettings: PendingReasonsRaw,
  });
  const [textEditorText, setTextEditorText] = useState(
    disputeReasons.map(
      ({name}: {name: string}): disputeField => ({
        key: name,
        value: RichTextEditor.createEmptyValue(),
      })
    )
  );
  const [fields, setFields] = useState<disputeField[]>(
    disputeReasons.map(({name}: {name: string}): disputeField => ({key: name, value: null}))
  );
  const [expandedByKey, setExpandedByKey] = useState<boolean[]>([
    ...Array(disputeReasons.length).fill(false),
  ]);
  const {saveDisputeReasons} = useDisputeHistory();

  const cancelDialog = useCallback(() => {
    setDisposition(application.disposition);
    setDialogVisible(false);
  }, [application.disposition, setDialogVisible, setDisposition]);

  const getFieldValue = useCallback(
    (name: string) => fields.find(field => field.key === name)?.value,
    [fields]
  );

  const getTextEditorValue = useCallback(
    (name: string) => textEditorText.find(field => field.key === name)?.value,
    [textEditorText]
  );

  const getCheckboxListValue = useCallback(
    (name: string) => (fields.find(field => field.key === name)?.value as string[]) || [],
    [fields]
  );

  const setFieldByName = useCallback(
    (name: string, value: any) => {
      const _fields = fields.slice();
      const idx = _fields.findIndex(field => field.key === name);
      _fields.splice(idx, 1, {key: name, value});
      setFields(_fields);
    },
    [fields]
  );

  const getSelectedFields = useCallback(
    () =>
      fields.filter(
        (field, idx) =>
          expandedByKey[idx] ||
          (field.value && disputeReasons[idx].inputType === ReasonInputType.Checkbox)
      ),
    [expandedByKey, disputeReasons, fields]
  );

  const fieldsMarkup = useCallback(
    async (displaySelectValue: boolean): Promise<string[]> => {
      // list of selected fields
      const selectedFields = getSelectedFields();

      return selectedFields
        .map((field: disputeField) => {
          const disputeReason = disputeReasons.find(({name}) => name === field.key);
          const disputeReasonMarkup = `${disputeReason?.descriptionHtml}`;
          let fieldMarkup =
            typeof field.value === 'string' &&
            (displaySelectValue || disputeReason?.inputType !== ReasonInputType.Select)
              ? `<p class="dispute-text">${field.value}</p>`
              : Array.isArray(field.value) && field.value.every(v => typeof v === 'string')
              ? `<p class="dispute-text">${field.value.join(', ')}</p>`
              : '';

          return `<div class="dispute-reason">${disputeReasonMarkup}${fieldMarkup}</div>`;
        })
        .filter(field => field !== null) as string[];
    },
    [disputeReasons, getSelectedFields]
  );

  const handleNotification = useCallback(async () => {
    try {
      // prepare email for agent/group admin based on settings
      const _fields = await fieldsMarkup(false);
      const _fieldsForNote = await fieldsMarkup(true);

      const additionalEmails: ApplicationDisputeAdditionalEmail[] = [];

      // prepare the email for merchant if applicable
      const notifyMerchant =
        (application.dispositionNotifyMerchant ?? true) &&
        dispositionsForSendingEmailToMerchant.includes(disposition);
      if (notifyMerchant) {
        const merchantFields = await fieldsMarkup(false);
        const merchantUploadButton = await uploadButtonMarkup(
          fields as any,
          application.merchantUid ?? '',
          application.customerServiceEmail!,
          `${application.firstName ?? ''} ${application.lastName ?? ''}`.trim(),
          'merchant'
        );
        additionalEmails.push({
          fields: merchantFields,
          to: [application.customerServiceEmail!],
          content: {
            firstName: application.firstName,
            uploadButton: merchantUploadButton,
          },
          isAgent: false,
        });
      }

      // prepare the email for agent if applicable
      const notifyAgent =
        (application.dispositionNotifyAgent ?? true) &&
        dispositionsForSendingEmailToAgent.includes(disposition);
      if (notifyAgent && application.agent?.id) {
        const appAgent = await getAgentByUid(application.agent?.id);
        if (appAgent && appAgent.email) {
          const agentFields = await fieldsMarkup(false);
          const agentUploadButton = await uploadButtonMarkup(
            fields as any,
            appAgent.id,
            appAgent.email,
            `${appAgent.firstName ?? ''} ${appAgent.lastName ?? ''}`.trim(),
            'agent'
          );
          additionalEmails.push({
            fields: agentFields,
            to: [appAgent.email],
            content: {
              firstName: application.agent.firstName,
              uploadButton: agentUploadButton,
            },
            isAgent: true,
          });
        }
      }

      await sendApplicationDispute({
        applicationId,
        disposition,
        fields: _fields,
        fieldsForNote: _fieldsForNote,
        saveNotes: true,
        additionalEmails: additionalEmails,
      });
    } catch (err) {
      console.error('error sendApplicationDispute', err);
    }
  }, [
    application.merchantUid,
    application.agent?.firstName,
    application.agent?.id,
    application.customerServiceEmail,
    application.dispositionNotifyAgent,
    application.dispositionNotifyMerchant,
    application.firstName,
    application.lastName,
    applicationId,
    disposition,
    fields,
    fieldsMarkup,
    sendApplicationDispute,
    uploadButtonMarkup,
  ]);

  const handleSaveDisputeReasons = useCallback(async () => {
    try {
      const selectedFields = getSelectedFields();

      const disputableReasons = selectedFields.map((field: disputeField) => {
        return field as ApplicationDisputeReason;
      });
      await saveDisputeReasons(applicationId, disposition, disputableReasons);
    } catch (err) {
      console.error(`error saveDisputeReasons for ${application.distinguishableId}`, err);
    }
  }, [
    application.distinguishableId,
    applicationId,
    disposition,
    getSelectedFields,
    saveDisputeReasons,
  ]);

  const handleSubmit = useCallback(async () => {
    try {
      await handleSaveDisputeReasons();
      await handleNotification();
      setLoading(false);
      setDialogVisible(false);
      setDisposition(disposition);
      showNotification('success', 'Notification sent');
    } catch (err) {
      console.error('submit dispute reasons error', err);
      setLoading(false);
      cancelDialog();
      showNotification('error', 'Unable to send notification');
    }
  }, [
    cancelDialog,
    disposition,
    handleNotification,
    handleSaveDisputeReasons,
    setDialogVisible,
    setDisposition,
    showNotification,
  ]);

  const isSubmitDisabled = useMemo(() => {
    if (loading) {
      return true;
    }

    const selectedFields = getSelectedFields();

    if (!selectedFields.length) {
      return true;
    }

    const nonEmptyFields = selectedFields.filter(({key, value}) => {
      const reason = disputeReasons.find(x => x.name === key);

      if (!reason) {
        return false;
      }

      // extra check for reach text editor value
      // the issue is: if you click into editor, value will be <p><br></p> which is not empty actually,
      // but value like this doesn't make sense
      if (reason.inputType === ReasonInputType.RichText) {
        return isRteValueEmpty(value as string);
      }

      // otherwise all other selected fields are ok; we are checking them in getSelectedFields method
      return !!value;
    });

    return selectedFields.length > 0 && nonEmptyFields.length !== selectedFields.length;
  }, [disputeReasons, getSelectedFields, loading]);

  return (
    <Dialog
      open={true}
      onClose={cancelDialog}
      aria-labelledby="create-dialog-title"
      fullScreen={fullScreen}
      maxWidth={'md'}
      fullWidth
    >
      <DialogTitle id="create-dialog-title">Application Dispute: {disposition}</DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          <Typography variant="body1" gutterBottom>
            Please select reason(s) for disposition and include text for agent email notification
            where needed:
          </Typography>
          {disputeReasons.map(
            ({title, name, inputType, FieldInput, Description}: DisputeReason, id: number) => (
              <Accordion
                key={id}
                expanded={expandedByKey[id]}
                classes={{expanded: classes.expanded}}
              >
                <AccordionSummary aria-label="Expand" aria-controls={name} id={name}>
                  <FormControlLabel
                    aria-label="Acknowledge"
                    onClick={event => {
                      event.stopPropagation();
                      if (inputType !== 'checkbox') {
                        const _expandedByKey = expandedByKey.slice();
                        const idx = fields.findIndex(field => field.key === name);
                        _expandedByKey.splice(idx, 1, !expandedByKey[idx]);
                        setExpandedByKey(_expandedByKey);
                      } else {
                        setFieldByName(name, !Boolean(getFieldValue(name)));
                      }
                    }}
                    onFocus={event => event.stopPropagation()}
                    control={
                      inputType === 'checkbox' ? (
                        <FieldInput disabled={loading} value={getFieldValue(name)} />
                      ) : (
                        <Checkbox
                          disabled={loading}
                          checked={expandedByKey[fields.findIndex(field => field.key === name)]}
                          onChange={() => {
                            if (!expandedByKey[fields.findIndex(field => field.key === name)]) {
                              setFieldByName(name, null);
                            }
                          }}
                        />
                      )
                    }
                    label={title}
                  />
                </AccordionSummary>
                <AccordionDetails classes={{root: classes.rootAccordionDetails}}>
                  {inputType === 'checkbox' ? (
                    <Typography variant="h4" color="textSecondary">
                      {title}
                    </Typography>
                  ) : (
                    <>
                      {expandedByKey[id] === true && (
                        <Description variant="subtitle1" color="textSecondary" />
                      )}
                      {inputType === ReasonInputType.RichText ? (
                        <FieldInput
                          disabled={loading}
                          className={classes.richTextEditorReason}
                          editorStyle={{minHeight: '100px'}}
                          onChange={(nextValue: EditorValue) => {
                            const _textFields = textEditorText.slice();
                            const textIdx = textEditorText.findIndex(field => field.key === name);
                            _textFields.splice(textIdx, 1, {key: name, value: nextValue});
                            setTextEditorText(_textFields);

                            const value = nextValue.toString('html');
                            const _fields = fields.slice();
                            const idx = fields.findIndex(field => field.key === name);
                            _fields.splice(idx, 1, {key: name, value});
                            setFields(_fields);
                          }}
                          value={getTextEditorValue(name) || ''}
                        />
                      ) : inputType === ReasonInputType.CheckboxList ? (
                        <FieldInput
                          disabled={loading}
                          onChange={(event: any) => {
                            const _fields = fields.slice();
                            const idx = fields.findIndex(field => field.key === name);
                            const value = event.target.value;
                            _fields.splice(idx, 1, {key: name, value});
                            setFields(_fields);
                          }}
                          value={getCheckboxListValue(name)}
                        />
                      ) : (
                        <FieldInput
                          disabled={loading}
                          onChange={(event: any) => {
                            const _fields = fields.slice();
                            const idx = fields.findIndex(field => field.key === name);
                            const value = event.target.value;
                            _fields.splice(idx, 1, {key: name, value});
                            setFields(_fields);
                          }}
                          value={getFieldValue(name)}
                        />
                      )}
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            )
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelDialog}>Cancel</Button>
        <Button
          disabled={isSubmitDisabled}
          onClick={() => {
            setLoading(true);
            handleSubmit();
          }}
          color="primary"
        >
          {loading ? <CircularProgress size={24} /> : 'Send Email'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
