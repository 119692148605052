import {adaptV4Theme, createTheme} from '@mui/material';

const disabledStyleOverride = {
  '&.Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.6)',
    WebkitTextFillColor: 'rgba(0, 0, 0, 0.6)',
  },
};

let theme = createTheme(
  adaptV4Theme({
    palette: {
      text: {
        primary: '#000',
      },
      primary: {
        main: '#5351F2',
        light: '#34ADF7',
        dark: '#123ED7',
      },
      secondary: {
        main: '#4d6575',
        light: '#4d6575',
        dark: '#4d6575',
      },
      tonalOffset: 0.2,
    },
    typography: {
      fontFamily: ['Rubik', 'sans-serif'].join(','),
    },
    overrides: {
      MuiPaper: {
        root: {
          color: '#000',
        },
      },
      MuiAppBar: {
        colorPrimary: {
          color: '#586069',
        },
        root: {
          color: '#000',
          fill: '#000',
          backgroundColor: '#fff !important',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
      },
      MuiDrawer: {
        paper: {
          backgroundColor: '#fff',
        },
      },
      MuiInputBase: {
        root: disabledStyleOverride,
        input: disabledStyleOverride,
      },
      MuiInputLabel: {
        root: disabledStyleOverride,
      },
      MuiOutlinedInput: {
        root: disabledStyleOverride,
        input: disabledStyleOverride,
        notchedOutline: disabledStyleOverride,
      },
      MuiAutocomplete: {
        tag: {
          '&.Mui-disabled': {
            opacity: 0.8,
          },
        },
      },
    },
  })
);

export default theme;
