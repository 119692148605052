import {Box} from '@mui/material';
import {format, isToday} from 'date-fns';
import {useMemo} from 'react';
import {BoxCentered} from '../../common';
import {ROW_HEIGHT_HEADER} from '../constants';

type Props = {
  date: Date;
};

export function ColumnDateHeader({date}: Props) {
  const today = useMemo(() => isToday(date), [date.toISOString()]);
  return (
    <BoxCentered
      flexDirection="column"
      height={ROW_HEIGHT_HEADER}
      bgcolor="#ebeff4"
      color={today ? 'primary.main' : 'inherit'}
    >
      <Box sx={{fontSize: '11px'}} textAlign="center">
        {format(date, 'EEE').toUpperCase()}
      </Box>
      <Box sx={{fontSize: '1.4em'}} textAlign="center">
        {format(date, 'd')}
      </Box>
    </BoxCentered>
  );
}
