import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import {Alert, Divider, IconButton, Link, Tooltip} from '@mui/material';
import {GroupView, useGroups} from '@ozark/common';
import {AutoCompleteInputBase, Loading, Title} from '@ozark/common/components';
import {useUrlQuery} from '@ozark/common/lib/hooks/useUrlQuery';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';
import {CreateDialog} from './CreateDialog';
import {GroupGrid} from './GroupGrid';
import {
  GROUP_STATUS,
  GROUP_STATUS_ALL,
  GROUP_STATUS_INACTIVE_ONY,
  InactiveGroupsDropdown,
} from './InactiveGroupsDropdown';

const Groups = () => {
  const showInactiveGroupsParam = useUrlQuery().get(GROUP_STATUS);
  const history = useHistory();
  const {documents: groups} = useGroups();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const {authProfile, isUserAdmin} = useStore();
  const isAdmin = isUserAdmin();

  const [selected, setSelected] = useState<GroupView>();
  const [groupsFiltered, setGroupsFiltered] = useState<GroupView[]>([]);

  useEffect(() => {
    let filteredGroups: GroupView[] = [];
    if (selected) {
      filteredGroups = [selected];
    } else {
      if (groups.data) {
        filteredGroups = groups.data;
      }
    }
    if (showInactiveGroupsParam === GROUP_STATUS_ALL) {
      //Do nothing
    } else if (showInactiveGroupsParam === GROUP_STATUS_INACTIVE_ONY) {
      filteredGroups = filteredGroups.filter(x => x.isActive !== true);
    } else {
      filteredGroups = filteredGroups.filter(x => x.isActive === true);
    }
    setGroupsFiltered(filteredGroups);
  }, [selected, groups, showInactiveGroupsParam]);

  if (groups.promised || authProfile.promised) {
    return <Loading />;
  }

  return (
    <div>
      <Title
        breadcrumbs={[
          <Link component="button" variant="body1" onClick={() => history.push(ROUTES.GROUPS)}>
            Groups
          </Link>,
        ]}
      >
        <AutoCompleteInputBase
          selected={selected}
          setSelected={setSelected}
          icon={SearchIcon}
          placeholder="Select group..."
          options={groups.data ?? []}
          getOptionLabel={(item: GroupView) => item.name}
          onItemSelect={(item: GroupView | null) => setSelected(item ?? undefined)}
        />
        <>
          <Divider orientation="vertical" flexItem style={{margin: 10}} />
          <InactiveGroupsDropdown />
        </>
        {isAdmin ? (
          <>
            <Divider orientation="vertical" flexItem style={{margin: 10}} />
            <Tooltip title="Add a group">
              <IconButton onClick={() => setCreateDialogOpen(true)} size="large">
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
          </>
        ) : null}
      </Title>
      {!isAdmin ? (
        <Alert severity="info">
          You currently have <b>read only</b> access to this page.
        </Alert>
      ) : null}
      {groupsFiltered.length > 0 && <GroupGrid groups={groupsFiltered} isAuthUserAdmin={isAdmin} />}
      {createDialogOpen && <CreateDialog onClose={() => setCreateDialogOpen(false)} />}
    </div>
  );
};

export default Groups;
