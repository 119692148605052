import {Button} from '@mui/material';
import {generatePath} from 'react-router';
import {Link} from 'react-router-dom';
import * as ROUTES from '../../../../constants/routes';
import {RiskOverviewItemProps} from '../common';

type Props = Pick<RiskOverviewItemProps, 'shouldOpenDetailsInNewTab'> & {
  applicationId: string;
};

export const DetailsButton = ({applicationId, shouldOpenDetailsInNewTab}: Props) => {
  return (
    <Link
      to={generatePath(ROUTES.RISK_OVERVIEW_APPLICATION_DETAILS, {id: applicationId})}
      target={shouldOpenDetailsInNewTab ? '_blank' : '_self'}
    >
      <Button variant="contained" sx={{width: '100%'}}>
        View details
      </Button>
    </Link>
  );
};
