import {styled} from '@mui/material/styles';

const EventFieldName = styled('span')(() => ({
  fontWeight: '600',
}));

interface Props {
  caption: string;
  value: string | undefined;
}
export const EventFieldData = ({caption, value}: Props) => {
  if (!value) {
    return null;
  }

  return (
    <>
      <EventFieldName>{caption}</EventFieldName>
      <span>&nbsp;</span>
      <span>{value}</span>
      <span>&nbsp;&nbsp;</span>
    </>
  );
};
