import {Agent, ViewBase} from '@ozark/common';
import {useAgents, useGroups} from '@ozark/common/hooks';
import {useEffect, useState} from 'react';

const ALL_OPTION = '0';

export const useGroupsAndAgents = () => {
  const {documents: agents} = useAgents();
  const {documents: groups} = useGroups();
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [selectedAgent, setSelectedAgent] = useState<string | null>(null);
  const [filteredAgents, setFilteredAgents] = useState<ViewBase<Agent>[]>([]);

  const onGroupChange = async (id: string) => {
    const isAllSelected = id === ALL_OPTION;

    setSelectedGroup(isAllSelected ? null : id);
    setSelectedAgent(null);
  };

  const onAgentChange = async (id: string) => {
    if (!agents.data) {
      return;
    }

    const isAllSelected = id === '0';
    setSelectedAgent(isAllSelected ? null : id);
  };

  useEffect(() => {
    if (!agents.data) {
      return;
    }
    setFilteredAgents(agents.data);
  }, [agents]);

  useEffect(() => {
    if (!agents.data) {
      if (filteredAgents.length > 0) {
        setFilteredAgents([]);
      }

      return;
    }

    const newFilteredAgents =
      selectedGroup != null ? agents.data.filter(x => x.group.id === selectedGroup) : agents.data;
    setFilteredAgents(newFilteredAgents);
  }, [agents.data, selectedGroup]);

  return {
    selectedGroup,
    groups,
    onGroupChange,

    selectedAgent,
    agents,
    filteredAgents,
    onAgentChange,
  };
};
