import GestureIcon from '@mui/icons-material/Gesture';
import {Fab, Grid, Paper, Tooltip} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ApplicationView} from '@ozark/common';
import {omit} from '@s-libs/micro-dash';
import ReactJson from 'react-json-view';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      position: 'relative',
    },
    fab: {
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 9999,
    },
  })
);

export const Json = ({application}: {application: ApplicationView}) => {
  const classes = useStyles();

  const handleEdit = (edit: any) => {
    console.log('saved', {
      field: edit.name,
      oldValue: application[edit.name],
      newValue: edit.new_value,
      namespace: edit.namespace,
    });
  };

  const jsonView = () => {
    const app = Object.keys(application).reduce(
      (previous: any, current: any) => {
        try {
          const field = previous[current];
          if (field && typeof field.toDate === 'function') {
            previous[current] = field.toDate();
          }
          return previous;
        } catch (err) {
          console.error(err);
          return previous;
        }
      },
      {...application}
    );
    return omit(app, 'ref', 'id');
  };

  const toConsole = () => {
    const app = Object.keys(application).reduce(
      (previous: any, current: any) => {
        try {
          const field = previous[current];
          if (field && typeof field.toDate === 'function') {
            previous[current] = {
              _seconds: field.seconds,
              _nanoseconds: field.nanoseconds,
            };
          }
          return previous;
        } catch (err) {
          console.error(err);
          return previous;
        }
      },
      {...application}
    );
    return console.log(JSON.stringify(omit(app, 'ref', 'id'), null, 2));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper className={classes.container}>
          <Tooltip title="Write to Console">
            <Fab
              size="small"
              color="default"
              component="button"
              className={classes.fab}
              onClick={toConsole}
            >
              <GestureIcon />
            </Fab>
          </Tooltip>
          <ReactJson
            name={application.id}
            src={jsonView()}
            sortKeys
            collapsed={1}
            onEdit={handleEdit}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
