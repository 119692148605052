import {Box} from '@mui/material';
import {BoxParentHeight} from '../common';
import {CalendarViewToolbar} from './CalendarViewToolbar';
import {CalendarViewWeek} from './CalendarViewWeek';
import {ROW_HEIGHT_HEADER} from './constants';

export const CalendarViewLayout = () => {
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <CalendarViewToolbar />
      <BoxParentHeight id="calendar-view">
        <Box
          position="sticky"
          top={ROW_HEIGHT_HEADER}
          width="100%"
          sx={{boxShadow: '0px 2px 7px 3px #e2e2e2'}}
        />
        <CalendarViewWeek />
      </BoxParentHeight>
    </Box>
  );
};
