import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ApplicationView, BoardingTaskStatus, BoardingTaskView} from '@ozark/common';
import {format} from 'date-fns';
import React, {Fragment, useState} from 'react';
import {usePostBoardingTasks} from '../../../hooks/usePostBoardingTasks';
import {BoardingTaskDetails} from './BoardingTaskDetails';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    list: {
      width: '100%',
    },
    empty: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    doneIcon: {
      color: 'green',
    },
    errorIcon: {
      color: 'red',
    },
  })
);

type BoardingTaskListProps = {
  application: ApplicationView;
};

export const PostBoardingTaskList = ({application}: BoardingTaskListProps) => {
  const classes = useStyles();
  const {postBoardingTasks} = usePostBoardingTasks(application.id);
  const [currentTask, setCurrentTask] = useState<BoardingTaskView | null>(null);
  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null);

  const handleClick =
    (currentTask: BoardingTaskView) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setAnchorElement(event.currentTarget);
      setCurrentTask(currentTask);
    };

  const handleClose = () => {
    setAnchorElement(null);
  };

  if (postBoardingTasks.promised)
    return (
      <div className={classes.empty}>
        <CircularProgress />
      </div>
    );

  return (
    <Fragment>
      {postBoardingTasks.data && postBoardingTasks.data?.length > 0 ? (
        <List className={classes.list} dense>
          {postBoardingTasks.data?.map((boardingTask: BoardingTaskView) => {
            return (
              <ListItem button onClick={handleClick(boardingTask)}>
                <ListItemIcon>
                  {boardingTask.status === BoardingTaskStatus.processing && (
                    <CircularProgress size={24} />
                  )}
                  {boardingTask.status === BoardingTaskStatus.failed && (
                    <ErrorOutlineOutlinedIcon className={classes.errorIcon} />
                  )}
                  {boardingTask.status === BoardingTaskStatus.done && (
                    <CheckCircleOutlineOutlinedIcon className={classes.doneIcon} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={`${boardingTask.name}: ${boardingTask.status}`}
                  secondary={`${format(boardingTask.createdAt?.toDate?.(), 'MM/dd/yyyy h:mm a')}`}
                />
                <ListItemSecondaryAction></ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <div className={classes.empty}>
          <Typography>No Post Boarding Tasks</Typography>
        </div>
      )}
      <BoardingTaskDetails
        anchorElement={anchorElement}
        boardingTaskView={currentTask}
        onClose={handleClose}
      />
    </Fragment>
  );
};
