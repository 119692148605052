import {yupResolver} from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Fade,
  Grid,
  Paper,
  Theme,
  Typography,
} from '@mui/material';
import {SxProps} from '@mui/system';
import {ProfileView, UserRoles} from '@ozark/common';
import {Loading, Title} from '@ozark/common/components';
import {omit, pick} from '@s-libs/micro-dash';
import {Fragment, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Redirect} from 'react-router';
import * as yup from 'yup';
import {saveProfile} from '../../helpers/saveProfile';
import {useNotification} from '../../hooks/useNotification';
import {useStore} from '../../store/helpers';
import {WorkingHours, workingHoursSchema} from '../WorkingHours';

export const styles: Record<string, SxProps<Theme>> = {
  root: {
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  outside: {
    flex: 1,
    scrollbarWidth: 'none',
    paddingTop: theme => theme.spacing(0.5),
    position: 'relative',
  },
  inside: {
    height: '100%',
  },
  alert: {
    marginBottom: theme => theme.spacing(1),
  },
  paper: {
    marginTop: theme => theme.spacing(0),
    padding: theme => theme.spacing(1, 2, 2),
    position: 'relative',
  },
  heading: {
    height: 48,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  saveButton: {
    width: 150,
  },
};

const schema = yup.object().shape({
  workingHours: yup.object().when('role', (role: UserRoles, schema: any) => {
    if (role === UserRoles.sales) {
      return workingHoursSchema.required();
    }
    return schema.optional();
  }),
});

const getFormData = (profile: ProfileView | undefined) => {
  if (!profile) {
    return {};
  }
  const result = pick(profile, 'role', 'workingHours');
  if (!result.workingHours) {
    result.workingHours = {};
  }

  return result;
};

export const ProfileWorkingHours = () => {
  const showNotification = useNotification();
  const {authProfile} = useStore();
  const [formProfile, setFormProfile] = useState(authProfile.data);
  const [loading, setLoading] = useState(false);

  const {
    formState: {errors, isDirty},
    control,
    reset,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      ...getFormData(authProfile.data as ProfileView),
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (authProfile.promised || formProfile) {
      return;
    }

    setFormProfile(authProfile.data);
    reset(getFormData(authProfile.data as ProfileView));
  }, [authProfile.promised, authProfile.data, formProfile, reset]);

  const onSuccess = async (data: any) => {
    if (!authProfile.data?.uid) {
      return;
    }

    setLoading(true);

    // We MUST exclude role before save
    const dataToSave = omit(data, 'role');

    try {
      await saveProfile(authProfile.data?.uid, dataToSave);

      reset({...getFormData(authProfile.data), ...dataToSave});

      showNotification('success', 'User successfully updated.');
    } catch (err) {
      console.error(err);
      showNotification('error', 'Failed to Save Changes.');
    } finally {
      setLoading(false);
    }
  };

  if (authProfile.promised) {
    return <Loading />;
  }

  if (!authProfile.data) {
    return <Redirect to="/" />;
  }

  return (
    <Box sx={styles.root}>
      <Title breadcrumbs={[<Typography variant="body1">My Profile</Typography>]} />
      <Box sx={styles.outside}>
        <Box sx={styles.inside}>
          <Grid item xs={12}>
            <Paper sx={styles.paper}>
              <form noValidate onSubmit={handleSubmit(onSuccess)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={styles.heading}>
                      <Typography variant="h6">Working Hours</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <WorkingHours control={control} errors={errors} setValue={setValue} />

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Fade in={isDirty} unmountOnExit={false}>
                    <Grid item xs={12}>
                      <Box sx={styles.footer}>
                        <Button
                          type="submit"
                          variant="outlined"
                          color="primary"
                          sx={styles.saveButton}
                          disabled={loading}
                        >
                          {loading ? (
                            <CircularProgress size={24} />
                          ) : (
                            <Fragment>Save Changes</Fragment>
                          )}
                        </Button>
                      </Box>
                    </Grid>
                  </Fade>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
