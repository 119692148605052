import {FormControl, FormHelperText, Grid, MenuItem} from '@mui/material';
import {getErrorMessage, UnitedStatesTimeZonesShortList} from '@ozark/common';
import {Select} from '@ozark/common/components';
import {DayWorkingHours} from './DayWorkingHours';

interface Props {
  control: any;
  errors: any;
  setValue: any;
  isReadOnly?: boolean;
}

export const WorkingHours = ({control, errors, setValue, isReadOnly = false}: Props) => {
  const name = 'workingHours.hasNotWorkDays';
  const hasNotWorkDaysMessage = getErrorMessage(name, errors)?.message;

  return (
    <>
      <Grid item xs={12}>
        <Select
          name="workingHours.timeZoneId"
          disabled={isReadOnly}
          label="Time Zone"
          errors={errors}
          control={control}
        >
          {Object.entries(UnitedStatesTimeZonesShortList).map(([key, value]) => (
            <MenuItem key={key} value={value}>
              {key}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      {!!hasNotWorkDaysMessage && (
        <Grid item xs={12}>
          <FormControl component="fieldset" error>
            <FormHelperText>{hasNotWorkDaysMessage}</FormHelperText>
          </FormControl>
        </Grid>
      )}
      <DayWorkingHours
        isReadOnly={isReadOnly}
        control={control}
        weekDayName="Monday"
        setValue={setValue}
        errors={errors}
      />
      <DayWorkingHours
        control={control}
        isReadOnly={isReadOnly}
        weekDayName="Tuesday"
        setValue={setValue}
        errors={errors}
      />
      <DayWorkingHours
        control={control}
        isReadOnly={isReadOnly}
        weekDayName="Wednesday"
        setValue={setValue}
        errors={errors}
      />
      <DayWorkingHours
        control={control}
        isReadOnly={isReadOnly}
        weekDayName="Thursday"
        setValue={setValue}
        errors={errors}
      />
      <DayWorkingHours
        control={control}
        isReadOnly={isReadOnly}
        weekDayName="Friday"
        setValue={setValue}
        errors={errors}
      />
      <DayWorkingHours
        control={control}
        isReadOnly={isReadOnly}
        weekDayName="Saturday"
        setValue={setValue}
        errors={errors}
      />
      <DayWorkingHours
        control={control}
        isReadOnly={isReadOnly}
        weekDayName="Sunday"
        setValue={setValue}
        errors={errors}
      />
    </>
  );
};
