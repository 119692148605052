import {Department} from '@ozark/common';
import {useMemo} from 'react';
import {useStore} from '../store/helpers';
import {useActiveProfiles} from './useActiveProfiles';

export const useDepartmentUsers = (department?: Department) => {
  const {profiles} = useStore();
  const activeProfiles = useActiveProfiles(profiles);

  return useMemo(() => {
    if (!department || !activeProfiles.data) {
      return [];
    }

    return activeProfiles.data.filter(profile => profile.department === department);
  }, [department, activeProfiles.data]);
};
