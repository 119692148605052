import {Box, Divider, Grid, Typography} from '@mui/material';
import {Lead} from '@ozark/common';
import {getMaskedValue, TextField} from '@ozark/common/components';
import PhoneNumber from 'awesome-phonenumber';
import {useEffect} from 'react';
import {UseFormReturn} from 'react-hook-form';
import * as yup from 'yup';

export type SalesLeadFormData = Pick<
  Lead,
  | 'firstName'
  | 'lastName'
  | 'phone'
  | 'email'
  | 'businessName'
  | 'source'
  | 'ipAddress'
  | 'campaign'
  | 'sourceCampaignDescription'
  | 'externalId'
  | 'externalSalesRepEmail'
>;

export const SalesLeadSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email().required('Email is required'),
  phone: yup
    .string()
    .min(12, 'Must be a valid phone number')
    .transform(value => {
      return value ? new PhoneNumber(value, 'US').getNumber('e164') : value;
    })
    .matches(/^\+1[2-9]{1}[0-9]{9}$/, 'Must be a valid phone number')
    .required('Phone is required'),
  businessName: yup.string().required('Business name is required'),
  source: yup.string().required('Source is required'),
  sourceCampaignDescription: yup.string(),
  externalId: yup.string(),
  externalSalesRepEmail: yup.string().email(),
});

const PHONE_MASK = '(999) 999-9999';

type Props = {
  hookForm: UseFormReturn<SalesLeadFormData>;
  lead?: Lead;
  isReadonly?: boolean;
  editMode?: boolean;
};

export const SalesLeadForm = ({hookForm, lead, isReadonly, editMode}: Props) => {
  const {
    formState: {errors},
    control,
    reset,
  } = hookForm;

  useEffect(() => {
    if (lead) {
      const {
        firstName,
        lastName,
        phone,
        email,
        businessName,
        ipAddress,
        source,
        campaign,
        sourceCampaignDescription,
        externalId,
        externalSalesRepEmail,
      } = lead;

      reset({
        firstName,
        lastName,
        phone: phone ? getMaskedValue(phone, PHONE_MASK) : '',
        email,
        businessName,
        ipAddress,
        source,
        campaign,
        sourceCampaignDescription,
        externalId,
        externalSalesRepEmail,
      });
    }
  }, [lead, reset]);

  return (
    <>
      <Grid item xs={12}>
        <Box>
          <Typography sx={{fontSize: '1.2rem'}} variant="caption" gutterBottom>
            Lead Information
          </Typography>
          <Divider />
        </Box>
      </Grid>
      <Grid item xs={editMode ? 6 : 12}>
        <TextField
          name="firstName"
          label="First Name"
          required
          errors={errors}
          control={control}
          disabled={isReadonly}
          autoFocus={!editMode}
        />
      </Grid>
      <Grid item xs={editMode ? 6 : 12}>
        <TextField
          name="lastName"
          label="Last Name"
          required
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={editMode ? 6 : 12}>
        <TextField
          name="email"
          label="Email"
          required
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={editMode ? 6 : 12}>
        <TextField
          name="phone"
          label="Phone Number"
          required
          errors={errors}
          control={control}
          disabled={isReadonly}
          transform={{
            pattern: PHONE_MASK,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="businessName"
          label="Business Name"
          required
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{mt: 1}}>
          <Typography sx={{fontSize: '1.2rem'}} variant="caption" gutterBottom>
            Source
          </Typography>
          <Divider />
        </Box>
      </Grid>
      <Grid item xs={editMode ? 6 : 12}>
        <TextField
          name="source"
          label="Source"
          required
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={editMode ? 6 : 12}>
        <TextField
          name="campaign"
          label="Campaign"
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="sourceCampaignDescription"
          label="Description"
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      {editMode && (
        <Grid item xs={12}>
          <TextField
            name="ipAddress"
            label="IP address"
            errors={errors}
            control={control}
            disabled
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Box sx={{mt: 1}}>
          <Typography sx={{fontSize: '1.2rem'}} variant="caption" gutterBottom>
            Keap
          </Typography>
          <Divider />
        </Box>
      </Grid>
      <Grid item xs={editMode ? 6 : 12}>
        <TextField
          name="externalId"
          label="Contact ID"
          required
          errors={errors}
          control={control}
          disabled
        />
      </Grid>
      <Grid item xs={editMode ? 6 : 12}>
        <TextField
          name="externalSalesRepEmail"
          label="Sales Rep Email"
          required
          errors={errors}
          control={control}
          disabled
        />
      </Grid>
    </>
  );
};
