import WarningIcon from '@mui/icons-material/Warning';
import {LoadingButton} from '@mui/lab';
import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import {blueGrey} from '@mui/material/colors';
import {AccountStatus, ApplicationView, TerminatedSubCategories} from '@ozark/common';
import {format} from 'date-fns-tz';
import {Controller, useForm} from 'react-hook-form';
import {EligibleReleaseDateEditor} from './EligibleReleaseDateEditor';
import {RiskApplicationAssociations} from './RiskApplicationAssociations';
import {ApplicationRiskInfoFormModel} from './types';
import {useRiskModuleIsReadOnly, useRiskSection} from './useRiskSection';

interface Props {
  application: ApplicationView;
}

export const RiskSection = ({application}: Props) => {
  const hookForm = useForm<ApplicationRiskInfoFormModel>({
    defaultValues: {
      accountStatus: application.accountStatus?.status,
      accountStatusSubCategory: application.accountStatus?.subCategory,
      riskStatus: application.riskStatus,
      riskLabels: application.riskLabels,
      eligibleReleaseDate: application.accountStatus?.eligibleReleaseDate?.toDate(),
    },
  });

  const {
    formState: {isDirty},
    handleSubmit,
    control,
    watch,
  } = hookForm;

  const isReadOnly = useRiskModuleIsReadOnly();
  const {
    loading,
    handleSaveChanges,
    handleAccountStatusChanged,
    riskLabelDictionaryValues,
    allRiskStatuses,
  } = useRiskSection(application, hookForm);

  const watchAccountStatus = watch('accountStatus');
  const isAccountStatusTerminated = watchAccountStatus === AccountStatus.terminated;

  return (
    <>
      <Paper
        sx={{
          padding: theme => theme.spacing(1, 2, 3),
          marginBottom: 2,
          position: 'relative',
        }}
        square
      >
        <Grid
          container
          rowSpacing={{md: 1, xs: 2.5}}
          columnSpacing={2}
          direction="row"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Box sx={{mb: 0}}>
              <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 600,
                    color: blueGrey[700],
                    py: 1,
                  }}
                  variant="body1"
                  gutterBottom
                >
                  <WarningIcon sx={{ml: 1, mr: 2}} /> Risk Info
                </Typography>
                {isDirty && (
                  <LoadingButton
                    loading={loading}
                    variant="outlined"
                    size="small"
                    onClick={handleSubmit(handleSaveChanges)}
                  >
                    Save changes
                  </LoadingButton>
                )}
              </Box>

              <Divider />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{pr: {md: 2}}}>
            <Controller
              name="accountStatus"
              control={control}
              render={({field: {onChange, ...props}}) => (
                <Autocomplete
                  options={Object.values(AccountStatus)}
                  onChange={(_e, value) => {
                    handleAccountStatusChanged();
                    onChange(value);
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Account Status" variant="standard" />
                  )}
                  disabled={isReadOnly}
                  {...props}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{pr: {md: 1}}}>
            <Controller
              name="accountStatusSubCategory"
              control={control}
              render={({field: {value, onChange, ...props}}) => (
                <Autocomplete
                  options={Object.values(TerminatedSubCategories)}
                  disabled={isReadOnly || !isAccountStatusTerminated}
                  value={value ?? null}
                  onChange={(_e, value) => onChange(value)}
                  renderInput={params => (
                    <TextField {...params} label="Account Sub Category" variant="standard" />
                  )}
                  {...props}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{pl: {md: 1}}}>
              <Controller
                name="eligibleReleaseDate"
                control={control}
                render={({field: {value, onChange}}) => (
                  <TextField
                    label="Reserve Eligible Release Date"
                    variant="standard"
                    fullWidth
                    value={value ? format(value, 'MM/dd/yyyy') : ''}
                    disabled
                    InputLabelProps={{
                      sx: {
                        paddingRight: '50px',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <EligibleReleaseDateEditor
                            eligibleReleaseDate={value}
                            handleSave={onChange}
                            allowEdit={!isReadOnly && isAccountStatusTerminated}
                          />
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{pr: {md: 2}}}>
            <Controller
              name="riskStatus"
              control={control}
              render={({field: {value, onChange, ...props}}) => (
                <Autocomplete
                  options={allRiskStatuses}
                  value={value ?? null}
                  onChange={(_e, value) => onChange(value)}
                  renderInput={params => (
                    <TextField {...params} label="Risk Status" variant="standard" />
                  )}
                  disabled={isReadOnly}
                  {...props}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="riskLabels"
              control={control}
              render={({field: {onChange, ...props}}) => (
                <Autocomplete
                  multiple
                  size="small"
                  freeSolo
                  options={riskLabelDictionaryValues.data ?? []}
                  sx={{
                    '&.MuiAutocomplete-root .MuiInput-root.MuiInputBase-sizeSmall': {
                      marginTop: '19px',
                    },
                  }}
                  onChange={(_e, values: string[]) => onChange(values)}
                  renderInput={params => (
                    <TextField {...params} label="Risk Labels" variant="standard" />
                  )}
                  disabled={isReadOnly}
                  disableCloseOnSelect
                  {...props}
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>

      {application && (
        <RiskApplicationAssociations application={application} readOnly={isReadOnly} />
      )}
    </>
  );
};
