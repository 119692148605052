import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {IconButton, Link, useMediaQuery, useTheme} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Preview, Title} from '@ozark/common/components';
import React, {useState} from 'react';
import {useHistory} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {Tab, Tabs} from '../Tabs';
import {RatePrograms} from './RatePrograms';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(1),
    },
  })
);

const Pricing = () => {
  const classes = useStyles();
  const history = useHistory<{referrer: string}>();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('lg'));
  const [tab, setTab] = useState(0);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    setTab(newTab);
  };

  return (
    <div className={classes.root}>
      <Title
        breadcrumbs={
          smDown
            ? [
                <IconButton onClick={() => history.goBack()} size="large">
                  <ChevronLeftIcon />
                </IconButton>,
              ]
            : [
                <Link
                  component="button"
                  variant="body1"
                  onClick={() => history.push(ROUTES.PRICING)}
                >
                  Pricing
                </Link>,
              ]
        }
      >
        <Tabs value={tab} onChange={handleTabChange} centered>
          <Tab label="Rate Programs" />
          <Tab label="Preview" />
        </Tabs>
      </Title>
      {tab === 0 && <RatePrograms />}
      {tab === 1 && <Preview />}
    </div>
  );
};

export default Pricing;
