import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  ActionPayload,
  ApplicationView,
  Score,
  ScorecardAction,
  ScorecardExecutionStatus,
  UniversalTimestamp,
  useCallable,
} from '@ozark/common';
import {Column} from '@ozark/common/api/Column';
import {ScorecardServiceLogo, Table} from '@ozark/common/components';
import clsx from 'clsx';
import format from 'date-fns/format';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {StatusColorMap} from '..';
import {useNotification} from '../../../../hooks/useNotification';
import {ScorecardReport, ScorecardReportStep} from '../scorecardReports';
import {useRunScores} from './hooks/useRunScores';

function usePrevious(value: Record<ScorecardReportStep, Score> | undefined) {
  const ref = useRef<Record<ScorecardReportStep, Score>>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export type Props<TDataRow> = {
  scorecardReport: ScorecardReport;
  application: ApplicationView;
  scores?: Record<ScorecardAction, Score>;
  whichExpanded: string | false;
  onChange: (panel: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  onSubmit?: () => void;
  columns: Column<{}>[];
  formatResults: (results: {}) => TDataRow[];
  resolveActionPayload: (selectedRow: TDataRow) => ActionPayload;
  resolveDownloadReportLink: (result: {}) => Promise<string>;
  confirmationDialogTitle: (selectedRow: TDataRow) => string;
  step1Action: ScorecardAction;
  step1Name: string;
  step2Action: ScorecardAction;
  step2Name: string;
};

export const TwoStepScorecardReportAccordion = <TDataRow extends {[key: string]: any}>({
  application,
  scorecardReport,
  scores,
  whichExpanded,
  onChange,
  columns,
  formatResults,
  resolveActionPayload,
  resolveDownloadReportLink,
  confirmationDialogTitle,
  step1Action,
  step1Name,
  step2Action,
  step2Name,
}: Props<TDataRow>) => {
  const classes = useStyles();
  const showNotification = useNotification();
  const [stepScores, setStepScores] = useState<Record<ScorecardReportStep, Score>>();
  const statusLast =
    scores?.[step2Action]?.status ??
    scores?.[step1Action]?.status ??
    ScorecardExecutionStatus.neverRan;
  const statusUpdatedAt = scores?.[step2Action]?.updatedAt ?? scores?.[step1Action]?.updatedAt;

  // Resolve step scores
  useEffect(() => {
    const reportScores = scores;
    if (!reportScores) return;

    const result = {} as Record<ScorecardReportStep, Score>;
    if (reportScores[step1Action]) {
      result.step1 = reportScores[step1Action];
    }

    if (reportScores[step2Action]) {
      result.step2 = reportScores[step2Action];
    }

    setStepScores(result);
    // eslint-disable-next-line
  }, [scores, scorecardReport]);

  const {runScorecardService} = useCallable();

  const [reportStatus, setReportStatus] = useState(statusLast);
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<TDataRow>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const {onRunnerStatusChange, runAllTicket} = useRunScores();

  const previousStepScores = usePrevious(stepScores);

  // Resolve current step based on score card results
  useEffect(() => {
    if (!stepScores?.step1 || stepScores.step1.status !== ScorecardExecutionStatus.complete) {
      setActiveStep(0);
    } else if (!stepScores.step2) {
      setActiveStep(1);
    } else {
      setActiveStep(2);
    }
  }, [stepScores]);

  // Resolve if actions are loading or errored out.
  useEffect(() => {
    if (!stepScores) return;

    for (const [key, stepScore] of Object.entries(stepScores)) {
      if (
        stepScore.status === ScorecardExecutionStatus.complete &&
        (!previousStepScores ||
          !previousStepScores[key as ScorecardReportStep] ||
          previousStepScores[key as ScorecardReportStep].status !==
            ScorecardExecutionStatus.complete)
      ) {
        setLoading(false);
      }

      if (
        stepScore.status === ScorecardExecutionStatus.failed &&
        (!previousStepScores ||
          !previousStepScores[key as ScorecardReportStep] ||
          previousStepScores[key as ScorecardReportStep].status !== ScorecardExecutionStatus.failed)
      ) {
        setLoading(false);
        if (stepScore.results && 'errorMessage' in stepScore.results) {
          showNotification('error', stepScore.results?.errorMessage);
        }
      }
    }
    // eslint-disable-next-line
  }, [stepScores, previousStepScores]);

  // Resolve report status
  useEffect(() => {
    if (!stepScores) return;

    // First is not complete: never ran
    if (!stepScores?.step1 || stepScores?.step1.status === ScorecardExecutionStatus.neverRan) {
      setReportStatus(ScorecardExecutionStatus.neverRan);
    }

    // First is complete, second is not: action required
    if (
      (!stepScores?.step2 || stepScores.step2.status !== ScorecardExecutionStatus.complete) &&
      stepScores.step1.status === ScorecardExecutionStatus.complete
    ) {
      setReportStatus(ScorecardExecutionStatus.actionRequired);
      return;
    }

    if (!stepScores?.step2) {
      setReportStatus(stepScores.step1.status);
      return;
    }

    // Both complete: complete
    if (
      stepScores.step1.status === ScorecardExecutionStatus.complete &&
      stepScores.step2.status === ScorecardExecutionStatus.complete
    ) {
      setReportStatus(ScorecardExecutionStatus.complete);
      return;
    }

    // Any failed: failed
    if (
      stepScores.step1.status === ScorecardExecutionStatus.failed ||
      stepScores.step2.status === ScorecardExecutionStatus.failed
    ) {
      setReportStatus(ScorecardExecutionStatus.failed);
    }

    // Any running: running
    if (
      stepScores.step1.status === ScorecardExecutionStatus.running ||
      stepScores.step2.status === ScorecardExecutionStatus.running
    ) {
      setReportStatus(ScorecardExecutionStatus.running);
    }
  }, [stepScores]);

  const handleStep1Click = useCallback(async () => {
    setLoading(true);
    await runScorecardService({
      applicationId: application.id,
      scorecardName: scorecardReport.serviceName,
      scorecardReportId: scorecardReport.id,
      scorecardAction: step1Action,
    });
    // eslint-disable-next-line
  }, [application.id, scorecardReport.id, step1Action]);

  const handleStep2Click = async () => {
    if (!selectedRecord) return;

    setReportStatus(ScorecardExecutionStatus.running);
    setLoading(true);
    setDialogOpen(false);

    await runScorecardService({
      applicationId: application.id,
      scorecardName: scorecardReport.serviceName,
      scorecardReportId: scorecardReport.id,
      scorecardAction: step2Action,
      actionPayload: resolveActionPayload(selectedRecord),
    });
  };

  const handleRowClick = async (row: TDataRow) => {
    setSelectedRecord(row as TDataRow);
    setDialogOpen(true);
  };

  const handleDownloadReport = async () => {
    if (!stepScores?.step2.results) return;
    const a = document.createElement('a');
    a.href = await resolveDownloadReportLink(stepScores.step2.results);
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const reportStatusActual = loading ? ScorecardExecutionStatus.running : reportStatus;
  const isLoading = reportStatusActual === ScorecardExecutionStatus.running;

  useEffect(() => {
    onRunnerStatusChange(scorecardReport.id, reportStatus);
    console.info(scorecardReport.id, 'report status:', reportStatus);
    // eslint-disable-next-line
  }, [onRunnerStatusChange, reportStatus]);

  useEffect(() => {
    if (runAllTicket > 0) {
      console.info(scorecardReport.id, 'starting...');
      handleStep1Click();
    }
    // eslint-disable-next-line
  }, [runAllTicket, handleStep1Click]);

  return (
    <>
      <Accordion
        TransitionProps={{unmountOnExit: true}}
        expanded={whichExpanded === scorecardReport.id}
        onChange={onChange(scorecardReport.id)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-${scorecardReport.id}-content`}
          id={`panel-${scorecardReport.id}-header`}
        >
          <div className={classes.logo}>
            <ScorecardServiceLogo scorecardService={scorecardReport.serviceName} />
            <div className={classes.panelName}>
              <Typography variant="body1">{scorecardReport.title}</Typography>
            </div>
          </div>

          <div className={classes.column} style={{justifyContent: 'space-between'}}>
            <div>
              <Typography variant="body1">
                Status:&nbsp;&nbsp;
                <span
                  style={{
                    color: StatusColorMap[reportStatusActual],
                  }}
                >
                  {reportStatusActual}
                </span>
              </Typography>
            </div>
            {isLoading && (
              <CircularProgress style={{color: '#1877f2', marginRight: 20}} size={25} />
            )}
          </div>
          <div className={clsx(classes.column, classes.grow)}>
            <Typography variant="body1">
              Last Update:&nbsp;&nbsp;
              <span>
                {statusUpdatedAt
                  ? format((statusUpdatedAt as UniversalTimestamp).toDate(), 'MM/dd/yyyy h:mm a')
                  : 'Never'}
              </span>
            </Typography>
          </div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails
          classes={{
            root: classes.details,
          }}
        >
          <div className={classes.root}>
            <Stepper activeStep={activeStep} classes={{root: classes.stepper}}>
              <Step key={'0'}>
                <StepLabel>{step1Name}</StepLabel>
              </Step>
              <Step key={'1'}>
                <StepLabel>{step2Name}</StepLabel>
              </Step>
              <Step key={'2'}>
                <StepLabel>Download Report</StepLabel>
              </Step>
            </Stepper>

            {activeStep === 1 &&
              !loading &&
              stepScores?.step1.results &&
              stepScores?.step1?.status === ScorecardExecutionStatus.complete && (
                <div className={classes.table}>
                  <Table
                    rows={formatResults(stepScores?.step1.results)}
                    columns={columns}
                    onRowClick={handleRowClick}
                  />
                </div>
              )}
            {activeStep === 2 && (
              <AccordionActions classes={{root: classes.actionsRoot}}>
                <Button onClick={() => setActiveStep(1)}>Back</Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  onClick={handleDownloadReport}
                >
                  Download PDF Report
                </Button>
              </AccordionActions>
            )}
          </div>
        </AccordionDetails>

        {activeStep === 0 && (
          <AccordionActions classes={{root: classes.actionsRoot}}>
            <Button
              color="primary"
              variant="contained"
              disabled={loading}
              onClick={handleStep1Click}
            >
              {step1Name}
            </Button>
          </AccordionActions>
        )}
      </Accordion>
      <Dialog
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Report request</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {selectedRecord && confirmationDialogTitle(selectedRecord)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleStep2Click} color="primary" autoFocus>
            Request Report
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    icon: {
      verticalAlign: 'bottom',
      height: 20,
      width: 20,
    },
    column: {
      display: 'flex',
      flexDirection: 'row',
      flexBasis: '20%',
      alignItems: 'center',
    },
    grow: {
      flexGrow: 1,
      '& > *': {
        margin: theme.spacing(0, 0.5),
      },
    },
    logo: {
      display: 'flex',
      flexDirection: 'row',
      flexBasis: '25%',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    processingTypeTitle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      '& > *': {
        marginRight: theme.spacing(2),
      },
    },
    primaryColor: {
      color: theme.palette.primary.main,
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    panelName: {
      width: 200,
    },
    details: {
      width: '100%',
    },
    root: {
      width: '100%',
      marginLeft: theme.spacing(10),
      marginRight: theme.spacing(10),
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    stepper: {
      width: 600,
      padding: theme.spacing(2),
    },
    actionsRoot: {
      justifyContent: 'center',
      paddingBottom: theme.spacing(2),
    },
    table: {
      height: 500,
      width: '100%',
    },
    stepProgress: {
      margin: theme.spacing(2),
    },
  })
);
