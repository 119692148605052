import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import {Collections, Firebase, Merchant, useCallable} from '@ozark/common';
import {Loading} from '@ozark/common/components';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {useNotification} from '../../hooks/useNotification';

type Props = {
  merchantUid?: string;
};

export const MerchantDeleteDialog = ({merchantUid}: Props) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [subMerchants, setSubMerchants] = useState<Merchant[] | undefined>(undefined);
  const showNotification = useNotification();
  const {deleteMerchantUser} = useCallable();
  const history = useHistory();

  useEffect(() => {
    Firebase.firestore
      .collection(Collections.merchants)
      .where('masterUid', '==', merchantUid)
      .get()
      .then(snap => {
        if (snap.size) {
          setSubMerchants(
            snap.docs.map(doc => {
              return doc.data() as Merchant;
            })
          );
        } else {
          setSubMerchants([]);
        }
      });
  }, [merchantUid]);

  const handleDeleteMerchant = async () => {
    setConfirmationOpen(false);

    try {
      if (!merchantUid) throw new Error('Merchant uid missing');
      const deleteResult = await deleteMerchantUser({uid: merchantUid});
      if (deleteResult.status === 'ok') {
        showNotification('success', 'Merchant account successfully deleted.');
        history.push(ROUTES.MERCHANTS);
      } else {
        showNotification('error', deleteResult.message);
      }
    } catch (err) {
      console.log(err);
      showNotification('error', 'Merchant account deletion failed.');
    }
  };

  const loadingRender = () => {
    return <Loading />;
  };

  const noSubMerchantsRender = () => {
    return 'Delete merchant account?';
  };

  const subMerchantsExistRender = () => {
    return (
      <>
        <Typography>This Main Merchant is associated with a Sub-Merchant User:</Typography>
        <List sx={{listStyleType: 'disc', pl: 4}} dense={true}>
          {subMerchants?.map(x => (
            <ListItem sx={{display: 'list-item'}}>
              <ListItemText primary={x.email} />
            </ListItem>
          ))}
        </List>
        <Typography>Deleting the Main Merchant will delete these Sub-Merchant users.</Typography>
      </>
    );
  };

  return (
    <div>
      <Button size="large" startIcon={<DeleteIcon />} onClick={() => setConfirmationOpen(true)}>
        Delete
      </Button>
      <Dialog open={confirmationOpen}>
        <DialogTitle>Merchant Account Deletion</DialogTitle>
        <DialogContent dividers>
          {!subMerchants
            ? loadingRender()
            : subMerchants.length > 0
            ? subMerchantsExistRender()
            : noSubMerchantsRender()}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setConfirmationOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleDeleteMerchant}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
