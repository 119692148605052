import {
  AsyncState,
  Collections,
  Firebase,
  Group,
  GroupView,
  selectGroupView,
  UniversalSnapshot,
} from '@ozark/common';
import {useEffect, useMemo, useState} from 'react';

export const useGroups = () => {
  const [groups, setGroups] = useState<AsyncState<GroupView[]>>({
    promised: true,
  });

  useEffect(() => {
    Firebase.firestore.collection(Collections.groups).onSnapshot(snapshot => {
      if (snapshot.empty) {
        setGroups({promised: false, data: []});
        return;
      }

      const groups = snapshot.docs.map(doc => selectGroupView(doc as UniversalSnapshot<Group>));
      setGroups({
        promised: false,
        data: groups.filter(group => !group.deleted),
      });
    });
  }, []);

  const groupsMap = useMemo(() => {
    if (!groups.data) {
      return {} as Record<string, GroupView>;
    }

    return groups.data?.reduce((acc, group) => {
      acc[group.id] = group;
      return acc;
    }, {} as Record<string, GroupView>);
  }, [groups.data]);

  return {
    groups,
    groupsMap,
  };
};
