import TableRowsIcon from '@mui/icons-material/TableRows';
import TableViewIcon from '@mui/icons-material/TableView';
import {Box, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';
import {AccountStatus, Platforms} from '@ozark/common';
import {
  Filter,
  FilterOption,
  FiltersAddButton,
  FiltersApplied,
  GroupAgentAndSortFilters,
  GroupAgentAndSortFiltersProps,
  InputSearch,
  StyledDivider,
  Title,
} from '@ozark/common/components';
import {Dispatch, Fragment, SetStateAction} from 'react';
import {CardView} from './Card';
import {RiskAssigneeFilter, RiskAssigneeFilterProps} from './RiskAssigneeFilter';

type BarProps = {
  filteredMids?: Record<string, string> | null;
  filters: Filter;
  setFilters: Dispatch<SetStateAction<Filter>>;
  view: CardView;
  onViewChange: (view: CardView) => void;
  searchQuery?: string;
  onSearchQueryChange: (value: string) => void;
  riskAssigneeFilterValue: RiskAssigneeFilterProps['value'];
  onChangeRiskAssignee: RiskAssigneeFilterProps['onChange'];
} & Omit<GroupAgentAndSortFiltersProps, 'sortOptions'>;

export const RiskOverviewBar = ({
  selectedGroup,
  selectedAgent,
  orderBy,
  order,
  onGroupChange = () => {},
  groups,
  agents,
  onAgentChange,
  onSortChange,
  filters,
  setFilters,
  view,
  onViewChange,
  searchQuery,
  onSearchQueryChange,
  riskAssigneeFilterValue,
  onChangeRiskAssignee,
}: BarProps) => {
  const onResetFilters = (newFilters: Filter) => {
    setFilters(newFilters);
  };
  const searchAndFiltersButton = (
    <>
      <InputSearch
        fieldName="searchMerchants"
        placeholder="Search..."
        inputValue={searchQuery}
        onSearchChange={onSearchQueryChange}
      />
      <StyledDivider orientation="vertical" flexItem />
      <FiltersAddButton filters={filters} setFilters={setFilters} filtersConfig={filtersConfig} />
    </>
  );

  return (
    <Fragment>
      <Title
        breadcrumbs={[
          <Typography sx={{pt: 2, pb: 1}} variant="body1">
            Risk Overview
          </Typography>,
        ]}
        noMargins
      >
        <Box
          sx={theme => ({
            display: 'flex',
            justifyContent: 'flex-end',
            [theme.breakpoints.down('xl')]: {
              flexDirection: 'column',
            },
          })}
        >
          <Box
            sx={theme => ({
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr) 2fr',
              [theme.breakpoints.down(1700)]: {
                gridTemplateColumns: 'repeat(3, 1fr)',
              },
              [theme.breakpoints.down(1500)]: {
                gridTemplateColumns: 'repeat(3, 1fr)',
              },
              [theme.breakpoints.down('xl')]: {
                gridTemplateColumns: 'repeat(4, 1fr)',
              },
              [theme.breakpoints.down('lg')]: {
                gridTemplateColumns: 'repeat(2, 1fr)',
              },
            })}
          >
            <Box display="flex">
              <StyledDivider orientation="vertical" flexItem />
              <RiskAssigneeFilter value={riskAssigneeFilterValue} onChange={onChangeRiskAssignee} />
            </Box>
            <GroupAgentAndSortFilters
              orderBy={orderBy}
              order={order}
              onSortChange={onSortChange}
              sortOptions={sortOptions}
              selectedAgent={selectedAgent}
              agents={agents}
              onAgentChange={onAgentChange}
              selectedGroup={selectedGroup}
              groups={groups}
              onGroupChange={onGroupChange}
            />
            <Box
              sx={theme => ({
                display: 'flex',
                alignItems: 'center',
                [theme.breakpoints.down(1700)]: {
                  gridColumn: '-1/-3',
                },
                [theme.breakpoints.down('xl')]: {
                  display: 'none',
                },
              })}
            >
              <StyledDivider orientation="vertical" flexItem />
              {searchAndFiltersButton}
            </Box>
          </Box>
        </Box>
      </Title>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          py: 0.25,
          mb: 0.5,
        }}
      >
        <Box sx={{py: 1.5}}>
          <FiltersApplied filters={filters} setFilters={onResetFilters} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            pt: 0.5,
          }}
        >
          <Box
            sx={{
              display: {xs: 'flex', xl: 'none'},
              alignItems: 'center',
            }}
          >
            {searchAndFiltersButton}
            <StyledDivider orientation="vertical" flexItem />
          </Box>
          <ToggleButtonGroup
            value={view}
            onChange={(_, newView) => newView && onViewChange(newView)}
            exclusive
            aria-label="Content view"
            color="primary"
            size="small"
            sx={{pt: 0.5}}
          >
            <ToggleButton value="card">
              <TableRowsIcon />
            </ToggleButton>
            <ToggleButton value="table">
              <TableViewIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
    </Fragment>
  );
};

const sortOptions: {title: string; key: string; defaultOrder: 'asc' | 'desc'}[] = [
  {
    title: 'Agent Name',
    key: 'agentName',
    defaultOrder: 'desc',
  },
  {
    title: 'Agent Group',
    key: 'groupName',
    defaultOrder: 'desc',
  },
  {
    title: 'MID',
    key: 'mid',
    defaultOrder: 'desc',
  },
  {
    title: 'First Batch Date',
    key: 'firstBatchDate',
    defaultOrder: 'desc',
  },
  {
    title: 'First Batch Amount',
    key: 'firstBatchAmount',
    defaultOrder: 'desc',
  },
  {
    title: 'Last Batch Date',
    key: 'lastBatchDate',
    defaultOrder: 'asc',
  },
  {
    title: 'Last Batch Amount',
    key: 'lastBatchAmount',
    defaultOrder: 'asc',
  },
  {
    title: 'Risk Priority',
    key: 'riskPriority',
    defaultOrder: 'asc',
  },
];

export const filtersConfig: FilterOption[] = [
  {
    id: 'accountStatus',
    column: 'accountStatus',
    label: 'Account Status',
    type: 'list',
    options: Object.values(AccountStatus).map(status => ({
      key: status,
      value: status,
    })),
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'riskStatus',
    column: 'riskStatus',
    label: 'Risk Status',
    type: 'text',
    operators: [
      {
        id: '__like',
        label: 'contains',
      },
    ],
  },
  {
    id: 'riskLabels',
    column: 'riskLabels',
    label: 'Risk Labels',
    type: 'text',
    operators: [
      {
        id: '__like',
        label: 'contains',
      },
    ],
  },
  {
    id: 'platform',
    column: 'platform',
    label: 'Bank',
    type: 'list',
    options: Object.values(Platforms)
      .sort()
      .map(status => ({
        key: status,
        value: status,
      })),
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'firstBatchDate',
    column: 'firstBatchDate',
    label: 'First Batch Date',
    type: 'date',
    dateFormat: 'MMM dd, yyyy',
    operators: [
      {
        id: '__between',
        label: 'equals',
      },
    ],
  },
  {
    id: 'firstBatchDateRange',
    column: 'firstBatchDate',
    label: 'First Batch Date Range',
    type: 'dateRange',
    dateFormat: 'MMM dd, yyyy',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'lastBatchDate',
    column: 'lastBatchDate',
    label: 'Last Batch Date',
    type: 'date',
    dateFormat: 'MMM dd, yyyy',
    operators: [
      {
        id: '__between',
        label: 'equals',
      },
    ],
  },
  {
    id: 'lastBatchDateRange',
    column: 'lastBatchDate',
    label: 'Last Batch Date Range',
    type: 'dateRange',
    dateFormat: 'MMM dd, yyyy',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'firstBatchAmount',
    column: 'firstBatchAmount',
    label: 'First Batch Amount',
    type: 'currency',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'firstBatchAmountRange',
    column: 'firstBatchAmount',
    label: 'First Batch Amount range',
    type: 'currencyRange',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'lastBatchAmount',
    column: 'lastBatchAmount',
    label: 'Last Batch Amount',
    type: 'currency',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'lastBatchAmountRange',
    column: 'lastBatchAmount',
    label: 'Last Batch Amount range',
    type: 'currencyRange',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
];
