import MenuIcon from '@mui/icons-material/Menu';
import WarningIcon from '@mui/icons-material/Warning';
import {
  AppBar,
  Avatar,
  Divider,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import {
  Department,
  Dispositions,
  getSearchGroup,
  hasOzarkRole,
  IndexableApplication,
  IndexableLead,
  OzarkUserRoles,
  TicketSearchItemModel,
  useAuthContainer,
  UserRoles,
  useSiteJumpAsUser,
  useUserInfo,
  withDialog,
} from '@ozark/common';
import {
  ApplicationOption,
  ApplicationOptionHeader,
  BoxEllipsis,
  GreetingMessage,
  LeadOption,
  Notifications,
  SalesCampaigns,
  Search,
  SubscriptionManagement,
  TicketSearchItem,
  TicketSearchItemHeader,
} from '@ozark/common/components';
import {GlobalSearch} from '@ozark/common/components/GlobalSearch';
import {EntityAutocompleteOptionConfig, Option} from '@ozark/common/components/GlobalSearch/types';
import {useAgents} from '@ozark/common/hooks/useAgents';
import {ImposterIcon} from '@ozark/common/icons';
import React, {MouseEventHandler, useCallback, useMemo} from 'react';
import {generatePath, matchPath, useHistory, useLocation} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';

const useStyles = makeStyles(theme => ({
  appBar: {
    color: '#4d6575',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0.5, 0),
    },
  },
  stagingWarning: {
    display: 'flex',
    color: 'red',
    padding: theme.spacing(0, 0.5),
    '& > *': {
      margin: theme.spacing(0, 0.5),
    },
  },
  secondaryBar: {
    zIndex: 0,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 0,
    marginLeft: theme.spacing(2),
    height: theme.spacing(6),
    width: theme.spacing(6),
  },
  avatar: {
    border: 'solid 1px rgba(0, 0, 0, 0.12)',
    height: theme.spacing(6),
    width: theme.spacing(6),
  },
  impostorInput: {
    backgroundColor: '#FFF !important',
    '&:focus,&:active': {
      backgroundColor: '#FFF',
    },
  },
}));

const useProfileMenu = ({handleLogout}: {handleLogout: () => Promise<void>}) => {
  const history = useHistory();
  const {authProfile} = useStore();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const {isErpAdmin, userRole, department} = useUserInfo();

  const canSeeSubscriptionManagement = isErpAdmin;

  const handleEditProfile = () => {
    history.push(ROUTES.PROFILE);
    hideProfileMenu();
  };

  const handleChangePassword = () => {
    history.push(ROUTES.CHANGE_PASSWORD);
    hideProfileMenu();
  };
  const handleWorkingHours = () => {
    history.push(ROUTES.PROFILE_WORKING_HOURS);
    hideProfileMenu();
  };
  const hideProfileMenu = () => {
    setAnchorEl(null);
  };
  const onLogout = async () => {
    hideProfileMenu();
    await handleLogout();
    history.push(ROUTES.LOGIN);
  };

  const ProfileMenu = () => (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={hideProfileMenu}
    >
      <MenuItem onClick={handleEditProfile}>Update Profile</MenuItem>

      <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>

      {userRole === UserRoles.sales && (
        <MenuItem onClick={handleWorkingHours}>Working Hours</MenuItem>
      )}

      {department === Department.sales && (
        <MenuItem
          onClick={() => {
            history.push({hash: 'sales-campaigns'});
            hideProfileMenu();
          }}
        >
          Sales Campaigns
        </MenuItem>
      )}

      {canSeeSubscriptionManagement && (
        <MenuItem
          onClick={() => {
            history.push({hash: 'subscription-management'});
            hideProfileMenu();
          }}
        >
          Email Management
        </MenuItem>
      )}

      {authProfile?.data?.role === UserRoles.admin && (
        <MenuItem
          onClick={() => {
            history.push(ROUTES.UTILITIES);
            hideProfileMenu();
          }}
        >
          Super Admin Utilities
        </MenuItem>
      )}

      <MenuItem onClick={onLogout}>Logout</MenuItem>
    </Menu>
  );

  return {ProfileMenu, showProfileMenu: setAnchorEl, hideProfileMenu};
};

const SubscriptionManagementDialog = withDialog(SubscriptionManagement, '#subscription-management');
const SalesCampaignsDialog = withDialog(SalesCampaigns, '#sales-campaigns');

const Header = (props: {onDrawerToggle: MouseEventHandler}) => {
  const classes = useStyles();
  const {claims} = useAuthContainer();
  const history = useHistory();
  // we use location object here because Header is out of Switch now and we cannot track location change from history
  const location = useLocation();
  const isTicketsSection = matchPath(location.pathname, {path: ROUTES.TICKETS});
  const isTicketsSearchVisible = isTicketsSection && (claims ? hasOzarkRole(claims.role) : true);

  const {logout, authProfile, impersonateRole, allowImpersonation} = useStore();
  const {ProfileMenu, showProfileMenu} = useProfileMenu({handleLogout: logout});
  const {onDrawerToggle} = props;

  const {agents} = useAgents();
  const {siteJumpAsUser} = useSiteJumpAsUser();

  const handleProfileClick: MouseEventHandler<HTMLButtonElement> = event => {
    showProfileMenu(event.currentTarget);
  };

  const loginPortalAsAgent = (uid: string | 'null') => {
    if (uid === 'null') {
      return;
    }

    siteJumpAsUser({uid});
  };

  const handleImpostorRoleChange = (event: any) => {
    impersonateRole(event.target.value);
    history.push(ROUTES.DASHBOARD);
  };

  const applicationAutocompleteOptionConfig = useMemo<
    EntityAutocompleteOptionConfig<IndexableApplication>
  >(
    () => ({
      getLinkRoute: (option: Option<IndexableApplication>) => {
        if (!option || !option.id) {
          return null;
        }

        if (authProfile.data?.role === UserRoles.auditor) {
          return {
            pathname: ROUTES.AUDIT,
            state: {applicationId: option.id},
          };
        }

        const searchGroup = getSearchGroup(option.disposition) as string;

        return {
          pathname: `/${searchGroup.toLowerCase()}/${option.id}`,
          state: {
            referrer: searchGroup,
          },
        };
      },

      pathsToLiftUpSearchResult: [
        ROUTES.AUDIT,
        ...Object.values(Dispositions).map(
          disposition => `/${getSearchGroup(disposition)!.toLowerCase()}`
        ),
      ],

      render: option => <ApplicationOption option={option} isERP />,

      renderGroupHeader: props => <ApplicationOptionHeader group={props.group} isERP />,
    }),
    [authProfile.data?.role] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const leadAutocompleteOptionConfig = useMemo<EntityAutocompleteOptionConfig<IndexableLead>>(
    () => ({
      getLinkRoute: (option: Option<IndexableLead>) => {
        // to avoid click on headers
        if (!option || !option.id) {
          return null;
        }

        return generatePath(ROUTES.SALES_LEADS_DETAILS, {leadId: option.id});
      },

      pathsToLiftUpSearchResult: [
        ROUTES.SALES_LEADS,
        // we avoid lead details page here: details page is a sub-route of list page
      ],

      render: option => <LeadOption option={option} />,
    }),
    [authProfile.data?.role] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleSearchChange = (option: TicketSearchItemModel | null) => {
    if (!option) return;

    const {id} = option as TicketSearchItemModel;

    id && history.push(`${ROUTES.TICKETS}/id/${id}`);
  };

  const handleRenderOption = useCallback(
    (option: TicketSearchItemModel, options?: Record<string, any>) => {
      if (!option.id) {
        return (
          <TicketSearchItemHeader
            ticketSearchItem={option}
            wideSubject={!!options?.wideTicketSubject}
          />
        );
      }

      return (
        <TicketSearchItem
          key={option.id}
          ticketSearchItem={option}
          wideSubject={!!options?.wideTicketSubject}
          isErpUser={true}
        />
      );
    },
    []
  );

  return (
    <>
      <AppBar className={classes.appBar} position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs>
              {isTicketsSearchVisible ? (
                <Search onChange={handleSearchChange} renderOption={handleRenderOption} />
              ) : (
                <GlobalSearch
                  applicationAutocompleteOptionConfig={applicationAutocompleteOptionConfig}
                  leadAutocompleteOptionConfig={leadAutocompleteOptionConfig}
                />
              )}
            </Grid>
            <Grid item>
              <Hidden lgDown>
                <div className={classes.actions}>
                  {/*
                  TODO: wire these up
                  <Divider orientation="vertical" flexItem />
                  <Tooltip title="Settings">
                    <IconButton color="inherit">
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                  */}
                  {['ozark-staging', 'getevolved-staging'].includes(
                    process.env.REACT_APP_FIREBASE_PROJECT_ID ?? ''
                  ) && (
                    <>
                      <div className={classes.stagingWarning}>
                        <WarningIcon />
                        <Typography>Staging Environment</Typography>
                      </div>
                      <Divider orientation="vertical" flexItem />
                    </>
                  )}

                  {allowImpersonation && (
                    <>
                      <TextField
                        onChange={handleImpostorRoleChange}
                        value={authProfile.data?.role}
                        variant="standard"
                        InputProps={{
                          classes: {
                            input: classes.impostorInput,
                          },
                          startAdornment: (
                            <Tooltip title="Selecting a role to impersonate will allow you to view the ERP as a user of the selected role. This feature is only available to Administrators.">
                              <InputAdornment position="start">
                                <ImposterIcon />
                              </InputAdornment>
                            </Tooltip>
                          ),
                          disableUnderline: true,
                        }}
                        select
                      >
                        {[...OzarkUserRoles, UserRoles.auditor].sortAndMap(e => (
                          <MenuItem key={e} value={e}>
                            {e}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Divider orientation="vertical" flexItem />
                    </>
                  )}

                  {!!authProfile.data?.associatedAgents &&
                    authProfile.data.associatedAgents.length > 0 && (
                      <>
                        <TextField
                          onChange={event => loginPortalAsAgent(event.target.value)}
                          value="null"
                          variant="standard"
                          InputProps={{
                            classes: {
                              input: classes.impostorInput,
                            },
                            disableUnderline: true,
                          }}
                          select
                        >
                          <MenuItem disabled value={'null'}>
                            Jump to Portal as Agent
                          </MenuItem>

                          {authProfile.data.associatedAgents.sortAndMap(
                            (agentUid: string) => {
                              const agent = agents.data?.find(x => x.id === agentUid);

                              const agentName = agent
                                ? `${agent.firstName} ${agent.lastName} (${
                                    agent.group?.name ?? '-'
                                  })`
                                : `Agent ID: ${agentUid}`;

                              return (
                                <MenuItem key={agentUid} value={agentUid} sx={{maxWidth: 400}}>
                                  <BoxEllipsis title={agentName}>{agentName}</BoxEllipsis>
                                </MenuItem>
                              );
                            },
                            (agentUid: string) => {
                              const agent = agents.data?.find(x => x.id === agentUid);
                              const agentName = agent
                                ? `${agent.firstName} ${agent.lastName} (${
                                    agent.group?.name ?? '-'
                                  })`
                                : `Agent ID: ${agentUid}`;
                              return agentName;
                            }
                          )}
                        </TextField>

                        <Divider orientation="vertical" flexItem />
                      </>
                    )}

                  {authProfile.data?.role !== UserRoles.auditor && (
                    <>
                      <Notifications /> <Divider orientation="vertical" flexItem />
                    </>
                  )}

                  <GreetingMessage profile={authProfile?.data} />
                  <IconButton
                    color="inherit"
                    className={classes.iconButtonAvatar}
                    onClick={handleProfileClick}
                    size="large"
                  >
                    <Avatar src={authProfile.data?.photoUrl} className={classes.avatar} alt="Me" />
                  </IconButton>
                  <ProfileMenu />
                </div>
              </Hidden>
              <Hidden mdUp>
                <div className={classes.actions}>
                  <IconButton
                    color="inherit"
                    className={classes.iconButtonAvatar}
                    onClick={handleProfileClick}
                    size="large"
                  >
                    <Avatar src={authProfile.data?.photoUrl} className={classes.avatar} alt="Me" />
                  </IconButton>
                  <ProfileMenu />
                </div>
              </Hidden>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <SalesCampaignsDialog fullWidth maxWidth="md" title="Sales Campaign" />

      <SubscriptionManagementDialog
        fullWidth
        maxWidth="md"
        title="Email Management"
        subTitle="The place you can manage your email and web notifications"
      />
    </>
  );
};

export default Header;
