import {LoadingButton} from '@mui/lab';
import {AttachmentMetadata, getAttachmentsListWithMetadata} from '@ozark/common';
import {ChangeEventHandler, InputHTMLAttributes, useState} from 'react';

type Props<T extends {cloudPath: string; name: string}> = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onChange'
> & {
  uploadFile: (file: File) => Promise<T>;
  onChange: (attachments: (T & AttachmentMetadata)[]) => void;
};

export const AttachmentInput = <T extends {cloudPath: string; name: string}>({
  uploadFile,
  onChange,
  ...props
}: Props<T>) => {
  const [uploading, setUploading] = useState(false);

  const onInputChange: ChangeEventHandler<HTMLInputElement> = async event => {
    const {files} = event.target;

    if (!files?.length) {
      return;
    }

    setUploading(true);

    try {
      const uploadedFiles = await Promise.all(Array.from(files).map(file => uploadFile(file)));
      const uploadedFilesWithMetadata = await getAttachmentsListWithMetadata(uploadedFiles);
      onChange(uploadedFilesWithMetadata);
    } catch (error) {
      console.error('Failed to upload file to storage with an error:', error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <LoadingButton loading={uploading} variant="outlined" component="label" sx={{mb: 2}}>
      Add Attachments
      <input type="file" hidden multiple {...props} onChange={onInputChange} />
    </LoadingButton>
  );
};
