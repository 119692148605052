import ContactPageIcon from '@mui/icons-material/ContactPage';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import GroupIcon from '@mui/icons-material/Group';
import LabelIcon from '@mui/icons-material/Label';
import WarningIcon from '@mui/icons-material/Warning';
import {Box, CardContent, Tooltip, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {TransactionsPendingIcon} from '@ozark/common/icons';
import React from 'react';
import {RiskOverviewItemButtons} from './Buttons';
import {
  getFilterableValueTooltipTitle,
  IconWrapper,
  NotAvailableText,
  renderAccountStatus,
  renderApprovalInformation,
  renderFirstBatchDateAndAmount,
  renderGroupAgentBankInfo,
  renderLastBatchDateAndAmount,
  renderMerchantInformation,
  renderRiskAssignmentInfo,
  renderRiskLabels,
  RiskOverviewItemProps,
  RiskStatusValue,
  RiskStatusValueButton,
} from './common';

const Column = styled('span')(({theme}) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  minHeight: '100%',
  padding: theme.spacing(0, 1, 0, 1),
  overflow: 'hidden',
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
}));

const ColumnContent = styled(Box)(() => ({
  width: 'calc(100% - 31px)',
  maxWidth: '100%',
  flexGrow: 1,
}));

export const CardViewItemContent = ({
  data,
  assigneeProfile,
  onFilterableValueClick,
  onAssociatedApplicationsOpen,
  onRemoveAssociation,
  hideViewDetailsButton,
  shouldOpenDetailsInNewTab,
}: RiskOverviewItemProps) => {
  const getFilterableValueClickHandler = onFilterableValueClick
    ? (key: string, value: string) => (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        onFilterableValueClick(key, value);
      }
    : undefined;

  return (
    <CardContent
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: {xs: 'column', lg: 'row'},
        flexWrap: {xs: 'wrap', xl: 'nowrap'},
        pr: 3,
        pl: 1,
      }}
    >
      <Box
        sx={{
          mr: -2,
          height: '100%',
          width: '100%',
          display: 'grid',
          gridTemplateColumns: {xs: '1fr repeat(4, 2fr)', xl: '1fr repeat(4, 2fr) 3fr'},
        }}
      >
        <Column>
          <ColumnContent sx={{display: 'flex', flexDirection: 'column'}}>
            {renderRiskAssignmentInfo(data, assigneeProfile)}
          </ColumnContent>
        </Column>

        <Column>
          <IconWrapper>
            <GroupIcon color="inherit" />
          </IconWrapper>
          <ColumnContent>
            <Typography variant="subtitle2" noWrap gutterBottom sx={{pt: 0.25}}>
              Group/Agent/Processing Bank
            </Typography>

            {renderGroupAgentBankInfo(data, getFilterableValueClickHandler)}
          </ColumnContent>
        </Column>

        <Column>
          <IconWrapper>
            <DomainVerificationIcon color="inherit" />
          </IconWrapper>
          <ColumnContent>
            <Typography variant="subtitle2" noWrap gutterBottom sx={{pt: 0.25}}>
              Approval Information
            </Typography>
            {renderApprovalInformation(data, getFilterableValueClickHandler)}
          </ColumnContent>
        </Column>

        <Column>
          <IconWrapper>
            <ContactPhoneIcon color="inherit" />
          </IconWrapper>
          <ColumnContent>
            <Typography variant="subtitle2" noWrap gutterBottom sx={{pt: 0.25}}>
              Merchant Information
            </Typography>
            {renderMerchantInformation(data, getFilterableValueClickHandler)}
          </ColumnContent>
        </Column>

        <Column sx={{pr: 0, borderRight: {xs: 'none', xl: '1px solid rgba(0, 0, 0, 0.12)'}}}>
          <ColumnContent>
            <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
              <Box sx={{display: 'flex', minWidth: '50%', pr: 2, mb: 2}}>
                <IconWrapper>
                  <TransactionsPendingIcon color="inherit" />
                </IconWrapper>
                <ColumnContent>
                  <Typography variant="subtitle2" noWrap gutterBottom sx={{pt: 0.25}}>
                    First Batch Date/Amount
                  </Typography>
                  {renderFirstBatchDateAndAmount(data, getFilterableValueClickHandler)}

                  {!data.firstBatchDate && NotAvailableText}
                </ColumnContent>
              </Box>
              <Box sx={{display: 'flex', minWidth: '50%'}}>
                <IconWrapper>
                  <TransactionsPendingIcon color="inherit" />
                </IconWrapper>
                <ColumnContent>
                  <Typography variant="subtitle2" noWrap gutterBottom sx={{pt: 0.25}}>
                    Last Batch Date/Amount
                  </Typography>
                  {renderLastBatchDateAndAmount(data, getFilterableValueClickHandler)}

                  {!data.lastBatchDate && NotAvailableText}
                </ColumnContent>
              </Box>
            </Box>
          </ColumnContent>
        </Column>

        <Box
          sx={{
            px: {xl: 1, xs: 0},
            py: {xl: 0, xs: 1.5},
            gridColumn: {xs: '1 / 4', xl: 'unset'},
          }}
        >
          <Box sx={{display: 'flex', mb: 1.5}}>
            <IconWrapper>
              <ContactPageIcon color="inherit" />
            </IconWrapper>
            <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
              <Typography
                variant="subtitle2"
                component="span"
                sx={{display: 'inline-block', pt: 0.25, mr: 0.5}}
              >
                Account Status:
              </Typography>
              {data.accountStatus?.status && (
                <Box component="span" sx={{display: 'inline-block'}}>
                  {renderAccountStatus(data, getFilterableValueClickHandler)}

                  {data.accountStatus.subCategory && (
                    <Typography variant="body1" sx={{display: 'inline-block'}}>
                      ({data.accountStatus.subCategory})
                    </Typography>
                  )}
                </Box>
              )}

              {!data.accountStatus?.status && NotAvailableText}
            </Box>
          </Box>
          <Box sx={{display: 'flex', mb: 1.5}}>
            <IconWrapper>
              <WarningIcon color="inherit" />
            </IconWrapper>
            <Box sx={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
              <Typography variant="subtitle2" sx={{pt: 0.25, pr: 0.5}}>
                Risk Status:
              </Typography>
              {data.riskStatus && (
                <>
                  {getFilterableValueClickHandler ? (
                    <Tooltip
                      title={getFilterableValueTooltipTitle(data.riskStatus)}
                      disableInteractive
                    >
                      <RiskStatusValueButton
                        sx={{
                          WebkitLineClamp: '1',
                        }}
                        onClick={getFilterableValueClickHandler('riskStatus', data.riskStatus)}
                      >
                        {data.riskStatus}
                      </RiskStatusValueButton>
                    </Tooltip>
                  ) : (
                    <RiskStatusValue
                      title={data.riskStatus}
                      sx={{
                        WebkitLineClamp: '1',
                      }}
                    >
                      {data.riskStatus}
                    </RiskStatusValue>
                  )}
                </>
              )}
            </Box>
          </Box>
          <Box sx={{display: 'flex'}}>
            <IconWrapper>
              <LabelIcon sx={{mt: 0.5}} color="inherit" />
            </IconWrapper>
            <Box display="flex" flexWrap="wrap" alignItems="center">
              <Typography variant="subtitle2" sx={{mb: 0.5, mr: 1, pt: 0.75}}>
                Risk Labels:
              </Typography>
              <Box>{renderRiskLabels(data, getFilterableValueClickHandler)}</Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: {xs: 'row', xl: 'column'},
          alignItems: {xs: 'flex-start', xl: 'flex-end'},
          flexWrap: 'wrap',
          flexShrink: 0,
          width: {xl: 150},
          m: -0.5,
          ml: {xs: -0.5, xl: 1},
          '& > .MuiButton-root': {
            m: 0.5,
            width: {xl: '100%'},
          },
          '& > a': {
            m: 0.5,
            width: {xl: '100%'},
          },
        }}
      >
        <RiskOverviewItemButtons
          data={data}
          onAssociatedApplicationsOpen={onAssociatedApplicationsOpen}
          onRemoveAssociation={onRemoveAssociation}
          hideViewDetailsButton={hideViewDetailsButton}
          shouldOpenDetailsInNewTab={shouldOpenDetailsInNewTab}
        />
      </Box>
    </CardContent>
  );
};
