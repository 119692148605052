import {Box, Divider, Grid, Paper, Typography} from '@mui/material';
import {CalendarEventNewButton} from '@ozark/common/components';
import {useSalesLeadId} from '../../../../hooks/useSalesLeadId';
import {CalendarEventsInfo} from './CalendarEventsInfo';

export const ScheduledEvents = () => {
  const {leadId} = useSalesLeadId();
  return (
    <Paper sx={{height: '100%'}}>
      <Box sx={{mt: 0, p: [1, 2, 2]}}>
        <Grid container>
          <Grid item xs={12}>
            <Box>
              <Typography sx={{fontSize: '1.2rem'}} variant="caption" gutterBottom>
                Calendar
              </Typography>
              <Divider />
            </Box>
            <Box>
              <CalendarEventsInfo />
              <CalendarEventNewButton
                title="New Event"
                type="Event"
                association={{id: leadId, type: 'Lead'}}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
