import {Box, Typography} from '@mui/material';
import {Group, SubmittedPageSettings} from '@ozark/common';
import {TextField, TextFieldRichEditor} from '@ozark/common/components';
import {useEffect} from 'react';
import {UseFormReturn} from 'react-hook-form';
import * as yup from 'yup';

export const SubmittedPageSettingsSchema = yup.object().shape({
  title: yup.string(),
  subTitle: yup.string(),
  footerText: yup.string(),
});

type Props = {
  group: Group;
  hookForm: UseFormReturn<SubmittedPageSettings>;
  readOnly?: boolean;
};

export const Form = ({group, hookForm, readOnly}: Props) => {
  const {
    formState: {errors},
    control,
    reset,
  } = hookForm;

  useEffect(() => {
    if (group) {
      const {title, subTitle, footerText} = group.applicationSettings?.submittedPage ?? {};

      reset({
        title,
        subTitle,
        footerText,
      });
    }
  }, [group, reset]);

  return (
    <>
      <TextField
        name="title"
        label="Title"
        errors={errors}
        control={control}
        sx={{mb: 2}}
        disabled={readOnly}
      />

      <Box sx={{mb: 2}}>
        <Typography variant="body1" gutterBottom>
          Main content
        </Typography>

        <TextFieldRichEditor
          name="subTitle"
          label="Main content"
          errors={errors}
          control={control}
          enabled={!readOnly}
        />
      </Box>

      <Typography variant="body1" gutterBottom>
        Footer content
      </Typography>

      <TextFieldRichEditor
        name="footerText"
        label="Footer content"
        errors={errors}
        control={control}
        enabled={!readOnly}
      />
    </>
  );
};
