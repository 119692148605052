import {DocumentBase} from './DocumentBase';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type Dictionary = DocumentBase & {
  values: string[];
};

export type DictionaryView = ViewBase<Dictionary>;

export const selectDictionaryView: ViewSelector<DictionaryView, Dictionary> = snapshot => {
  const dictionary: DictionaryView = {
    ...defaultViewSelector(snapshot),
  };
  return dictionary;
};
