import {DirectionsService, GoogleMap, useJsApiLoader} from '@react-google-maps/api';
import {isEqual} from '@s-libs/micro-dash';
import React, {memo} from 'react';

const containerStyle = {
  width: 'auto',
  height: '250px',
};

type Props = {
  startIpInfo: {lat: number; lng: number};
  businessLocation?: {lat: number; lng: number};
  onDistance: (miles: number) => void;
};

const Map = ({startIpInfo, businessLocation, onDistance}: Props) => {
  const {isLoaded, loadError} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY || '',
    libraries: ['places'],
  });

  const MapWithDirections = () => {
    // wrapping to a function is needed in order to make sure `window.google`
    // is available after script is loaded.
    const mapOnLoad = React.useCallback((map: google.maps.Map) => {
      const startMarker = new google.maps.Marker({
        position: startIpInfo,
        title: 'Application Started Location',
      });
      startMarker.setMap(map);

      if (businessLocation) {
        const businessMarker = new google.maps.Marker({
          position: businessLocation,
          title: 'Business Location',
        });
        businessMarker.setMap(map);

        const bounds = new google.maps.LatLngBounds();

        const latLngList = [
          new google.maps.LatLng(startIpInfo.lat, startIpInfo.lng),
          new google.maps.LatLng(businessLocation.lat, businessLocation.lng),
        ];

        latLngList.forEach(e => bounds.extend(e));

        map.setCenter(bounds.getCenter());
        map.fitBounds(bounds);
      }
    }, []);

    const onDirectionsCallback = React.useCallback(
      (result: google.maps.DirectionsResult | null, status: google.maps.DirectionsStatus) => {
        if (status === google.maps.DirectionsStatus.OK && result) {
          const {routes} = result;
          const {legs} = routes[0];
          const {distance} = legs[0];
          if (distance) {
            const {value: meters} = distance;
            const miles = meters / 1609.344;
            onDistance(miles);
          }
        }
      },
      []
    );

    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={startIpInfo}
        zoom={12}
        onLoad={mapOnLoad}
        options={{disableDefaultUI: true}}
      >
        {businessLocation && (
          <DirectionsService
            options={{
              travelMode: window.google.maps.TravelMode.DRIVING,
              origin: new window.google.maps.LatLng(startIpInfo),
              destination: new window.google.maps.LatLng(businessLocation),
            }}
            callback={onDirectionsCallback}
          />
        )}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? <MapWithDirections /> : <></>;
};

export default memo(Map, isEqual);
