import {MenuItem, TextField, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {useUrlQuery} from '@ozark/common/hooks/useUrlQuery';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';

export const GROUP_STATUS = 'groupStatus';
export const GROUP_STATUS_ALL = 'all';
export const GROUP_STATUS_INACTIVE_ONY = 'inactiveOnly';
export const GROUP_STATUS_ACTIVE_ONY = 'activeOnly';

const useStyles = makeStyles(() =>
  createStyles({
    selectInput: {
      backgroundColor: 'transparent !important',
    },
  })
);

export const InactiveGroupsDropdown = () => {
  const classes = useStyles();
  const history = useHistory();
  const search = useUrlQuery();
  const [groupsStatus, setGroupsStatus] = useState<string>(GROUP_STATUS_ACTIVE_ONY);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const status = event.target.value as string;
    search.set(GROUP_STATUS, status);
    setGroupsStatus(status);
    history.push(`${history.location.pathname}?${search}`);
  };

  return (
    <TextField
      value={groupsStatus}
      onChange={handleOnChange}
      variant="standard"
      sx={{width: '130px', backgroundColor: 'transparent !important'}}
      InputProps={{
        classes: {
          input: classes.selectInput,
        },
        disableUnderline: true,
      }}
      select
    >
      <MenuItem key={GROUP_STATUS_ACTIVE_ONY} value={GROUP_STATUS_ACTIVE_ONY}>
        <Typography>Active</Typography>
      </MenuItem>
      <MenuItem key={GROUP_STATUS_INACTIVE_ONY} value={GROUP_STATUS_INACTIVE_ONY}>
        <Typography>Inactive</Typography>
      </MenuItem>
      <MenuItem key={GROUP_STATUS_ALL} value={GROUP_STATUS_ALL}>
        <Typography>All</Typography>
      </MenuItem>
    </TextField>
  );
};
