import {AppBar, Divider, Paper, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {BoxEllipsis} from '@ozark/common/components/common/BoxEllipsis';

const Column = styled('span')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  height: '100%',
  flexBasis: '9%',
  minWidth: '110px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const RiskOverviewHeader = () => {
  return (
    <AppBar
      sx={{
        position: 'sticky',
        margin: theme => theme.spacing(0, 0, 2, 0),
        minWidth: '1570px',
      }}
      elevation={0}
    >
      <Paper
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          color: '#4d6575',
          padding: 2,
          paddingLeft: 3,
          width: '100%',
          transition: theme => theme.transitions.create(['padding', 'border-color', 'box-shadow']),
          duration: theme => theme.transitions.duration.complex,
          textAlign: 'center',
        }}
      >
        <Column>
          <BoxEllipsis title="Group/Agent/Processing Bank">
            <Typography variant="body1" noWrap>
              Risk Assignment
            </Typography>
          </BoxEllipsis>
        </Column>
        <Divider orientation="vertical" flexItem />

        <Column>
          <BoxEllipsis title="Group/Agent/Processing Bank">
            <Typography variant="body1" noWrap>
              Group/Agent/Processing Bank
            </Typography>
          </BoxEllipsis>
        </Column>
        <Divider orientation="vertical" flexItem />

        <Column>
          <BoxEllipsis title="Approval Information">
            <Typography variant="body1" noWrap>
              Approval Information
            </Typography>
          </BoxEllipsis>
        </Column>
        <Divider orientation="vertical" flexItem />

        <Column>
          <BoxEllipsis title="Merchant Information">
            <Typography variant="body1" noWrap>
              Merchant Information
            </Typography>
          </BoxEllipsis>
        </Column>
        <Divider orientation="vertical" flexItem />
        <Column>
          <BoxEllipsis title="First Batch Date/Amount">
            <Typography variant="body1" noWrap>
              First Batch Date/Amount
            </Typography>
          </BoxEllipsis>
        </Column>
        <Divider orientation="vertical" flexItem />

        <Column>
          <BoxEllipsis title="Last Batch Date/Amount">
            <Typography variant="body1" noWrap>
              Last Batch Date/Amount
            </Typography>
          </BoxEllipsis>
        </Column>
        <Divider orientation="vertical" flexItem />

        <Column>
          <BoxEllipsis title="Account Status">
            <Typography variant="body1" noWrap>
              Account Status
            </Typography>
          </BoxEllipsis>
        </Column>
        <Divider orientation="vertical" flexItem />

        <Column>
          <BoxEllipsis title="Risk Status">
            <Typography variant="body1" noWrap>
              Risk Status
            </Typography>
          </BoxEllipsis>
        </Column>
        <Divider orientation="vertical" flexItem />

        <Column>
          <BoxEllipsis title="Risk Labels">
            <Typography variant="body1" noWrap>
              Risk Labels
            </Typography>
          </BoxEllipsis>
        </Column>
        <Divider orientation="vertical" flexItem />
        <Column />
      </Paper>
    </AppBar>
  );
};
