import {Avatar, Box, Chip, Fade, ListSubheader, Menu, MenuItem, Tooltip} from '@mui/material';
import {ProfileView} from '@ozark/common';
import React, {Fragment} from 'react';
import {useStore} from '../../../store/helpers';

type Props = {
  assigneeId?: string | null;
  updateAssignee: (assigneeId: string | null) => void;
  tooltipTitle?: string;
  options: {groupName: string; profiles: ProfileView[]}[];
  readOnly?: boolean;
};

export const AssigneeSelector = ({
  assigneeId,
  updateAssignee,
  tooltipTitle,
  options,
  readOnly,
}: Props) => {
  const {profiles} = useStore();
  const assignee = assigneeId ? profiles.dictionary[assigneeId as string] : null;

  const [userMenuEl, setUserMenuEl] = React.useState<null | HTMLElement>(null);

  const handleOpenUserAssignMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setUserMenuEl(event.currentTarget);
  };

  const handleUserSelect = async (userId: string | null) => {
    setUserMenuEl(null);
    updateAssignee(userId);
  };

  return (
    <>
      <Tooltip title={tooltipTitle ?? ''}>
        <Chip
          avatar={
            assignee ? (
              <Avatar alt={assignee.displayName} src={assignee.photoUrl} />
            ) : (
              <Avatar>?</Avatar>
            )
          }
          label={assignee?.displayName ?? 'Unassigned'}
          variant="outlined"
          onClick={readOnly ? undefined : handleOpenUserAssignMenu}
        />
      </Tooltip>

      <Menu
        id="user-menu"
        anchorEl={userMenuEl}
        onClose={() => setUserMenuEl(null)}
        keepMounted
        open={Boolean(userMenuEl)}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        TransitionComponent={Fade}
        PaperProps={{
          sx: {
            overflow: 'visible',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        <Box sx={{maxHeight: '500px', overflow: 'auto'}}>
          <MenuItem value={''} onClick={() => handleUserSelect(null)}>
            <Avatar>?</Avatar> Unassigned
          </MenuItem>
          {options.map(item => {
            if (!item.profiles?.length) {
              return null;
            }

            return (
              <Fragment key={item.groupName}>
                <ListSubheader component="div">{item.groupName}</ListSubheader>
                {item.profiles.map(e => (
                  <MenuItem key={e.uid} value={e.uid} onClick={() => handleUserSelect(e.uid)}>
                    <Avatar src={e.photoUrl} /> {e.displayName}
                  </MenuItem>
                ))}
              </Fragment>
            );
          })}
        </Box>
      </Menu>
    </>
  );
};
