import {
  AsyncState,
  BoardingTask,
  BoardingTaskView,
  Collections,
  Firebase,
  selectBoardingTaskView,
  UniversalSnapshot,
} from '@ozark/common';
import {useEffect, useState} from 'react';

export const useBoardingTasks = (applicationId: string) => {
  const [boardingTasks, setBoardingTasks] = useState<AsyncState<BoardingTaskView[]>>({
    promised: true,
  });
  useEffect(() => {
    const unsubscribe = Firebase.firestore
      .collection(Collections.applications)
      .doc(applicationId)
      .collection(Collections.boardingTasks)
      .orderBy('createdAt', 'desc')
      .onSnapshot(
        async snapshot => {
          if (snapshot.size === 0) {
            setBoardingTasks({promised: false});
            return;
          }

          const boardingTasks = snapshot.docs.map(doc =>
            selectBoardingTaskView(doc as UniversalSnapshot<BoardingTask>)
          );

          setBoardingTasks({promised: false, data: boardingTasks});
        },
        err => {
          console.error(err);
          setBoardingTasks({promised: false, error: err});
        }
      );
    return () => {
      unsubscribe();
    };
  }, [applicationId, setBoardingTasks]);

  return {boardingTasks};
};
