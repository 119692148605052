import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {IconButton, Link, Theme, useMediaQuery, useTheme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ComingSoon, Loading, Title} from '@ozark/common/components';
import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useStore} from '../../store/helpers';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    primaryColor: {
      color: theme.palette.primary.main,
      marginTop: theme.spacing(4),
    },
  })
);

const BlankPage = () => {
  const {authUser} = useStore();
  const history = useHistory();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('lg'));

  const location = useLocation();
  const [path, setPath] = React.useState('');

  React.useEffect(() => {
    const e = location.pathname;
    setPath(e);
  }, [location.pathname]);

  if (!authUser.data) return <Loading />;
  return (
    <div>
      <Title
        breadcrumbs={
          smDown
            ? [
                <IconButton onClick={() => history.goBack()} size="large">
                  <ChevronLeftIcon />
                </IconButton>,
              ]
            : path
                .split('/')
                .slice(1)
                .map((segment, _index) => (
                  <Link
                    component="button"
                    variant="body1"
                    style={{textTransform: 'capitalize'}}
                    onClick={() => {}}
                  >
                    {segment.replace('-', ' ')}
                  </Link>
                ))
        }
      />
      <ComingSoon />
    </div>
  );
};

export default BlankPage;
