import {useUserInfo} from '@ozark/common';
import UserEdit from '../Users/Edit';

const Profile = () => {
  const {uid} = useUserInfo();

  return <UserEdit uid={uid} />;
};

export default Profile;
