import {yupResolver} from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import {
  Collections,
  emptyStringToNull,
  Firebase,
  IMerchantSupportModel,
  useNotification,
} from '@ozark/common';
import PhoneNumber from 'awesome-phonenumber';
import {useEffect, useState} from 'react';
import {Control, Controller, SubmitHandler, useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import * as yup from 'yup';

const FIELD_NAME = 'merchantSupportModel';

const emailRegex = new RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const MerchantSupportModelValidationSchema = yup
  .object({
    customerPhoneNumber: yup
      .string()
      .required('Customer phone number is required')
      .transform(value => {
        const phone = value ? new PhoneNumber(value, 'US').getNumber('e164') ?? value : value;
        return phone;
      })
      .matches(/^\+1[2-9]{1}[0-9]{9}$/, 'Enter a valid phone number in e164 format (+18002333333)'),
    agentSupportEmail: yup
      .string()
      .required('Agent support email is required')
      .matches(emailRegex, 'Enter a valid email'),
    underwritingAgentNotificationsEmail: yup
      .string()
      .transform(emptyStringToNull)
      .nullable(true)
      .matches(emailRegex, 'Enter a valid email'),
    approvalAgentNotificationsEmail: yup
      .string()
      .transform(emptyStringToNull)
      .nullable(true)
      .matches(emailRegex, 'Enter a valid email'),
    ticketAgentNotificationsEmail: yup
      .string()
      .transform(emptyStringToNull)
      .nullable(true)
      .matches(emailRegex, 'Enter a valid email'),
    varAgentNotificationsEmail: yup
      .string()
      .transform(emptyStringToNull)
      .nullable(true)
      .matches(emailRegex, 'Enter a valid email'),
    riskAgentNotificationsEmail: yup
      .string()
      .transform(emptyStringToNull)
      .nullable(true)
      .matches(emailRegex, 'Enter a valid email'),
    disputesAgentNotificationsEmail: yup
      .string()
      .transform(emptyStringToNull)
      .nullable(true)
      .matches(emailRegex, 'Enter a valid email'),
  })
  .required();

export function MerchantSupportModel({isReadOnly = false}: {isReadOnly: boolean}) {
  const {id} = useParams<{id: string}>();
  const [isSaving, setIsSaving] = useState(false);
  const showNotification = useNotification();

  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<IMerchantSupportModel>({resolver: yupResolver(MerchantSupportModelValidationSchema)});

  const onSubmit: SubmitHandler<IMerchantSupportModel> = async data => {
    try {
      setIsSaving(true);
      await Firebase.firestore
        .collection(Collections.agents)
        .doc(id)
        .update({[FIELD_NAME]: data});
      showNotification('success', 'Merchant support model successfully saved');
    } catch (err) {
      console.error(err);
      showNotification('error', 'Merchant support model save failed');
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    const resetAgentForm = async () => {
      const agentSnap = await Firebase.firestore.collection(Collections.agents).doc(id).get();

      if (!agentSnap.exists) {
        return;
      }

      const agentMerchantSupportModel = agentSnap.get(FIELD_NAME) as
        | IMerchantSupportModel
        | undefined;
      if (agentMerchantSupportModel) {
        reset(agentMerchantSupportModel);
      }
    };

    resetAgentForm().catch(err => {
      showNotification('error', 'An error occurred while getting merchant support model');
      console.error('error fetching merchant support model', err);
    });
  }, [id, reset, showNotification]);

  return (
    <Paper
      sx={{
        mt: 2,
        px: 2,
        py: 3,
      }}
    >
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': {width: '100%'},
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="customerPhoneNumber"
              control={control}
              defaultValue=""
              render={({field}) => (
                <TextField
                  {...field}
                  required
                  disabled={isReadOnly}
                  type="tel"
                  label="Customer phone number"
                  error={Boolean(errors.customerPhoneNumber)}
                  helperText={
                    errors.customerPhoneNumber?.message ?? 'use e164 format (+18002333333)'
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <SwitchMerchantSupportModel
              isReadOnly={isReadOnly}
              control={control}
              name="whiteLabelPhoneNumberHostedByLuqra"
              label="White Label Phone Number Hosted by Luqra"
            />
          </Grid>

          <Grid item xs={12}>
            <SwitchMerchantSupportModel
              isReadOnly={isReadOnly}
              control={control}
              name="underwritingCommunicationDirectToMerchant"
              label="Underwriting Communication Direct to Merchant"
            />
          </Grid>

          <Grid item xs={12}>
            <SwitchMerchantSupportModel
              isReadOnly={isReadOnly}
              control={control}
              name="riskCommunicationDirectToMerchant"
              label="Risk Communication Direct to Merchant"
            />
          </Grid>

          <Grid item xs={12}>
            <SwitchMerchantSupportModel
              isReadOnly={isReadOnly}
              control={control}
              name="supportCommunicationDirectToMerchant"
              label="Support Communication Direct to Merchant"
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="agentSupportEmail"
              control={control}
              defaultValue=""
              render={({field}) => (
                <TextField
                  disabled={isReadOnly}
                  required
                  type="email"
                  label="Agent Support Email"
                  {...field}
                  error={Boolean(errors.agentSupportEmail)}
                  helperText={errors.agentSupportEmail?.message}
                />
              )}
            />
          </Grid>

          <Box component="fieldset" sx={{margin: 2}}>
            <legend>Agent Notifications Setting</legend>

            <Box
              sx={{
                fontSize: '14px',
                fontStyle: 'italic',
                marginBottom: '1em',
              }}
            >
              Agent email will be used by default
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="underwritingAgentNotificationsEmail"
                  control={control}
                  defaultValue=""
                  render={({field}) => (
                    <TextField
                      disabled={isReadOnly}
                      type="email"
                      label="Underwriting Agent Notifications Email"
                      {...field}
                      error={Boolean(errors.underwritingAgentNotificationsEmail)}
                      helperText={errors.underwritingAgentNotificationsEmail?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="approvalAgentNotificationsEmail"
                  control={control}
                  defaultValue=""
                  render={({field}) => (
                    <TextField
                      disabled={isReadOnly}
                      type="email"
                      label="Approval Agent Notifications Email"
                      {...field}
                      error={Boolean(errors.approvalAgentNotificationsEmail)}
                      helperText={errors.approvalAgentNotificationsEmail?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="ticketAgentNotificationsEmail"
                  control={control}
                  defaultValue=""
                  render={({field}) => (
                    <TextField
                      disabled={isReadOnly}
                      type="email"
                      label="Ticket Agent Notifications Email"
                      {...field}
                      error={Boolean(errors.ticketAgentNotificationsEmail)}
                      helperText={errors.ticketAgentNotificationsEmail?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="varAgentNotificationsEmail"
                  control={control}
                  defaultValue=""
                  render={({field}) => (
                    <TextField
                      disabled={isReadOnly}
                      type="email"
                      label="VAR Agent Notifications Email"
                      {...field}
                      error={Boolean(errors.varAgentNotificationsEmail)}
                      helperText={errors.varAgentNotificationsEmail?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="riskAgentNotificationsEmail"
                  control={control}
                  defaultValue=""
                  render={({field}) => (
                    <TextField
                      disabled={isReadOnly}
                      type="email"
                      label="Risk Agent Notifications Email"
                      {...field}
                      error={Boolean(errors.riskAgentNotificationsEmail)}
                      helperText={errors.riskAgentNotificationsEmail?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="disputesAgentNotificationsEmail"
                  control={control}
                  defaultValue=""
                  render={({field}) => (
                    <TextField
                      disabled={isReadOnly}
                      type="email"
                      label="Disputes Agent Notifications Email"
                      {...field}
                      error={Boolean(errors.disputesAgentNotificationsEmail)}
                      helperText={errors.disputesAgentNotificationsEmail?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Box textAlign="right" pt={2}>
          <Button type="submit" variant="contained" disabled={isSaving || isReadOnly}>
            Save
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}

type SwitchMerchantSupportModelProps = {
  control: Control<IMerchantSupportModel, any>;
  name: string;
  label: string;
  isReadOnly?: boolean;
};

const SwitchMerchantSupportModel = ({
  control,
  name,
  label,
  isReadOnly = false,
}: SwitchMerchantSupportModelProps) => {
  return (
    <Controller
      name={name as keyof IMerchantSupportModel}
      control={control}
      defaultValue={false}
      render={({field: {value, ...rest}}) => (
        <FormControlLabel
          control={
            <Switch {...rest} disabled={isReadOnly} checked={value as boolean | undefined} />
          }
          label={label}
        />
      )}
    />
  );
};
