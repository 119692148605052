import {DocumentBase} from '.';
import {Overwrite} from '../helpers';
import {UniversalTimestamp} from './compat';
import {ScheduleAFeeRiskModel} from './ScheduleA';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type FeeModifier = DocumentBase & {
  operator: 'increase' | 'reduce';
  feeName: keyof ScheduleAFeeRiskModel;
  symbol: 'dollars' | 'percent';
  amount: number;
  active: boolean;
  createdAt: UniversalTimestamp;
};

export type FeeModifierView = ViewBase<FeeModifier>;

export type FeeModifierInput = Overwrite<FeeModifier, {amount: string; createdAt: Date}>;

export const selectFeeModifierView: ViewSelector<FeeModifierView, FeeModifier> = snapshot => {
  const feeModifier: FeeModifierView = {
    ...defaultViewSelector(snapshot),
  };
  return feeModifier;
};
