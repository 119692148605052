import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ErrorIcon from '@mui/icons-material/Error';
import {BottomNavigation, BottomNavigationAction, Popover} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {BoardingTaskView} from '@ozark/common';
import React from 'react';
import ReactJson from 'react-json-view';
// @ts-ignore
import XMLViewer from 'react-xml-viewer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: 800,
      maxHeight: 600,
      padding: theme.spacing(2),
      overflow: 'scroll',
    },
  })
);

type BoardingTaskDetailsProps = {
  anchorElement: HTMLDivElement | null;
  onClose: () => void;
  boardingTaskView: BoardingTaskView | null;
};

export const BoardingTaskDetails = ({
  anchorElement,
  onClose,
  boardingTaskView,
}: BoardingTaskDetailsProps) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);
  if (!boardingTaskView) return null;
  return (
    <Popover
      open={Boolean(anchorElement)}
      anchorEl={anchorElement}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <div className={classes.content}>
        {tabValue === 0 && <XMLViewer xml={boardingTaskView.rawRequest} overflowBreak={false} />}
        {tabValue === 1 && (
          <ReactJson name={boardingTaskView.id} src={boardingTaskView.result} sortKeys />
        )}
        {tabValue === 2 && boardingTaskView.error}
      </div>
      <BottomNavigation
        value={tabValue}
        onChange={(_event, newValue) => {
          setTabValue(newValue);
        }}
        showLabels
      >
        <BottomNavigationAction label="SOAP Request" icon={<ArrowUpwardIcon />} />
        <BottomNavigationAction label="JSON Response" icon={<ArrowDownwardIcon />} />
        {boardingTaskView.error && <BottomNavigationAction label="Error" icon={<ErrorIcon />} />}
      </BottomNavigation>
    </Popover>
  );
};
