import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {IconButton, Link, useMediaQuery, useTheme} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {EquipmentList, Loading, Title} from '@ozark/common/components';
import {useEquipment} from '@ozark/common/hooks/useEquipment';
import React, {useState} from 'react';
import {useHistory} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {Tab, Tabs} from '../Tabs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(1),
    },
  })
);

const Equipment = () => {
  const classes = useStyles();
  const history = useHistory<{referrer: string}>();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('lg'));
  const [tab, setTab] = useState(0);
  const {equipment} = useEquipment();

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    setTab(newTab);
  };

  if (equipment.promised || !equipment.data) return <Loading />;

  return (
    <div className={classes.root}>
      <Title
        breadcrumbs={
          smDown
            ? [
                <IconButton onClick={() => history.goBack()} size="large">
                  <ChevronLeftIcon />
                </IconButton>,
              ]
            : [
                <Link
                  component="button"
                  variant="body1"
                  onClick={() => history.push(ROUTES.EQUIPMENT)}
                >
                  Equpment
                </Link>,
              ]
        }
      >
        <Tabs value={tab} onChange={handleTabChange} centered>
          {/* TODO: managed at the group level: <Tab label="Group Equipment" /> */}
          <Tab label="Equipment" />
        </Tabs>
      </Title>
      {tab === 0 && <EquipmentList equipment={equipment.data} allowEdit={true} />}
    </div>
  );
};

export default Equipment;
