import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  Box,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {Title} from '@ozark/common/components';
import {useLead} from '@ozark/common/hooks';
import {useMemo} from 'react';
import {useHistory, useParams} from 'react-router';
import {Link as RouterLink} from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import {SalesLeadBar} from './Bar';
import {SalesLeadEdit} from './Edit';
import {SalesLeadNotes} from './Notes';
import {SalesLeadApplicationAssociations} from './SalesLeadApplicationAssociations';
import {ScheduledEvents} from './ScheduledEvents/ScheduledEvents';

export const SalesLeadsDetailsPage = (): JSX.Element => {
  const history = useHistory();
  const {leadId} = useParams<{leadId: string}>();
  const {document: lead, set: setLeadData} = useLead(leadId);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('lg'));

  const breadcrumbs = useMemo(() => {
    if (smDown) {
      return [
        <IconButton onClick={() => history.goBack()} size="large">
          <ChevronLeftIcon />
        </IconButton>,
      ];
    }

    const pageBreadcrumbs = [
      <Link component={RouterLink} variant="body1" to={ROUTES.SALES_LEADS}>
        Sales Leads
      </Link>,
    ];

    if (lead.data) {
      pageBreadcrumbs.push(
        <Typography variant="body1">
          {lead.data.firstName ?? ''} {lead.data.lastName ?? ''}{' '}
          {lead.data.businessName && `(${lead.data.businessName})`}
        </Typography>
      );
    }

    return pageBreadcrumbs;
  }, [history, lead.data, smDown]);

  return (
    <Box
      sx={{
        height: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Title breadcrumbs={breadcrumbs} />

      {lead.promised || !lead.data ? (
        <Box textAlign="center" p={2}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Fade in>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SalesLeadBar lead={lead.data} setLeadData={setLeadData} />
            </Grid>
            <Grid item xl={6}>
              <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <SalesLeadEdit lead={lead.data} setLeadData={setLeadData} />
                <ScheduledEvents />
              </Box>
            </Grid>
            <Grid item xl={6} sx={{width: '100%'}}>
              <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <SalesLeadApplicationAssociations leadId={leadId} />
                <SalesLeadNotes leadId={leadId} />
              </Box>
            </Grid>
          </Grid>
        </Fade>
      )}
    </Box>
  );
};
