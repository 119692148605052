import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {useRatePrograms} from '@ozark/common';
import {Loading} from '@ozark/common/components';
import React from 'react';
import {RateProgramAccordion} from './RateProgramAccordion';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
  })
);

export const RatePrograms = () => {
  const classes = useStyles();

  const {ratePrograms} = useRatePrograms();

  const [whichExpanded, setWhichExpanded] = React.useState<string | false>(false);

  const handleExpandChange =
    (panel: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setWhichExpanded(isExpanded ? panel : false);
    };

  const getRateProgramsAsArray = () =>
    !ratePrograms.promised && ratePrograms.data ? ratePrograms.data : [];

  if (ratePrograms.promised) return <Loading />;

  return (
    <div className={classes.root}>
      {getRateProgramsAsArray().map(rateProgram => {
        return (
          <RateProgramAccordion
            key={rateProgram.id}
            onChange={handleExpandChange}
            whichExpanded={whichExpanded}
            rateProgram={rateProgram}
            onSubmit={() => setWhichExpanded(false)}
          />
        );
      })}
    </div>
  );
};
