import {Agent, AsyncState, useAgents, ViewBase} from '@ozark/common';
import {useEffect, useState} from 'react';

export type AgentResponsibilityCode = {
  agentFirstName: string;
  agentLastName: string;
  agentCode: string;
  agentAndSubAgentCode: string;
};

export const useAgentResponsibilityCode = () => {
  const [agentResponsibilityCode, setAgentResponsibilityCode] = useState<
    AsyncState<AgentResponsibilityCode[]>
  >({promised: true});
  const {documents: agents} = useAgents();

  const getFilteredAgents = () => {
    return agents
      .data!.filter(
        (agent: Agent) => agent.deleted !== true && agent.isActive === true && agent.agentCode
      )
      .sort((a, b) => (a.firstName > b.firstName ? 1 : -1));
  };

  useEffect(() => {
    if (!agents.data) {
      return agents.data;
    }

    const activeAgents = getFilteredAgents();

    const agentCodes = activeAgents.map((agent: ViewBase<Agent>, idx: number) => {
      let agentResponsibilityCode = agent.agentCode;
      if (agent.masterUid) {
        agentResponsibilityCode = `${agent.agentCode}-${agent.subAgentCode ?? 'No-SubAgent-Code'}`;
      }
      return {
        agentFirstName: agent.firstName,
        agentLastName: agent.lastName,
        agentCode: agent.agentCode,
        agentAndSubAgentCode: agentResponsibilityCode,
      } as AgentResponsibilityCode;
    });

    setAgentResponsibilityCode({promised: false, data: agentCodes});
  }, [agents]);

  return agentResponsibilityCode;
};
