import {Grid} from '@mui/material';
import {ApplicationView, isLocked} from '@ozark/common';
import {useState} from 'react';
import {useStore} from '../../store/helpers';
import {Bar} from './Elements/Bar';
import {RiskSection} from './RiskSection/RiskSection';
export const RiskDetails = ({application}: {application: ApplicationView}) => {
  const {authProfile} = useStore();
  const [loading, setLoading] = useState(false);
  const locked = authProfile.data
    ? isLocked(authProfile.data?.role, application.disposition)
    : true;

  return (
    <>
      <Grid container spacing={2} direction="row" alignItems="stretch">
        <Grid item xs={12}>
          <Bar
            application={application}
            locked={locked}
            loading={loading}
            setLoading={setLoading}
            showRiskAssignee
          />
        </Grid>
        <Grid item xs={12}>
          <RiskSection application={application} />
        </Grid>
      </Grid>
    </>
  );
};
