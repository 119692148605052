import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  Avatar,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ApplicationView, Dispositions, getColor} from '@ozark/common';
import {sortBy} from '@s-libs/micro-dash';
import {format} from 'date-fns';
import {Fragment} from 'react';
import {useStore} from '../../../store/helpers';

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      width: '100%',
    },
    noResults: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

type DispositionHistoryListProps = {
  application: ApplicationView;
};

export const DispositionHistoryList = ({application}: DispositionHistoryListProps) => {
  const classes = useStyles();
  const {profiles} = useStore();
  const history = application.dispositionHistory || [];
  return (
    <Fragment>
      {history.length > 0 ? (
        <List className={classes.list} dense>
          {sortBy(history, (e: any) => e.updatedAt.toDate().seconds)
            .reverse()
            .map((e: any, index) => {
              const profile = profiles.dictionary[e.uid];
              return (
                <ListItem key={`disposition-log-${application.id}-${index}`}>
                  <ListItemIcon>
                    {history.length === index + 1 ? (
                      <FiberManualRecordIcon
                        style={{fill: getColor(e.disposition || Dispositions.incomplete)}}
                      />
                    ) : (
                      <ArrowUpwardIcon
                        style={{fill: getColor(e.disposition || Dispositions.incomplete)}}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={`${e.disposition}`}
                    secondary={`${format(e.updatedAt?.toDate?.(), 'MM/dd/yyyy h:mm a')}`}
                  />
                  <ListItemSecondaryAction>
                    <Chip
                      avatar={
                        profile ? (
                          <Avatar alt={profile.displayName} src={profile.photoUrl}></Avatar>
                        ) : (
                          <Avatar>?</Avatar>
                        )
                      }
                      label={profile ? profile.displayName : 'Unassigned'}
                      variant="outlined"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
        </List>
      ) : (
        <div className={classes.noResults}>
          <Typography>No Previous Disposition History</Typography>
        </div>
      )}
    </Fragment>
  );
};
