import {yupResolver} from '@hookform/resolvers/yup';
import {LoadingButton} from '@mui/lab';
import {Box, Container, CssBaseline, Link, Paper, TextField} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Collections, Firebase, MFASettings, MFASettingsDocId, UserRoles} from '@ozark/common';
import {Copyright} from '@ozark/common/components';
import Background from '@ozark/common/static/images/background.png';
import {useMemo, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {Redirect, useHistory} from 'react-router';
import * as yup from 'yup';
import {DEFAULT_GROUP} from '../../constants/group';
import * as ROUTES from '../../constants/routes';
import {MFA_AUTH} from '../../constants/routes';
import {useOnLogin, useStore} from '../../store/helpers';

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    margin: theme.spacing(2),
    width: 300,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  background: {
    backgroundImage: `url(${Background})`,
    height: '100vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type FormData = {email: string; password: string};

const Login = () => {
  const {mfaLogin, login, isUserLoggingIn, authUser, authProfile} = useStore();
  const history = useHistory();

  const returnTo = useMemo(() => {
    const query = new URLSearchParams(history.location.search ?? '');
    return query.get('returnTo');
  }, [history.location.search]);

  useOnLogin(() => {
    history.push(returnTo ?? ROUTES.DASHBOARD);
  });

  const classes = useStyles();

  const [error, setError] = useState('');

  const {
    handleSubmit,
    register,
    formState: {errors},
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  const handleLogin: SubmitHandler<FormData> = async data => {
    try {
      const settingSnap = await Firebase.firestore
        .collection(Collections.settings)
        .doc(MFASettingsDocId)
        .get();

      const setting = settingSnap.data() as MFASettings;
      if (!setting.isEnabledForERPUsers) {
        await login(data.email, data.password);
      } else {
        const result = await mfaLogin(data.email, data.password);
        history.push(MFA_AUTH, {
          email: result.email,
          phone: result.phone,
          token: result.token,
          returnTo: returnTo,
        });
      }
    } catch (err) {
      console.error('Failed to login user by email and password with an error:', err);
      setError('There was an error logging in.');
    }
  };

  if (!authUser.promised && authUser.data?.uid && !authProfile.promised) {
    if (authProfile.data?.role === UserRoles.auditor) {
      return <Redirect to={ROUTES.AUDIT} />;
    } else if (authProfile.data?.role === UserRoles.sales) {
      return <Redirect to={ROUTES.APPLICATIONS} />;
    }
    return <Redirect to={returnTo ?? '/'} />;
  }

  return (
    <div className={classes.background}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />

        <Paper className={classes.paper}>
          <img alt="Sign in" className={classes.logo} src={DEFAULT_GROUP.logoUrl} />

          <form className={classes.form} noValidate onSubmit={handleSubmit(handleLogin)}>
            <TextField
              {...register('email')}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              data-test="email"
              autoComplete="email"
              error={Boolean(error) || Boolean(errors.email)}
              helperText={error || errors.email?.message}
              autoFocus
            />

            <TextField
              {...register('password')}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              id="password"
              data-test="password"
              autoComplete="current-password"
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
            />
            <LoadingButton
              loading={isUserLoggingIn}
              type="submit"
              data-test="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </LoadingButton>
          </form>

          <Box mt={1}>
            <Link href={ROUTES.FORGOT_PASSWORD} variant="body2">
              Forgot password?
            </Link>
          </Box>

          <Box mt={8}>
            <Copyright groupName={DEFAULT_GROUP.name} groupDomain={DEFAULT_GROUP.portalDomain} />
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default Login;
