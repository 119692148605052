import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import {format} from 'date-fns';
import React from 'react';
import {useWeekDatesContainer} from '../hooks/useWeekDatesContainer';
import {SelectWeekRangeCal} from './SelectWeekRangeCal';

export function SelectWeekRange() {
  const {weekDates, setWeekDates} = useWeekDatesContainer();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getWeekDisplayName = () => {
    if (!weekDates?.length) return 'n/a';
    const weekStart = weekDates[0];
    const weekEnd = weekDates.slice(-1)[0];
    return `${weekStart.getDate()}-${weekEnd.getDate()} ${format(
      weekStart,
      'MMMM'
    )}, ${weekStart.getFullYear()}`;
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon fontSize="medium" />}
        sx={{
          textTransform: 'none',
        }}
      >
        {getWeekDisplayName()}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <SelectWeekRangeCal datesSelected={weekDates} onDatesSelected={setWeekDates} />
      </Menu>
    </div>
  );
}
