import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import GroupIcon from '@mui/icons-material/Group';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ApplicationSubCollection, useApplicationView} from '@ozark/common';
import {
  Authorizations,
  Batches,
  ChargebacksSummaryByMid,
  DepositsCpyDays,
  Loading,
  StatementsPage,
  Tab,
  Tabs,
  Transactions,
} from '@ozark/common/components';
import {TicketsFiltersContainer} from '@ozark/common/components/Tickets/hooks/useTicketsFiltersContainer';
import {TicketsListViewMerchant} from '@ozark/common/components/Tickets/TicketsList/TicketsListViewMerchant';
import {Title} from '@ozark/common/components/Title';
import {MerchantPortfolioShortInfo} from '@ozark/common/types/MerchantInfo';
import {format} from 'date-fns';
import React, {FC, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';
import {ApplicationAttachments} from '../Application/Attachments';
import {ApplicationNotes} from '../Application/Notes/Notes';
import {Applications} from './Elements';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '100%',
    },
    paper: {
      height: '100%',
      padding: theme.spacing(1, 2, 1),
      color: '#4d6575',
    },
    title: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      height: 48,
      alignItems: 'center',
      margin: theme.spacing(0, 0, 0.5),
      '& > *': {
        margin: theme.spacing(0, 1),
      },
    },
    titleText: {
      flex: 1,
    },
    list: {
      padding: theme.spacing(0.5, 0, 0),
    },
    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
);

enum MerchantPortfolioTab {
  profile = 'profile',
  applications = 'applications',
  authorizations = 'authorizations',
  transactions = 'transactions',
  batches = 'batches',
  deposits = 'deposits',
  statements = 'statements',
  chargebacks = 'chargebacks',
  attachments = 'attachments',
  uwRiskNotes = 'uw-risk-notes',
  tickets = 'ticket',
}

const MerchantPortfolioDetails: FC<React.PropsWithChildren<unknown>> = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('lg'));
  const {getAuthorizedMerchantPortfolio} = useStore();
  const {id, tabValue} = useParams<{id: string; tabValue?: MerchantPortfolioTab}>();
  const {application} = useApplicationView(id);
  const [tab, setTab] = useState<MerchantPortfolioTab | null>(null);
  const [merchant, setMerchant] = useState<MerchantPortfolioShortInfo | null>(null);

  useEffect(() => {
    getAuthorizedMerchantPortfolio(id).then(setMerchant);
  }, [getAuthorizedMerchantPortfolio, id]);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: MerchantPortfolioTab) => {
    setTab(newTab);
    history.push(`${ROUTES.MERCHANTS_PORTFOLIO}/${id}${newTab ? '/' + newTab : ''}`);
  };

  useEffect(() => {
    if (!tabValue) {
      return;
    }

    setTab(tabValue);
  }, [tabValue]);

  const displayCreatedDate = () =>
    merchant!.createdAt ? format(merchant!.createdAt.toDate(), 'MM/dd/yyyy h:mm a') : 'N/A';

  const displayAccountStatus = () => merchant!.accountStatus?.status;

  const route = (path?: string) =>
    `${ROUTES.MERCHANTS_PORTFOLIO}/${merchant?.id}${path ? '/' + path : ''}`;

  if (!merchant) return <Loading />;

  return (
    <div className={classes.root}>
      <Title
        breadcrumbs={
          smDown
            ? [
                <IconButton onClick={() => history.goBack()} size="large">
                  <ChevronLeftIcon />
                </IconButton>,
              ]
            : [
                <Link
                  component="button"
                  variant="body1"
                  onClick={() => history.push(ROUTES.MERCHANTS_PORTFOLIO)}
                >
                  Merchants
                </Link>,
                <Typography variant="body1">
                  {merchant.firstName ? application.data?.doingBusinessAs : 'No Name'}
                </Typography>,
              ]
        }
      >
        <Tabs value={tab} onChange={handleTabChange} centered>
          <Tab
            value={null}
            label="Profile"
            selected={!tab || tab === MerchantPortfolioTab.profile}
          />
          <Tab value={MerchantPortfolioTab.applications} label="Applications" />
          <Tab value={MerchantPortfolioTab.authorizations} label="Authorizations" />
          <Tab value={MerchantPortfolioTab.transactions} label="Transactions" />
          <Tab value={MerchantPortfolioTab.batches} label="Batches" />
          <Tab value={MerchantPortfolioTab.deposits} label="Deposits" />
          <Tab value={MerchantPortfolioTab.statements} label="Statements" />
          <Tab value={MerchantPortfolioTab.chargebacks} label="Disputes" />
          <Tab value={MerchantPortfolioTab.attachments} label="Attachments" />
          <Tab value={MerchantPortfolioTab.uwRiskNotes} label="UW & Risk Notes" />
          <Tab value={MerchantPortfolioTab.tickets} label="Tickets" />
        </Tabs>
      </Title>

      {(!tab || tab === MerchantPortfolioTab.profile) && (
        <Grid container spacing={2} direction="row" alignItems="stretch">
          <Grid item xs={12} md={9}>
            <Paper className={classes.paper}>
              <Typography className={classes.title} variant="body1" component="div" noWrap>
                <GroupIcon />
                <b className={classes.titleText}>Contact Information</b>
              </Typography>
              <Divider />
              <List className={classes.list}>
                <ListItem>
                  <ListItemText
                    className={classes.text}
                    primary={merchant.firstName}
                    secondary="First Name"
                  />
                  <ListItemText
                    className={classes.text}
                    primary={merchant.lastName}
                    secondary="Last Name"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    className={classes.text}
                    primary={merchant.customerServiceEmail ?? merchant.email}
                    secondary="Email"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    className={classes.text}
                    primary={merchant.phoneNumber}
                    secondary="Phone Number"
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={3}>
            <Paper className={classes.paper}>
              <Typography className={classes.title} variant="body1" component="div" noWrap>
                <GroupIcon />
                <b className={classes.titleText}>Activity Status</b>
              </Typography>
              <Divider />
              <List className={classes.list}>
                <ListItem>
                  <ListItemText
                    className={classes.text}
                    primary={displayCreatedDate()}
                    secondary="Date Created"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    className="classes.text"
                    primary={displayAccountStatus()}
                    secondary="Account Status"
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
      )}

      {tab === MerchantPortfolioTab.applications && (
        <Applications midOptionPreselected={merchant.mid} />
      )}

      {tab === MerchantPortfolioTab.transactions && (
        <Transactions midOptionPreselected={merchant.mid} />
      )}

      {tab === MerchantPortfolioTab.batches && (
        <Batches midOptionPreselected={merchant.mid} transactionRouteUrl={route('transactions')} />
      )}

      {tab === MerchantPortfolioTab.authorizations && (
        <Authorizations midOptionPreselected={merchant.mid} />
      )}

      {tab === MerchantPortfolioTab.statements && <StatementsPage />}

      {tab === MerchantPortfolioTab.deposits && <DepositsCpyDays />}

      {tab === MerchantPortfolioTab.chargebacks && merchant.mid && (
        <ChargebacksSummaryByMid
          midOptionPreselected={merchant.mid}
          chargebackDetailsUrl={ROUTES.CHARGEBACKS_DETAILS}
        />
      )}

      {tab === MerchantPortfolioTab.attachments && application.data && (
        <ApplicationAttachments application={application.data} />
      )}

      {tab === MerchantPortfolioTab.uwRiskNotes && application.data && (
        <ApplicationNotes
          applicationId={id}
          notesSubCollection={ApplicationSubCollection.uwRiskNotes}
        />
      )}

      {tab === MerchantPortfolioTab.tickets && merchant.mid && (
        <TicketsFiltersContainer.Provider>
          <Box display="flex" my={2} height="100%" flex={1} position="relative">
            <Box display="flex" width="100%" flexDirection="column">
              <TicketsListViewMerchant mid={merchant.mid.mid} />
            </Box>
          </Box>
        </TicketsFiltersContainer.Provider>
      )}
    </div>
  );
};

export default MerchantPortfolioDetails;
