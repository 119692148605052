import {Loading} from '@ozark/common/components';
import {MerchantsList} from '@ozark/common/components/Merchants';
import {useStore} from '../../store/helpers';

const Merchants = () => {
  const {authProfile} = useStore();

  if (authProfile.promised) {
    return <Loading />;
  }

  return <MerchantsList />;
};

export default Merchants;
