import EditIcon from '@mui/icons-material/Edit';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link} from '@mui/material';
import {generatePath} from 'react-router';
import {Link as RouterLink} from 'react-router-dom';
import {RISK_OVERVIEW_APPLICATION_DETAILS} from '../../constants/routes';
import {useRiskModuleIsReadOnly} from '../Application/RiskSection/useRiskSection';
import {AssociatedApplications} from './AssociatedApplications';

type Props = {
  applicationId?: string;
  onClose: () => void;
};

export const AssociatedApplicationsModal = ({applicationId, onClose}: Props): JSX.Element => {
  const readOnly = useRiskModuleIsReadOnly();

  return (
    <Dialog open={!!applicationId} onClose={onClose}>
      <DialogTitle>
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <span>Associated MIDs</span>
          {applicationId && !readOnly && (
            <Link
              title="Edit associated MIDs"
              to={generatePath(RISK_OVERVIEW_APPLICATION_DETAILS, {id: applicationId})}
              component={RouterLink}
              sx={{display: 'flex'}}
            >
              <EditIcon />
            </Link>
          )}
        </Box>
      </DialogTitle>
      <DialogContent sx={{width: 500}}>
        <AssociatedApplications applicationId={applicationId!} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
