import {Box} from '@mui/material';
import {Route, Switch} from 'react-router-dom';
import {Breadcrumbs, BreadcrumbsContainer} from '../../common/Breadcrumbs';
import {TabsRouter} from '../../Tabs/TabsRouter';
import {FraudAnalysisAuthDeclines} from '../FraudAnalysisAuthDeclines';
import {FraudAnalysisBatchMonitoring} from '../FraudAnalysisBatchMonitoring';
import {FraudAnalysisLargeCb} from '../FraudAnalysisLargeCb';
import {FraudAnalysisSameBin} from '../FraudAnalysisSameBin';
import {FraudAnalysisSwipeVsKeyed} from '../FraudAnalysisSwipeVsKeyed';
import {FraudAnalysisUnmatchedRefunds} from '../FraudAnalysisUnmatchedRefunds';
import {FraudAnalysisVDMP} from '../FraudAnalysisVDMP';
import * as FRAUD_ROUTES from './routes';

const fraudTabs = [
  {label: 'VDMP/CB', href: FRAUD_ROUTES.FRAUD_VDMP, component: FraudAnalysisVDMP, exact: true},
  {
    label: 'Auth Declines',
    href: FRAUD_ROUTES.FRAUD_AUTH_DECLINES,
    component: FraudAnalysisAuthDeclines,
  },
  {label: 'Same BIN', href: FRAUD_ROUTES.FRAUD_SAME_BIN, component: FraudAnalysisSameBin},
  {
    label: 'Swipe vs Keyed',
    href: FRAUD_ROUTES.FRAUD_SWIPE_VS_KEYED,
    component: FraudAnalysisSwipeVsKeyed,
  },
  {
    label: 'Unmatched Refunds',
    href: FRAUD_ROUTES.FRAUD_UNMATCHED_REFUNDS,
    component: FraudAnalysisUnmatchedRefunds,
  },
  {
    label: 'Batch Monitoring',
    href: FRAUD_ROUTES.FRAUD_BATCH_MONITORING,
    component: FraudAnalysisBatchMonitoring,
  },
  {label: 'Large CB', href: FRAUD_ROUTES.FRAUD_LARGE_CB, component: FraudAnalysisLargeCb},
];
export const FraudAnalysis = () => {
  return (
    <Box height="100%" display="flex" flexDirection="column">
      <BreadcrumbsContainer.Provider
        initialState={[{text: 'Fraud Analysis', url: '/fraud-analysis'}]}
      >
        <Breadcrumbs>
          <Box flex={1} />
          <Box mb="-18px">
            <TabsRouter tabs={fraudTabs} />
          </Box>
        </Breadcrumbs>
        <Switch>
          {fraudTabs.map(tab => (
            <Route key={tab.href} path={tab.href} component={tab.component} exact={tab.exact} />
          ))}
        </Switch>
      </BreadcrumbsContainer.Provider>
    </Box>
  );
};
